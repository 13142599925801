import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { Button, Collapse, Menu, MenuItem } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Build, DeleteOutline, ExpandLess, ExpandMore, SearchOutlined } from '@material-ui/icons';
import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import LastPageIcon from '@material-ui/icons/LastPage';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import * as VarOpportunityDecoder from '../../codec/varOpportunityInDTODecoder';
import SendIcon from '@material-ui/icons/Send';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { Stack } from '@mui/system';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import * as React from "react";
import { VarOpportunityStatusOptions } from '../../codec/varOpportunityInDTODecoder';
import { IVarOpportunityInDTO } from '../../repository/jago/model/input/IVarOpportunityInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import translations from '../../translations/i18next';
import Permission from '../Permission/Permission';
import ReduxLanguage from '../ReduxLanguage';
import { getOpportunitiesStatusColor } from '../../utils/Utils';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  tableCellStyle: {
    padding: '12px',
    borderBottom: '1px solid #ddd',
  },
});


export interface IVarOpportunitiesTableState {
  anchorElActions: any;
  selectedOpportunity: IVarOpportunityInDTO | null;
  actionsStatusAdminOpen: boolean;
}

export interface IVarOpportunitiesTableProps extends WithStyles<typeof styles>{
  opportunities: PaginatedDataDTO<IVarOpportunityInDTO>;
  rowsPerPage: number;
  onRowClick: (customer: IVarOpportunityInDTO) => void;
  onFetchElementsOnPage: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onDeleteUserRequest?: (user: IVarOpportunityInDTO) => void;
  onDeleteVarOpportunityRequest: (opportunity: IVarOpportunityInDTO) => void;
  onRejectVarOpportunityRequest: (opportunity: IVarOpportunityInDTO) => void;
  onApproveVarOpportunityRequest: (opportunity: IVarOpportunityInDTO) => void;
  onUpgradeVarOpportunityRequest: (opportunity: IVarOpportunityInDTO) => void;
  onWinVarOpportunityRequest: (opportunity: IVarOpportunityInDTO) => void;
  onLoseVarOpportunityRequest: (opportunity: IVarOpportunityInDTO) => void;
  onEditStatusAdmin?: (opportunity: IVarOpportunityInDTO, newStatus: string) => void;
  tableColumns?: GridColumns<IVarOpportunityInDTO>;
  hideVarColumn?: boolean;
  hideCustomerColumn?: boolean;
  isLoading?:boolean;
}

class VarOpportunitiesTable extends React.Component<IVarOpportunitiesTableProps, IVarOpportunitiesTableState> {
  private defaultColumns: GridColumns<IVarOpportunityInDTO>;

  constructor(props: IVarOpportunitiesTableProps) {
    super(props);
    this.state = {
      selectedOpportunity: null,
      anchorElActions: null,
      actionsStatusAdminOpen: false
    }
  }

  public openActionsMenu(event, opportunity) {
    this.setState({ selectedOpportunity: opportunity});
    this.setState({ anchorElActions: event.currentTarget });
  }

  public closeActionsMenu() {
    this.setState({ selectedOpportunity: null });
    this.setState({ anchorElActions: null });
  };

  public toggleActionsStatusAdminOpen() {
    this.setState({ actionsStatusAdminOpen: !this.state.actionsStatusAdminOpen });
  }

  public render() {
    const { opportunities, rowsPerPage, onRowClick, onPageSizeChange, onEditStatusAdmin, onFetchElementsOnPage, classes, tableColumns, onUpgradeVarOpportunityRequest, onApproveVarOpportunityRequest, onRejectVarOpportunityRequest, onWinVarOpportunityRequest, onLoseVarOpportunityRequest, onDeleteVarOpportunityRequest, hideVarColumn, hideCustomerColumn } = this.props;
    const { anchorElActions, selectedOpportunity, actionsStatusAdminOpen } = this.state;

    this.defaultColumns = [
      {
        field: 'id',
        headerName: translations.t('vars.opportunityID'),
        type: 'string',
        sortable: false,
        width: 50,
      },
      {
        field: 'optyId',
        headerName: translations.t('vars.opportunityOpty'),
        type: 'string',
        width: 120,
        sortable: false,
        resizable: true,
      },
      {
        field: 'name',
        headerName: translations.t('vars.opportunityName'),
        type: 'string',
        sortable: false,
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'varName',
        headerName: translations.t('vars.opportunityVar'),
        type: 'string',
        flex: 1,
        minWidth: 120,
        sortable: false,
        resizable: true,
      },
      {
        field: 'customerName',
        headerName: translations.t('vars.opportunityCustomer'),
        type: 'string',
        flex: 1,
        minWidth: 150,
        sortable: false,
        resizable: true,
      },
      {
        field: 'value',
        valueGetter: (params) => `${params.row?.value} ${params.row?.currency}`,
        headerName: translations.t('vars.opportunityValue'),
        type: 'string',
        sortable: false,
        width: 100,
      },
      {
        field: 'status',
        headerName: translations.t('vars.opportunityStatus'),
        type: 'string',
        sortable: false,
        width: 120,
        renderCell: (params: GridRenderCellParams) => {
          const status = params.row.status;
          return (
            <div>
              <h5 style={{ color: getOpportunitiesStatusColor(status), fontWeight: 'bold', whiteSpace: 'normal' }}>{translations.t(`vars.opportunityStatus_${status}`).toUpperCase()}</h5>
            </div>
          )
          
        }
      },
    ];

    let finalTableColumns = tableColumns ? tableColumns: this.defaultColumns;

    finalTableColumns.push({
      field: 'actions',
      headerName: translations.t('forms.actions'),
      renderCell: (params: any) => {
        const opportunity = params.row;
        return (
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_UPDATE}>
            {opportunity.status !== VarOpportunityStatusOptions.REJECTED ? (
              <td className={classes.tableCellStyle} style={{ textAlign:'center' }}>
                <Button
                  variant="contained"
                  style={{backgroundColor:'#282c34', flexShrink:0}}
                  className={classes.actionButton}
                  onClick={(e) => {e.stopPropagation(); this.openActionsMenu(e,opportunity)}}
                >
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.actions" /></h5>
                  {Boolean(anchorElActions) && selectedOpportunity && selectedOpportunity.id === opportunity.id ? 
                    <ExpandLess className={classes.icon}/> : <ExpandMore className={classes.icon}/>
                  }
                </Button>
              </td>
            ) : null}
          </Permission>
        )
      },
      type: 'string',
      sortable: false,
      minWidth: 150,
    });

    finalTableColumns = hideCustomerColumn ? _.filter(finalTableColumns, column => column.field !== 'customerName') : finalTableColumns;
    finalTableColumns = hideVarColumn ? _.filter(finalTableColumns, column => column.field !== 'varName') : finalTableColumns;

    const isAdmin = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN]);

    const canEditOpportunity = AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.OPPORTUNITY_UPDATE);


    return (
      <div style={{ width: '100%'}}>
        <DataGrid
          getRowId={(d) => d.id}
          rows={opportunities.content || []}
          columns={finalTableColumns}
          rowHeight={55}
          onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
          onPageChange={page => onFetchElementsOnPage(page)}
          page={(opportunities?.pagination as PaginationDTO)?.number}
          rowCount={(opportunities?.pagination as PaginationDTO)?.totalElements}
          pageSize={rowsPerPage}
          onRowClick={row => onRowClick(row.row)}
          paginationMode="server"
          loading={this.props.isLoading}
          componentsProps={{
            pagination: {
              labelRowsPerPage: translations.t('forms.rowsPerPage')
            }
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <SearchOutlined style={{ alignSelf: 'center' }} />
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='vars.noOpportunityFindTitle' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='vars.noOpportunityFindDescription' /></h4>
                </div>
              </Stack>
            ),
            
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='vars.noOpportunityFindTitle' /></h3>
                <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='vars.noOpportunityFindDescription' /></h4>
              </Stack>
            )
          }}
        />
        {selectedOpportunity && canEditOpportunity ? (
          <Menu
            id="simple-menu"
            anchorEl={anchorElActions}
            keepMounted
            open={Boolean(anchorElActions)}
            onClose={(e)=>{this.closeActionsMenu()}}
          >
            {selectedOpportunity.status === VarOpportunityStatusOptions.DRAFT ? (
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_MARK_TO_BE_APPROVED}>
                <MenuItem onClick={(e)=>{e.stopPropagation(); this.closeActionsMenu(); onUpgradeVarOpportunityRequest(selectedOpportunity) }}>
                  <SendIcon className={classes.icon} color="primary"/>
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="vars.upgradeOpportunity" /></h5>
                </MenuItem>
              </Permission>
            ):(null)}
            {selectedOpportunity.status === VarOpportunityStatusOptions.ON_APPROVAL ? (
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_APPROVE}>
                <MenuItem onClick={(e)=>{ e.stopPropagation(); this.closeActionsMenu(); onApproveVarOpportunityRequest(selectedOpportunity) }}>
                  <DoneOutlineIcon className={classes.icon} color="primary"/>
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="vars.approveOpportunity" /></h5>
                </MenuItem>
              </Permission>
            ):(null)}
            {selectedOpportunity.status === VarOpportunityStatusOptions.ON_APPROVAL ? (
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_REJECT}>
                <MenuItem onClick={(e)=>{ e.stopPropagation(); this.closeActionsMenu(); onRejectVarOpportunityRequest(selectedOpportunity) }}>
                  <ThumbDownIcon className={classes.icon} color="primary"/>
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="vars.rejectOpportunity" /></h5>
                </MenuItem>
              </Permission>
            ):(null)}
            {selectedOpportunity.status === VarOpportunityStatusOptions.APPROVED ? (
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_MARK_WON}>
                <MenuItem onClick={(e)=>{ e.stopPropagation(); this.closeActionsMenu(); onWinVarOpportunityRequest(selectedOpportunity) }}>
                  <CheckBoxOutlinedIcon className={classes.icon} color="primary"/>
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="vars.wonOpportunity" /></h5>
                </MenuItem>
              </Permission>
            ):(null)}
            {selectedOpportunity.status === VarOpportunityStatusOptions.APPROVED ? (
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_MARK_LOST}>
                <MenuItem onClick={(e)=>{ e.stopPropagation(); this.closeActionsMenu(); onLoseVarOpportunityRequest(selectedOpportunity) }}>
                  <CancelPresentationOutlinedIcon className={classes.icon} color="primary"/>
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="vars.lostOpportunity" /></h5>
                </MenuItem>
              </Permission>
            ):(null)}
            {isAdmin || selectedOpportunity.status === VarOpportunityStatusOptions.DRAFT||selectedOpportunity.status === VarOpportunityStatusOptions.ON_APPROVAL||
              selectedOpportunity.status === VarOpportunityStatusOptions.APPROVED ? (
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_CANCEL}>
                <MenuItem onClick={(e)=>{ e.stopPropagation(); this.closeActionsMenu(); onDeleteVarOpportunityRequest(selectedOpportunity) }}>
                  <DeleteOutline className={classes.icon} style={{ color: 'darkred' }}/>
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.delete" /></h5>
                </MenuItem>
              </Permission>
            ):null}
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SOFIA_ADMIN]}>
              <MenuItem onClick={()=> this.toggleActionsStatusAdminOpen()}>
                <Build className={classes.icon} color="secondary"/>
                <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.changeStatusAdmin" /></h5>
                {actionsStatusAdminOpen?<ExpandLess />:<ExpandMore />}
              </MenuItem>
              <Collapse in={actionsStatusAdminOpen} timeout="auto" unmountOnExit>
                {_.map(VarOpportunityDecoder.VarOpportunityStatusOptions, status => (
                  <MenuItem key={status} onClick={()=>{ this.closeActionsMenu(); this.toggleActionsStatusAdminOpen(); onEditStatusAdmin(selectedOpportunity, status) }}>
                    <LastPageIcon className={classes.icon} style={{ color: 'black' }}/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={`vars.opportunityStatus_${status}`} /></h5>
                  </MenuItem>
                ))}
              </Collapse>
          </Permission>
          </Menu>
        ):null}
      </div>
    );
  }
}

export default withStyles(styles)(VarOpportunitiesTable);