import _ from 'lodash';
import { ISubscriptionInDTO } from '../repository/jago/model/input/ISubscriptionInDTO';
import { IWalletSubscriptionInDTO } from '../repository/jago/model/input/IWalletSubscriptionInDTO';
import { Roles } from '../services/PermissionService/PermissionConstants';
import { getDefaultAPIBaseURL } from '../repository/utils';
import { RoleEntities } from '../services/PermissionService/PermissionsParser';

export default class Utils {
  public static getAvailableISEOCompanies(): ISEOCompanyInfo[] {
    const markets  = [
      {name: "ISEO Serrature", country: "Italy"},
      {name: "ISEO France", country: "France"},
      {name: "ISEO Deutschland", country: "Germany"},
      {name: "ISEO Ibérica", country: "Spain"},
      {name: "ISEO Middle East", country: "U.A.E"},
      {name: "ISEO Asia Pacific", country: "Malaysia"},
      {name: "ISEO (Beijing) Security Technology", country: "P.R.C"},
      {name: "Iseo South Africa", country: "South Africa"},
      {name: "Feroneria Prod", country: "Romania"},
      {name: "Sofia Locks", country: "Italy"},
      {name: "Microhard", country: "Italy"},
      {name: "ISEO Colombia", country: "Colombia"},
      {name: "Iseo Perú", country: "Perú"},
      {name: "Locken", country: "France - Spain - U.K"},
    ];

    return markets;
  }

  public static getAvailableCountries(): CountryInfo[] {
    const countries = [
      "IT-Italy", "AL-Albania", "AM-Armenia", "AZ-Azerbaijan", "BE-Belgium",
      "BG-Bulgaria", "BIH-Bosnia-Erzegovina", "BY-Bielorussia", "CY-Cyprus", "CZ-Czech Republic",
      "DK-Denmark", "EW-Estonia", "FIN-Finland", "FL-Liechtenstein", "GB-United Kingdom",
      "GBM-Isola di Man", "GE-Georgia", "GR-Greece", "HU-Hungary", "HR-Croatia",
      "IL-Israel", "IRL-Ireland", "IS-Iceland", "KAZ-Kazakhstan", "KG-Kirghizistan",
      "KOS-Kosovo", "LU-Luxembourg", "LT-Lithuania", "LV-Latvia", "M-Malta",
      "MK-Macedonia", "MNE-Montenegro", "MOL-Rupublic of Moldavia", "NO-Norway", "NL-Netherlands",
      "PL-Poland", "RL-Lebanon", "RUS-Russia", "SE-Sweden", "SK-Slovakia",
      "SLO-Slovenia", "SRB-Serbia", "TJ-Tajikistan", "TM-Turkmenistan", "TR-Turkey",
      "UA-Ukraine", "UZ-Uzbekistan", "RO-Romania", "AUS-Australia", "BRU-Brunei",
      "BUR-Myanmar", "HK-Hong Kong", "JP-Japan", "KH-Cambodia", "KOR-South Korea",
      "LAO-Laos", "MAC-Macau", "MAL-Malaysia", "MNG-Mongolia", "NZ-New Zealand",
      "PG-New Guinea", "PRC-China", "RC-Taiwan-Formosa", "RI-Indonesia", "RP-Philippines",
      "SGP-Singapore", "TH-Thailand", "VN-Vietnam", "AG-Antigua and Barbuda", "AO-Antille Olandesi",
      "AW-Aruba", "BH-Belize", "BOL-Bolivia", "BR-Brazil", "BS-Bahamas",
      "CU-Cuba", "CDN-Canada", "CO-Colombia", "CR-Costa Rica", "CW-Curaçao",
      "DOM-Dominican Republic", "EC-Ecuador", "ES-El Salvador", "FK-Falkland Islands", "GCA-Guatemala",
      "GS-South Georgia", "GUY-Guyana", "HON-Honduras", "JA-Jamaica", "KY-Cayman",
      "MEX-Mexico", "NIC-Nicaragua", "PA-Republic of Panama", "PE-Peru", "PUE-Puerto Rico",
      "PY-Paraguay", "RA-Argentine", "RCH-Chile", "RH-Haiti", "ROU-Uruguay",
      "SME-Suriname", "TT-Trinidad&Tobago", "TUC-Turks e Caicos Islands", "USA-United States of America", "YV-Venezuela",
      "ADN-Yemen", "AFG-Afghanistan", "AN-Angola", "AV-Alto Volta", "BEN-Benin",
      "BF-Burkina Faso", "BI-Guinea-Bissau", "BRN-Bahrain", "CI-Ivory Coast", "CON-Republic of Congo",
      "DJ-Djibouti", "EAK-Kenya", "EAT-Tanzania", "ERT-Eritrea", "ET-Egypt",
      "ETH-Ethiopia", "GAB-Gabon", "GH-Ghana", "GQ-Guinea Equatoriale", "HKJ-Jordan",
      "IR-Iran", "IRQ-Iraq", "JOR-Cisgiordania", "KWT-Kuwait", "LB-Liberia",
      "LBN-Libano", "MOC-Mozambique", "NCL-New Caledonia", "OMA-Sultanato of Oman", "PK-Pakistan",
      "QAT-Qatar", "RM-Madagascar", "RMM-Republic of Mali", "RU-Burundi", "RWA-Ruanda",
      "SA-Saudi Arabia", "SL-Sierra Leone", "SN-Senegal", "SU-Sudan", "SYR-Syria",
      "TC-Cameroon", "TG-Togo", "UAE-United Arab Emirates", "UG-Uganda", "WAG-Gambia",
      "WAN-Nigeria", "ZM-Zambia", "ZRE-Zaire", "BD-Bangladesh", "CL-Sri Lanka",
      "IND-India", "LAR-Libya", "ZA-South Africa", "NAM-Namibia", "SD-Swaziland",
      "ZW-Zimbabwe", "Dorma ZA", "DE-Deutschland", "AT-Austria", "CH-Switzerland",
      "FR-France", "ISR-Ile de Reunion", "GUA-Guadalupe", "MQ-Martinique", "NC-Nuova Caledonia",
      "GF-French Guinea", "PF-French Polinesia", "CF-central Africa Republic", "IM-Mauritius Islands", "DZ-Algeria",
      "MA-Morocco", "MRT-Mauritania", "TN-Tunisia", "ES-Spain", "GIB-Gibraltar",
      "AND-Andorra", "IC-Canarie"
    ];      
    return countries.map(e=>{return {id:e,value:e}});
   }

  public static getDefaultMarketplace() {
    let defaultMarketplace: string = "ISEO";
    const hostname = window && window.location && window.location.hostname;
    const isStagingSystem = _.includes(hostname, 'staging') || _.includes(hostname, 'localhost');
    if (isStagingSystem)
      defaultMarketplace = "ISEO STORE";
    return defaultMarketplace;
  }
  
  public static activationBaselineSubscriptions: ActivationBaselineSubscription[] = [
    {name: "BASIC", label:"Luckey Basic", skuRule: { SL: "018S" }, hidden: false},
    {name: "ENTERPRISE", label:"Luckey Enterprise", skuRule:{ SL: "001S", IS: "004S" } , hidden: false},
    {name: "ENGINE", label:"Luckey Engine", skuRule:{ SL: "024S" }, hidden: false},
    {name: "ESSENTIAL", label:"Luckey Essential", skuRule:{ SL: "025S" }, hidden: false},
  ]

  public static getBaselineSubscriptionsFromName(subscriptions: ISubscriptionInDTO[], baselineSubscriptionName: string) {
    const baseLineSub = this.activationBaselineSubscriptions.filter(e=>e.name===baselineSubscriptionName);
    if (!baseLineSub.length) {
      return [];
    }
    const skuRule = baseLineSub[0].skuRule;

    return _.filter(subscriptions, s => {
      if (_.startsWith(s.sku, 'IS')) {
        return s.resource === 'DOMAIN' && s.sku.includes(skuRule.IS);
      }
      return s.resource === 'DOMAIN' && s.sku.includes(skuRule.SL);
    });
  }

  public static isUnboundedAddon(subscription: ISubscriptionInDTO) {
    return subscription.resource!=='DOMAIN'&&(subscription.mainTier===null);
  }

  public static isWalletMainTierSubscription(s: IWalletSubscriptionInDTO) {
    return this.isMainTierSubscription(s.subscription);
  }

  public static isMainTierSubscription(s: ISubscriptionInDTO) {
    return s.resource === "DOMAIN";
  }

  public static isPluginSubscription(s: ISubscriptionInDTO) {
    return s.mainTier != null;
  }

  public static formatCreditsIn(credits?: number): number {
    if (!credits) {
      return 0;
    }
    return credits / 100;
  }

  public static formatCreditsOut(credits: number): number {
    return Math.round(credits * 100);
  }

  public static generateSFDCCRMLink(SFDCId: string): SFDCCRMLinks {
    const splittedId = _.split(SFDCId, '-');
    const linkId = _.last(splittedId);
    return {
      SFCDLinkBasic: `https://iseo.force.com/corporate/s/global-search/${linkId}`,
      SFCDLinkPremium: `https://iseo.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?searchType=2&str=${linkId}#`
    };
  }

  public static generateSofiaPDLink(SFDCId: string): string {
    const splittedId = _.split(SFDCId, '-');
    const linkId = _.first(splittedId);
    return `https://sofialocks.pipedrive.com/deal/${linkId}`;
  }

  public static getHardwareType(s: string) {
    switch (s) {
      case 'smartlock':
        return 'SMARTLOCK';
      case 'gateway':
        return 'HYPERGATE PRO';
      case 'atlas':
        return 'ATLAS';
      default:
        return s;
    }
  }

  public static getGatewayVersion(s: string) {
    const version =  s ? _.split(s, 'SW: ')[1] : '---';
    return version || s;
  }
}

export interface ISEOCompanyInfo {
  name: string;
  country: string;
}

export interface CountryInfo {
  id: string;
  value: string;
}

export interface ActivationBaselineSubscription {
  name: string;
  label: string;
  skuRule: {
    SL: string;
    IS?: string;
  };
  hidden: boolean;
}

export interface SFDCCRMLinks {
  SFCDLinkBasic: string;
  SFCDLinkPremium: string;
}


export function getGatewayDeploymentStatusColor(status: string) {
  switch (status) {
    case 'PENDING':
      return 'gray';
    case 'DONE':
      return 'forestgreen';
    case 'IN_PROGRESS':
      return 'darkorange';
    case 'ERROR':
      return 'orangered';
  }
}

export function getWalletStatusColor(status: string) {
  switch (status) {
    case 'PENDING':
      return 'gray';
    case 'ACTIVE':
      return 'forestgreen';
    case 'READY_TO_ACTIVATE':
      return 'darkorange';
  }
}

export function getActivationStatusColor(status: string) {
  switch (status) {
    case 'DRAFT':
      return 'gray';
    case 'UNDER_REVIEW':
      return 'orange';
    case 'REJECTED':
      return 'orangered';
    case 'PROCESSING':
      return 'darkorange';
    case 'CREATED':
      return 'dodgerblue';
    case 'INVOICED':
      return 'forestgreen';
    case 'ACTIVE':
      return 'lightseagreen';
    case 'DELETED':
      return 'orangered';
  }
}

export function getOpportunitiesStatusColor(status: string) {
  switch (status) {
    case 'DRAFT':
      return 'gray';
    case 'ON_APPROVAL':
      return 'darkorange';
    case 'APPROVED':
      return 'lightseagreen';
    case 'CANCELLED':
      return 'cadetblue';
    case 'REJECTED':
      return 'orangered';
    case 'WON':
      return 'forestgreen';
    case 'LOST':
      return 'darkslategrey';
  }
}

export function getRechargeStatusColor(status: string) {
  switch(status) {
    case 'TO_BE_MANAGED':
      return 'gray';
    case 'TAKEN_CHARGE':
      return 'darkorange';
    case 'REQUEST_TO_DELETE':
      return 'orangered';
    case 'SELLOUT_ORDER_GENERATED':
      return 'dodgerblue';
    case 'REJECTED':
      return 'orangered';
    case 'SCRATCHCARD_CREATED':
      return 'cadetblue';
    case 'INVOICED':
      return 'forestgreen';
    case 'WALLET_RECHARGED':
      return 'green';
  }
}

export function decodeUnicode(input) {
  // Regular expression to find characters in the form '&#xxxx'
  const regex = /&#(\d+);/g;

  // Use the replace method with a callback function
  return input.replace(regex, (match, charCode) => {
    // Convert the matched character code to the actual character
    return String.fromCharCode(parseInt(charCode, 10));
  });
}

export interface IVarUserRole {
  id: number;
  name: string;
  label: string;
};

export const VAR_USERS_ROLES: IVarUserRole[] = [
  {
    id: 26,
    name: Roles.ROLE_VASHO_VAR_ADMIN,
    label: "Admin",
  },
  {
    id: 35,
    name: Roles.ROLE_VASHO_VAR_SUPPORT,
    label: "Support",
  },
  {
    id: 36,
    name: Roles.ROLE_VASHO_VAR_SALES_MANAGER,
    label: "Sales Manager",
  },
  {
    id: 37,
    name: Roles.ROLE_VASHO_VAR_SALES_REPRESENTATIVE,
    label: "Sales Representative",
  },
]

export const VAR_USER_ROLES_NAME_MAPPING: any = {
  ROLE_VASHO_SOFIA_ADMIN: 'Admin Sofia',
  ROLE_VASHO_GLOBAL_SEGMENT_LEADER: 'Global Segment Leader',
  ROLE_VASHO_GLOBAL_SALES_OPERATION_MANAGER: 'Global Sales Operation Manager',
  ROLE_VASHO_SALES_OPERATION_MANAGER: 'Sales Operation Manager',
  ROLE_VASHO_MARKET_LEADER: 'Market Leader',
  ROLE_VASHO_EUSM: 'End-user Sales Manager',
  ROLE_VASHO_KAM: 'KAM',
  ROLE_VASHO_GLOBAL_SOC_MANAGER: 'Global SOC Manager',
  ROLE_VASHO_LOCAL_SOC_MANAGER: 'Local SOC Manager',
  ROLE_VASHO_VAR_ADMIN: 'Admin VAR',
  ROLE_VASHO_VAR_SALES_MANAGER: 'VAR Sales Manager',
  ROLE_VASHO_VAR_SALES_REPRESENTATIVE: 'VAR Sales Representative',
  ROLE_VASHO_VAR_SUPPORT: 'VAR Support',
}

export function getRoleReadableName(role) {
  if (VAR_USER_ROLES_NAME_MAPPING[role]) {
    return VAR_USER_ROLES_NAME_MAPPING[role];
  }
  return role;
}

export function groupPermissionsByEntity(permissions) {

  if (!permissions || _.isEmpty(permissions)) return [];
  const groupedPermissions = {};
  const entities = _.keys(RoleEntities);

  // Initialize each entity with an empty array to collect actions
  entities.forEach(entity => {
    groupedPermissions[entity] = [];
  });

  permissions.forEach(permission => {
    // Check each known entity to see if it is a prefix of the permission
    entities.forEach(entity => {
      if (permission.startsWith(entity + "_")) {
        // Extract the action by removing the entity and underscore
        const action = permission.slice(entity.length + 1);
        groupedPermissions[entity].push(action);
      }
    });
  });
  return _.pickBy(groupedPermissions, actions => !_.isEmpty(actions));

}