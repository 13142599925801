import { Button, Modal } from '@material-ui/core';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { MailOutline } from '@material-ui/icons';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import logo from '../assets/logos/logoVashoWhite.png';
import logoSofia from '../assets/logos/logoSodiaIseoWhite.png';
import OTPEmailLoginForm from '../components/Forms/OTPEmailLoginForm';
import OTPOTPLoginForm from '../components/Forms/OTPOTPLoginForm';
import { ModalTypes } from '../components/Modals/ModalTypes';
import ReduxLanguage from '../components/ReduxLanguage';
import * as ModalsActions from '../redux/modals/modals.actions';
import * as SettingsActions from '../redux/settings/settings.actions';
import { IState } from '../redux/store';
import * as UserActions from '../redux/user/user.actions';
import { LocalStorageRepository } from '../services/StorageService/LocalStorageRepository';
import { LanguageTypes } from '../translations/LanguageTypes';
import { detectBrowserLanguage } from '../translations/utils';


const styles = createStyles({
  mainContainer: {
    backgroundColor: '#282c34',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: 'white',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  logo: {
    width: '20%',
    marginBottom: 20,
  },
  loginButtonsContainer: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  otpModalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export type ILoginPageProps = WithStyles<typeof styles> & RouteComponentProps<any> & IReduxProps;

enum OTPLoginPhase {
  START = 'START',
  EMAIL_SENT = 'EMAIL_SENT',
}

interface ILoginPageState {
  otpLoginModalVisible: boolean;
  otpLoginPhase: OTPLoginPhase;
}

export class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {

  constructor(props: ILoginPageProps) {
    super(props);
    this.state = {
      otpLoginModalVisible: false,
      otpLoginPhase: OTPLoginPhase.START,
    };
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    const storage = new LocalStorageRepository();
    let language = storage.readKey<string>('language', undefined);
    if (language===undefined||(language!=LanguageTypes.ENGLISH&&language!=LanguageTypes.ITALIAN)){
      language = detectBrowserLanguage();
      storage.storeKey('language', language);
    }
    dispatch<any>(SettingsActions.saveLanguageSettings(language));
  }

  public async onGoogleLoginSuccess(res: CredentialResponse) {
    const { history, dispatch } = this.props;

    const tokenId = res.credential;
    dispatch<any>(SettingsActions.setSpinnerVisible(true));
    try {
      if (!tokenId) {
        throw new Error();
      }
      await dispatch<any>(UserActions.userJagoLoginWithGoogle(tokenId));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      history.push('/home');
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_MODAL_LOGIN`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.loginJagoGoogleFailedTitle',
          errorMessageKey: 'errors.loginJagoGoogleFailed',
        }
      }));
    }
  }

  async onGoogleLoginFail() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`ERROR_MODAL_LOGIN`, {
      modalType: ModalTypes.ERROR_MODAL,
      modalProps: {
        titleMessageKey: 'errors.loginGoogleFailedTitle',
        errorMessageKey: 'errors.loginGoogleFailed',
      }
    }));
  }

  openOTPLoginModal() {
    this.setState({ otpLoginModalVisible: true });
  }

  closingOTPLoginModal() {
    const { otpLoginPhase } = this.state;
    if (otpLoginPhase===OTPLoginPhase.EMAIL_SENT)
      return
    this.closeOTPLoginModal()
  }

  closeOTPLoginModal() {
    this.setState({ otpLoginModalVisible: false });
  }

  async sendOTPEmail(email: string) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.sendOTPEmail(email));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      this.setState({ otpLoginPhase: OTPLoginPhase.EMAIL_SENT });
    } catch (err) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      this.setState({ otpLoginPhase: OTPLoginPhase.START, otpLoginModalVisible: false });
      dispatch<any>(ModalsActions.showModal(`ERROR_MODAL_LOGIN`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.loginJagoOTPEmailFailedTitle',
          errorMessageKey: 'errors.loginJagoOTPEmailFailed',
        }
      }));
    }
    
  }

  async loginWithOTP(code: string) {
    const { history, dispatch } = this.props;

    dispatch<any>(SettingsActions.setSpinnerVisible(true));
    try {
      await dispatch<any>(UserActions.userJagoLoginWithOTP(code));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      //this.setState({ otpLoginPhase: OTPLoginPhase.START, otpLoginModalVisible: false });
      history.push('/home');
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      //this.setState({ otpLoginPhase: OTPLoginPhase.START, otpLoginModalVisible: false });
      dispatch<any>(ModalsActions.showModal(`ERROR_MODAL_LOGIN`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.loginJagoOTPFailedTitle',
          errorMessageKey: 'errors.loginJagoOTPFailed',
        }
      }));
    }
  }

  async onResetOtpLoginPhase() {
    this.setState({ otpLoginPhase: OTPLoginPhase.START });
  }

  render() {
    const { classes } = this.props
    const { otpLoginModalVisible, otpLoginPhase } = this.state;
    return (
      <div>
        <header className={classes.mainContainer} style={{ position: 'relative', height: '100vh', paddingTop: 0, background: 'linear-gradient(#009688, #025b54)', backgroundSize: 'cover' }}>
          <img src={logo} className={classes.logo} alt="logo" />
          <div className={classes.container}>
            <h6 className={classes.text}><ReduxLanguage languageKey="login.ssoLogin" /></h6>
            <div className={classes.loginButtonsContainer}>     
              <Button
                variant="contained"
                style={{ backgroundColor: 'white', color: 'black', marginTop: 0, fontWeight: 'bold', height: 40  }}
                onClick={() => this.openOTPLoginModal()}
              >
                <MailOutline style={{ marginRight: 10 }} />
                <ReduxLanguage languageKey="login.otpLogin" />
              </Button>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>
                <div style={{ display: 'flex', flex: 1, backgroundColor: 'white', height: 1 }} />
                <h3><ReduxLanguage languageKey="login.or" /></h3>
                <div style={{ display: 'flex', flex: 1, backgroundColor: 'white', height: 1 }} />
              </div>
              <GoogleLogin
                onSuccess={(res) => this.onGoogleLoginSuccess(res)}
                onError={() => this.onGoogleLoginFail()}
                useOneTap
                size="large"
              />
              <Modal
                className={classes.otpModalContainer}
                open={otpLoginModalVisible}
                onClose={() => this.closingOTPLoginModal()}
              >
                <>
                  {otpLoginPhase === OTPLoginPhase.START ?
                    <OTPEmailLoginForm
                      onSubmit={(email) => this.sendOTPEmail(email)}
                    /> :
                    <OTPOTPLoginForm
                      onSubmit={(code) => this.loginWithOTP(code)}
                      onResetOtpLoginPhase={() => this.onResetOtpLoginPhase()}
                    />
                  }
                </>
              </Modal>
            </div>
          </div>
            <img src={logoSofia} style={{ width: 150, position: 'absolute', bottom: 10 }} alt="logo" />
        </header>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(LoginPage)));