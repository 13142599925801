import { Button, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from "react";
import ReduxLanguage from "../ReduxLanguage";

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 15,
  },
  formTitle: {
    marginBottom: 25,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 25,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});

export interface IOTPLoginFormState {
  email?: string;
  emailError: boolean;
};

export interface IOTPLoginFormProps extends WithStyles<typeof styles>{
  onSubmit: (card: string) => void;
}

class OTPEmailLoginForm extends React.Component<IOTPLoginFormProps, IOTPLoginFormState> {

  constructor(props: IOTPLoginFormProps) {
    super(props);
    this.state = {
      email: '',
      emailError: false
    };
  }
  
  public onChangeEmail(email: string) {
    this.setState({ emailError: !this.isEmailValid(email), email });
  }

  isEmailValid(email?: string): boolean {
    if (!email) {
      return false;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  canSubmitForm() {
    const { emailError, email } = this.state;
    return !emailError && email;
  }

  onSubmitForm() {
    const { onSubmit } = this.props;
    const { email } = this.state;
    if (email) {
      onSubmit(email);
    }
  }

  render() {
    const { classes } = this.props;
    const { email, emailError } = this.state;
    const canSubmitForm = this.canSubmitForm();
    return (
      <div className={classes.container}>
        <h3 className={classes.formTitle}>
          <ReduxLanguage languageKey={'forms.insertEmailOTPLoginForm'} /><br />
          <ReduxLanguage languageKey={'forms.insertEmailOTPLoginFormDisclaimer'} />
        </h3>
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey={'forms.email'} />}
          variant="outlined"
          fullWidth
          required
          error={emailError}
          value={email}
          onChange={e => this.onChangeEmail(e.target.value)}
        />

        <Button
          variant="contained"
          disabled={!canSubmitForm}
          style={{ width: '100%', opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 25, fontWeight: 'bold'  }}
          onClick={() => this.onSubmitForm()}
        >
          <ReduxLanguage languageKey="login.sendEmail" />
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(OTPEmailLoginForm);
