import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { SearchOutlined } from '@material-ui/icons';
import { Stack } from '@mui/system';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import * as React from "react";
import { ICustomerInDTO } from "../../repository/jago/model/input/ICustomerInDTO";
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import translations from '../../translations/i18next';
import ReduxLanguage from '../ReduxLanguage';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});


export interface ICustomersTableState {
}

export interface ICustomersTableProps extends WithStyles<typeof styles>{
  customers: PaginatedDataDTO<ICustomerInDTO>;
  vars?: PaginatedDataDTO<IVarInDTO>;
  tableColumns?: GridColumns<ICustomerInDTO>;
  onRowClick?: (customer: ICustomerInDTO) => void;
  onFetchCustomersOnPage: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onVarClick?: (VAR: IVarInDTO) => void;
  rowsPerPage: number;
  rowHeight?: number;
  disableSelectionOnClick?: boolean;
  isLoading?: boolean;
}

class CustomersTable extends React.Component<ICustomersTableProps, ICustomersTableState> {
  private defaultColumns: GridColumns<ICustomerInDTO>;
  public render() {
    const { customers, rowsPerPage, disableSelectionOnClick, onRowClick, onPageSizeChange, onFetchCustomersOnPage, vars, tableColumns, rowHeight } = this.props;
    const varsData = (vars as PaginatedDataDTO<IVarInDTO>);
    this.defaultColumns = [
      {
        field: 'name',
        headerName: translations.t('gateways.name'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 350,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          const customer = params.row;
          const customerVar = varsData && _.find(varsData.content, { id: customer.varId });
          return (
            <div>
              <h3 style={{ margin: 0,  fontWeight: 'bold' }}>{customer.name}</h3>
                {customerVar && !_.isEmpty(customerVar) && customerVar.name ? (
                  <div style={{ marginLeft:0, marginTop: -2 }}>
                    <h3 style={{ fontStyle:'italic', fontWeight:'bold', fontSize:'0.8em', display:'inline', border:'1px solid grey', borderRadius:5, marginRight:-2, marginLeft: 0 }}>
                      {"VAR "}
                    </h3>
                    <h3 style={{fontSize:'0.8em',display:'inline', marginLeft:-10,paddingLeft:20,paddingRight:5}}>
                      {customerVar.name}
                    </h3>
                  </div>
                ):null}
            </div>
        )}
      },
    ];

    return (
      <DataGrid
        getRowId={(d) => d.id}
        rows={customers.content || []}
        columns={tableColumns ? tableColumns: this.defaultColumns}
        rowHeight={rowHeight ? rowHeight: 55}
        onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
        onPageChange={page => onFetchCustomersOnPage(page)}
        page={(customers?.pagination as PaginationDTO)?.number}
        rowCount={(customers?.pagination as PaginationDTO)?.totalElements}
        pageSize={rowsPerPage}
        loading={this.props.isLoading}
        onRowClick={row => onRowClick ? onRowClick(row.row) : null}
        paginationMode="server"
        disableSelectionOnClick={disableSelectionOnClick}
        componentsProps={{
          pagination: {
            labelRowsPerPage: translations.t('forms.rowsPerPage')
          }
        }}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <SearchOutlined style={{ alignSelf: 'center' }} />
                <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='credits.noCustomerFindTitle' /></h3>
                <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='credits.noCustomerFindDescription' /></h4>
              </div>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='credits.noCustomerFindTitle' /></h3>
              <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='credits.noCustomerFindDescription' /></h4>
            </Stack>
          )
        }}
      />
    );
  }
}

export default withStyles(styles)(CustomersTable);