import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { CancelOutlined } from '@material-ui/icons';
import React from 'react';
import _ from 'lodash';
import Select from 'react-select';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { ICustomersSearchDTO } from '../../repository/jago/model/input/ICustomersSearchDTO';
import { IDomainOptionDTO } from '../../repository/jago/model/input/IDomainOptionDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import Permission from '../Permission/Permission';
import ReduxLanguage from '../ReduxLanguage';
import { Autocomplete } from '@mui/material';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});


export interface ICustomersSearchBarProps extends WithStyles<typeof styles> {
  onSearch: (searchValues: ICustomersSearchDTO) => void;
  onResetSearch: () => void;
  onFilterDomains: (value: string) => void;
  onUpdateSearchValues: (values: Record<keyof ICustomersSearchDTO, string>) => void;
  onCreateNewEntityButtonClick: () => void;
  totalElements: string | number;
  searchValues: Record<keyof ICustomersSearchDTO, string>;
  domainsOptions: IDomainOptionDTO[];
  varsOptions: IVarInDTO[];
  clustersOptions: IClusterInDTO[];
}

export interface ICustomersSearchBarState {
  domainSelected: IDomainOptionDTO | undefined;
  varSelected: IVarInDTO | undefined;
  clusterSelected: IClusterInDTO | undefined;
  searchText: string;
}


class CustomersSearchBar extends React.Component<ICustomersSearchBarProps, ICustomersSearchBarState> {
  
  constructor(props: ICustomersSearchBarProps) {
    super(props);
    this.state = {
      domainSelected: undefined,
      varSelected: undefined,
      clusterSelected: undefined,
      searchText: '',
    }
  }
  public onSetSearchValue(field: keyof ICustomersSearchDTO, value: string) {
    const { onUpdateSearchValues, searchValues } = this.props;
    searchValues[field] = value;
    onUpdateSearchValues(searchValues);
    this.onSearch();
  }

  public onSearch() {
    const { onSearch, searchValues } = this.props;
    onSearch(searchValues);
  }


  public onResetFields() {
    const { onResetSearch } = this.props;
    this.setState({ domainSelected: undefined, varSelected: undefined, clusterSelected: undefined, searchText: '' });
    onResetSearch();
  }

  public onSelectVar(VAR: IVarInDTO) {
    this.onSetSearchValue('varId', VAR?.id.toString());
    this.setState({ varSelected: VAR });
  }

  public onSelectCluster(cluster: IClusterInDTO) {
    this.onSetSearchValue('clusterId', cluster?.id.toString());
    this.setState({ clusterSelected: cluster });
  }

  public onSelectDomain(domain: IDomainOptionDTO) {
    if (domain) {
      this.onSetSearchValue('hostname', domain.value.hostname);
      this.setState({ domainSelected: domain });
    } else {
      this.onSetSearchValue('hostname', '');
      this.setState({ domainSelected: undefined });
    }
  }

  onFetchValues(value: string) {
    const { onFilterDomains } = this.props;
    onFilterDomains(value)
  }

  public render() {
    const { classes, totalElements, onCreateNewEntityButtonClick, searchValues, varsOptions, clustersOptions, domainsOptions } = this.props;
    const { varSelected, clusterSelected, domainSelected, searchText } = this.state;
    const debouncedSearch = _.debounce((text) => this.onFetchValues(text), 500);
    return (
      <div className={classes.container}>
        <div style={{ marginLeft: 0, display: 'flex' }}>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SOFIA_ADMIN]}>
            <Autocomplete
              options={domainsOptions}
              isClearable
              autoComplete
              inputValue={domainSelected ? domainSelected.label : searchText}
              value={domainSelected}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} variant="outlined" style={{ width: 250 }} label={<ReduxLanguage languageKey="forms.domainName" />}/>}
              placeholder={<ReduxLanguage languageKey={'forms.selectProduct'} />}
              onInputChange={(event, newInputValue) => { this.setState({ searchText: newInputValue }); debouncedSearch(newInputValue); }}
              onChange={(e, newValue) => this.onSelectDomain(newValue)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000, minWidth: 200, maxWidth:200 }),
                control: (styles) => ({ ...styles, minHeight: 55, minWidth: 200, maxWidth:200 }),
              }} 
            />
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.CLUSTER_FILTER}>
            <Select
              key={`my_unique_select_key__${JSON.stringify(clusterSelected)}`}
              isClearable
              isSearchable={false}
              value={clusterSelected}
              options={clustersOptions}
              placeholder={<><ReduxLanguage languageKey={'vars.clusterSelection'} /></>}
              formatOptionLabel={(o) => <>{o.name}</>}
              isOptionSelected={(o) => clusterSelected?.id === o.id}
              onChange={(e) => this.onSelectCluster(e)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, minWidth: 200, marginLeft: 10, marginRight: 10, maxWidth:200 }),
              }} 
            />
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.VAR_READ}>
            <Select
              key={`my_unique_select_key__${JSON.stringify(varSelected)}`}
              isClearable
              isSearchable={false}
              value={varSelected}
              options={varsOptions}
              placeholder={<><ReduxLanguage languageKey={'vars.varSelection'} /></>}
              formatOptionLabel={(o) => <>{o.name}</>}
              isOptionSelected={(o) => varSelected?.id === o.id}
              onChange={(e) => this.onSelectVar(e)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, minWidth: 200, marginLeft: 10, marginRight: 10, maxWidth:200 }),
              }} 
            />
          </Permission>
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey="credits.customerName" /> }
            variant="outlined"
            fullWidth
            type="search"
            style={{ marginLeft: 10, height: 50, minWidth:160 }}
            value={searchValues.name}
            onChange={e => this.onSetSearchValue('name', e.target.value)}
          />
          <Button
            variant="contained"
            style={{ backgroundColor: 'red', height: 40, color: 'white', marginTop: 7, marginLeft: 20, fontWeight: 'bold'  }}
            onClick={() => this.onResetFields()}
          >
            <CancelOutlined style={{ color: 'white' }} />
          </Button>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.CUSTOMER_CREATE}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#5AC0B1', color: 'white', width: '100%',  marginTop: 7, height: 40, marginLeft: 20, fontSize: 13, fontWeight: 'bold'  }}
              onClick={() => onCreateNewEntityButtonClick()}
            >
              <ReduxLanguage languageKey="forms.createNewCustomer" />
            </Button>
          </Permission>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CustomersSearchBar);
