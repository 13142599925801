import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { Button } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Business, SearchOutlined } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import { Stack } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DomainDetailsView from '../components/Domains/DomainDetailsView';
import UserImpersonateForm from '../components/Domains/DomainUsers/UserImpersonateForm';
import NewDomainUserForm from '../components/Forms/NewDomainUserForm';
import { ModalTypes } from '../components/Modals/ModalTypes';
import Permission from '../components/Permission/Permission';
import ReduxLanguage from '../components/ReduxLanguage';
import SearchBar from '../components/SearchBar/SearchBar';
import * as DomainsActions from '../redux/domains/domains.actions';
import { DomainsActions as DomainsBaseAction } from '../redux/domains/domains.actions';
import * as ModalsActions from '../redux/modals/modals.actions';
import * as ProductActions from '../redux/products/products.actions';
import * as SettingsActions from '../redux/settings/settings.actions';
import { IState } from '../redux/store';
import { IDomainDetailedDTO } from '../repository/jago/model/input/IDomainDetailedDTO';
import { DomainIrisStatusOptions, IDomainInDTO } from '../repository/jago/model/input/IDomainInDTO';
import { IDomainUserInDTO } from '../repository/jago/model/input/IDomainUserInDTO';
import { IProductInDTO } from '../repository/jago/model/input/IProductInDTO';
import { IDomainUserOutDTO } from '../repository/jago/model/output/IDomainUserOutDTO';
import { IDomainRequestParamsDTO } from '../repository/jago/model/output/RequestParamsDTOs';
import { getDomainFrontendAddressFromHostname, getDomainNameFromHostname } from '../repository/utils';
import AbilityProvider from '../services/PermissionService/AbilityProvider';
import { PERMISSIONS, SUPPORT_AGENT_READ_ONLY_EMAIL } from '../services/PermissionService/PermissionConstants';
import { BLUE, ORANGE } from '../style/StyleConsts';
import translations from '../translations/i18next';
import AddPluginForm from '../components/Forms/AddPluginForm';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  logo: {
    width: '20%',
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 160px)',
    width: '100%'
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  domainTypeContainer: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  domainTypeInnerContainer: {
    padding: 5,
    backgroundColor: BLUE,
    borderRadius: 5,
  },
  domainIrisContainer: {
  },
  domainIrisInnerContainer: {
    padding: 5,
    borderRadius: 5,
  },
  domainTypeText: {
    color: 'white',
    fontWeight: 'bold',
    margin: 0
  }
});


export interface IDomainPageState {
  searchText: string;
  pageSize: number;
  isLoading: boolean;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface IDomainsPageProps extends WithStyles<typeof styles> {
  language: string;
  domains: PaginatedDataDTO<IDomainInDTO>;
  detailedDomain: IDomainDetailedDTO;
  products: IProductInDTO[];
}

export type ComponentProps = IDomainsPageProps & IReduxProps;

export class DomainsPage extends React.Component<ComponentProps, IDomainPageState> {
  private domainsColumns: GridColumns<IDomainInDTO>;
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      searchText: '',
      pageSize: 100,
      isLoading: false,
    }
  }

  public async componentDidMount() {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    try {
      const params: IDomainRequestParamsDTO = { serviceName: 'LUCKEY', pageSize };
      this.setState({ isLoading: true });
      await dispatch<any>(DomainsActions.fetchSofiaDomains(params));
      if (AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.DOMAIN_EXTEND)) {
        dispatch<any>(ProductActions.fetchSofiaProducts());
      }
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }


  public async updateDomainConfiguration(config: string) {
    const { dispatch, detailedDomain } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(DomainsActions.updateSofiaDomainConfiguration(detailedDomain, { data: config }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'domains.domainConfigModifySuccess',
        }
      }));
      await dispatch<any>(DomainsActions.fetchSofiaDomainConfiguration());
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CONFIG_UPDATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.updateDomainConfigError',
        }
      }));
    }
  }

  public async impersonateDomainUserRequest(user: IDomainUserInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`IMPERSONATE_DOMAIN_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONTENT_MODAL,
      modalProps: {
        content: (
        <UserImpersonateForm
          user={user}
          onImpersonateReadyOnly={(duration) => this.impersonateDomainUser(SUPPORT_AGENT_READ_ONLY_EMAIL, duration)}
          onImpersonateUser={(duration) => this.impersonateDomainUser(user.email, duration)}
        />
        ),
      }
    }));
  }

  public async impersonateDomainUser(userEmail: string, duration?: number) {
    const { dispatch, detailedDomain } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const userToken = await dispatch<any>(DomainsActions.impersonateDomainUser(detailedDomain.hostname, userEmail, duration));
      const frontendURL = getDomainFrontendAddressFromHostname(detailedDomain.hostname);
      window.open(`https://${frontendURL}?p=${userToken}`);
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'domains.impersonateSuccess',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_IMPERSONATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.impersonateDomainUserError',
        }
      }));
    }
  }

  public async onUpdateAddionalInfo(note: string) {
    const { dispatch, detailedDomain } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const outDTO = {
        note,
      }
      await dispatch<any>(DomainsActions.updateSofiaDomainAdditionalInfo(detailedDomain, outDTO));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'domains.additionalInfoSavedSuccessfully',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_IMPERSONATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.errorSaveAdditionalInfo',
        }
      }));
    }
  }

  public onOpenAddPluginForm() {
    const { dispatch, products } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_EXTENDS_DOMAIN_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <AddPluginForm
            products={products}
            onAddNewPlugin={(productData: IProductInDTO) => this.onAddNewPluginRequest(productData)}
          />
        ),
        titleMessageKey: 'domains.extendDomain',
      }
    }));
  }


  public async appenDomainUsers(page: number) {
    const { dispatch } = this.props;
    await dispatch<any>(DomainsActions.fetchSofiaDomainUsers({ page, pageSize: 50 }));
  }
  
  public async onRowClick(domain: IDomainInDTO) {
    const { dispatch, history } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      dispatch<any>(DomainsBaseAction.setSelectedDetailedDomain(domain));
      await dispatch<any>(DomainsActions.fetchSofiaDomainConfiguration());
      if (!domain.cloudCredits) {
        await dispatch<any>(DomainsActions.fetchSofiaDomainSubscription());
      }
      await dispatch<any>(DomainsActions.fetchSofiaDomainUsers({ pageSize: 50 }));
      await dispatch<any>(DomainsActions.fetchSofiaDomainInstallers({ pageSize: 50 }));
      await dispatch<any>(DomainsActions.fetchSofiaDomainInfo());
      await dispatch<any>(DomainsActions.fetchSofiaDomainCustomer());
      await dispatch<any>(DomainsActions.fetchSofiaDomainAdditionalInfo());
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      const { detailedDomain } = this.props;
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_DOMAIN_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(DomainsActions.DomainsActions.setSelectedDetailedDomain({})),
          content: (
            <DomainDetailsView
              detailedDomain={detailedDomain}
              onSaveConfig={(config: string) => this.updateDomainConfiguration(config)}
              onImpersonateUser={(user: IDomainUserInDTO) => this.impersonateDomainUserRequest(user)}
              onAppenDomainUsers={(page: number) => this.appenDomainUsers(page)}
              onNewUserClick={() => this.onNewUserClick()}
              onNewInstallerClick={() => this.onNewInstallerClick()}
              onDeletePlantRequest={() => {/* UNUSED */}}
              onExtendDomainClick={() => this.onOpenAddPluginForm()}
              onUpdateAddionalInfo={value => this.onUpdateAddionalInfo(value)}
              onOpenCustomerDetails={customerId => {
                dispatch<any>(ModalsActions.hideModal(`OPERATIONAL_DOMAIN_MODAL`));
                history.push(`/customers?customerId=${customerId}`);
              }}
              onGatewaysClick={() => {
                if (detailedDomain.hostname) {
                  dispatch<any>(ModalsActions.hideModal(`OPERATIONAL_DOMAIN_MODAL`));
                  history.push(`/gateways?hostname=${detailedDomain.hostname}`);
                }
              }}
            />
          ),
          titleMessageKey: 'domains.domainDetails',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getDomainDetailsError',
        }
      }));
    }
  }

  public onAddNewPluginRequest(productData:IProductInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_DOMAIN_PLUGIN_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'domains.extendDomain',
        successMessageKey: 'forms.domainExtendsConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onAddNewPluginConfirm(productData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_DOMAIN_PLUGIN_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onAddNewPluginConfirm(productData:IProductInDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_DOMAIN_PLUGIN_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(DomainsActions.addProductToDomain(productData));
      await dispatch<any>(DomainsActions.fetchSofiaDomainSubscription());
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_EXTENDS_DOMAIN_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_EXTENSION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.addDomainPluginError',
        }
      }));
    }
    
  }

  onNewUserClick() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_NEW_USER_DOMAIN_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <NewDomainUserForm
            roleId={1}
            onCreateNewUser={(user: IDomainUserOutDTO) => this.onCreateUserFormDomain(user)}
          />
        ),
        titleMessageKey: 'forms.newUser',
      }
    }));
  }

  onNewInstallerClick() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_NEW_USER_DOMAIN_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <NewDomainUserForm
            roleId={2}
            onCreateNewUser={(user: IDomainUserOutDTO) => this.onCreateUserFormDomain(user)}
          />
        ),
        titleMessageKey: 'forms.newInstaller',
      }
    }));
  }

  onCreateUserAfterDomainCreation(newDomain: IDomainInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(DomainsBaseAction.setSelectedDetailedDomain(newDomain));
    dispatch<any>(ModalsActions.hideModal('CREATE_USER_AFTER_DOMAIN_MODAL'));
    this.onNewUserClick();
  }

  async onCreateUserFormDomain(user: IDomainUserOutDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(DomainsActions.createNewUserForDomain(user));
      await dispatch<any>(DomainsActions.fetchSofiaDomainUsers({ pageSize: 50 }));
      await dispatch<any>(DomainsActions.fetchSofiaDomainInstallers({ pageSize: 50 }));
      await dispatch<any>(DomainsActions.fetchSofiaDomainSubscription());
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_NEW_USER_DOMAIN_MODAL'));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_ADD_USER`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.addUserDomainError',
        }
      }));
    }
  }

  public handleChangeRowsPerPage(pageSize) {
    const { dispatch } = this.props;
    this.setState({ pageSize }, () => {
      const { searchText } = this.state;
      const params: IDomainRequestParamsDTO = { hostName: searchText, page: 0, pageSize, serviceName: 'LUCKEY' };
      dispatch<any>(DomainsActions.fetchSofiaDomains(params));
    });
  }

  public async onFetchDomainsOnPage(page: number) {
    const { dispatch } = this.props;
    const { pageSize, searchText } = this.state;
    const params: IDomainRequestParamsDTO = { hostName: searchText, page, pageSize, serviceName: 'LUCKEY' };
    try {
      this.setState({ isLoading: true });
      await dispatch<any>(DomainsActions.fetchSofiaDomains(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public async onSearchTextChange(text: string) {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    this.setState({ searchText: text, isLoading: true });
    const params: IDomainRequestParamsDTO = { hostName: text, serviceName: 'LUCKEY', pageSize };
    try {
      await dispatch<any>(DomainsActions.fetchSofiaDomains(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public async onSearchReset() {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    const params: IDomainRequestParamsDTO = { page: 0, serviceName: 'LUCKEY', pageSize };
    this.setState({ searchText: '', isLoading: true });
    try {
      await dispatch<any>(DomainsActions.fetchSofiaDomains(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public async onExportManagersCSV() {
    const { dispatch } = this.props;
    dispatch<any>(SettingsActions.setSpinnerVisible(true));
    try {
      await dispatch<any>(DomainsActions.exportManagersAndSubscriptionsCSV());
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }

  public onEnableIrisRequest(plant: IDomainDetailedDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`ENABLE_IRIS_PLANT_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'domains.domainIrisEnable',
        successMessageKey: 'domains.domainIrisEnableConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEnableIrisConfirm(plant),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`ENABLE_IRIS_PLANT_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onEnableIrisConfirm(plant: IDomainDetailedDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(ModalsActions.hideModal(`ENABLE_IRIS_PLANT_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      // TODO await dispatch<any>(CreditsActions.fetchWalletPlantConfiguration(plant.id));
      dispatch<any>(ModalsActions.hideModal('DOMAIN_DETAILS_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_DELETION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.enableIrisDomainRequestError',
        }
      }));
    }
  }

  render() {
    const { classes, domains } = this.props
    const { pageSize } = this.state;
    this.domainsColumns = [
      {
        field: 'name',
        headerName: translations.t('forms.domainName'),
        type: 'string',
        sortable: false,
        filterable: false,
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          const domain = params.row;
          const federatedMasterDomain = domain?.subscriptionDomainHostname ? getDomainNameFromHostname(domain?.subscriptionDomainHostname) : false;
          const isMasterDomainOfFederation = federatedMasterDomain && federatedMasterDomain === domain?.name;
          return (
            <div>
              <h3 className={classes.rowTitle}>{domain?.name}</h3>
              {federatedMasterDomain ? (
                <div className={classes.federatedContainer}>
                  <Business style={{ fontSize: 15, marginBottom: 5 }} />
                  {isMasterDomainOfFederation ? (
                    <h4 style={{ marginTop: 0}}><ReduxLanguage languageKey="domains.federatedMasterDomain" /></h4>
                  ) : (
                    <h4 style={{ marginTop: 0}}><ReduxLanguage languageKey="domains.federatedDomain" languageProps={{ domain: federatedMasterDomain }}/></h4>
                  )}
                </div>
              ): null}
            </div>
          )
        }
      },
      {
        field: 'hostname',
        headerName: translations.t('forms.hostName'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'serviceName',
        headerName: translations.t('forms.domainType'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          const domain = params.row;
          return (
            <div>
              <h4><ReduxLanguage languageKey={domain?.cloudCredits ? 'domains.domainWithCloudCredits' : 'domains.domainStandard'} /></h4>
            </div>
          )
        }
      },
      {
        field: 'subscriptionDomainCompanyId',
        headerName: translations.t('forms.actions'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 150,
        renderCell: (params) => {
          const domain = params.row;
          return (
            <div>
            {domain && (domain.enable1ris === DomainIrisStatusOptions.REQUIRED || domain.enable1ris === DomainIrisStatusOptions.ENABLED) ? (
              <div className={classes.domainIrisContainer}>
                {domain.enable1ris === DomainIrisStatusOptions.REQUIRED && AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.DOMAIN_IRIS_ENABLE) ? (
                  <Button className={classes.domainIrisInnerContainer} style={{backgroundColor: ORANGE }}
                    onClick={(e)=>{e.stopPropagation(); this.onEnableIrisRequest(domain);}}>
                    <h5 className={classes.domainTypeText}>
                      <ReduxLanguage languageKey={'domains.domainIrisEnable'} />
                    </h5>
                  </Button>
                ) : (
                  <div className={classes.domainIrisInnerContainer} style={{backgroundColor: domain.enable1ris===DomainIrisStatusOptions.REQUIRED ? ORANGE: BLUE}}>
                    <h5 className={classes.domainTypeText}>
                      <ReduxLanguage languageKey={domain.enable1ris===DomainIrisStatusOptions.REQUIRED ? 'domains.domainIrisRequired' : 'domains.domainIrisEnabled'} />
                    </h5>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        )}
      }
    ]
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <SearchBar
            searchTextTranslationKey="domains.domainName"
            newEntityTranslationKey="forms.createNewDomain"
            searchDebounceDelay={200}
            onSearchTextChange={(text: string) => this.onSearchTextChange(text)}
          />
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.DOMAIN_EXPORT_ALL_MANAGERS}>
            <Button
              variant="contained"
              style={{ backgroundColor: 'cornflowerblue', color: 'white', marginLeft: 10, height: 37, fontWeight: 'bold'  }}
              onClick={() => this.onExportManagersCSV()}
            >
              <ReduxLanguage languageKey="credits.exportManagersCSV" />
            </Button>
          </Permission>
        </div>
        <div className={classes.tableContainer}>
          <DataGrid
            getRowId={(d) => d.hostname}
            rows={domains.content || []}
            columns={this.domainsColumns}
            rowHeight={60}
            page={(domains?.pagination as PaginationDTO)?.number}
            rowCount={(domains?.pagination as PaginationDTO)?.totalElements}
            pageSize={pageSize}
            loading={this.state.isLoading}
            paginationMode="server"
            onPageSizeChange={(pageSize) => this.handleChangeRowsPerPage(pageSize)}
            onPageChange={page => this.onFetchDomainsOnPage(page)}
            onRowClick={row => this.onRowClick(row.row)}
            componentsProps={{
              pagination: {
                labelRowsPerPage: translations.t('forms.rowsPerPage')
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <SearchOutlined style={{ alignSelf: 'center' }} />
                    <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='domains.noDomainsFoundTitle' /></h3>
                    <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='domains.noDomainFoundDescription' /></h4>
                  </div>
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='domains.noDomainsFoundTitle' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='domains.noDomainFoundDescription' /></h4>
                </Stack>
              )
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    domains: state.domains.domains,
    detailedDomain: state.domains.selectedDetailedDomain,
    products: state.products.products,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(DomainsPage)));
