import MomentUtils from '@date-io/moment';
import { Button, Card, Checkbox, Divider, FormControlLabel, InputAdornment, TextField } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import CreditsIcon from '@material-ui/icons/MonetizationOn';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import Select from 'react-select';
import * as CreditsActions from '../../redux/credits/credits.actions';
import { IState } from '../../redux/store';
import { ICardInDTO } from '../../repository/jago/model/input/ICardInDTO';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { ICRMSourceEnum } from '../../repository/jago/model/output/ICRMSourceEnum';
import { ICardOutDTO } from '../../repository/jago/model/output/ICardOutDTO';
import { CurrenciesEnum } from '../../repository/jago/model/output/ICurrenciesEnum';
import translations from '../../translations/i18next';
import Utils from '../../utils/Utils';
import SubscriptionCostDetails from '../MarketplaceSubscriptions/SubscriptionCostDetails';
import ReduxLanguage from "../ReduxLanguage";
import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import { ICustomerPlantsRequestParamsDTO, ICustomerRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import { IPlantInDTO } from '../../repository/jago/model/input/IPlantInDTO';
import { IWalletInDTO } from '../../repository/jago/model/input/IWalletInDTO';


const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  cardContainer: {
    marginTop: 20,
    padding: 10,
    borderLeft: '3px solid #5AC0B1',
    overflowY: 'auto',
    minHeight: 450,
  },
  title: {
    fontWeight: 'bold'
  },
});

const standardCompStyles: { [key: string]: React.CSSProperties } = {
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  singleElementContainer: {
    flex: 1,
  },
}

export interface INewCardFormState {
  cardData: ICardOutDTO;
  textInputErrors: Record<keyof ICardOutDTO,boolean>;
  selectedMarketplaceId?: number;
  selectedMainTier?: ISubscriptionInDTO;
  selectedLicenses: ISubscriptionInDTO[];
  minCredits: number;
  mainTierOptions: SelectFieldValue<ISubscriptionInDTO>[];
  subscriptionsOptions: SelectFieldValue<ISubscriptionInDTO>[];
  servicesOptions: SelectFieldValue<ISubscriptionInDTO>[];
  customerSearchInput: string;
  customerSelected: ICustomerInDTO;
  customersLoading: boolean;
  plantSelected: IPlantInDTO;
  plantsLoading: boolean;
  walletSelected: IWalletInDTO;
};

export interface INewCardFormProps extends WithStyles<typeof styles>{
  card?: ICardInDTO;
  onSubmit: (card: ICardOutDTO) => void;
  marketplaces: IMarketplaceInDTO[];
}

export interface SelectFieldValue<T> {
  label: string;
  value: number;
  element: T;
}

type IReduxProps = ConnectedProps<typeof connector>;

export type ComponentProps = INewCardFormProps & IReduxProps;

class CardForm extends React.Component<ComponentProps, INewCardFormState> {

  constructor(props: ComponentProps) {
    super(props);

    const varsData = (props.vars as PaginatedDataDTO<IVarInDTO>).content

    this.state = {
      cardData: {
        varId: props.card&&props.card.varId&&varsData.filter(e=>e.id===props.card.varId).length?varsData.filter(e=>e.id===props.card.varId)[0].id:null,
        plantRequestId: null,
        walletRechargeId: null,
        walletId: null,
        quantity: Utils.formatCreditsIn(props.card?.quantity),
        notes: props.card?.notes || '',
        iseoSalesOrderReference: props.card?.iseoSalesOrderReference || '',
        iseoSalesOrderDate: props.card?.iseoSalesOrderDate || 0,
        iseoPurchaseOrderReference: props.card?.iseoPurchaseOrderReference || '',
        iseoPurchaseOrderDate: props.card?.iseoPurchaseOrderDate || 0,
        sofiaSalesOrderReference: props.card?.sofiaSalesOrderReference || '',
        sofiaSalesOrderDate: props.card?.sofiaSalesOrderDate || 0,
        crmSource: props.card?.crmSource || ICRMSourceEnum.SFDC_ISEO,
        crmId: props.card?.crmId || '',
        sofiaProjectId: props.card?.sofiaProjectId || '',
        kamReference: props.card?.kamReference || '',
        actions: props.card?.actions ? _.map(props.card?.actions, (a) => ({ subscriptionId: a.subscription.id })) : [],
        iseoInvoiceAmount: Utils.formatCreditsIn(props.card?.iseoInvoiceAmount),
        iseoInvoiceNumber: props.card?.iseoInvoiceNumber || '',
        iseoInvoiceDate: props.card?.iseoInvoiceDate || 0,
        iseoInvoiceCurrency: props.card?.iseoInvoiceCurrency || '',
        iseoInvoiceRecipient: props.card?.iseoInvoiceRecipient || '',
        iseoInvoiceIssuer: props.card?.iseoInvoiceIssuer || '',
        sofiaInvoiceRecipient: props.card?.sofiaInvoiceRecipient || '',
        sofiaInvoiceAmount: Utils.formatCreditsIn(props.card?.sofiaInvoiceAmount),
        sofiaInvoiceNumber: props.card?.sofiaInvoiceNumber || '',
        sofiaInvoiceDate: props.card?.sofiaInvoiceDate || 0,
        freeOfCharge: props.card?.freeOfCharge || false,
        freeOfChargeReason: props.card?.freeOfChargeReason || '',
        serviceSupplementaryInvoiceNumber: props.card?.serviceSupplementaryInvoiceNumber || '',
        serviceSupplementaryInvoiceAmount: Utils.formatCreditsIn(props.card?.serviceSupplementaryInvoiceAmount) || 0,
        serviceSupplementaryInvoiceDate: props.card?.serviceSupplementaryInvoiceDate || 0,
        serviceSupplementaryInvoiceRecipient: props.card?.serviceSupplementaryInvoiceRecipient || '',
      },
      selectedLicenses: [],
      minCredits: 0,
      customerSearchInput: "",
      customerSelected: null,
      customersLoading: false,
      plantSelected: null,
      plantsLoading: false,
      walletSelected: null,
      textInputErrors: {
        varId: false,
        plantRequestId: false,
        walletRechargeId: false,
        walletId: false,
        quantity: false,
        notes: false,
        iseoSalesOrderReference: false,
        iseoSalesOrderDate: false,
        iseoPurchaseOrderReference: false,
        iseoPurchaseOrderDate: false,
        sofiaSalesOrderReference: false,
        sofiaSalesOrderDate: false,
        crmSource: false,
        crmId: false,
        sofiaProjectId: false,
        crmLink: false,
        kamReference: false,
        actions: false,
        iseoInvoiceAmount: false,
        iseoInvoiceNumber: false,
        iseoInvoiceRecipient: false,
        iseoInvoiceDate: false,
        sofiaInvoiceAmount: false,
        sofiaInvoiceNumber: false,
        sofiaInvoiceDate: false,
        sofiaInvoiceRecipient: false,
        iseoInvoiceIssuer: false,
        freeOfCharge: false,
        freeOfChargeReason: false,
        iseoInvoiceCurrency: false,
        serviceSupplementaryInvoiceNumber: false,
        serviceSupplementaryInvoiceAmount: false,
        serviceSupplementaryInvoiceDate: false,
        serviceSupplementaryInvoiceRecipient: false,
      },
      mainTierOptions: [],
      subscriptionsOptions: [],
      servicesOptions: [],
    };
  }

  public async componentDidMount() {
    const { dispatch, wallets } = this.props;
    // Init per caso "edit"
    if (this.props.card && this.props.card.customerId && this.props.card.walletId) {
      try {
        // Init customerSelected
        const customer = await dispatch<any>(CreditsActions.fetchCustomerDetails(this.props.card.customerId));
        if (customer) {
          this.setState({ customerSelected:customer })
          const walletsData = (wallets as PaginatedDataDTO<IWalletInDTO>)
          const walletsSearch = walletsData.content?walletsData.content.filter(e=>e.id===this.props.card.walletId):[]
          if (walletsSearch.length)
            this.setState({ walletSelected:walletsSearch[0] })
        }
      }
      catch (error) {
        console.log(error)
      }
    }
  }
  
  public onSetValue(field: keyof ICardOutDTO, value: any) {
    const { cardData, minCredits } = this.state;
    if (!value) {
      this.setErrorForField(field, true);
    } else {
      this.setErrorForField(field, false);
    }
    if (field === 'quantity' && (!_.isNumber(Number(value)) || value < 0)) {
      this.setErrorForField(field, true);
    }
    if (field === 'quantity' && minCredits && value < Utils.formatCreditsIn(minCredits)) {
      this.setErrorForField(field, true);
    }

    if (field === 'freeOfCharge' && value === false) {
      (cardData as any)['freeOfChargeReason'] = '';
    }
    (cardData as any)[field] = value;
    this.setState({ cardData });
  }

  public setErrorForField(field: keyof ICardOutDTO, hasError: boolean) {
    const { textInputErrors } = this.state;
    textInputErrors[field] = hasError;
    this.setState({ textInputErrors });
  }

  public canSubmitForm() {
    const { cardData } = this.state;
    if (!cardData.quantity || cardData.quantity < 0 || !cardData.iseoSalesOrderReference || !cardData.crmId) {
      return false;
    }
    return true;
  }

  public onSubmitForm() {
    const { cardData, selectedMainTier } = this.state;
    const { onSubmit } = this.props;
    let cardToSend = {
      ...cardData,
      quantity: Utils.formatCreditsOut(cardData.quantity),
      sofiaInvoiceAmount:  Utils.formatCreditsOut(cardData.sofiaInvoiceAmount),
      iseoInvoiceAmount:  Utils.formatCreditsOut(cardData.iseoInvoiceAmount),
      serviceSupplementaryInvoiceAmount: Utils.formatCreditsOut(cardData.serviceSupplementaryInvoiceAmount),
    };

    if (selectedMainTier) {
      cardToSend = {
        ...cardToSend,
        actions: [...cardData.actions, { subscriptionId: selectedMainTier.id }],
      }
    }
    onSubmit(cardToSend);
  }

  async onSelectMarketplaceValue(options: SelectFieldValue<IMarketplaceInDTO> | null) {
    const { dispatch } = this.props;
    if (!options || _.isEmpty(options)) {
      this.setState({ subscriptionsOptions: [] });
      this.setState({ mainTierOptions: [] });
      return;
    }
    this.setState({ selectedMarketplaceId: options.value });

    const response: ISubscriptionInDTO[] = await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptionsRawData(options.value, { pageSize: 200 }));
    const [mainTiers, services] = _.partition(response, { resource: 'DOMAIN' });
    const mainTierOptions: SelectFieldValue<ISubscriptionInDTO>[] = _.map(mainTiers, sub => ({
      label: `${sub.sku} - ${sub.name}`,
      value: sub.id,
      element: sub,
    }));
    const servicesOptions: SelectFieldValue<ISubscriptionInDTO>[] = _.map(services, sub => ({
      label: `${sub.sku} - ${sub.name}`,
      value: sub.id,
      element: sub,
    }));
    this.setState({ mainTierOptions, servicesOptions });
  }

  async onSelectMainTierValue(options: SelectFieldValue<ISubscriptionInDTO> | null) {
    const { dispatch } = this.props;
    const { selectedMarketplaceId, minCredits } = this.state;

    if (!options || _.isEmpty(options)) {
      this.setState({ subscriptionsOptions: [], minCredits: 0, selectedLicenses: [], selectedMainTier: undefined });
      return;
    }
    if (!selectedMarketplaceId) {
      return;
    }
    this.setState({ selectedMainTier: options.element, minCredits: minCredits + options.element.fixedPrice });
    const response = await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptionsRawData(selectedMarketplaceId, { pageSize: 200, mainTier: options.value }));
    const subscriptionsOptions: SelectFieldValue<ISubscriptionInDTO>[] = _.map(response, sub => ({
      label: sub.name,
      value: sub.id,
      element: sub,
    }));
    this.setState({ subscriptionsOptions });
  }

  onSelectSubscriptionValue(options: SelectFieldValue<ISubscriptionInDTO>[]) {
    let { selectedMainTier } = this.state;
    let minCredits = selectedMainTier?.fixedPrice || 0;
    if (_.isEmpty(options)) {
      this.onSetValue('actions', []);
      this.setState({ selectedLicenses: [] });
    } else {
      const subscriptions = _.map(options, option => ({ subscriptionId: option.value }));
      this.onSetValue('actions', subscriptions);
      const selectedLicenses = _.map(options, option => option.element);

      _.each(selectedLicenses, license => minCredits +=license.fixedPrice);
      this.setState({ selectedLicenses, minCredits });
    }
  }

  async onCustomerSearchInputChanged(newInputValue: string) {
    const { dispatch } = this.props;
    await dispatch<any>(CreditsActions.resetCustomerPlants());
    this.setState({ plantSelected: null })
    this.setState({ walletSelected: null })
    this.setState({ customerSearchInput: newInputValue })
    if (!newInputValue) {
      await dispatch<any>(CreditsActions.resetCustomersSearch());
      this.setState({ customersLoading: false })
      return;
    }
    this.setState({ customersLoading: true })
    const params: ICustomerRequestParamsDTO = { name: newInputValue };
    await dispatch<any>(CreditsActions.fetchCustomersSearch(params));
    this.setState({ customersLoading: false })
  }

  async onCustomerSelected(newValue: string | ICustomerInDTO) {
    const { dispatch } = this.props;
    const newValue_customer = !newValue?null:(newValue as ICustomerInDTO)
    this.setState({ customerSelected: newValue_customer })
    if (newValue_customer && newValue_customer.id) {
      this.setState({ plantsLoading: true })
      const params: ICustomerPlantsRequestParamsDTO = { customerId: newValue_customer.id };
      await dispatch<any>(CreditsActions.fetchCustomerPlants(params));
      this.setState({ plantsLoading: false })
    }
    else {
      await dispatch<any>(CreditsActions.resetCustomerPlants());
      this.setState({ plantSelected: null })
      this.setState({ walletSelected: null })
    }
  }

  async onPlantSelected(newValue: string | IPlantInDTO) {
    const { wallets } = this.props;
    const { cardData } = this.state;
    const newValue_plant = !newValue?null:(newValue as IPlantInDTO)
    this.setState({ plantSelected: newValue_plant })
    const walletsData = (wallets as PaginatedDataDTO<IWalletInDTO>)
    if (newValue_plant && newValue_plant.id && newValue_plant.walletId && 
      walletsData && walletsData.content && walletsData.content.filter(e=>e.id===newValue_plant.walletId).length>0) {
      this.setState({ walletSelected: walletsData.content.filter(e=>e.id===newValue_plant.walletId)[0] });
      (cardData as any)["walletId"] = walletsData.content.filter(e=>e.id===newValue_plant.walletId)[0].id;
      this.setState({ cardData });
    }
    else {
      this.setState({ walletSelected: null });
      (cardData as any)["walletId"] = null;
      this.setState({ cardData });
    }
  }

  public render() {
    const { classes, marketplaces, card, vars, customersSearch, wallets, customerPlants } = this.props;
    const { cardData, selectedLicenses, minCredits, textInputErrors, subscriptionsOptions, servicesOptions, mainTierOptions, selectedMainTier,
      customersLoading, customerSearchInput, customerSelected, plantsLoading, plantSelected, walletSelected,
    } = this.state;
    const marketplacesOptions: SelectFieldValue<IMarketplaceInDTO>[] = _.map(marketplaces, marketplace => ({
      label: marketplace.name,
      value: marketplace.id,
      element: marketplace,
    }));

    const varsData = _.sortBy((vars as PaginatedDataDTO<IVarInDTO>).content, (item => item.name.toLowerCase()))
    
    const walletsData = (wallets as PaginatedDataDTO<IWalletInDTO>)

    const plantOptions = walletsData&&walletsData.content&&customerPlants&&customerPlants.data&&(customerPlants.data as PaginatedDataDTO<IPlantInDTO>).content?
    (customerPlants.data as PaginatedDataDTO<IPlantInDTO>).content.filter(e=>walletsData.content.filter(f=>f.id===e.walletId).length>0):[]

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h4 style={{ marginTop: 0, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.cardQuantity'} />}<span style={{ color: 'red' }}>*</span></h4>
          <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.cardQuantityDescription'} />}</h5>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            type="number"
            required
            disabled={card?.id ? true : false}
            style={{ marginTop: 10 }}
            error={textInputErrors.quantity}
            value={cardData?.quantity}
            onChange={e => this.onSetValue('quantity', parseInt(e.target.value, 10))}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <CreditsIcon />
                </InputAdornment>
              }
            }
          />
          
          {minCredits ? (
            <h5 style={{ color: 'red' }}><ReduxLanguage languageKey={'errors.warningMinCreditsCard'} languageProps={{ credits: Utils.formatCreditsIn(minCredits) }} /></h5>
            ): null}
          <FormControlLabel
            control={<Checkbox checked={cardData?.freeOfCharge} onChange={(e, checked) => this.onSetValue('freeOfCharge', checked)} name="freeOfCharge" />}
            label={<h4><ReduxLanguage languageKey={'forms.freeOfCharge'} /></h4>}
          />
          {cardData?.freeOfCharge ? (
            <div style={{ ...standardCompStyles.singleElementContainer, marginRight: 10, marginTop: 0, }}>
              <h4 style={{ marginTop: 5, marginLeft: 0, marginBottom: 0, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.freeOfChargeReason'} />}</h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                style={{ marginTop: 10 }}
                value={cardData?.freeOfChargeReason}
                onChange={e => this.onSetValue('freeOfChargeReason', e.target.value)}
            />
            <Divider style={{ marginTop: 20 }} />
            </div>
          ) : null}

          {!card||(card.id&&card.varId)?(
            <h4 style={{ marginLeft: 0, marginBottom: 10, fontWeight: 'bold', marginTop: 20 }}>{<ReduxLanguage languageKey={'forms.cardVar'} />}</h4>
          ):null}
          {(!card||(card.id&&card.varId))?(
            <Select
              options={varsData}
              isClearable
              isSearchable={false}
              formatOptionLabel={(o) => <>{o.name}</>}
              value={_.find(varsData,{ id: card?.varId })}
              isDisabled={card?.id ? true : false}
              required
              isOptionSelected={(o) => card?.varId === o.id}
              placeholder={<></>}
              onChange={(data) => this.onSetValue('varId', data?data.id:null)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, marginTop: 5 }),
              }} 
            />
          ):null}

          {!card||(card.id&&card.walletId)?(
            <h4 style={{ marginLeft: 0, marginBottom: 10, fontWeight: 'bold', marginTop: 20 }}>{<ReduxLanguage languageKey={'forms.cardWallet'} />}</h4>
          ):null}
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginBottom:10,paddingTop:5}}>
            {!card||!card.id?(
              <Autocomplete
                id="customer-search"
                style={{ width: 400 }}
                getOptionLabel={(option) => option.name}
                filterOptions={(x) => (x as ICustomerInDTO[])}
                options={
                  customersSearch&&customersSearch.data&&(customersSearch.data as PaginatedDataDTO<ICustomerInDTO>).content?
                  (customersSearch.data as PaginatedDataDTO<ICustomerInDTO>).content:[]
                }
                autoComplete
                loading={customersLoading}
                includeInputInList
                filterSelectedOptions
                value={customerSelected}
                noOptionsText={<ReduxLanguage languageKey={customerSearchInput?'recharges.customerSearchNoMatch':'recharges.customerSearchEnterName'} />}
                onChange={(event, newValue) => {this.onCustomerSelected(newValue)}}
                onInputChange={(event, newInputValue) => {this.onCustomerSearchInputChanged(newInputValue)}}
                renderInput={(params) => (
                  <TextField
                    autoComplete='off'
                    {...params}
                    variant="outlined"
                    label={<ReduxLanguage languageKey={'recharges.customerSearch'} />}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {customersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            ):null}
            {(card&&card.id&&customerSelected)?(
              <Select
                options={[]}
                isClearable
                isSearchable={false}
                formatOptionLabel={(o) => <>{o.name}</>}
                value={customerSelected}
                isDisabled={true}
                required
                isOptionSelected={(o) => {return true}}
                placeholder={<></>}
                onChange={(data) => {}}
                styles={{
                  menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                  control: (styles) => ({ ...styles, minHeight: 55, marginTop: 5 }),
                }} 
              />
            ):null}
            {(!card||!card.id)&&customerSelected&&!plantsLoading?(
              <div style={{marginTop:0}}>
                <Select
                  key={`select_modal_select_key`}
                  value={plantSelected}
                  options={plantOptions}
                  isSearchable={false}
                  isDisabled={card?.id ? true : false}
                  placeholder={<ReduxLanguage languageKey={'recharges.plantSearchEnterName'} />}
                  onChange={(e) => {this.onPlantSelected(e)}}
                  formatOptionLabel={(o) => <>
                    {walletsData.content.filter(e=>e.id===o.walletId).length>0?(
                      "[# " + walletsData.content.filter(e=>e.id===o.walletId)[0].id + "] " + walletsData.content.filter(e=>e.id===o.walletId)[0].name
                    ):""} - <b>{o.hostname}</b></>}
                  required
                  isOptionSelected={(o) => plantSelected&&plantSelected.id?plantSelected.id === o.id:false}
                  styles={{
                    menu: (styles) => Object.assign(styles, { zIndex: 1000, width: 600 }),
                    control: (styles) => ({ ...styles, minHeight: 55, width: 600 }),
                  }} 
                />
              </div>
            ):null}
            {(card&&card.id&&customerSelected&&walletSelected)?(
              <div style={{marginTop:0}}>
                <Select
                  options={[]}
                  isClearable
                  isSearchable={false}
                  formatOptionLabel={(o) => <>{
                    "[# " + o.id + "] " + o.name
                  }</>}
                  value={walletSelected}
                  isDisabled={true}
                  required
                  isOptionSelected={(o) => {return true}}
                  placeholder={<></>}
                  onChange={(data) => {}}
                  styles={{
                    menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                    control: (styles) => ({ ...styles, minHeight: 55, marginTop: 5 }),
                  }} 
                />
              </div>
            ):null}
          </div>

          <div style={standardCompStyles.rowContainer}>
            <div style={{ ...standardCompStyles.singleElementContainer, marginRight: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.iseoSalesOrderReference'} />}</h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                style={{ marginTop: 10 }}
                error={textInputErrors.iseoSalesOrderReference}
                value={cardData?.iseoSalesOrderReference}
                onChange={e => this.onSetValue('iseoSalesOrderReference', e.target.value)}
              />
            </div>
            <div style={{ ...standardCompStyles.singleElementContainer, marginLeft: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.iseoSalesOrderDate'} />}</h4>
              <KeyboardDatePicker
                disableToolbar
                style={{ width: '100%' }}
                variant="inline"
                format="DD/MM/YYYY"
                margin="dense"
                clearable
                id="date-picker-inline"
                placeholder={translations.t('forms.pickDate') + ' (dd/mm/yyyy)'}
                value={cardData.iseoSalesOrderDate ? moment(cardData.iseoSalesOrderDate) : null}
                onChange={e => this.onSetValue('iseoSalesOrderDate', e?.valueOf())}
              />
            </div>
          </div>
          <div style={standardCompStyles.rowContainer}>
            <div style={{ ...standardCompStyles.singleElementContainer, marginRight: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.iseoPurchaseOrderReference'} />}</h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                style={{ marginTop: 10 }}
                error={textInputErrors.iseoPurchaseOrderReference}
                value={cardData?.iseoPurchaseOrderReference}
                onChange={e => this.onSetValue('iseoPurchaseOrderReference', e.target.value)}
              />
            </div>
            <div style={{ ...standardCompStyles.singleElementContainer, marginLeft: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.iseoPurchaseOrderDate'} />}</h4>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                margin="dense"
                clearable
                id="date-picker-inline"
                placeholder={translations.t('forms.pickDate') + ' (dd/mm/yyyy)'}
                value={cardData.iseoPurchaseOrderDate ? cardData.iseoPurchaseOrderDate : null}
                onChange={e => this.onSetValue('iseoPurchaseOrderDate', e?.valueOf())}
              />
            </div>
          </div>
          <div style={standardCompStyles.rowContainer}>
            <div style={{ ...standardCompStyles.singleElementContainer, marginRight: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.sofiaSalesOrderReference'} />}</h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                style={{ marginTop: 10 }}
                error={textInputErrors.sofiaSalesOrderReference}
                value={cardData?.sofiaSalesOrderReference}
                onChange={e => this.onSetValue('sofiaSalesOrderReference', e.target.value)}
              />
            </div>
            <div style={{ ...standardCompStyles.singleElementContainer, marginLeft: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.sofiaSalesOrderDate'} />}</h4>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                margin="dense"
                clearable
                id="date-picker-inline"
                placeholder={translations.t('forms.pickDate') + ' (dd/mm/yyyy)'}
                value={cardData.sofiaSalesOrderDate ? cardData.sofiaSalesOrderDate : null}
                onChange={e => this.onSetValue('sofiaSalesOrderDate', e?.valueOf())}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 20 }} />
          <h2 style={{ margin: 0, fontWeight: 'bold', marginTop: 20}}>{'Fatturazione Sofia'}</h2>
          <div style={{ display: 'flex' , flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <div style={{ flex: 1, marginRight: 20 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.sofiaInvoiceNumber'} />}</h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                style={{ marginTop: 10 }}
                error={textInputErrors.sofiaInvoiceNumber}
                value={cardData?.sofiaInvoiceNumber}
                onChange={e => this.onSetValue('sofiaInvoiceNumber', e.target.value)}
              />
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.sofiaInvoiceAmount'} />}</h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                type="number"
                required
                style={{ marginTop: 10 }}
                error={textInputErrors.sofiaInvoiceAmount}
                value={cardData?.sofiaInvoiceAmount}
                onChange={e => this.onSetValue('sofiaInvoiceAmount', parseFloat(e.target.value))}
              />
            </div>
            <div style={{ flex: 1 }}>
            <div style={{ ...standardCompStyles.singleElementContainer, marginLeft: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.sofiaInvoiceDate'} />}</h4>
              <KeyboardDatePicker
                disableToolbar
                style={{ width: '100%' }}
                variant="inline"
                format="DD/MM/YYYY"
                margin="dense"
                clearable
                id="date-picker-inline"
                placeholder={translations.t('forms.pickDate') + ' (dd/mm/yyyy)'}
                value={cardData?.sofiaInvoiceDate ? moment(cardData.sofiaInvoiceDate) : null}
                onChange={e => this.onSetValue('sofiaInvoiceDate', e?.valueOf())}
              />
            </div>
            </div>
          </div>
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.sofiaInvoiceRecipient'} />}</h4>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              required
              style={{ marginTop: 10 }}
              value={cardData?.sofiaInvoiceRecipient}
              onChange={e => this.onSetValue('sofiaInvoiceRecipient', e.target.value)}
            />
          <Divider style={{ marginTop: 20 }} />
          <h2 style={{ margin: 0, fontWeight: 'bold', marginTop: 20}}>{'Fatturazione ISEO'}</h2>
          <div style={{ display: 'flex' , flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <div style={{ flex: 1, marginRight: 20 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.iseoInvoiceNumber'} />}</h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                style={{ marginTop: 10 }}
                error={textInputErrors.iseoInvoiceNumber}
                value={cardData?.iseoInvoiceNumber}
                onChange={e => this.onSetValue('iseoInvoiceNumber', e.target.value)}
              />
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.iseoInvoiceAmount'} />}</h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                type="number"
                required
                style={{ marginTop: 10 }}
                error={textInputErrors.iseoInvoiceAmount}
                value={cardData?.iseoInvoiceAmount}
                onChange={e => this.onSetValue('iseoInvoiceAmount', parseFloat(e.target.value))}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ ...standardCompStyles.singleElementContainer, marginLeft: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.iseoInvoiceDate'} />}</h4>
              <KeyboardDatePicker
                disableToolbar
                style={{ width: '100%' }}
                variant="inline"
                format="DD/MM/YYYY"
                margin="dense"
                clearable
                id="date-picker-inline"
                placeholder={translations.t('forms.pickDate') + ' (dd/mm/yyyy)'}
                value={cardData?.iseoInvoiceDate ? moment(cardData.iseoInvoiceDate) : null}
                onChange={e => this.onSetValue('iseoInvoiceDate', e?.valueOf())}
              />
            </div>
            </div>
          </div>
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.currency'} />}</h4>
            <Select
              options={_.map(CurrenciesEnum, (v) => ({ value: v }))}
              isClearable
              placeholder={<ReduxLanguage languageKey={'forms.currency'} />}
              getOptionLabel={(v) => v.value}
              value={{ value: cardData.iseoInvoiceCurrency }}
              onChange={(e) => this.onSetValue('iseoInvoiceCurrency', e ? e.value : null)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, marginTop: 10, marginBottom: 10 }),
              }} 
            />
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.iseoInvoiceRecipient'} />}</h4>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10 }}
            value={cardData?.iseoInvoiceRecipient}
            onChange={e => this.onSetValue('iseoInvoiceRecipient', e.target.value)}
          />
           <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.iseoInvoiceIssuer'} />}</h4>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10 }}
            value={cardData?.iseoInvoiceIssuer}
            onChange={e => this.onSetValue('iseoInvoiceIssuer', e.target.value)}
          />
          <Divider style={{ marginTop: 20 }} />
          <h2 style={{ margin: 0, fontWeight: 'bold', marginTop: 20}}>{<ReduxLanguage languageKey={'forms.serviceSupplementaryInvoice'} />}</h2>
          <div style={{ display: 'flex' , flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <div style={{ flex: 1, marginRight: 20 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.serviceSupplementaryInvoiceNumber'} />}</h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                style={{ marginTop: 10 }}
                value={cardData?.serviceSupplementaryInvoiceNumber}
                onChange={e => this.onSetValue('serviceSupplementaryInvoiceNumber', e.target.value)}
              />
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.serviceSupplementaryInvoiceAmount'} />}</h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                type="number"
                required
                style={{ marginTop: 10 }}
                value={cardData?.serviceSupplementaryInvoiceAmount}
                onChange={e => this.onSetValue('serviceSupplementaryInvoiceAmount', parseFloat(e.target.value))}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ ...standardCompStyles.singleElementContainer, marginLeft: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.serviceSupplementaryInvoiceDate'} />}</h4>
              <KeyboardDatePicker
                disableToolbar
                style={{ width: '100%' }}
                variant="inline"
                format="DD/MM/YYYY"
                margin="dense"
                clearable
                id="date-picker-inline"
                placeholder={translations.t('forms.pickDate') + ' (dd/mm/yyyy)'}
                value={cardData?.serviceSupplementaryInvoiceDate ? moment(cardData.serviceSupplementaryInvoiceDate) : null}
                onChange={e => this.onSetValue('serviceSupplementaryInvoiceDate', e?.valueOf())}
              />
            </div>
            </div>
          </div>
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.serviceSupplementaryInvoiceRecipient'} />}</h4>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            style={{ marginTop: 10 }}
            value={cardData?.serviceSupplementaryInvoiceRecipient}
            onChange={e => this.onSetValue('serviceSupplementaryInvoiceRecipient', e.target.value)}
          />
          <Divider style={{ marginTop: 20 }} />
          <h4 style={{ marginTop: 34, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.crmSource'} />}<span style={{ color: 'red' }}>*</span></h4>
          <Select
            options={_.map(ICRMSourceEnum, (v) => ({ value: v }))}
            isClearable
            placeholder={<ReduxLanguage languageKey={'forms.crmSource'} />}
            getOptionLabel={(v) => v.value}
            value={{ value: cardData.crmSource }}
            onChange={(e) => this.onSetValue('crmSource', e ? e.value : null)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, marginTop: 10, marginBottom: 10 }),
            }} 
          />

          <div style={standardCompStyles.rowContainer}>
            <div style={{ ...standardCompStyles.singleElementContainer, marginRight: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.crmId'} />}<span style={{ color: 'red' }}>*</span></h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                style={{ marginTop: 10 }}
                error={textInputErrors.crmId}
                value={cardData?.crmId}
                onChange={e => this.onSetValue('crmId', e.target.value)}
              />
            </div>
            <div style={{ ...standardCompStyles.singleElementContainer, marginLeft: 10 }}>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.sofiaProjectId'} />}<span style={{ color: 'red' }}>*</span></h4>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                style={{ marginTop: 10 }}
                error={textInputErrors.sofiaProjectId}
                value={cardData?.sofiaProjectId}
                onChange={e => this.onSetValue('sofiaProjectId', e.target.value)}
              />
            </div>
          </div>

          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.kamReference'} />}<span style={{ color: 'red' }}>*</span></h4>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10 }}
            error={textInputErrors.kamReference}
            value={cardData?.kamReference}
            onChange={e => this.onSetValue('kamReference', e.target.value)}
          />
      
          {!card?.id ?
            <Card className={classes.cardContainer}>
              <h3 className={classes.title}>{<ReduxLanguage languageKey="forms.cardSubscriptionsLinked" />}</h3>

              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.cardMarketplacesLinked'} />}</h4>
              <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.cardMarketplacesLinkedDescription'} />}</h5>
              <Autocomplete
                id="marketplaces"
                options={marketplacesOptions}
                style={{ marginTop: 10 }}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                onChange={(e, value) => this.onSelectMarketplaceValue(value)}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
              
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'credits.tierSubscription'} />}</h4>
              <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.mainTierDescription'} />}</h5>
              <Autocomplete
                id="actionsMainTier"
                options={mainTierOptions}
                style={{ marginTop: 10 }}
                getOptionLabel={(option) => option.label}
                onChange={(e, value) => this.onSelectMainTierValue(value)}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
              {selectedMainTier ? (
                <div key={selectedMainTier.id} style={{ marginTop: 10 }}>
                  <Card style={{ padding: 5 }}>
                    <SubscriptionCostDetails
                      subscription={selectedMainTier}
                    />
                  </Card>
                </div>
              ) : null}

              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.pluginAndService'} />}</h4>
              <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.cardSubscriptionsLinkedDescription'} />}</h5>
              <Autocomplete
                id="actionsPlugins"
                options={[...servicesOptions, ...subscriptionsOptions]}
                multiple
                style={{ marginTop: 10 }}
                getOptionLabel={(option) => option.label}
                onChange={(e, values) => this.onSelectSubscriptionValue(values)}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
              {selectedLicenses ? (
                _.map(selectedLicenses, license => (
                  <div key={license.id} style={{ marginTop: 10 }}>
                    <Card style={{ padding: 5 }}>
                      <SubscriptionCostDetails
                        subscription={license}
                      />
                    </Card>
                  </div>
                ))
              ) : null}
            </Card> : null
          }
          
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.cardNotes'} />}</h4>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            rows={4}
            multiline
            style={{ marginTop: 10 }}
            error={textInputErrors.notes}
            value={cardData?.notes}
            onChange={e => this.onSetValue('notes', e.target.value)}
          />
          <Button
            variant="contained"
            disabled={!this.canSubmitForm()}
            style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 20, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    vars: state.vars.vars.data,
    customersSearch: state.credits.systemCards.customersSearch,
    customerPlants: state.credits.systemCards.plants,
    wallets: state.credits.systemCards.wallets.data,
  };
}

const connector = connect(mapStateToProps);
export default connector(withStyles(styles)(CardForm));