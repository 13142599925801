import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import 'moment/locale/it';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IState } from '../redux/store';

const styles = createStyles({
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  }
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IServiceStatusProps extends WithStyles<typeof styles> {

}

export type ComponentProps = IServiceStatusProps & IReduxProps;

export interface IActivationNewPageState {
  
};

class ServiceStatus extends React.Component<ComponentProps,IActivationNewPageState> {

  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      
    } catch (error) {
      
    }
  }

  render() {
    const { classes } = this.props;
    
    return (
      <div style={{maxHeight:'calc(100vh - 64px)',minHeight:'calc(100vh - 64px)'}}>
        <div className={classes.header}>
          <iframe src={"https://sofialocks-luckey.freshstatus.io/"} title="description"
            style={{border: 0, width:'calc(100% - 20px)', maxHeight:'calc(100vh - 90px)',minHeight:'calc(100vh - 90px)'}}>
          </iframe>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(ServiceStatus as any));