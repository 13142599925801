import { PaginatedDataDTO, PaginationDTO } from "@bottega52/commons-pagination";
import { Button, Card } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import * as React from "react";
import { connect, ConnectedProps } from 'react-redux';
import * as VarOpportunityDecoder from '../../codec/varOpportunityInDTODecoder';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as VarsActions from '../../redux/vars/vars.actions';
import { ICustomerInDTO } from "../../repository/jago/model/input/ICustomerInDTO";
import { IVarInDTO } from "../../repository/jago/model/input/IVarInDTO";
import { IVarOpportunityFormDTO } from "../../repository/jago/model/input/IVarOpportunityFormDTO";
import { IVarOpportunityInDTO } from "../../repository/jago/model/input/IVarOpportunityInDTO";
import { IVarOpportunityOutDTO } from "../../repository/jago/model/output/IVarOpportunityOutDTO";
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';
import VarOpportunityForm from "../Forms/VarOpportunityForm";
import { ModalTypes } from "../Modals/ModalTypes";
import Permission from "../Permission/Permission";
import ReduxLanguage from "../ReduxLanguage";
import VarOpportunitiesTable from "../Vars/VarOpportunitiesTable";

const styles = createStyles({
  container: {
    display: 'flex',
    marginTop: 20,
    flexDirection: 'column',
  },
  cardContainer: {
    padding: 10,
    borderLeft: '3px solid #5AC0B1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 370px)',
    width: '100%',
    marginTop: 10
  }
});

export interface ICustomerOpportunitySectionState {
  pageSize: number;
}

type IReduxProps = ConnectedProps<typeof connector>;

export interface ICustomerOpportunitySectionProps extends WithStyles<typeof styles> {
  language: string;
  opportunities: PaginatedDataDTO<IVarOpportunityInDTO>;
  customer: ICustomerInDTO;
}

export type ComponentProps = ICustomerOpportunitySectionProps & IReduxProps;

class CustomerOpportunitySection extends React.Component<ComponentProps, ICustomerOpportunitySectionState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      pageSize: 100,
    } 
  }

  onCreateOpportunityRequest(opportunity: IVarOpportunityFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.newOpportunity',
        successMessageKey: 'forms.createVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onCreateOpportunityConfirm(opportunity: IVarOpportunityFormDTO) {
    const { dispatch, customer, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityFromForm(opportunity,true)
      opportunityOut.varId = opportunity.varId
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.createNewVarOpportunity(opportunity.customerId, opportunityOut));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('OPPORTUNITY_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('CREATE_OPPORTUNITY_CONFIRM_MODAL'));
      dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize, customerId: customer.id.toString() }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createVarOpportunityError',
        }
      }));
    }
  }

  async onOpenOpportunityForm(opportunityToEdit?: IVarOpportunityInDTO) {
    const { dispatch, VAR, customer } = this.props;
    try {
      const opportunity = opportunityToEdit?VarOpportunityDecoder.decodeVarOpportunityContentToForm(opportunityToEdit):undefined
      const varId = opportunity?opportunity.varId:(VAR as IVarInDTO).id
      if (!varId)
        throw new Error()
      dispatch<any>(ModalsActions.showModal(`OPPORTUNITY_FORM_VIEW`, {
        modalType: opportunityToEdit?ModalTypes.OPERATIONAL_VIEW_MODAL:ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
        modalProps: {
          content: (
            <VarOpportunityForm
              opportunity={opportunity}
              varId={varId}
              onCreateEditVarOpportunity={opportunity => opportunityToEdit ? this.onEditOpportunityRequest(opportunityToEdit.id, opportunity) : this.onCreateOpportunityRequest(opportunity)}
              onOpenCustomerInfo={()=>{}}
              hideShowCustomerInfo={true}
              forcedCustomer={!opportunityToEdit?customer:undefined}
            />
          ),
          titleMessageKey: opportunityToEdit ?  'vars.editOpportunity' : 'vars.newOpportunity',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }

  onEditOpportunityRequest(opportunityId: number, opportunity: IVarOpportunityFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.editOpportunity',
        successMessageKey: 'forms.editVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditOpportunityConfirm(opportunityId, opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onEditOpportunityConfirm(opportunityId: number, opportunity: IVarOpportunityFormDTO) {
    const { dispatch, customer, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityFromForm(opportunity,true)
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.editVarOpportunity(opportunity.customerId, opportunityId, opportunityOut));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('OPPORTUNITY_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('EDIT_OPPORTUNITY_CONFIRM_MODAL'));
      dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize, customerId: customer.id.toString() }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_EDIT`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.editVarOpportunityError',
        }
      }));
    }
  }

  onDeleteVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.deleteVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, customer, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.deleteVarOpportunity(opportunity.customerId, opportunity.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize, customerId: customer.id.toString() }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteVarOpportunityError',
        }
      }));
    }
  }

  onUpgradeVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`UPGRADE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.upgradeVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onUpgradeVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`UPGRADE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onUpgradeVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, customer, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.upgradeVarOpportunity(opportunity.customerId, opportunity.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('UPGRADE_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize, customerId: customer.id.toString() }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_UPGRADE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.upgradeVarOpportunityError',
        }
      }));
    }
  }

  onWinVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`WIN_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.winVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onWinVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`WIN_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onWinVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, customer, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.concludeVarOpportunity(
        opportunity.customerId, opportunity.id, VarOpportunityDecoder.VarOpportunityStatusOptions.WON));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('WIN_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize, customerId: customer.id.toString() }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_WIN`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.winVarOpportunityError',
        }
      }));
    }
  }

  onLoseVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`LOSE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.lostVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onLoseVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`LOSE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onLoseVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, customer } = this.props;
    const { pageSize } = this.state;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.concludeVarOpportunity(
        opportunity.customerId, opportunity.id, VarOpportunityDecoder.VarOpportunityStatusOptions.LOST));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('LOSE_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page:0, pageSize, customerId: customer.id.toString() }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_LOSE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.loseVarOpportunityError',
        }
      }));
    }
  }

  async onFetchElementsOnPage(page: number) {
    const { dispatch, customer } = this.props;
    const { pageSize } = this.state;
    await dispatch<any>(VarsActions.fetchOpportunities({ page, pageSize, customerId: customer.id.toString() }));
  }

  onPageSizeChange(pageSize: number) {
    const { dispatch, customer } = this.props;
    this.setState({ pageSize }, async () => {
      await dispatch<any>(VarsActions.fetchOpportunities({ page: 0, pageSize, customerId: customer.id.toString() }));
    })
  }
  
  public render() {
    const { opportunities, classes } = this.props;
    const { pageSize } = this.state;
    return (
      <div className={classes.container}>
        <Card className={classes.cardContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <MonetizationOnOutlinedIcon className={classes.icon} />
              <h3 className={classes.title}>{<ReduxLanguage languageKey="vars.opportunities" />}</h3>
            </div>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_CREATE}>
              <Button
                variant="contained"
                style={{ fontWeight: 'bold', backgroundColor: '#5AC0B1', fontSize: 11, color: 'white' }}
                onClick={() => this.onOpenOpportunityForm()}
              >
                <ReduxLanguage languageKey="vars.newOpportunity" />
              </Button>
            </Permission>
          </div>
          <div className={classes.tableContainer}>
            <VarOpportunitiesTable
              rowsPerPage={pageSize}
              opportunities={opportunities}
              hideVarColumn={true}
              hideCustomerColumn={true}
              onRowClick={(opportunity) => this.onOpenOpportunityForm(opportunity)}
              onDeleteVarOpportunityRequest={(opportunity)=> this.onDeleteVarOpportunityRequest(opportunity)}
              onRejectVarOpportunityRequest={()=>{}}
              onApproveVarOpportunityRequest={()=>{}}
              onUpgradeVarOpportunityRequest={(opportunity)=> this.onUpgradeVarOpportunityRequest(opportunity)}
              onWinVarOpportunityRequest={(opportunity)=> this.onWinVarOpportunityRequest(opportunity)}
              onLoseVarOpportunityRequest={(opportunity)=> this.onLoseVarOpportunityRequest(opportunity)}
              onPageSizeChange={pageSize => this.onPageSizeChange(pageSize)}
              onFetchElementsOnPage={page => this.onFetchElementsOnPage(page)}
            />
          </div>
        </Card>
      </div>
    );
  }
}


function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    opportunities: state.vars.opportunities.data,
    VAR: state.vars.VAR.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(CustomerOpportunitySection as any));