import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import { ActivationstatusOptions } from '../../codec/activationInDTODecoder';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import translations from '../../translations/i18next';
import ReduxLanguage from '../ReduxLanguage';
import { IVarsSearchDTO } from '../../repository/jago/model/input/IVarsSearchDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import Permission from '../Permission/Permission';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems:'center',
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});


export interface IActivationStatusOption {
  label: string;
  value: string;
  original: string;
}
export interface IActivationClusterOption {
  label: string;
  value: string;
}

export interface IVarsSearchBarProps extends WithStyles<typeof styles> {
  onSearch: (searchValues: IVarsSearchDTO) => void;
  onResetSearch: () => void;
  totalElements: string | number;
  onCreateNewEntityButtonClick: () => void;
  clusters: PaginatedDataDTO<IClusterInDTO>;
}

export interface IVarsSearchBarState {
  searchValues: Record<keyof IVarsSearchDTO, string>;
  clusterSelected: IClusterInDTO | undefined;
}

class VarsSearchBar extends React.Component<IVarsSearchBarProps, IVarsSearchBarState> {

  constructor(props: IVarsSearchBarProps) {
    super(props);
    this.state = {
      searchValues: {
        name: "",
        clusterId: "",
      },
      clusterSelected: undefined,
    }
  }

  public onSetSearchValue(field: keyof IVarsSearchDTO, value: string) {
    const { searchValues } = this.state;
    searchValues[field] = value;
    this.setState({ searchValues });
    this.onSearch()
  }

  public onSelectCluster(clusterOption: IClusterInDTO) {
    this.onSetSearchValue('clusterId', clusterOption && clusterOption.id ? clusterOption.id.toString() : '');
    this.setState({ clusterSelected: clusterOption });
  }

  public onSearch() {
    const { onSearch } = this.props;
    onSearch(this.state.searchValues);
  }

  public onResetFields() {
    const { onResetSearch } = this.props;
    this.setState({
        searchValues:{
          name: "",
          clusterId: "",
        },
        clusterSelected: undefined,
    });
    onResetSearch();
  }

  public render() {
    const { classes, onCreateNewEntityButtonClick, clusters } = this.props;
    const { searchValues, clusterSelected } = this.state;
    return (
      <div className={classes.container}>
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey="forms.search" /> }
          variant="outlined"
          type="search"
          style={{ marginLeft: 0, marginRight: 10 }}
          value={searchValues.name}
          onChange={e => this.onSetSearchValue('name', e.target.value)}
        />
        <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.CLUSTER_FILTER}>
          <Select
            isClearable
            isSearchable={false}
            value={clusterSelected}
            options={(clusters as PaginatedDataDTO<IClusterInDTO>).content}
            placeholder={<><ReduxLanguage languageKey={'vars.clusterSelection'} /></>}
            formatOptionLabel={(o) => <>[{o.market}] - <b>{o.name}</b></>}
            isOptionSelected={(o) => clusterSelected?.id === o.id}
            onChange={(e) => this.onSelectCluster(e)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, minWidth: 350, marginLeft: 10, marginRight: 10, maxWidth:350 }),
            }} 
          />
        </Permission>
        <span style={{flexGrow:'1'}}></span>
        <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.VAR_CREATE}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#5AC0B1', color: 'white', marginRight: 20, marginLeft: 40, fontWeight: 'bold'  }}
            onClick={() => onCreateNewEntityButtonClick()}
          >
            <ReduxLanguage languageKey="forms.add" />
          </Button>
        </Permission>
        
      </div>
    );
  }
}

export default withStyles(styles)(VarsSearchBar);
