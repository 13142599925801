import { Card, CardActionArea } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import WalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import CreditsIcon from '@material-ui/icons/MonetizationOn';
import * as React from "react";
import { IWalletCustomerDTO } from "../../repository/jago/model/input/IWalletCustomerDTO";
import { ORANGE } from "../../style/StyleConsts";
import Utils from '../../utils/Utils';
import WalletStatusView from "./WalletStatusView";

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
  },
  rowActionBodyContainer: {
    padding: 10,
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowBodyInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    marginRight: 10,
  },
  logo: {
    width: '20%',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  icon: {
    fontSize: 15
  },
  headerCreditsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center', 
  },
  creditIcon: {
    color: ORANGE
  }
});




export interface IWalletRowProps extends WithStyles<typeof styles>{
  wallet?: IWalletCustomerDTO;
  onClick: () => void;
}

class WalletRow extends React.Component<IWalletRowProps, any> {
  public render() {
    const { wallet, classes, onClick } = this.props;
    return (
      <Card className={classes.rowContainer}>
        <CardActionArea className={classes.rowActionBodyContainer} onClick={() => onClick()}>
          <div className={classes.rowBodyContainer}>
            <div className={classes.rowBodyInnerContainer}>
              <WalletIcon className={classes.avatar} />
              <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h3 className={classes.rowTitle}>#{wallet?.id} - {wallet?.name}</h3>
                  <WalletStatusView status={wallet?.status} />
                </div>
                {wallet?.notes ? <h3 style={{ marginTop: 10 }}>{wallet?.notes}</h3> : null}
              </div>
            </div>
            <div className={classes.headerCreditsContainer}>
              <CreditsIcon className={classes.creditIcon}/>
              <h3>{Utils.formatCreditsIn(wallet?.availableCredits)}</h3>
            </div>
            
          </div>
        </CardActionArea>
      </Card>
    );
  }
}

export default withStyles(styles)(WalletRow);