import _ from 'lodash';

export default class AbilityHelper {

  userRole = '';
  userPermissions = [];

  setUserRole(role: string) {
    this.userRole = role;
  }

  setUserPermissions(permissions: string[]) {
    this.userPermissions = permissions;
  }

  hasRoles(roles: string[]) {
    if (!this.userRole) return false;
    return _.includes(roles, this.userRole);
  }

  hasPermission(permission: string) {
    if (!this.userPermissions.length) return false;
    return _.includes(this.userPermissions, permission);
  }

  hasPermissions(permissions: string[]) {
    if (!this.userPermissions.length) return false;
    const missingPermissions = _.difference(this.userPermissions, permissions);
    return _.isEmpty(missingPermissions);
  }
}
