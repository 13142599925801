export enum CompatibilityRoles {
  ROLE_SUPPORT_ADMIN_SOFIA = 'ROLE_SUPPORT_ADMIN_SOFIA',
  ROLE_ORDER_MANAGEMENT = 'ROLE_ORDER_MANAGEMENT',
  ROLE_SUPPORT_ADMIN = 'ROLE_SUPPORT_ADMIN',
  ROLE_SUPPORT_VAR = 'ROLE_SUPPORT_VAR',
}

export enum Roles {
  ROLE_VASHO_SOFIA_ADMIN = 'ROLE_VASHO_SOFIA_ADMIN',
  ROLE_VASHO_GLOBAL_SEGMENT_LEADER = 'ROLE_VASHO_GLOBAL_SEGMENT_LEADER',
  ROLE_VASHO_GLOBAL_SALES_OPERATION_MANAGER = 'ROLE_VASHO_GLOBAL_SALES_OPERATION_MANAGER',
  ROLE_VASHO_SALES_OPERATION_MANAGER = 'ROLE_VASHO_SALES_OPERATION_MANAGER',
  ROLE_VASHO_MARKET_LEADER = 'ROLE_VASHO_MARKET_LEADER',
  ROLE_VASHO_EUSM = 'ROLE_VASHO_EUSM',
  ROLE_VASHO_KAM = 'ROLE_VASHO_KAM',
  ROLE_VASHO_GLOBAL_SOC_MANAGER = 'ROLE_VASHO_GLOBAL_SOC_MANAGER',
  ROLE_VASHO_LOCAL_SOC_MANAGER = 'ROLE_VASHO_LOCAL_SOC_MANAGER',
  ROLE_VASHO_VAR_ADMIN = 'ROLE_VASHO_VAR_ADMIN',
  ROLE_VASHO_VAR_SALES_MANAGER = 'ROLE_VASHO_VAR_SALES_MANAGER',
  ROLE_VASHO_VAR_SALES_REPRESENTATIVE = 'ROLE_VASHO_VAR_SALES_REPRESENTATIVE',
  ROLE_VASHO_VAR_SUPPORT = 'ROLE_VASHO_VAR_SUPPORT',
  ROLE_UNDEFINED = 'ROLE_UNDEFINED',
}

export const RolesWithClusters = [
  Roles.ROLE_VASHO_SALES_OPERATION_MANAGER,
  Roles.ROLE_VASHO_MARKET_LEADER,
  Roles.ROLE_VASHO_EUSM,
  Roles.ROLE_VASHO_KAM,
  Roles.ROLE_VASHO_LOCAL_SOC_MANAGER,
];

export const SUPPORT_AGENT_READ_ONLY_EMAIL = 'support+readonly@sofialocks.com';

export enum PERMISSIONS {
  NAVIGATION_VAR_MANAGEMENT = 'NAVIGATION_VAR_MANAGEMENT',
  NAVIGATION_MARKETPLACES = 'NAVIGATION_MARKETPLACES',
  NAVIGATION_CUSTOMERS = 'NAVIGATION_CUSTOMERS',
  NAVIGATION_OPPORTUNITIES = 'NAVIGATION_OPPORTUNITIES',
  NAVIGATION_DOMAINS = 'NAVIGATION_DOMAINS',
  NAVIGATION_PLANT_ACTIVATIONS = 'NAVIGATION_PLANT_ACTIVATIONS',
  NAVIGATION_RECHARGE_REQUESTS = 'NAVIGATION_RECHARGE_REQUESTS',
  NAVIGATION_ORDERS_STATUS = 'NAVIGATION_ORDERS_STATUS',
  NAVIGATION_GATEWAYS = 'NAVIGATION_GATEWAYS',
  NAVIGATION_PRICELIST = 'NAVIGATION_PRICELIST',
  NAVIGATION_CARDS = 'NAVIGATION_CARDS',
  NAVIGATION_SALESINFO = 'NAVIGATION_SALESINFO',
  NAVIGATION_PRODUCTINFO = 'NAVIGATION_PRODUCTINFO',
  NAVIGATION_VAR_USERS = 'NAVIGATION_VAR_USERS',
  NAVIGATION_VAR_SETTINGS = 'NAVIGATION_VAR_SETTINGS',
  NAVIGATION_PLATFORM_USERS = 'NAVIGATION_PLATFORM_USERS',

  CLUSTER_FILTER = 'CLUSTER_FILTER',

  KAM_READ = 'KAM_READ',

  VAR_READ = 'VAR_READ',
  VAR_CREATE = 'VAR_CREATE',
  VAR_UPDATE = 'VAR_UPDATE',

  VAR_PLANT_THEME = 'VAR_PLANT_THEME',
  VAR_USER_READ = 'VAR_USER_READ',
  VAR_USER_UPDATE = 'VAR_USER_UPDATE',
  VAR_USER_CREATE = 'VAR_USER_CREATE',

  CUSTOMER_READ = 'CUSTOMER_READ',
  CUSTOMER_CREATE = 'CUSTOMER_CREATE',
  CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
  CUSTOMER_DELETE_REQUEST = 'CUSTOMER_DELETE_REQUEST',
  CUSTOMER_EXPORT = 'CUSTOMER_EXPORT',
  CUSTOMER_DELETE = 'CUSTOMER_DELETE',

  OPPORTUNITY_READ = 'OPPORTUNITY_READ',
  OPPORTUNITY_CREATE = 'OPPORTUNITY_CREATE',
  OPPORTUNITY_UPDATE = 'OPPORTUNITY_UPDATE',
  OPPORTUNITY_EXPORT = 'OPPORTUNITY_EXPORT',
  
  OPPORTUNITY_MARK_TO_BE_APPROVED = 'OPPORTUNITY_MARK_TO_BE_APPROVED',
  OPPORTUNITY_APPROVE = 'OPPORTUNITY_APPROVE',
  OPPORTUNITY_REJECT = 'OPPORTUNITY_REJECT',
  OPPORTUNITY_MARK_LOST = 'OPPORTUNITY_MARK_LOST',
  OPPORTUNITY_MARK_WON = 'OPPORTUNITY_MARK_WON',
  OPPORTUNITY_CANCEL = 'OPPORTUNITY_CANCEL',

  WALLET_READ = 'WALLET_READ',
  WALLET_CREATE = 'WALLET_CREATE',
  WALLET_EXPORT = 'WALLET_EXPORT',
  WALLET_UPDATE = 'WALLET_UPDATE',
  WALLET_RECHARGE = 'WALLET_RECHARGE',
  WALLET_DELETE_REQUEST = 'WALLET_DELETE_REQUEST',
  WALLET_SUBSCRIPTION_UPDATE = 'WALLET_SUBSCRIPTION_UPDATE',
  WALLET_SUBSCRIPTION_DELETE = 'DOMAIN_SUBSCRIPTION_DELETE',

  DOMAIN_CREATE = 'DOMAIN_CREATE',
  DOMAIN_EXPORT_ALL_MANAGERS = 'DOMAIN_EXPORT_ALL_MANAGERS',
  DOMAIN_READ = 'DOMAIN_READ',
  DOMAIN_MANAGER_CREATE = 'DOMAIN_MANAGER_CREATE',
  DOMAIN_MANAGER_UPDATE = 'DOMAIN_MANAGER_UPDATE',
  DOMAIN_INSTALLER_CREATE = 'DOMAIN_INSTALLER_CREATE',
  DOMAIN_INSTALLER_UPDATE = 'DOMAIN_INSTALLER_UPDATE',
  DOMAIN_DELETE_REQUEST = 'DOMAIN_DELETE_REQUEST',
  DOMAIN_ADDITIONAL_INFO_UPDATE = 'DOMAIN_ADDITIONAL_INFO_UPDATE',
  DOMAIN_EXTEND = 'DOMAIN_EXTEND',
  DOMAIN_CONFIG = 'DOMAIN_CONFIG',
  DOMAIN_IRIS_ENABLE = 'DOMAIN_IRIS_ENABLE',
  DOMAIN_IMPERSONATE_WRITE = 'DOMAIN_IMPERSONATE_WRITE',
  DOMAIN_IMPERSONATE_SUPPORT = 'DOMAIN_IMPERSONATE_SUPPORT',

  RECHARGE_REQUEST_EXPORT = 'RECHARGE_REQUEST_EXPORT',
  RECHARGE_REQUEST_READ = 'RECHARGE_REQUEST_READ',
  RECHARGE_REQUEST_CREATE = 'RECHARGE_REQUEST_CREATE',
  RECHARGE_REQUEST_EDIT = 'RECHARGE_REQUEST_EDIT',
  RECHARGE_REQUEST_TAKE_CHARGE = 'RECHARGE_REQUEST_TAKE_CHARGE',
  
  ACTIVATION_REQUEST_EXPORT = 'ACTIVATION_REQUEST_EXPORT',
  ACTIVATION_REQUEST_READ = 'ACTIVATION_REQUEST_READ',
  ACTIVATION_REQUEST_CREATE = 'ACTIVATION_REQUEST_CREATE',
  ACTIVATION_REQUEST_EDIT = 'ACTIVATION_REQUEST_EDIT',

  GATEWAYS = 'GATEWAYS',

  CARD_CREATE = 'CARD_CREATE',
  CARD_UPDATE = 'CARD_UPDATE',
};