import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { Button, Collapse, Menu, MenuItem, Popover, Tooltip } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { DeleteOutline, Edit, ExpandLess, ExpandMore, PublicOutlined, SearchOutlined } from '@material-ui/icons';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import BuildIcon from '@material-ui/icons/Build';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import CardIcon from '@material-ui/icons/CreditCard';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CustomersIcon from '@material-ui/icons/Group';
import LastPageIcon from '@material-ui/icons/LastPage';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import SendIcon from '@material-ui/icons/Send';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Stack } from '@mui/system';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import * as React from "react";
import { ActivationstatusOptions } from '../../codec/activationInDTODecoder';
import { IActivationInDTO } from '../../repository/jago/model/input/IActivationInDTO';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import translations from '../../translations/i18next';
import { getActivationStatusColor } from '../../utils/Utils';
import Permission from '../Permission/Permission';
import ReduxLanguage from '../ReduxLanguage';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  tableCellStyle: {
    padding: '12px',
    borderBottom: '1px solid #ddd',
  },
  info1Container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems:'flex-start',
    justifyContent:'space-evenly',
    flexGrow:1,
    flexBasis:0
  },
  info2Container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems:'flex-start',
    justifyContent:'flex-end',
    flexGrow:1,
    flexBasis:0
  },
  info3Container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'flex-end',
    flexGrow:2,
    flexBasis:0
  },
  SFCDtext: {
    fontSize:'0.9em'
  },
  plantNameText: {
    fontWeight: 'bold',
    fontSize:'1.2em',
    marginTop:'5px',
    marginBottom:'5px'
  },
  customerNameText: {
    fontSize:'0.8em',
    color:'grey',
    textTransform:'uppercase'
  },
  info2Text: {
    fontSize: 13
  },
});


export interface IActivationsTableState {
  anchorElActions: any;
  selectedActivation: IActivationInDTO | null;
  actionsStatusAdminOpen: boolean;
}

export interface IActivationsTableProps extends WithStyles<typeof styles>{
  activations: PaginatedDataDTO<IActivationInDTO>;
  rowsPerPage: number;
  isLoading: boolean;
  clusters: PaginatedDataDTO<IClusterInDTO>;
  vars: PaginatedDataDTO<IVarInDTO>;
  tableColumns?: GridColumns<IActivationInDTO>;
  onRowClick?: (activation: IActivationInDTO) => void;
  onFetchElementsOnPage: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onDeleteActivationRequest: (activation: IActivationInDTO) => void;
  onDeleteActivationAdminRequest: (activation: IActivationInDTO) => void;
  onPromoteActivationRequest: (activation: IActivationInDTO) => void;
  onCreatedActivationRequest: (activation: IActivationInDTO) => void;
  onRejectActivationRequest: (activation: IActivationInDTO) => void;
  onProcessingActivationRequest: (activation: IActivationInDTO) => void;
  onInvoicedActivationRequest:  (activation: IActivationInDTO) => void;
  onEditCustrefRequest:  (activation: IActivationInDTO) => void;
  onNewActivation: () => void;
  onEditActivation: (activation: IActivationInDTO) => void;
  onEditStatusAdmin: (activation: IActivationInDTO, newStatus: string) => void;
  onCreateNewCustomerRequest: (activation: IActivationInDTO) => void;
  onCreateNewWalletRequest: (activation: IActivationInDTO) => void;
  onCreateNewScratchcardRequest: (activation: IActivationInDTO) => void;
}

class ActivationsTable extends React.Component<IActivationsTableProps, IActivationsTableState> {
  private tableColumns: GridColumns<IActivationInDTO>;

  constructor(props: IActivationsTableProps) {
    super(props);
    this.state = {
      selectedActivation: null,
      anchorElActions: null,
      actionsStatusAdminOpen: false,
    }
  }

  public openActionsMenu(event, activation) {
    this.setState({ selectedActivation: activation});
    this.setState({ anchorElActions: event.currentTarget });
  }

  public closeActionsMenu() {
    this.setState({ selectedActivation: null });
    this.setState({ anchorElActions: null });
  };

  public toggleActionsStatusAdminOpen() {
    this.setState({ actionsStatusAdminOpen: !this.state.actionsStatusAdminOpen });
  }

  public render() {
    const { activations, vars, clusters, rowsPerPage, onPageSizeChange, onFetchElementsOnPage, classes, onEditCustrefRequest, onCreatedActivationRequest, onProcessingActivationRequest, onRejectActivationRequest, onEditStatusAdmin, onCreateNewCustomerRequest, onCreateNewWalletRequest, onCreateNewScratchcardRequest, onDeleteActivationAdminRequest, onDeleteActivationRequest, onPromoteActivationRequest, onInvoicedActivationRequest, onEditActivation } = this.props;
    const { anchorElActions, actionsStatusAdminOpen, selectedActivation } = this.state;

    this.tableColumns = [
      {
        field: 'optyId',
        headerName: translations.t('forms.customerInfo'),
        type: 'string',
        width: 280,
        sortable: false,
        resizable: true,
        renderCell: (params: any) => {
          const activation = params.row;
          const varCurrent = activation.plantRegistry && activation.plantRegistry.varId && vars && vars.content && vars.content.filter(e=>e.id === activation.plantRegistry.varId).length > 0 ? vars.content.filter(e=>e.id===activation.plantRegistry.varId)[0] : null;
          return (
            <div className={classes.info1Container}>
              <span className={classes.SFCDtext}>{activation?.optId}</span>
              <div style={{ marginLeft: 0, marginBottom: 4, marginTop: varCurrent ? 5 : 0 }}>
                {varCurrent ? (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h3 style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '0.7em', display: 'inline', border: '1px solid grey', borderRadius: 5, paddingRight: 3, margin: 0, marginRight: 5 }}>
                      {"VAR "}
                    </h3>
                    <h3 className={classes.plantNameText} style={{ margin: 0, marginBottom: 1 }}>
                      {varCurrent.name}
                    </h3>
                  </div>
                ) : (
                  <span className={classes.plantNameText}>{activation?.plantName}</span>
                )}
              </div>
              {activation.plantRegistry && activation.plantRegistry.customerName ? (
                <span className={classes.customerNameText}>
                  {activation.plantRegistry.customerName}
                </span>
              ) : null}
            </div>
          )
        }
      },
      {
        field: 'cluster',
        headerName: translations.t('activations.customerCluster'),
        type: 'string',
        minWidth: 150,
        sortable: false,
        resizable: true,
        flex: 1,
        renderCell: (params: any) => {
          const activation = params.row;
          const clusterCurrent = clusters && !_.isEmpty(clusters) && clusters.content.filter(e=>e.id === activation.clusterId).length > 0 ? clusters.content.filter(e=> e.id === activation.clusterId)[0] : null;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PublicOutlined style={{ fontSize: 15 }}/>
              <h4 style={{ marginLeft: 0, fontSize:'0.9em', fontWeight:'bold', whiteSpace: 'normal', margin: 5, marginBottom: 7 }} key={clusterCurrent && clusterCurrent.id}>
                  {clusterCurrent? (" ["+clusterCurrent.market+"] "+clusterCurrent.name):(" ND")}
              </h4>
            </div>
          )
        },
      },
      {
        field: 'value',
        headerName: translations.t('forms.details'),
        type: 'string',
        sortable: false,
        minWidth: 150,
        flex: 1,
        renderCell: (params: any) => {
          const activation = params.row;
          return (
            <div>
              <div>
                <span className={classes.info2Text} style={{textDecoration:'underline'}}>
                  <ReduxLanguage languageKey={"activations.selloutOrder"}/>
                </span>
                <span className={classes.info2Text}>
                  {" "+(activation?.plantRegistry?.salesOrderConfirmationNumber?activation.plantRegistry.salesOrderConfirmationNumber:"N/D")}
                </span>
                </div>
                <div>
                  <span className={classes.info2Text} style={{textDecoration:'underline'}}>
                    <ReduxLanguage languageKey={"activations.intragroupOrder"}/>
                  </span>
                  <span className={classes.info2Text}>
                    {" "+(activation?.plantRegistry?.purchaseOrderNumberSofia?activation.plantRegistry.purchaseOrderNumberSofia:"N/D")}
                  </span>
                </div>
              <div>
                <span className={classes.info2Text} style={{textDecoration:'underline'}}>
                  <ReduxLanguage languageKey={"activations.custref"}/>
                </span>
                <span className={classes.info2Text}>
                  {" "+(activation?.plantRegistry?.custRefID?activation.plantRegistry.custRefID:"N/D")}
                </span>
              </div>
            </div>
          )
        }
      },
      {
        field: 'status',
        headerName: translations.t('vars.opportunityStatus'),
        renderCell: (params) => {
          const status = params.row.status;
          return (
            <div>
              <h5 className={classes.info2Text} style={{ color: getActivationStatusColor(status), fontWeight: 'bold' }}>{translations.t(`activations.status${status}`).toUpperCase()}</h5>
            </div>
          )
        },
        type: 'string',
        sortable: false,
        width: 140,
      },
      {
        field: 'actions',
        headerName: translations.t('forms.actions'),
        flex: 1,
        renderCell: (params: any) => {
          const activation = params.row;
          return (
            <div style={{display:'flex', flexWrap:'wrap'}}>
              {AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.ACTIVATION_REQUEST_EDIT)?(
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  style={{ backgroundColor:'darkcyan', flexShrink:0, marginTop:5, marginBottom:5}}
                  onClick={() => onEditActivation(activation)}
                >
                  {!(activation.status===ActivationstatusOptions.DRAFT||activation.status===ActivationstatusOptions.REJECTED||activation.status===ActivationstatusOptions.CREATED)?<VisibilityIcon className={classes.icon} />:<Edit className={classes.icon} />}
                  <h5 className={classes.actionButtonText}>
                    <ReduxLanguage languageKey={(activation.status===ActivationstatusOptions.DRAFT||activation.status===ActivationstatusOptions.REJECTED)?"forms.edit":(activation.status===ActivationstatusOptions.CREATED)?"activations.setInvoiceData":"forms.open"} />
                  </h5>
                </Button>
              ):(
                <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.ACTIVATION_REQUEST_READ}>
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    style={{ backgroundColor:'darkcyan', flexShrink:0, marginTop:5, marginBottom:5}}
                    onClick={() => onEditActivation(activation)}
                  >
                    {<VisibilityIcon className={classes.icon}/>}
                    <h5 className={classes.actionButtonText}>
                      <ReduxLanguage languageKey={"forms.open"} />
                    </h5>
                  </Button>
                </Permission>
              )}
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SALES_OPERATION_MANAGER,Roles.ROLE_VASHO_GLOBAL_SALES_OPERATION_MANAGER]}>
                {activation&&(activation.status===ActivationstatusOptions.DRAFT||activation.status===ActivationstatusOptions.REJECTED)?(
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    style={{ backgroundColor:'green', flexShrink:0, marginTop:5, marginBottom:5}}
                    onClick={() => {onPromoteActivationRequest(activation)}}
                  >
                    <SendIcon className={classes.icon} />
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"activations.changeStatusToUnderReview"} /></h5>
                  </Button>
                ):null}
              </Permission>
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SALES_OPERATION_MANAGER,Roles.ROLE_VASHO_GLOBAL_SALES_OPERATION_MANAGER]}>
                {activation&&activation.status===ActivationstatusOptions.CREATED?(
                  <Tooltip title={!activation.plantRegistry||!activation.plantRegistry.invoiceNumber?
                    <ReduxLanguage languageKey="activations.dataMissingCreated" />:""
                    } placement="left" arrow>
                    <span style={{width:'max-content'}}>
                    <Button
                      variant="contained"
                      className={classes.actionButton}
                      disabled={!activation.plantRegistry||!activation.plantRegistry.invoiceNumber}
                      style={{backgroundColor:!activation.plantRegistry||!activation.plantRegistry.invoiceNumber?'lightgrey':'green', flexShrink:0, marginTop:5, marginBottom:5}}
                      onClick={() => {onInvoicedActivationRequest(activation)}}
                    >
                      <MonetizationOnIcon className={classes.icon} />
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"activations.changeStatusToInvoiced"} /></h5>
                    </Button>
                    </span>
                  </Tooltip>
                ):null}
              </Permission>
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SALES_OPERATION_MANAGER,Roles.ROLE_VASHO_GLOBAL_SALES_OPERATION_MANAGER]}>
                {activation&&activation.status===ActivationstatusOptions.DRAFT?(
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    style={{ backgroundColor:'darkred', flexShrink:0, marginTop:5, marginBottom:5}}
                    onClick={() => {onDeleteActivationRequest(activation)}}
                  >
                    <DeleteOutline className={classes.icon}/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"forms.delete"} /></h5>
                  </Button>
                ):null}
              </Permission>
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SOFIA_ADMIN]}>
                <Button
                  variant="contained"
                  style={{ backgroundColor:'#282c34', flexShrink:0, marginTop:5, marginBottom:5}}
                  className={classes.actionButton}
                  onClick={(e) => this.openActionsMenu(e, activation)}
                >
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.actions" /></h5>
                  {Boolean(anchorElActions) && selectedActivation && selectedActivation.id === activation.id ? <ExpandLess className={classes.icon}/> : <ExpandMore className={classes.icon}/>}
                </Button>
              </Permission>
              {selectedActivation && selectedActivation.id === activation.id ? (
                <Popover
                  id={`simple-menu-${activation.id}`}
                  anchorEl={anchorElActions}
                  keepMounted
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElActions) && selectedActivation && selectedActivation.id === activation.id}
                  onClose={()=> this.closeActionsMenu()}
                >
                  {selectedActivation.status === ActivationstatusOptions.PROCESSING || selectedActivation.status === ActivationstatusOptions.CREATED || selectedActivation.status === ActivationstatusOptions.INVOICED || selectedActivation.status === ActivationstatusOptions.ACTIVE ? (
                    <MenuItem onClick={()=>{ this.closeActionsMenu(); onEditCustrefRequest(selectedActivation) }}>
                      <ConfirmationNumberIcon className={classes.icon} color="primary"/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.changeCustRef" /></h5>
                    </MenuItem>
                  ) : null}
                  {activation && activation.status === ActivationstatusOptions.CREATED ? (
                    <Tooltip title={!activation.plantRegistry||!activation.plantRegistry.invoiceNumber?
                      <ReduxLanguage languageKey="activations.dataMissingCreated" />:""
                      } placement="left" arrow>
                      <span style={{width:'max-content'}}>
                        <MenuItem onClick={()=>{ this.closeActionsMenu(); onInvoicedActivationRequest(selectedActivation) }} disabled={!activation.plantRegistry||!activation.plantRegistry.invoiceNumber}> 
                        <MonetizationOnIcon className={classes.icon} color="primary"/>
                        <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.changeStatusToInvoiced" /></h5>
                      </MenuItem>
                      </span>
                    </Tooltip>
                  ):null}
                  {activation && (activation.status===ActivationstatusOptions.DRAFT||activation.status===ActivationstatusOptions.REJECTED)?(
                    <MenuItem onClick={()=>{ this.closeActionsMenu(); onPromoteActivationRequest(selectedActivation) }}>
                      <SendIcon className={classes.icon} color="primary"/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.changeStatusToUnderReview" /></h5>
                    </MenuItem>
                  ):null}
                  {selectedActivation.status === ActivationstatusOptions.PROCESSING ? (
                    <MenuItem onClick={()=>{ this.closeActionsMenu(); onCreatedActivationRequest(selectedActivation) }}>
                      <NewReleasesIcon className={classes.icon} color="primary"/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.changeStatusToCreated" /></h5>
                    </MenuItem>
                  ):null}
                  {selectedActivation.status === ActivationstatusOptions.UNDER_REVIEW ? (
                    <MenuItem onClick={()=>{ this.closeActionsMenu(); onProcessingActivationRequest(selectedActivation) }}>
                      <DoneOutlineIcon className={classes.icon} color="primary"/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.changeStatusToProcessing" /></h5>
                    </MenuItem>
                  ):(null)}
                  {selectedActivation.status === ActivationstatusOptions.UNDER_REVIEW ? (
                    <MenuItem onClick={()=>{ this.closeActionsMenu(); onRejectActivationRequest(selectedActivation) }}>
                      <ThumbDownIcon className={classes.icon} color="primary"/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.changeStatusToRejected" /></h5>
                    </MenuItem>
                  ):(null)}
                  <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SOFIA_ADMIN]}>
                    <MenuItem onClick={()=> this.toggleActionsStatusAdminOpen()}>
                      <BuildIcon className={classes.icon} color="secondary"/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.changeStatusAdmin" /></h5>
                      {actionsStatusAdminOpen?<ExpandLess />:<ExpandMore />}
                    </MenuItem>
                    <Collapse in={actionsStatusAdminOpen} timeout="auto" unmountOnExit>
                      {_.map(ActivationstatusOptions, status => (
                        <MenuItem key={status} onClick={()=>{ this.closeActionsMenu(); this.toggleActionsStatusAdminOpen(); onEditStatusAdmin(selectedActivation, status) }}>
                          <LastPageIcon className={classes.icon} style={{ color: 'black' }}/>
                          <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={`activations.status${status}`} /></h5>
                        </MenuItem>
                      ))}
                    </Collapse>
                  </Permission>
                  <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SOFIA_ADMIN]}>
                    {!selectedActivation.plantRegistry.customerId && !selectedActivation.plantRegistry.varId && (
                      selectedActivation.status===ActivationstatusOptions.PROCESSING||selectedActivation.status===ActivationstatusOptions.CREATED||
                      selectedActivation.status===ActivationstatusOptions.INVOICED||selectedActivation.status===ActivationstatusOptions.ACTIVE
                    )?(
                      <MenuItem onClick={()=>{ this.closeActionsMenu(); onCreateNewCustomerRequest(selectedActivation) }}>
                        <CustomersIcon className={classes.icon} color="primary"/>
                        <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.createNewCustomer" /></h5>
                      </MenuItem>
                    ):null}
                  </Permission>
                  <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SOFIA_ADMIN]}>
                    {!selectedActivation.plantRegistry.walletId && selectedActivation.plantRegistry.customerId && selectedActivation.plantRegistry.keyAccountManager && (
                      selectedActivation.status===ActivationstatusOptions.PROCESSING||selectedActivation.status===ActivationstatusOptions.CREATED||
                      selectedActivation.status===ActivationstatusOptions.INVOICED||selectedActivation.status===ActivationstatusOptions.ACTIVE
                    )?(
                      <MenuItem onClick={()=>{ this.closeActionsMenu(); onCreateNewWalletRequest(selectedActivation) }}>
                        <AccountBalanceWalletIcon className={classes.icon} color="primary"/>
                        <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.createNewWallet" /></h5>
                      </MenuItem>
                    ):null}
                  </Permission>
                  <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SOFIA_ADMIN]}>
                    {!selectedActivation.plantRegistry.scratchcardId && (selectedActivation.plantRegistry.baselineSubscriptionID||selectedActivation.plantRegistry.varId) && (
                      selectedActivation.status===ActivationstatusOptions.PROCESSING||selectedActivation.status===ActivationstatusOptions.CREATED||
                      selectedActivation.status===ActivationstatusOptions.INVOICED||selectedActivation.status===ActivationstatusOptions.ACTIVE
                    )?(
                      <MenuItem onClick={()=>{ this.closeActionsMenu(); onCreateNewScratchcardRequest(selectedActivation) }}>
                        <CardIcon className={classes.icon} color="primary"/>
                        <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.createNewScratchcard" /></h5>
                      </MenuItem>
                    ):null}
                  </Permission>
                  {selectedActivation.status === ActivationstatusOptions.REJECTED || selectedActivation.status === ActivationstatusOptions.PROCESSING ? (
                    <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SOFIA_ADMIN]}>
                      <MenuItem onClick={()=>{ this.closeActionsMenu(); onDeleteActivationAdminRequest(selectedActivation) }}>
                        <DeleteOutline className={classes.icon} style={{ color: 'darkred' }}/>
                        <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.deleteAdminOnly" /></h5>
                      </MenuItem>
                    </Permission>
                  ):(selectedActivation.status === ActivationstatusOptions.DRAFT ? (
                      <MenuItem onClick={()=>{ this.closeActionsMenu(); onDeleteActivationRequest(selectedActivation) }}>
                        <DeleteOutline className={classes.icon} style={{ color: 'darkred' }}/>
                        <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.delete" /></h5>
                      </MenuItem>
                  ):(null))}
              </Popover>
            ):null}
          </div>
          )
        },
        type: 'string',
        sortable: false,
        minWidth: 150,
      }
    ];

    return (
      <div style={{ width: '100%'}}>
        <DataGrid
          getRowId={(d) => d.id}
          rows={activations.content || []}
          columns={this.tableColumns}
          rowHeight={100}
          loading={this.props.isLoading}
          disableSelectionOnClick
          onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
          onPageChange={page => onFetchElementsOnPage(page)}
          page={(activations?.pagination as PaginationDTO)?.number}
          rowCount={(activations?.pagination as PaginationDTO)?.totalElements}
          pageSize={rowsPerPage}
          paginationMode="server"
          componentsProps={{
            pagination: {
              labelRowsPerPage: translations.t('forms.rowsPerPage')
            }
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <SearchOutlined style={{ alignSelf: 'center' }} />
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='activations.noActivationsFoundTitle' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='activations.noActivationsFoundDescription' /></h4>
                </div>
              </Stack>
            ),
            
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='activations.noActivationsFoundTitle' /></h3>
                <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='activations.noActivationsFoundDescription' /></h4>
              </Stack>
            )
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ActivationsTable);