import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { Button, createStyles, WithStyles } from '@material-ui/core';
import { DeleteOutline, SecurityRounded } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/styles';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import 'moment/locale/it';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as UserDecoder from '../../codec/userInDTODecoder';
import VarUserForm from '../../components/Forms/VarUserForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import VarUsersSearchBar from '../../components/SearchBar/VarUsersSearchBar';
import UsersTable from '../../components/Users/UsersTable';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as UserActions from '../../redux/user/user.actions';
import * as VarsActions from '../../redux/vars/vars.actions';
import { IUserFormDTO } from '../../repository/jago/model/input/IUserFormDTO';
import { IUserInDTO } from '../../repository/jago/model/input/IUserInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IVarUsersSearchDTO } from '../../repository/jago/model/input/IVarUsersSearchDTO';
import translations from '../../translations/i18next';
import { getRoleReadableName } from '../../utils/Utils';
import AbilityHelper from '../../services/PermissionService/AbilityHelper';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 160px)',
    width: '100%'
  },
});
export interface IVarUsersPageState {
  searchValuesCurrent: IVarUsersSearchDTO;
  rowsPerPage: number;
  isLoading: boolean;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface IVarUsersPageProps extends WithStyles<typeof styles> {
  language: string;
  varUsers: PaginatedDataDTO<IUserInDTO>;
}

export type ComponentProps = IVarUsersPageProps & IReduxProps;

class VarUsersPage extends React.Component<ComponentProps, IVarUsersPageState> {
  private usersColumns: GridColumns<IUserInDTO>;
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      searchValuesCurrent: {},
      rowsPerPage: 100,
      isLoading: false
    }
  }

  public async componentDidMount() {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    try {
      this.setState({ isLoading: true });
      await dispatch<any>(VarsActions.fetchVar());
      const { VAR } = this.props;
      const varData = (VAR as IVarInDTO)
      await dispatch<any>(VarsActions.fetchVarUsers(varData.id, { page: 0, pageSize: rowsPerPage, ...this.state.searchValuesCurrent}));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public onCreateNewVarUserRequest(newVarUserData: IUserFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_VAR_USER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.newVarUser',
        successMessageKey: 'vars.varUserCreationConfirm',
        confirmMessageKey: 'vars.createVarUser',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewVarUserConfirm(newVarUserData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_VAR_USER_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onCreateNewVarUserConfirm(newVarUserData: IUserFormDTO) {
    const { dispatch, VAR, varUsers } = this.props;
    const { rowsPerPage } = this.state;
    const varData = (VAR as IVarInDTO);
    const varUsersPagination = varUsers.pagination as PaginationDTO;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_VAR_USER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.createNewVarUser(varData.id,newVarUserData));
      this.setState({ isLoading: true });
      await dispatch<any>(VarsActions.fetchVarUsers(varData.id, { page: varUsersPagination.number, pageSize: rowsPerPage, ...this.state.searchValuesCurrent }));
      this.setState({ isLoading: false });
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CREATE_VAR_USER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      this.setState({ isLoading: false });
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_VAR_USER_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createVarUserError',
        }
      }));
    }
  }

  onDeleteVarUserRequest(varUser: IUserInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_VARUSER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.deleteVarUserConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteVarUserConfirm(varUser),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_VARUSER_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteVarUserConfirm(varUser: IUserInDTO) {
    const { VAR, dispatch } = this.props;
    const varData = (VAR as IVarInDTO)
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.deleteVarUser(varData.id, varUser.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_VARUSER_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchVarUsers(varData.id));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_VARUSER_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteVarUserError',
        }
      }));
    }
  }

  async onOpenNewVarUserForm() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_VAR_USER_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
      modalProps: {
        content: (
          <VarUserForm
            onCreateEditVarUser={(newVarUserData: IUserFormDTO) => this.onCreateNewVarUserRequest(newVarUserData)}
          />
        ),
        titleMessageKey: 'vars.varUserFormTitle',
      }
    }));
  }

  public async onRowClick(user: IUserInDTO) {
    const { dispatch } = this.props
    try {
      const userParsed = UserDecoder.decodeUserContentToForm(user)
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_USER_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => {},
          content: (
            <VarUserForm
              varUser={userParsed}
              isEditing
              onCreateEditVarUser={(newVarUserData: IUserFormDTO) => this.onEditUserRequest(user.id, newVarUserData)}
            />
          ),
          titleMessageKey: 'users.editUserForm',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_USER_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  public onEditUserRequest(userId: number, newUserData: IUserFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_USER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.editUser',
        successMessageKey: 'credits.editCreationUserConfirm',
        confirmMessageKey: 'credits.editUser',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditUserConfirm(userId, newUserData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_USER_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onEditUserConfirm(userId: number, newUserData: IUserFormDTO) {
    const { VAR, dispatch } = this.props;
    const varData = (VAR as IVarInDTO)
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_USER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      this.setState({ isLoading: true });
      await dispatch<any>(UserActions.editUser(userId, newUserData));
      await dispatch<any>(VarsActions.fetchVarUsers(varData.id));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_USER_MODAL'));
      this.setState({ isLoading: false });
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      this.setState({ isLoading: false });
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_USER_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createUserError',
        }
      }));
    }
  }

  onResetSearch() {
    const { dispatch, VAR, varUsers } = this.props;
    const { rowsPerPage } = this.state;
    const varData = (VAR as IVarInDTO)
    const varUsersPagination = varUsers.pagination as PaginationDTO;
    this.setState({ searchValuesCurrent:{}, isLoading: true }, async() => {
      try {
        this.setState({ isLoading: true });
        await dispatch<any>(VarsActions.fetchVarUsers(varData.id, {page: varUsersPagination.number, pageSize: rowsPerPage, ...this.state.searchValuesCurrent})); 
        this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      } 
    });
  }

  public onSearch(searchValues: IVarUsersSearchDTO) {
    const { dispatch, VAR, varUsers } = this.props;
    const { rowsPerPage } = this.state;
    const varUsersPagination = varUsers.pagination as PaginationDTO;
    const varData = (VAR as IVarInDTO);
    this.setState({ searchValuesCurrent:searchValues, isLoading: true }, async() => {
      try {
        this.setState({ isLoading: true });
        await dispatch<any>(VarsActions.fetchVarUsers(varData.id, {page: varUsersPagination.number, pageSize: rowsPerPage, ...this.state.searchValuesCurrent}));
        this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    });
  }

  async onPageSizeChange(pageSize) {
    const { dispatch, VAR } = this.props;
    const varData = (VAR as IVarInDTO);
    this.setState({ rowsPerPage: pageSize, isLoading: true }, async() => {
      try {
        this.setState({ isLoading: true });
        await dispatch<any>(VarsActions.fetchVarUsers(varData.id, {page: 0, pageSize: pageSize, ...this.state.searchValuesCurrent}));
        this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    });
  }

  async onFetchCustomersOnPage(page) {
    const { dispatch, VAR } = this.props;
    const { rowsPerPage, searchValuesCurrent } = this.state;
    const varData = (VAR as IVarInDTO);
    try {
      this.setState({ isLoading: true });
      await dispatch<any>(VarsActions.fetchVarUsers(varData.id, { page: page, pageSize: rowsPerPage, ...searchValuesCurrent }));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { classes, varUsers, userData } = this.props;
    const { rowsPerPage } = this.state;

    this.usersColumns = [
      {
        field: 'firstname',
        headerName: translations.t('forms.user'),
        valueGetter: (params) => `${params.row?.firstname} ${params.row?.lastname}`,
        renderCell: (params: any) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h4 style={{ fontWeight: 'bold', margin: 0 }}>{params.row?.firstname + " " + params.row?.lastname}</h4>
              <h4 style={{ fontWeight: 'normal', margin: 0 }}>{params.row?.email}</h4>
            </div>
          )
        },
        type: 'string',
        sortable: false,
        flex: 1,
        width: 250,
      },
      {
        field: 'role',
        headerName: translations.t('forms.roles'),
        renderCell: (params: any) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {_.map(params.row.roles, role => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SecurityRounded style={{ fontSize: 15 }}/>
                  <h4 style={{ padding:6, marginLeft: 0, fontSize:'0.9em', fontWeight:'bold'}} key={role.id}>
                    {getRoleReadableName(role.name)}
                  </h4>
                </div>
              ))}
            </div>
          )
        },
        type: 'string',
        minWidth: 150,
        flex: 1,
        resizable: true,
      },
      {
        field: 'actions',
        headerName: translations.t('forms.actions'),
        renderCell: (params: any) => {
          const user = params.row;
          const canDeleteUser = user && userData && user.id !== userData.userId && AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.VAR_USER_UPDATE);
          if (!canDeleteUser) return null;
          return (
            <Button
              variant="contained"
              style={{
                backgroundColor: 'darkred',
                flexShrink: 0,
                marginRight: 20,
                width: 'fit-content',
                color: 'white',
                padding: 4,
                paddingLeft: 10,
                paddingRight: 10,
                fontWeight: 'bold'}}
              onClick={(e) => {e.stopPropagation(); this.onDeleteVarUserRequest(params.row)}}
            >
              <DeleteOutline style={{ fontSize: 15 }}/>
              <h5 style={{ fontWeight: 'bold', margin: 0, marginLeft: 5 }} ><ReduxLanguage languageKey={"forms.delete"} /></h5>
            </Button>
          )
        },
        type: 'string',
        sortable: false,
        minWidth: 150,
      },
    ]

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <VarUsersSearchBar
            onCreateNewEntityButtonClick={() => this.onOpenNewVarUserForm()}
            onSearch={(searchValues: IVarUsersSearchDTO) => this.onSearch(searchValues)}
            onResetSearch={() => this.onResetSearch()}
          />
        </div>
        <div className={classes.tableContainer}>
          <UsersTable
            users={varUsers}
            tableColumns={this.usersColumns}
            rowsPerPage={rowsPerPage}
            onFetchElementsOnPage={page => this.onFetchCustomersOnPage(page)}
            onPageSizeChange={pageSize => this.onPageSizeChange(pageSize)}
            onRowClick={user => this.onRowClick(user)}
            onDeleteUserRequest={(varUser) => {this.onDeleteVarUserRequest(varUser)}}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    VAR: state.vars.VAR.data,
    varUsers: state.vars.users.data,
    userData: state.user.userData,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(VarUsersPage)));