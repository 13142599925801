import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { Card } from '@material-ui/core';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { SearchOutlined } from '@material-ui/icons';
import { Stack } from '@mui/material';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import gatewayIseoImg from '../../assets/hypergate_iseo.png';
import gatewayProImg from '../../assets/hypergate_pro.png';
import CopyToClipboardWrapper from '../../components/CopyToClipboardWrapper/CopyToClipboardWrapper';
import ReduxLanguage from '../../components/ReduxLanguage';
import GatewaysSearchBar from '../../components/SearchBar/GatewaysSearchBar';
import * as DomainsActions from '../../redux/domains/domains.actions';
import * as GatewaysActions from '../../redux/gateways/gateways.actions';
import { IState } from '../../redux/store';
import { IDomainOptionDTO } from '../../repository/jago/model/input/IDomainOptionDTO';
import { IGatewayDetailedDTO } from '../../repository/jago/model/input/IGatewayDetailedDTO';
import IGatewayInDTO, { IGatewayDeviceTypeEnum } from '../../repository/jago/model/input/IGatewayInDTO';
import { IGatewaysSearchDTO } from '../../repository/jago/model/input/IGatewaysSearchDTO';
import { IDomainRequestParamsDTO, IGatewaysRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import { getDomainNameFromHostname, getDomainsOptions } from '../../repository/utils';
import { GREEN, RED } from '../../style/StyleConsts';
import translations from '../../translations/i18next';
import Utils from '../../utils/Utils';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  logo: {
    width: '20%',
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 230px)',
    width: '100%'
  },
  gatewayImage: {
    width: 27,
  }
});


export interface IGatewaysPageState {
  searchValues: Record<keyof IGatewaysSearchDTO, string>;
  domainsOptions: IDomainOptionDTO[];
  gatewaysRowsPerPage: number;
  isLoading: boolean;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface IGatewaysPageProps extends WithStyles<typeof styles> {
  language: string;
  gateways: PaginatedDataDTO<IGatewayInDTO>;
  detailedGateways: IGatewayDetailedDTO;
}

export type ComponentProps = IGatewaysPageProps & IReduxProps;

export class GatewaysPage extends React.Component<ComponentProps, IGatewaysPageState> {
  private gatewaysColumns: GridColumns<IGatewayInDTO>;
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      searchValues: {
        name: '',
        hostname: '',
        deviceType: '',
        deviceId: '',
        swVersion: ''
      },
      gatewaysRowsPerPage: 100,
      domainsOptions: [],
      isLoading: false,
    }
  }

  public async componentDidMount() {
    const { dispatch, history } = this.props;
    const { gatewaysRowsPerPage } = this.state;
    try {
      this.setState({ isLoading: true });
      const params: IGatewaysRequestParamsDTO = { page: 0, pageSize: gatewaysRowsPerPage };
      await dispatch<any>(GatewaysActions.fetchSofiaGateways(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
    const parsed = qs.parse(history.location.search);
      
    if (parsed && parsed.hostname) {
      this.onSearch({ name: '', hostname: parsed.hostname as string, deviceType: undefined });
    }
    
  }

  handleChangeGatewaysRowsPerPage(pageSize: number) {
    const { dispatch } = this.props;
    this.setState({ gatewaysRowsPerPage: pageSize, isLoading: true }, async () => {
      const params: IGatewaysRequestParamsDTO = { page: 0, pageSize };
      try {
        await dispatch<any>(GatewaysActions.fetchSofiaGateways(params));
        this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    });
  }

  public async onFetchGatewaysOnPage(page: number) {
    const { dispatch } = this.props;
    const { searchValues, gatewaysRowsPerPage } = this.state;
    const params: IGatewaysRequestParamsDTO = {
      page,
      pageSize: gatewaysRowsPerPage,
      hostname: searchValues.hostname,
      name: searchValues.name,
      deviceType: searchValues.deviceType as string,
      swVersion: searchValues.swVersion,
      deviceId: searchValues.deviceId
    };
    const filteredParams: IGatewaysRequestParamsDTO = _.pickBy(params, param => param !== '') as IGatewaysRequestParamsDTO;
    try {
      this.setState({ isLoading: true });
      await dispatch<any>(GatewaysActions.fetchSofiaGateways(filteredParams));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public async onSearch(values: IGatewaysSearchDTO) {
    const { dispatch } = this.props;
    this.setState({ isLoading: true, searchValues: { name: values.name || '', hostname: values.hostname || '', deviceType: values.deviceType || '', deviceId: values.deviceId || '', swVersion: values.swVersion || '' }});
    const params: IGatewaysRequestParamsDTO = { hostname: values.hostname, name: values.name, deviceType: values.deviceType, deviceId: values.deviceId, swVersion: values.swVersion };
    const filteredParams: IGatewaysRequestParamsDTO = _.pickBy(params, param => param !== '') as IGatewaysRequestParamsDTO;
    try {
      this.setState({ isLoading: true });
      await dispatch<any>(GatewaysActions.fetchSofiaGateways(filteredParams));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public async onSearchReset() {
    const { dispatch } = this.props;
    const params: IGatewaysRequestParamsDTO = { page: 0 };
    this.setState({ searchValues: { name: '', hostname: '', deviceType: '', deviceId: '', swVersion: '' }, isLoading: true });
    try {
      await dispatch<any>(GatewaysActions.fetchSofiaGateways(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public onUpdateSearchValues(values: Record<keyof IGatewaysSearchDTO, string>) {
    this.setState({ searchValues: values });
  }

  async onFetchDomains(value: string) {
    const { dispatch } = this.props;
    const params: IDomainRequestParamsDTO = { serviceName: 'LUCKEY', hostName: value };
    const domains = await dispatch<any>(DomainsActions.fetchSofiaDomainsSearch(params));
    const domainsOptions = getDomainsOptions(domains);
    this.setState({ domainsOptions });
  }

  render() {
    const { classes, gateways } = this.props
    const { domainsOptions, gatewaysRowsPerPage } = this.state;
    const gatewaysPagination = !_.isEmpty(gateways.pagination) ? (gateways.pagination as PaginationDTO) : null;
    this.gatewaysColumns = [
      {
        field: 'deviceId',
        headerName: translations.t('gateways.deviceId'),
        renderCell: (params: GridRenderCellParams<IGatewayInDTO>) => {
          const gateway = params.row;
          return (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <Card style={{ borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 35, width: 35, marginRight: 5 }}>
                <img alt="gateway" src={gateway?.deviceType === IGatewayDeviceTypeEnum.HYPERGATE_ISEO ? gatewayIseoImg : gatewayProImg} className={classes.gatewayImage} /> 
              </Card>
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                {gateway?.name ? <h3 style={{ fontWeight: 'bold', marginRight: 5, marginBottom: 0, whiteSpace: 'normal' }}>{gateway?.name}</h3> : null}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4 style={{ marginRight: 5, whiteSpace: 'normal' }}>{gateway?.deviceId}</h4>
                  <CopyToClipboardWrapper textToCopy={gateway?.deviceId || ''} />
                </div>
              </div>
            </div>
          )
        },
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'hostName',
        valueGetter: (params) => params.row?.hostName ?  getDomainNameFromHostname(params.row.hostName) : '',
        headerName: translations.t('sales.domain'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 250,
      },
      {
        field: 'lastConnectionDate',
        headerName: translations.t('gateways.lastConnectionDate'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 250,
        renderCell: (params: GridRenderCellParams<any>) => {
          if (params && params.value) {
            const difference = moment(moment().valueOf()).diff(moment(params.value), 'minutes');
            const isWarning = difference > 5;
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h4 style={{ marginLeft: 0 }}>{moment(params.value).format('LLL')}</h4>
                <div style={{ width: 10, height: 10, borderRadius: 40, backgroundColor: isWarning ? RED : GREEN }} />
              </div>
            )
          }
          return 'N/A';
        }
      },
      {
        field: 'deviceVersion',
        valueGetter: (params) => params.row?.swVersion ?  params.row?.swVersion : Utils.getGatewayVersion(params.row?.deviceVersion),
        headerName: translations.t('gateways.gatewayVersion'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 100,
      },
      {
        field: 'iseoSdkVersion',
        headerName: translations.t('gateways.iseoSdkVersion'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 100,
      },
      {
        field: 'kernelVersion',
        headerName: translations.t('gateways.kernelVersion'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 150,
      },
    ];
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <GatewaysSearchBar
            searchValues={this.state.searchValues}
            domainsOptions={domainsOptions}
            preselectedHostname={this.state.searchValues.hostname}
            totalElements={gatewaysPagination ? gatewaysPagination.totalElements : ''}
            onSearch={(values: IGatewaysSearchDTO) => this.onSearch(values)}
            onResetSearch={() => this.onSearchReset()}
            onUpdateSearchValues={values => this.onUpdateSearchValues(values)}
            onFilterDomains={value => this.onFetchDomains(value)}
          />
        </div>
        <div className={classes.tableContainer}>
          <DataGrid
            getRowId={(d) => d.id}
            rows={gateways.content || []}
            columns={this.gatewaysColumns}
            rowHeight={60}
            loading={this.state.isLoading}
            onPageSizeChange={(pageSize) => this.handleChangeGatewaysRowsPerPage(pageSize)}
            onPageChange={page => this.onFetchGatewaysOnPage(page)}
            page={(gateways?.pagination as PaginationDTO)?.number}
            rowCount={(gateways?.pagination as PaginationDTO)?.totalElements}
            pageSize={gatewaysRowsPerPage}
            paginationMode="server"
            componentsProps={{
              pagination: {
                labelRowsPerPage: translations.t('forms.rowsPerPage')
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <SearchOutlined style={{ alignSelf: 'center' }} />
                    <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='gateways.noGatewaysFound' /></h3>
                    <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='gateways.noGatewaysFoundDescription' /></h4>
                  </div>
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='gateways.noGatewaysFound' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='gateways.noGatewaysFoundDescription' /></h4>
                </Stack>
              )
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    gateways: state.gateways.gateways,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(GatewaysPage)));