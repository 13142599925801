import { PaginatedDataDTO, PaginationDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { Button } from '@material-ui/core';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import 'moment/locale/it';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as VarOpportunityDecoder from '../../codec/varOpportunityInDTODecoder';
import CustomerDetailsView from '../../components/Customers/CustomerDetailsView';
import VarOpportunityForm from '../../components/Forms/VarOpportunityForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import Permission from '../../components/Permission/Permission';
import ReduxLanguage from '../../components/ReduxLanguage';
import VarOpportunitySearchBar from '../../components/SearchBar/VarOpportunitySearchBar';
import VarOpportunitiesTable from '../../components/Vars/VarOpportunitiesTable';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as VarsActions from '../../redux/vars/vars.actions';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IVarOpportunityFormDTO } from '../../repository/jago/model/input/IVarOpportunityFormDTO';
import { IVarOpportunityInDTO } from '../../repository/jago/model/input/IVarOpportunityInDTO';
import { IVarOpportunitySearchDTO } from '../../repository/jago/model/input/IVarOpportunitySearchDTO';
import { IVarOpportunityOutDTO } from '../../repository/jago/model/output/IVarOpportunityOutDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import { VAR_USERS_ROLES } from '../../utils/Utils';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: 0,
  },
  titleText: {
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 160px)',
    width: '100%'
  },
});

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;
export interface IOpportunitiesPageState {
  pageSize: number;
  isLoading: boolean;
}

export interface IOpportunitiesPageProps extends WithStyles<typeof styles> {
  language: string;
  opportunities: PaginatedDataDTO<IVarOpportunityInDTO>;
}

export type ComponentProps = IOpportunitiesPageProps & IReduxProps;

class OpportunitiesPage extends React.Component<ComponentProps, IOpportunitiesPageState> {

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      pageSize: 100,
      isLoading: false,
    }
  }
  async componentDidMount() {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    this.setState({ isLoading: true });
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    try {
      if (isVar)
        await dispatch<any>(VarsActions.fetchVar());
      else
        await dispatch<any>(VarsActions.fetchVars({page:0, pageSize: 500 }));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: 0, pageSize: pageSize }));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      dispatch<any>(ModalsActions.showModal(`ERROR_OPEN_PARAMETERS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  onFetchElementsOnPage(page: number) {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    const params: PaginationQueryParamsDTO = { page, pageSize};
    dispatch<any>(VarsActions.fetchOpportunities(params));
  }

  onPageSizeChange(pageSize: number) {
    const { dispatch } = this.props;
    this.setState({ pageSize }, () => {
      const params: PaginationQueryParamsDTO = { page: 0, pageSize};
      dispatch<any>(VarsActions.fetchOpportunities(params));
    })
  }

  public onOpenVarDetails(varId: number) {
    const { history } = this.props
    history.push(`/vars?varId=${varId}`);
  } 

  public async onOpenCustomerInfo(customerId: number) {
    const { dispatch } = this.props;
    try {
      const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const customer = await dispatch<any>(CreditsActions.fetchCustomerDetails(customerId));
      dispatch<any>(CreditsActions.setSelecteCustomer(customer));
      await dispatch<any>(CreditsActions.fetchCustomerWallets({pageSize: 200, customerId: customerId }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CUSTOMER_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(CreditsActions.setSelecteCustomer({})),
          content: (
            <CustomerDetailsView
              onEditCustomerData={() => {}}
              hideEditCustomer={true}
              hideDeleteCustomer={true}
              onVarClick={isVar?(()=>{}): (varId => {
                dispatch<any>(ModalsActions.hideModal(`OPERATIONAL_CUSTOMER_MODAL`));
                dispatch<any>(ModalsActions.hideModal(`OPPORTUNITY_FORM_VIEW`));
                this.onOpenVarDetails(varId)
              })}
            />
          ),
          titleMessageKey: 'credits.customerDetails',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getCustomerDetailsError',
        }
      }));
    }
  }

  onCreateOpportunityRequest(opportunity: IVarOpportunityFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.newOpportunity',
        successMessageKey: 'forms.createVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onCreateOpportunityConfirm(opportunity: IVarOpportunityFormDTO) {
    const { dispatch, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
      const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityFromForm(opportunity,isVar)
      opportunityOut.varId = opportunity.varId
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.createNewVarOpportunity(opportunity.customerId, opportunityOut));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('OPPORTUNITY_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('CREATE_OPPORTUNITY_CONFIRM_MODAL'));
      dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createVarOpportunityError',
        }
      }));
    }
  }

  async onOpenOpportunityForm(opportunityToEdit?: IVarOpportunityInDTO) {
    const { dispatch, VAR, vars } = this.props;
    try {
      const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
      const isSofiaAdmin = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN]);
      const opportunity = opportunityToEdit ? VarOpportunityDecoder.decodeVarOpportunityContentToForm(opportunityToEdit) : undefined;
      const varId = opportunity ? opportunity.varId : isVar ? (VAR as IVarInDTO).id: undefined;
      const varsData = (vars as PaginatedDataDTO<IVarInDTO>);
      const varsOptions = _.orderBy(_.map(varsData.content, VAR => ({...VAR, name: VAR.name, value: VAR.id })), 'name');
      if (!varId && !isSofiaAdmin)
        throw new Error()
      dispatch<any>(ModalsActions.showModal(`OPPORTUNITY_FORM_VIEW`, {
        modalType: opportunityToEdit?ModalTypes.OPERATIONAL_VIEW_MODAL:ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
        modalProps: {
          content: (
            <VarOpportunityForm
              opportunity={opportunity}
              varId={varId}
              varsOptions={varsOptions}
              onCreateEditVarOpportunity={opportunity => opportunityToEdit ? this.onEditOpportunityRequest(opportunityToEdit.id, opportunity) : this.onCreateOpportunityRequest(opportunity)}
              onOpenCustomerInfo={(customerId: number) => this.onOpenCustomerInfo(customerId)}
            />
          ),
          titleMessageKey: opportunityToEdit ?  'vars.editOpportunity' : 'vars.newOpportunity',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }

  onEditOpportunityRequest(opportunityId: number, opportunity: IVarOpportunityFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.editOpportunity',
        successMessageKey: 'forms.editVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditOpportunityConfirm(opportunityId, opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onEditOpportunityConfirm(opportunityId: number, opportunity: IVarOpportunityFormDTO) {
    const { dispatch, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    try {
      const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityFromForm(opportunity,isVar)
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      if (isVar)
        await dispatch<any>(VarsActions.editVarOpportunity(opportunity.customerId, opportunityId, opportunityOut));
      else
        await dispatch<any>(VarsActions.editVarOpportunityAdmin(opportunity.customerId, opportunityId, opportunityOut));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('OPPORTUNITY_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('EDIT_OPPORTUNITY_CONFIRM_MODAL'));
      dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_EDIT`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.editVarOpportunityError',
        }
      }));
    }
  }

  onDeleteVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.deleteVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.deleteVarOpportunity(opportunity.customerId, opportunity.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteVarOpportunityError',
        }
      }));
    }
  }

  onRejectVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`REJECT_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.rejectVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onRejectVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`REJECT_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onRejectVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const isAdminSofia = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN]);
      if (isAdminSofia) {
        const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityInFromOut({ ...opportunity, status: VarOpportunityDecoder.VarOpportunityStatusOptions.REJECTED });
        await dispatch<any>(VarsActions.editVarOpportunityAdmin(opportunity.customerId, opportunity.id, opportunityOut));
      } else {
        await dispatch<any>(VarsActions.rejectVarOpportunity(opportunity.customerId, opportunity.id));
      }
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('REJECT_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_REJECT`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.rejectVarOpportunityError',
        }
      }));
    }
  }

  onApproveVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`APPROVE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.approveVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onApproveVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`APPROVE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onApproveVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const isAdminSofia = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN]);
      if (isAdminSofia) {
        const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityInFromOut({ ...opportunity, status: VarOpportunityDecoder.VarOpportunityStatusOptions.APPROVED });
        await dispatch<any>(VarsActions.editVarOpportunityAdmin(opportunity.customerId, opportunity.id, opportunityOut));
      } else {
        await dispatch<any>(VarsActions.approveVarOpportunity(opportunity.customerId, opportunity.id));
      }
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('APPROVE_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_APPROVE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.approveVarOpportunityError',
        }
      }));
    }
  }

  async onEditStatusAdmin(opportunity,newStatus) {
    const { dispatch, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityInFromOut({ ...opportunity, status: newStatus });
      await dispatch<any>(VarsActions.editVarOpportunityAdmin(opportunity.customerId, opportunity.id, opportunityOut));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_APPROVE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.modifyVarOpportunityError',
        }
      }));
    }
  }

  onUpgradeVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`UPGRADE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.upgradeVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onUpgradeVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`UPGRADE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onUpgradeVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const isAdminSofia = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN]);
      if (isAdminSofia) {
        const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityInFromOut({ ...opportunity, status: VarOpportunityDecoder.VarOpportunityStatusOptions.ON_APPROVAL });
        await dispatch<any>(VarsActions.editVarOpportunityAdmin(opportunity.customerId, opportunity.id, opportunityOut));
      } else {
        await dispatch<any>(VarsActions.upgradeVarOpportunity(opportunity.customerId, opportunity.id));
      }
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('UPGRADE_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_UPGRADE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.upgradeVarOpportunityError',
        }
      }));
    }
  }

  onWinVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`WIN_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.winVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onWinVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`WIN_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onWinVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const isAdminSofia = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN]);
      if (isAdminSofia) {
        const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityInFromOut({ ...opportunity, status: VarOpportunityDecoder.VarOpportunityStatusOptions.WON });
        await dispatch<any>(VarsActions.editVarOpportunityAdmin(opportunity.customerId, opportunity.id, opportunityOut));
      } else {
        await dispatch<any>(VarsActions.concludeVarOpportunity(opportunity.customerId, opportunity.id, VarOpportunityDecoder.VarOpportunityStatusOptions.WON));
      }
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('WIN_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_WIN`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.winVarOpportunityError',
        }
      }));
    }
  }

  onLoseVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`LOSE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.lostVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onLoseVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`LOSE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onLoseVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const isAdminSofia = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN]);
      if (isAdminSofia) {
        const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityInFromOut({ ...opportunity, status: VarOpportunityDecoder.VarOpportunityStatusOptions.LOST });
        await dispatch<any>(VarsActions.editVarOpportunityAdmin(opportunity.customerId, opportunity.id, opportunityOut));
      } else {
        await dispatch<any>(VarsActions.concludeVarOpportunity(opportunity.customerId, opportunity.id, VarOpportunityDecoder.VarOpportunityStatusOptions.LOST));
      }
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('LOSE_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_LOSE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.loseVarOpportunityError',
        }
      }));
    }
  }

  async onSearchOpportunities(searchValues: IVarOpportunitySearchDTO) {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    this.setState({ isLoading: true });
    try {
      await dispatch<any>(VarsActions.fetchOpportunities({ page: 0, pageSize, ...searchValues }));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onResetSearch() {
    const { dispatch, opportunities } = this.props;
    const { pageSize } = this.state;
    const pagination = opportunities.pagination as PaginationDTO;
    this.setState({ isLoading: true });
    try {
      dispatch<any>(VarsActions.fetchOpportunities({ page: pagination.number, pageSize }));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  exportOpportunitiesCSV() {
    const { dispatch } = this.props;
    dispatch<any>(VarsActions.exportOpportunitiesCSV());
  }

  render() {
    const { opportunities, classes, vars } = this.props;
    const cardsPagination = !_.isEmpty(opportunities.pagination) ? (opportunities.pagination as PaginationDTO) : null;
    
    const varsData = (vars as PaginatedDataDTO<IVarInDTO>)
    const varsOptions = varsData.content

    return (
      <div>
        <div className={classes.header} style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
          <VarOpportunitySearchBar
            totalElements={cardsPagination  ? cardsPagination.totalElements : ''}
            onCreateNewEntityButtonClick={() => this.onOpenOpportunityForm()}
            onSearch={(searchValues: IVarOpportunitySearchDTO) => this.onSearchOpportunities(searchValues)}
            onResetSearch={() => this.onResetSearch()}
            varsOptions={varsOptions}
          />
          {/* <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_EXPORT}>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              <Button
                variant="contained"
                style={{ backgroundColor: 'cornflowerblue', color: 'white', marginLeft: 10, fontWeight: 'bold'  }}
                onClick={() => this.exportOpportunitiesCSV()}
              >
                <ReduxLanguage languageKey="vars.exportOpportunities" />
              </Button>
            </div>
          </Permission> */}
        </div>
        <div className={classes.tableContainer}>
          <VarOpportunitiesTable
            opportunities={opportunities}
            isLoading={this.state.isLoading}
            rowsPerPage={this.state.pageSize}
            onFetchElementsOnPage={page => this.onFetchElementsOnPage(page)}
            onPageSizeChange={pageSize => this.onPageSizeChange(pageSize)}
            onRowClick={(opportunity) => this.onOpenOpportunityForm(opportunity)}
            onDeleteVarOpportunityRequest={(opportunity)=> this.onDeleteVarOpportunityRequest(opportunity)}
            onRejectVarOpportunityRequest={(opportunity)=> this.onRejectVarOpportunityRequest(opportunity)}
            onApproveVarOpportunityRequest={(opportunity)=> this.onApproveVarOpportunityRequest(opportunity)}
            onUpgradeVarOpportunityRequest={(opportunity)=> this.onUpgradeVarOpportunityRequest(opportunity)}
            onWinVarOpportunityRequest={(opportunity)=> this.onWinVarOpportunityRequest(opportunity)}
            onLoseVarOpportunityRequest={(opportunity)=> this.onLoseVarOpportunityRequest(opportunity)}
            onEditStatusAdmin={(opportunity, newStatus) => this.onEditStatusAdmin(opportunity,newStatus)}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    opportunities: state.vars.opportunities.data,
    vars: state.vars.vars.data,
    VAR: state.vars.VAR.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(OpportunitiesPage)));