import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { SearchOutlined, Share } from '@material-ui/icons';
import { Stack } from '@mui/material';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NewDeploymentForm from '../../components/Forms/NewDeploymentForm';
import DeploymentDetailsView from '../../components/Gateways/DeploymentDetailsView';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import SearchBar from '../../components/SearchBar/SearchBar';
import * as GatewaysActions from '../../redux/gateways/gateways.actions';
import { GatewaysActions as GatewaysBaseActions } from '../../redux/gateways/gateways.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import { IGatewayDeploymentDetailedInDTO } from '../../repository/jago/model/input/IGatewayDeploymentDetailedInDTO';
import { IGatewayDeploymentInDTO } from '../../repository/jago/model/input/IGatewayDeploymentInDTO';
import { IGatewayDeploymentOutDTO } from '../../repository/jago/model/output/IGatewayDeploymentOutDTO';
import { IGatewaysArtifactsRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import translations from '../../translations/i18next';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  logo: {
    width: '20%',
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 155px)',
    width: '100%'
  },
});


export interface IGatewaysDeploymentsPageState {
  rowsPerPage: number;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface IGatewaysDeploymentsPageProps extends WithStyles<typeof styles> {
  language: string;
  deployments: PaginatedDataDTO<IGatewayDeploymentInDTO>;
  detailedDeployment: IGatewayDeploymentDetailedInDTO;
}

export type ComponentProps = IGatewaysDeploymentsPageProps & IReduxProps;

export class GatewaysDeploymentsPage extends React.Component<ComponentProps, IGatewaysDeploymentsPageState> {
  private tableColumns: GridColumns<IGatewayDeploymentInDTO>;
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      rowsPerPage: 50,
    }
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    const params: IGatewaysArtifactsRequestParamsDTO = { page: 0, pageSize: rowsPerPage };
    dispatch<any>(GatewaysActions.fetchSofiaGatewaysDeployments(params));
  }

  public onFetchDeploymentsOnPage(page: number) {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    const params: IGatewaysArtifactsRequestParamsDTO = { page, pageSize: rowsPerPage };
    dispatch<any>(GatewaysActions.fetchSofiaGatewaysDeployments(params));
  }



  public async onRowClick(gatewayDeployment: IGatewayDeploymentInDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(GatewaysActions.fetchDeploymentDetails(gatewayDeployment));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      const { detailedDeployment } = this.props;
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_DOMAIN_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(GatewaysBaseActions.setSelectedDetailedDeployment({})),
          content: (
            <DeploymentDetailsView
              deployment={detailedDeployment}
            />
          ),
          titleMessageKey: 'gateways.deploymentDetail',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_GATEWAY_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getGatewayDetailsError',
        }
      }));
    }
  }

  public async onCreateNewDeployment(newDeploymentForm: IGatewayDeploymentOutDTO) {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(GatewaysActions.createDeployment(newDeploymentForm));
      const params: IGatewaysArtifactsRequestParamsDTO = { page: 0, pageSize: rowsPerPage };
      dispatch<any>(GatewaysActions.fetchSofiaGatewaysDeployments(params));
      dispatch<any>(ModalsActions.hideModal(`OPERATIONAL_CREATE_DEPLOYMENT_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_GATEWAY_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createDeploymentError',
        }
      }));
    }
  }

  public onCreateNewDeploymentClick() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_DEPLOYMENT_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <NewDeploymentForm
            onCreateNewDeployment={(newDeploymentForm: IGatewayDeploymentOutDTO) => this.onCreateNewDeployment(newDeploymentForm)}
          />
        ),
        titleMessageKey: 'gateways.newDeployment',
      }
    }));
  }

  public handleChangeRowsPerPage(pageSize: number) {
    const { dispatch } = this.props;
    this.setState({ rowsPerPage: pageSize }, () => {
      const params: IGatewaysArtifactsRequestParamsDTO = { page: 0, pageSize };
      dispatch<any>(GatewaysActions.fetchSofiaGatewaysDeployments(params));
    });
  }

  render() {
    const { rowsPerPage } = this.state;
    const { classes, deployments } = this.props
    const deploymentsPagination = !_.isEmpty(deployments.pagination) ? (deployments.pagination as PaginationDTO) : null;
    this.tableColumns = [
      {
        field: 'artifact',
        headerName: translations.t('gateways.gatewaysArtifact'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<any>) => {
          const deployment = params.row;
          return <h3 style={{ marginLeft: 0, fontWeight: 'bold' }}>{deployment?.artifact?.name}</h3>
        }
      },
      {
        field: 'scheduledAt',
        headerName: translations.t('gateways.deploymentScheduledAt'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          const deployment = params.row;
          return (
            <div>
              {deployment.scheduledAt ? (
                <h4>{moment(deployment?.scheduledAt).format('LLL')}</h4>
              ) : <h4>{'/NA'}</h4>}
            </div>
          )
        }
      },
      {
        field: 'createdByEmail',
        headerName: translations.t('gateways.createdBy'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          const deployment = params.row;
          return (
            <div>
              {deployment.createdByEmail ? (
                <h4>{deployment.createdByEmail}</h4>
              ) : <h4>{'/NA'}</h4>}
            </div>
          )
        }
      },
    ];
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <SearchBar
            headerTitleTranslationKey="gateways.gatewaysDeployments"
            searchDebounceDelay={200}
            newEntityTranslationKey="gateways.newDeployment"
            icon={<Share style={{ alignSelf: 'center', marginRight: 15, fontSize: 30  }} />}
            onCreateNewEntityButtonClick={() => this.onCreateNewDeploymentClick()}
          />
        </div>
        <div className={classes.tableContainer}>
          <DataGrid
            getRowId={(d) => d.id}
            rows={deployments.content || []}
            columns={this.tableColumns}
            rowHeight={50}
            onPageSizeChange={(pageSize) => this.handleChangeRowsPerPage(pageSize)}
            onPageChange={page => this.onFetchDeploymentsOnPage(page)}
            page={(deployments?.pagination as PaginationDTO)?.number}
            rowCount={(deployments?.pagination as PaginationDTO)?.totalElements}
            pageSize={rowsPerPage}
            onRowClick={row => this.onRowClick(row.row)}
            paginationMode="server"
            componentsProps={{
              pagination: {
                labelRowsPerPage: translations.t('forms.rowsPerPage')
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <SearchOutlined style={{ alignSelf: 'center' }} />
                    <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='gateways.noDeploymentFound' /></h3>
                    <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='gateways.noDeploymentFoundDescription' /></h4>
                  </div>
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='gateways.noDeploymentFound' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='gateways.noDeploymentFoundDescription' /></h4>
                </Stack>
              )
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    deployments: state.gateways.deployments,
    artifacts: state.gateways.artifacts,
    gateways: state.gateways.gateways,
    detailedDeployment: state.gateways.selectedDetailedDeployment,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(GatewaysDeploymentsPage)));