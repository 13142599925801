import { Button, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from "react";
import ReduxLanguage from "../ReduxLanguage";

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 15,
  },
  formTitle: {
    marginBottom: 25,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 25,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});

export interface IOTPOTPLoginFormState {
  code?: string;
  codeError: boolean;
};

export interface IOTPOTPLoginFormProps extends WithStyles<typeof styles>{
  onSubmit: (code: string) => void;
  onResetOtpLoginPhase: () => void;
}

class OTPOTPLoginForm extends React.Component<IOTPOTPLoginFormProps, IOTPOTPLoginFormState> {

  constructor(props: IOTPOTPLoginFormProps) {
    super(props);
    this.state = {
      code: '',
      codeError: false
    };
  }
  
  public onChangeCode(code: string) {
    this.setState({ codeError: !code, code });
  }

  canSubmitForm() {
    const { code, codeError } = this.state;
    return !codeError && code;
  }

  onSubmitForm() {
    const { onSubmit } = this.props;
    const { code } = this.state;
    if (code) {
      onSubmit(code);
    }
  }

  render() {
    const { classes, onResetOtpLoginPhase } = this.props;
    const { code, codeError } = this.state;
    const canSubmitForm = this.canSubmitForm();
    return (
      <div className={classes.container}>
        <h3 className={classes.formTitle}>
          <ReduxLanguage languageKey={'forms.insertOTPOTPLoginForm'} /><br />
          <ReduxLanguage languageKey={'forms.insertOTPOTPLoginFormDisclaimer'} />
        </h3>
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey={'forms.otp'} />}
          variant="outlined"
          fullWidth
          required
          error={codeError}
          value={code}
          onChange={e => this.onChangeCode(e.target.value)}
        />

        <Button
          variant="contained"
          disabled={!canSubmitForm}
          style={{ width: '100%', opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 25, fontWeight: 'bold'  }}
          onClick={() => this.onSubmitForm()}
        >
          <ReduxLanguage languageKey="login.login" />
        </Button>
        
        <Button
          variant="contained"
          style={{ width: '100%', backgroundColor: '#888888', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
          onClick={() => onResetOtpLoginPhase()}
        >
          <ReduxLanguage languageKey="login.changeEmail" />
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(OTPOTPLoginForm);
