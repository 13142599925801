import { PaginationDTO } from '@bottega52/commons-pagination';
import { AppBar, Button, createStyles, Tab, Tabs, withStyles, WithStyles } from '@material-ui/core';
import { OpenInNewOutlined, Warning } from '@material-ui/icons';
import { DataGrid, getGridStringOperators, GridColumns, GridFilterItem, GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import _, { omit } from 'lodash';
import moment from 'moment';
import 'moment/locale/it';
import React from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as salesInfoParamsDTODecoder from '../../codec/salesInfoParamsDTODecoder';
import DataGridBetweenInput from '../../components/DataGrid/DataGridBetweenInput';
import AddSalesInfoForm from '../../components/Forms/AddSalesInfoForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SalesActions from '../../redux/sales/sales.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import { IHardwareInDTO, ISalesInfoInDTO } from '../../repository/jago/model/input/ISalesInfoInDTO';
import IAddSalesInfoOutDTO from '../../repository/jago/model/output/IAddSalesInfoOutDTO';
import IEditBatchSalesInfoOutDTO from '../../repository/jago/model/output/IEditBatchSalesInfoOutDTO';
import IUnreconciledHardwareOutDTO from '../../repository/jago/model/output/IUnreconciledHardwareOutDTO';
import translations from "../../translations/i18next";
import Utils from '../../utils/Utils';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 110px)',
    width: '100%'
  },
  tableContainerUnreconciled: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 150px)',
    width: '100%'
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  logo: {
    width: '20%',
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableTitle: {
    fontWeight: 'bold'
  },
  addInfoButtonDiv: {
    flex: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 10,
  },
  warningDiv: {
    display: 'flex',
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IHardwareSalesInfoPageState {
  activeTab: number;
  salesInfoRowsPerPage: number;
  selectedUnreconciledHardware: IHardwareInDTO[];
  selectedSalesInfo: ISalesInfoInDTO[];
  selectionWithError: boolean;
  salesInfoFiltersModel?: GridFilterModel;
  salesInfoSortModel?: GridSortModel;
  unreconciledHardwareFilters?: IUnreconciledHardwareOutDTO;
  focusedUnreconciledHardwareDates: FocusedInputShape | null;
}

export interface IHardwareSalesInfoPageProps extends WithStyles<typeof styles> {
  language: string;
}

export type ComponentProps = IHardwareSalesInfoPageProps & IReduxProps;
export interface IHardwareSalesInfoPageProps extends IReduxProps, RouteComponentProps<any> {}

class HardwareSalesInfoPage extends React.Component<ComponentProps, IHardwareSalesInfoPageState> {
  private unreconciledHardwareColumns: GridColumns<IHardwareInDTO>;
  private salesInfoColumns: GridColumns<ISalesInfoInDTO>;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      activeTab: 0,
      salesInfoRowsPerPage: 100,
      selectedUnreconciledHardware: [],
      selectedSalesInfo: [],
      selectionWithError: false,
      focusedUnreconciledHardwareDates: null,
      unreconciledHardwareFilters: {
        fromDate: moment().subtract(3, 'month').startOf('day').valueOf(),
        toDate: moment().endOf('day').valueOf(),
      },
    }

    this.unreconciledHardwareColumns = [
      {
        field: 'hostname',
        headerName: translations.t('sales.domain'),
        type: 'string',
        sortable: true,
        width: 250,
      },
      {
        field: 'createdAt',
        headerName: translations.t('forms.createdAt'),
        type: 'date',
        width: 150,
        sortable: true,
        valueFormatter: (v) => v.value ? moment(v.value).format('L') : 'N/A',
        filterOperators: [{
          label: 'Between',
          requiresFilterValue: true,
          value: 'between',
          InputComponent: DataGridBetweenInput,
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
              return null;
            }
            if (filterItem.value[0] == null || filterItem.value[1] == null) {
              return null;
            }
            return ({ value }): boolean => {
              return value != null && filterItem.value[0] <= value && value <= filterItem.value[1];
            };
          },
        }],
      },
      {
        field: 'id',
        headerName: translations.t('sales.hardwareId'),
        type: 'number',
        sortable: true,
        width: 70,
      },
      {
        field: 'name',
        headerName: translations.t('sales.hardwareName'),
        type: 'string',
        sortable: true,
        width: 150,
      },
      {
        field: 'serialNumber',
        headerName: translations.t('sales.hardwareSerialNumber'),
        type: 'string',
        sortable: true,
        width: 150,
      },
      {
        field: 'type',
        headerName: translations.t('sales.hardwareType'),
        valueGetter: (v) => Utils.getHardwareType(v.value),
        type: 'string',
        width: 150,
        sortable: true,
      },
      {
        field: 'vendor',
        headerName: translations.t('sales.hardwareVendor'),
        type: 'string',
        width: 150,
        sortable: true,
      },
      {
        field: 'model',
        headerName: translations.t('sales.hardwareModel'),
        type: 'string',
        sortable: true,
        width: 150,
      },
      /*{
        field: '',
        headerName: translations.t('sales.actions'),
        type: 'actions',
        getActions: (p) => {
          const { selectedUnreconciledHardware } = this.state;
          return [
            <IconButton disabled={selectedUnreconciledHardware.length > 0} onClick={() => this.openAddSalesInfoForm([p.row])}>
              <OpenInNewOutlined fontSize="small" />
            </IconButton>
          ];
        },
      }*/
    ];

    this.salesInfoColumns = [
      {
        field: 'hostname',
        headerName: translations.t('sales.domain'),
        type: 'string',
        sortable: false,
        width: 250,
        filterOperators: _.filter(getGridStringOperators(), { value: 'contains' }),
      },
      {
        valueGetter: (params) => params.row.hardware?.id,
        field: 'hardware.id',
        headerName: translations.t('sales.hardwareId'),
        type: 'number',
        sortable: false,
        filterable: false,
        width: 70,
      },
      {
        valueGetter: (params) => params.row.hardware?.name,
        field: 'hardware.name',
        headerName: translations.t('sales.hardwareName'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 150,
      },
      {
        valueGetter: (params) => params.row.hardware?.serialNumber,
        field: 'hardware.serialNumber',
        headerName: translations.t('sales.hardwareSerialNumber'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 150,
      },
      {
        valueGetter: (params) => Utils.getHardwareType(params.row.hardware?.type),
        field: 'hardware.type',
        headerName: translations.t('sales.hardwareType'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 150,
      },
      {
        valueGetter: (params) => params.row.hardware?.model,
        field: 'hardware.model',
        headerName: translations.t('sales.hardwareModel'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 150,
      },
      {
        valueGetter: (params) => params.row.hardware?.vendor,
        field: 'hardware.vendor',
        headerName: translations.t('sales.hardwareVendor'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 150,
      },
      {
        field: 'sfdcId',
        headerName: translations.t('sales.sfdcId'),
        type: 'string',
        width: 150,
        sortable: true,
        filterOperators: _.filter(getGridStringOperators(), { value: 'contains' }),
      },
      {
        field: 'customerReference',
        headerName: translations.t('sales.sofiaCustRef'),
        type: 'string',
        width: 150,
        sortable: true,
      },
      {
        field: 'iseoSellOutOrderId',
        headerName: translations.t('sales.iseoSelloutOrderId'),
        type: 'string',
        width: 150,
        sortable: true,
        filterOperators: _.filter(getGridStringOperators(), { value: 'contains' }),
      },
      {
        field: 'iseoSellOutOrderDate',
        headerName: translations.t('sales.iseoSelloutOrderDate'),
        type: 'date',
        width: 150,
        sortable: true,
        valueFormatter: (v) => v.value ? moment(v.value).format('L') : 'N/A',
        filterOperators: [{
          label: 'Between',
          requiresFilterValue: true,
          value: 'between',
          InputComponent: DataGridBetweenInput,
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
              return null;
            }
            if (filterItem.value[0] == null || filterItem.value[1] == null) {
              return null;
            }
            return ({ value }): boolean => {
              return value != null && filterItem.value[0] <= value && value <= filterItem.value[1];
            };
          },
        }],
      },
      {
        field: 'iseoSellOutInvoiceId',
        headerName: translations.t('sales.iseoSelloutInvoiceId'),
        type: 'string',
        width: 150,
        sortable: true,
      },
      {
        field: 'iseoSellOutInvoiceDate',
        headerName: translations.t('sales.iseoSelloutInvoiceDate'),
        type: 'date',
        width: 150,
        sortable: true,
        valueFormatter: (v) => v.value ? moment(v.value).format('L') : 'N/A',
        filterOperators: [{
          label: 'Between',
          requiresFilterValue: true,
          value: 'between',
          InputComponent: DataGridBetweenInput,
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
              return null;
            }
            if (filterItem.value[0] == null || filterItem.value[1] == null) {
              return null;
            }
            return ({ value }): boolean => {
              return value != null && filterItem.value[0] <= value && value <= filterItem.value[1];
            };
          },
        }],
      },
    ];
  }

  componentDidMount() {
    this.fetchPaginatedSalesInfo(0);
    this.fetchNotReconciledHardwareWithFilters();
  }

  onTabChange(index: number) {
    this.setState({ activeTab: index, selectedUnreconciledHardware: [], selectedSalesInfo: [], selectionWithError: false });
  }

  handleChangeSalesInfoRowsPerPage(pageSize: number) {
    this.setState({ salesInfoRowsPerPage: pageSize }, () => {
      this.fetchPaginatedSalesInfo(0);
    });
  }

  handleUnreconciledHardwareSelected(tableIds: string[]) {
    const { sales } = this.props;
    const hardwareIds = _.map(tableIds, (el) => {
      const [hardwareType, hardwareId] = _.split(el, '-');
      return { type: hardwareType, id: parseInt(hardwareId, 10) }
    });
    const hardware = _.filter(sales.unreconciledHardware,
      (uh) => _.findIndex(hardwareIds, h => h.type === uh.type && h.id === uh.id ) > -1);
    this.setState({ selectedUnreconciledHardware: hardware });
  }

  handleSalesInfoSelected(salesInfoIds: number[]) {
    const { sales } = this.props;
    let selectionWithError = false;
    const info = _.filter(sales.salesInfo?.content, (uh) => _.includes(salesInfoIds, uh.id));
    for (let index = 0; index < info.length - 1; index++) {
      const curr = info[index];
      const next = info[index + 1];
      if (
        curr.sfdcId !== next.sfdcId ||
        curr.iseoSellOutInvoiceId !== next.iseoSellOutInvoiceId ||
        curr.iseoSellOutOrderId !== next.iseoSellOutOrderId
      ) {
        selectionWithError = true;
      }
    }
    this.setState({ selectedSalesInfo: info, selectionWithError });
  }

  handleSalesInfoFilterChange(model: GridFilterModel) {
    const { salesInfoFiltersModel } = this.state;
    const currentFilterValue = salesInfoFiltersModel?.items.length && salesInfoFiltersModel?.items[0].value;
    if (model.items.length && model.items[0].value !== currentFilterValue) {
      this.setState({ salesInfoFiltersModel: model }, () => {
        this.fetchPaginatedSalesInfo(0);
      });
    }
  }

  handleSalesInfoSortChange(model: GridSortModel) {
    this.setState({ salesInfoSortModel: model }, () => {
      this.fetchPaginatedSalesInfo(0);
    });
  }

  fetchPaginatedSalesInfo(page: number) {
    const { dispatch } = this.props;
    const { salesInfoRowsPerPage, salesInfoFiltersModel, salesInfoSortModel } = this.state;

    const formattedParamsDTO = salesInfoParamsDTODecoder.encode(
      page, salesInfoRowsPerPage, salesInfoSortModel, salesInfoFiltersModel
    );

    dispatch<any>(SalesActions.fetchSalesInfo(formattedParamsDTO));
  }

  async fetchNotReconciledHardwareWithFilters() {
    const { dispatch } = this.props;
    const { unreconciledHardwareFilters } = this.state;
    await dispatch<any>(SalesActions.fetchNotReconciledHardware(unreconciledHardwareFilters));
  }

  openAddSalesInfoForm(selectedUnreconciledHardware: IHardwareInDTO[]) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_SALES_INFO_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <AddSalesInfoForm
            onSubmit={(data) => this.addSalesInfoToUnreconciledHardware(data)}
            selectedHardware={selectedUnreconciledHardware}
          />
        ),
        titleMessageKey: 'sales.addSalesInfo',
      }
    }));
  }

  openEditSalesInfoForm(salesInfoToEdit: ISalesInfoInDTO[]) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_SALES_INFO_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <AddSalesInfoForm
            onSubmit={(data) => {
              const formattedData: IEditBatchSalesInfoOutDTO = {
                ids: _.map(salesInfoToEdit, el => el.id),
                ...omit(data, 'smartLockIds', 'gatewayIds', 'atlasIds'),
              }
              this.editBatchSalesInfo(formattedData);
            }}
            selectedHardware={_.map(salesInfoToEdit, (el) => el.hardware)}
            salesInfoToEdit={salesInfoToEdit[0]}
          />
        ),
        titleMessageKey: 'sales.editSalesInfo',
      }
    }));
  }

  async editBatchSalesInfo(data: IEditBatchSalesInfoOutDTO) {
    const { dispatch } = this.props;

    dispatch<any>(SettingsActions.setSpinnerVisible(true));
    try {
      await dispatch<any>(SalesActions.editBatchHardwareSalesInfo(data));
      await this.fetchNotReconciledHardwareWithFilters();
      await this.fetchPaginatedSalesInfo(0);
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_SALES_INFO_MODAL'));
    } catch (err) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SALES_INFO_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createSalesInfoError',
        }
      }));
    }
  }

  async addSalesInfoToUnreconciledHardware(data: IAddSalesInfoOutDTO) {
    const { dispatch } = this.props;

    dispatch<any>(SettingsActions.setSpinnerVisible(true));
    try {
      await dispatch<any>(SalesActions.addHardwareSalesInfo(data));
      await this.fetchNotReconciledHardwareWithFilters();
      await this.fetchPaginatedSalesInfo(0);
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_SALES_INFO_MODAL'));
    } catch (err) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SALES_INFO_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createSalesInfoError',
        }
      }));
    }
  }

  onDatesChange(startDateMoment: moment.Moment | null, endDateMoment: moment.Moment | null) {
    if (startDateMoment && endDateMoment) {
      this.setState({ unreconciledHardwareFilters: { fromDate: moment(startDateMoment).valueOf(), toDate: moment(endDateMoment).valueOf() }}, () => this.fetchNotReconciledHardwareWithFilters());
    }
  }

  render() {
    const { classes, sales } = this.props;
    const { activeTab, selectedUnreconciledHardware, selectedSalesInfo, selectionWithError, unreconciledHardwareFilters } = this.state;

    return (
      <div className={classes.container}>
        <AppBar position="sticky" style={{ backgroundColor: 'white' }}>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            style={{ backgroundColor: 'white', width: '100%' }}
            onChange={(e, index) => this.onTabChange(index)}
          >
            <Tab label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="sales.unreconciledHardware" /></h5>} />
            <Tab label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="sales.hardwareWithSalesInfo" /></h5>} />
            <div className={classes.addInfoButtonDiv}>
              {selectionWithError ?
                <div className={classes.warningDiv}>
                  <Warning style={{ color: 'red' }} /> 
                  <h4 style={{ color: 'red', fontWeight: 'bold' }}>
                    <ReduxLanguage languageKey="sales.selectedSalesInfoError" />
                  </h4>
                </div>
              : null}
              <Button
                variant='contained'
                color='primary'
                style={{ margin: '10px 0px', backgroundColor: selectionWithError ? 'red' : undefined }}
                disabled={selectedUnreconciledHardware.length < 1 && selectedSalesInfo.length < 1}
                onClick={() => {
                  activeTab === 0 ? this.openAddSalesInfoForm(selectedUnreconciledHardware) : this.openEditSalesInfoForm(selectedSalesInfo)
                }}
              >
                <OpenInNewOutlined style={{ marginRight: 10 }} fontSize="small" />
                <span style={{ fontSize: 11 }}>
                  <ReduxLanguage languageKey={activeTab === 0 ? "sales.addSalesInfo" : "sales.editSalesInfo"} />
                </span>
              </Button>
            </div>
          </Tabs>
          
        </AppBar>
        {activeTab === 0 ? (
          <>
            <div>
              <DateRangePicker
                onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
                startDate={moment(unreconciledHardwareFilters?.fromDate)} 
                endDate={moment(unreconciledHardwareFilters?.toDate)}
                startDateId="start_date_id"
                displayFormat="DD/MM/YYYY"
                endDateId="end_date_id"
                focusedInput={this.state.focusedUnreconciledHardwareDates}
                onFocusChange={focusedInput => this.setState({ focusedUnreconciledHardwareDates: focusedInput })}
                showDefaultInputIcon
                small
                hideKeyboardShortcutsPanel
                isOutsideRange={() => false}
              />
            </div>
            <div className={classes.tableContainerUnreconciled}>
              <DataGrid
                getRowId={(d) => `${d.type}-${d.id}`}
                rows={sales.unreconciledHardware || []}
                columns={this.unreconciledHardwareColumns}
                rowHeight={38}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(ids) => this.handleUnreconciledHardwareSelected(ids as string[])}
                componentsProps={{
                  pagination: {
                    labelRowsPerPage: translations.t('forms.rowsPerPage')
                  }
                }}
              />
            </div>
          </>  
         ) : null}
         {activeTab === 1 ? (
          <div className={classes.tableContainer}>
            <DataGrid
              rows={sales?.salesInfo?.content || []}
              columns={this.salesInfoColumns}
              rowHeight={38}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => this.handleSalesInfoSelected(ids as number[])}
              page={(sales.salesInfo?.pagination as PaginationDTO)?.number}
              onPageChange={(page) => this.fetchPaginatedSalesInfo(page)}
              paginationMode="server"
              filterMode="server"
              sortingMode="server"
              onPageSizeChange={(pageSize) => this.handleChangeSalesInfoRowsPerPage(pageSize)}
              onSortModelChange={(model) => this.handleSalesInfoSortChange(model)}
              onFilterModelChange={(model) => this.handleSalesInfoFilterChange(model)}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: translations.t('forms.rowsPerPage')
                }
              }}
            />
          </div>
         ) : null}
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    sales: state.sales,
  };
}


const connector = connect(mapStateToProps);
export default connector(withStyles(styles)(HardwareSalesInfoPage));