const translations = {
  login: {
    welcome: 'Benvenuto in Luckey Support Backoffice',
    ssoLogin: 'Effettua l\'accesso tramite l\'email del tuo account',
    customers: 'Clienti',
    sendEmail: 'Invia email',
    otpLogin: 'Accedi tramite email',
    login: 'Login',
    changeEmail: 'Usa un\'altro indirizzo email',
    or: 'OPPURE'
  },
  domains: {
    customerAdminDeletionConfirm: 'Sei sicuro di voler procedere all\'eliminazione di questo cliente? L\'operazione non può essere annullata',
    customerAdminDeletionSuccess: 'Cliente eliminato con successo',
    luckeyDomainsTitle: 'Domini',
    domainName: 'Nome dominio',
    domainDetails: 'Dettagli dominio',
    domainConfigModifySuccess: 'Configurazioni Dominio modificate e aggiornate',
    domainConfigTitle: 'Configurazioni - Feature Toggling',
    domainConfigDescription: 'JSON di configurazione del dominio. Usalo per abilitare/disablitare funzionalità',
    domainConfigGuide: 'Guida alle Configurazioni',
    domainUsersTitle: 'Managers Dominio',
    domainUsersDescription: 'Impersona uno degli utenti managers per operazioni di manutenzione straordinaria sul dominio.\nCliccando su uno dei managers verrai rediretto a Luckey Web App già autenticato con quell\'account',
    domainUsersWarning: 'ATTENZIONE: Impersonando gli utenti potrai apportare modifiche all\'impianto anche irreversibili, usare la massima cautela',
    noUsersFoundTitle: 'Lista utenti vuota',
    noUsersFoundDescription: 'Non è stato trovato nessun utente in questo dominio',
    noDomainsFoundTitle: 'Nessun dominio trovato',
    noDomainFoundDescription: 'La lista dei domini è vuota. Se hai impostato un filtro di ricerca prova a modificarlo e riprovare',
    impersonateSuccess: 'Utente impersonato con successo',
    domainSubsriptionTitle: 'Subscriptions del dominio',
    domainSubsriptionsEmptyTitle: 'Subscriptions non trovate o scadute',
    domainSubsriptionsEmptyDescription: 'Il dominio non ha una subscription attiva. Prova a rinnovarla e ricaricare la pagina',
    domainSubsriptionDescription: 'Lista della subscription del dominio: serrature, utenti e scadenza dominio',
    expiration: 'Scadenza',
    totalNumber: 'Totale',
    used: 'Utilizzati',
    tooMuchElements: 'ATTENZIONE: IL DOMINIO HA SUPERATO IL LIMITE DI ELEMENTI',
    newDomain: 'Nuovo dominio',
    externalReference: 'External reference',
    externalReferenceDetails: 'Ad esempio "data-IdCommessa"',
    productTitle: 'Tipologia Subscription',
    extendDomain: 'Estendi subscriptions dominio',
    extendDomainDescription: 'Estendi il dominio con un add-on o un codice prodotto',
    domainInstallersTitle: 'Installers del dominio',
    domainInstallersDescription: 'Elenco degli utenti Installer presenti nel dominio selezionato',
    domainDeletionTitle: 'Cancellazione dominio',
    domainDeletionDescription: 'Richiedi la cancellazione di questo dominio',
    sendDeletionRequest: 'Invia',
    domainDeletionConfirm: 'Vuoi inviare una richiesta per cancellare questo dominio?',
    domainDeletionSuccess: 'Richiesta inviata con successo',
    customerDeletionTitle: 'Cancellazione cliente',
    customerDeletionConfirm: 'Vuoi inviare una richiesta per cancellare questo cliente?',
    customerDeletionSuccess: 'Richiesta inviata con successo',
    walletDeletionTitle: 'Cancellazione wallet',
    walletDeletionConfirm: 'Vuoi inviare una richiesta per cancellare questo wallet?',
    walletDeletionSuccess: 'Richiesta inviata con successo',
    verifyServiceStatus: 'Stato del servizio',
    impersonateUser: 'Impersona utente',
    impersonate: 'Impersona',
    impersonateReadOnly: 'Impersona in sola lettura',
    domainAdditionalInfo: 'Informazioni dominio',
    domainAdditionalInfoDescription: 'Indica qui delle informazioni aggiuntive sul dominio',
    additionalInfoSavedSuccessfully: 'Note salvate con successo',
    EASY: 'Solo serrature Argo',
    SMART: 'Serrature Argo + Gateway',
    STANDARD: 'Solo serrature V364',
    ENTERPRISE: 'Serrature V364 + Argo',
    numberOfArgoLocks: 'Serrature Argo',
    numberOfVegaLocks: 'Serrature V364',
    numberOfGatewaysLocks: 'Gateways',
    numberOfUsers: 'Utenti',
    numberOfLocks: 'Serrature',
    federatedDomain: 'Dominio collegato a: {{domain}}',
    federatedMasterDomain: 'Dominio Master di un gruppo',
    federatedDomains: 'Gruppo di domini',
    domainFederatedLicense: 'Attenzione: questa subscription è unificata per tutti i domini che fanno capo al dominio principale {{domain}}',
    federatedDomainsDescription: 'Dominio appartiene ad un gruppo',
    installationMode: 'Modalità Installazione',
    domainStandard: 'Dominio standard',
    domainWithCloudCredits: 'Dominio cloud credits',
    domainIrisRequired: '1ris Richiesto',
    domainIrisEnabled: '1ris Abilitato',
    domainIrisEnable: 'Abilita 1ris',
    domainIrisEnableConfirm: 'Vuoi impostare 1ris come ABILITATO su questo dominio?',
  },
  gateways: {
    createdBy: 'Creato da',
    noGatewayFoundTitle: 'Nessun HyperGate trovato',
    noGatewayFoundDescription: 'La lista degli HyperGate è vuota. Se hai impostato un filtro di ricerca prova a modificarlo e riprovare',
    gatewayName: 'Nome HyperGate',
    luckeyGatewaysTitle: 'HyerGates del sistema',
    lastConnectionDate: 'Ultima connessione: {{ date }}',
    gatewaysDevices: 'Dispositivi',
    gatewaysArtifacts: 'Artifacts',
    gatewaysDeployments: 'Deployments',
    noArtifactFoundTitle: 'Nessun Artifact trovato',
    noArtifactFoundDescription: 'La lista degli Artifacts è vuota. Se hai impostato un filtro di ricerca prova a modificarlo e riprovare',
    noDeploymentFoundTitle: 'Nessun Deployment trovato',
    noDeploymentFoundDescription: 'La lista degli Deployment è vuota. Se hai impostato un filtro di ricerca prova a modificarlo e riprovare',
    newDeployment: 'Nuovo Deployment',
    deploymentScheduledAt: 'Data di lancio',
    gatewaysArtifact: 'Artifact',
    gateways: 'Gateways',
    artifactName: 'Nome artifact',
    createNewDeployment: 'Lancia deployment',
    selectHyperGates: 'Seleziona HyperGates',
    allGateways: 'Lancia su tutti gli HyperGates',
    HYPERGATE_PRO: 'HyperGate Pro',
    HYPERGATE_ISEO: 'HyperGate Lite',
    deploymentDetail: 'Dettaglio Deployment',
    noDeploymentDetailedFound: 'Nessun Gateway trovato per il deployment',
    noDeploymentDetailedFoundDescription: 'La lista dei gateway per questo deployment è vuota',
    DONE: 'Completato',
    PENDING: 'Rilascio in corso',
    IN_PROGRESS: 'In installazione',
    ERROR: 'Errore',
    deviceId: 'Id dispositivo',
    name: 'Nome',
    gatewayVersion: 'Versione',
    iseoSdkVersion: 'ISEO SDK',
    kernelVersion: 'Kernel Version',
    serialNumber: 'Serial Number',
    noGatewaysFound: 'Nessun Gateway trovato',
    noGatewaysFoundDescription: 'La lista dei gateway è vuota. Prova a modificare i filtri di ricerca e riprova',
    noDeploymentFound: 'Nessun Deployment trovato',
    noArtifactFound: 'No Artifact found',
  },
  credits: {
    marketplaces: 'Marketplaces',
    searchMarketplace: 'Cerca marketplace...',
    marketplaceName: 'Nome marketplace',
    luckeyMarketplaceTitle: 'Marketplaces Luckey',
    noMarketplaceFoundTitle: 'Nessun marketplace trovato',
    noMarketplaceFoundDescription: 'La lista dei marketplace è vuota. Se hai impostato un filtro di ricerca prova a modificarlo e riprovare',
    newMarketplace: 'Nuovo marketplace',
    marketplaceCreationConfirm: 'Sei sicuro di voler creare il nuovo marketplace?',
    createMarketplace: 'Crea marketplace',
    marketplaceDetails: 'Dettagli marketplace',
    editMarketplaceCreationConfirm: 'Stai modificando i dati del marketplace, sei sicuro di voler procedere?',
    editMarketplace: 'Modifica marketplace',
    searchCustomer: 'Cerca cliente...',
    customerName: 'Nome cliente',
    luckeyCustomersTitle: 'Clienti',
    noCustomerFindTitle: 'Nessun cliente trovato',
    noCustomerFindDescription: 'La lista dei clienti è vuota. Se hai impostato un filtro di ricerca prova a modificarlo e riprovare',
    newCustomer: 'Nuovo cliente',
    customerCreationConfirm: 'Sei sicuro di voler creare il nuovo cliente?',
    newUser: 'Nuovo utente',
    userCreationConfirm: 'Sei sicuro di voler creare il nuovo utente?',
    createCustomer: 'Crea cliente',
    customerDetails: 'Dettagli cliente',
    editCreationConfirm: 'Stai modificando l\'anagrafica cliente, sei sicuro di voler procedere?',
    editCustomer: 'Modifica cliente',
    editCreationUserConfirm: 'Stai modificando l\'anagrafica utente, sei sicuro di voler procedere?',
    editUser: 'Modifica utente',
    customerWallets: 'Wallets di {{customerName}}',
    newWallet: 'Nuovo Wallet',
    walletCreationConfirm: 'Vuoi creare un wallet per il cliente selezionato?',
    createWallet: 'Crea wallet',
    editWallet: 'Modifica wallet',
    walletEditConfirm: 'Vuoi modificare il wallet selezionato?',
    noWalletFoundTitle: 'Nessun wallet trovato',
    noWalletFoundDescription: 'Il cliente non ha nessun wallet associato',
    walletDetails: 'Dettagli Wallet',
    rechargeWallet: 'Ricarica',
    walletRechargeHistory: 'Storico ricariche',
    rechargeRowTitle: 'Crediti modificati',
    rechargeCreditsDescription: 'Il wallet è stato ricaricato di {{credits}} crediti',
    rechargeCardDetails: 'Dettaglio ricarica utilizzata',
    systemParameters: 'Listino',
    systemSubscriptions: 'Subscriptions',
    systemSubscriptionsDescription: 'Queste sono le subscriptions presenti a sistema. Creane di nuove o assegnale ad un dominio esistente',
    newSubscription: 'Nuova Subscription',
    editSubscription: 'Modifica Subscription',
    noSystemSubscriptionFoundTitle: 'Nessuna subscription trovata',
    noSystemSubscriptionFoundDescription: 'Non esiste nessuna subscription a sistema assegnabile ad un dominio',
    products: 'Subscriptions legacy',
    rechargeCards: 'Ricariche',
    noSystemCardFoundTitle: 'Nessuna ricarica trovata',
    noSystemCardFoundDescription: 'Non ci sono ricariche a sistema. Creane di nuove per ricaricare i Wallet dei clienti',
    newCard: 'Nuova ricarica',
    cardStatusUsed: 'Usata',
    cardStatusUnused: 'Non usata',
    cardStatusInactive: 'Disabilitata',
    enableCard: 'Abilita',
    disabledCardTooltip: 'La ricarica sarà abilitata non appena verrà ricevuta la fattura ISEO associata',
    rechargeCardCode: 'Codice',
    rechargeCardCreditsDetails: 'Questa ricarica vale {{credits}} crediti',
    newTierSubscription: 'Nuova subscription principale',
    pluginAndService: 'Add-on e servizi',
    newPluginSubscription: 'Nuovo add-on o servizio',
    tierSubscription: 'Subscription principale',
    walletSubscriptions: 'Subscriptions',
    noWalletSubscriptionFoundTitle: 'Nessuna subscription trovata',
    noWalletSubscriptionFoundDescription: 'Questo wallet non ha nessuna subscription associata',
    newWalletSubscription: 'Associa nuova subscription',
    editWalletSubscription: 'Modifica subscription',
    newWalletPluginSubscription: 'Associa nuovo add-on o servizio',
    walletConsumption: 'Consumi',
    noWalletUsageFoundTitle: 'Nessun dato trovato',
    noWalletUsageFoundDescription: 'Non sono presenti dati di consumi per questo wallet',
    ACTIVATION: 'Costo attivazione di una subscription o add-on',
    DAILY_USAGE: 'Consumo giornaliero',
    creditConsumptionType: 'Tipologia di consumo',
    consumptionCreditsDescription: 'Il wallet ha consumato {{credits}}',
    consumptionRowTitle: 'Consumo di crediti',
    walletPlants: 'Domini',
    noWalletPlantFoundTitle: 'Nessun dominio trovato',
    noWalletPlantFoundDescription: 'Non è stato trovato alcun dominio per questo wallet',
    newWalletPlant: 'Nuovo dominio',
    deleteWalletSubscription: 'Rimuovi subscription',
    editCard: 'Modifica ricarica',
    rechargeDetails: 'Dettagli ricarica',
    exportWalletsUsageCSV: 'Esporta utilizzo crediti',
    exportWalletsCreditUsageCSV: 'Esporta Wallet Credit',
    PENDING: 'In attivazione',
    ACTIVE: 'Attivo',
    READY_TO_ACTIVATE: 'Pronto per attivazione',
    walletStatus: 'Stato del Wallet',
    exportManagersCSV: 'Esporta Managers',
    needCreditsToCreatePlant: 'Per creare un dominio è necessario che il wallet abbia un numero di crediti maggiore di zero',
  },
  sales: {
    hardwareSalesInfo: 'Info vendite',
    domain: 'Dominio',
    hardwareId: 'ID HW',
    hardwareName: 'Nome HW',
    hardwareSerialNumber: 'Seriale HW',
    hardwareModel: 'Modello HW',
    hardwareVendor: 'Linea prodotto HW',
    hardwareType: 'Tipologia HW',
    sfdcId: 'ID SFDC',
    iseoSelloutOrderId: 'ID ord. sell-out ISEO',
    iseoSelloutOrderDate: 'Data ord. sell-out ISEO',
    iseoSelloutInvoiceId: 'ID fatt. sell-out ISEO',
    iseoSelloutInvoiceDate: 'Data fatt. sell-out ISEO',
    sofiaCustRef: 'Cust. Ref. (commessa Sofia)',
    unreconciledHardware: 'Hardware non riconciliato',
    hardwareWithSalesInfo: 'Hardware con info vendita',
    actions: 'Azioni',
    addSalesInfo: 'Aggiungi info vendite',
    editSalesInfo: 'Modifica info vendite',
    iseoSelloutOrder: 'Ordine sell-out ISEO',
    iseoSelloutInvoice: 'Fattura sell-out ISEO',
    selectedSalesInfoError: 'Attenzione! La seleziona non è omogenea. Sei sicuro di voler procedere?',
  },
  errors: {
    deleteWalletSubscriptionError: 'Si è verificato un errore nel modificare la subscription. Per favore riprova o contatto il nostor team di supporto',
    modifyVarOpportunityError: 'Si è verificato un errore nel modificare l\'Opportunità. Si prega di riprovare',
    error: 'Errore',
    getDomainDetailsError: 'Si è verificato un errore nell\'ottenere i dettagli del dominio. Riprova oppure contatta il Support Sofia',
    updateDomainConfigError: 'Si è verificato un errore nel salvare la configurazione dell\'impianto. Riprova oppure contatta il Support Sofia',
    updatePlantThemeError: 'Si è verificato un errore nel salvare il tema dell\'impianto. Riprova oppure contatta il Support Sofia',
    loginFailed: 'Sessione scaduta, per favore effettua nuovamente il login',
    loginGoogleFailedTitle: 'Google Login Fallito', 
    loginGoogleFailed: 'Assicurati di essere loggato in un account Google dell\'organizzazione Sofia, puoi provare ad aprire questa pagina una sessione di Chrome con il tuo account GSuite Sofia' ,
    loginJagoGoogleFailedTitle: 'Jago Login Fallito',
    loginJagoGoogleFailed: 'Assicurati che su Jago esista una tua utenza collegata al tuo account Google Sofia e riprova',
    impersonateDomainUserError: 'Impossibile impersonare l\'utente selezionato. Verifica che l\'utente sia attivo e riprova o contatta il Support Sofia',
    createDomainError: 'Si è verificato un errore nella creazione del dominio',
    addDomainPluginError: 'Si è verificato un errore nell\'estensione del dominio', 
    addUserDomainError: 'Impossibile creare utente. Controllare se l\'utente è già presente nel dominio o se il dominio ha una subscription valida',
    errorSaveAdditionalInfo: 'Impossibile aggiornare le note aggiuntive. Per favore riprova o contatta il Support Sofia',
    createCustomerError: 'Impossibile creare il cliente. Controllare se il cliente è già presente e riprovare',
    createUserError: 'Impossibile creare l\'utente. Controllare se l\'utente è già presente e riprovare',
    createVarUserError: 'Impossibile creare l\'utente VAR. Controllare se l\'utente è già presente e riprovare',
    createPlantThemeError: 'Impossibile creare il tema personalizzato. Controllare i dati e riprovare',
    updateVarError: 'Impossibile aggiornare. Controllare i dati e riprovare',
    getCustomerDetailsError: 'Impossibile ottenere i dettagli del cliente selezionato. Per favore riprova',
    getVarDetailsError: 'Impossibile ottenere i dettagli del VAR selezionato. Per favore riprova',
    getPlantThemeDetailsError: 'Impossibile ottenere i dettagli del tema personalizzato selezionato. Per favore riprova',
    deleteDomainRequestError: 'Impossibile inviare la richiesta. Per favore riprova.',
    deleteWalletRequestError: 'Impossibile inviare la richiesta. Per favore riprova.',
    deleteCustomerRequestError: 'Impossibile inviare la richiesta. Per favore riprova.',
    enableIrisDomainRequestError: 'Impossibile inviare la richiesta. Per favore riprova.',
    createCustomerWalletError: 'Impossibile creare il wallet. Per favore riprova',
    getWalletDetailsError: 'Impossibile ottenere i dettagli del wallet selezionato. Per favore riprova',
    openSystemParametersError: 'Impossibile aprire i parametri di sistema. Per favore riprova',
    createSubscriptionError: 'Impossibile creare la subscription. Per favore riprova',
    createCardError: 'Si è verificato un errore nel creare la ricarica. Per favore riprova',
    createVarOpportunityError: 'Si è verificato un errore durante la creazione. Per favore riprova',
    editVarOpportunityError: 'Si è verificato un errore durante la modifica. Per favore riprova',
    rechargeWalletError: 'Si è verificato un errore nella ricarica del Wallet. Per favore riprova',
    deleteCardError: 'Si è verificato un errore nel cancellare la ricarica. Verifica che non sia in uso e riprova',
    enableCardError: 'Si è verificato un errore nell\'aggiornamento della ricarica. Verifica che non sia in uso e riprova',
    deletePlantThemeError: 'Si è verificato un errore durante la cancellazione. Verifica che non sia in uso e riprova',
    deleteVarUserError: 'Si è verificato un errore durante la cancellazione. Verifica che non sia in uso e riprova',
    deleteVarOpportunityError: 'Si è verificato un errore durante la cancellazione. Verifica che non sia in uso e riprova',
    rejectVarOpportunityError: 'Si è verificato un errore durante l\'aggiornamento dell\'opportunità. Verifica che non sia in uso e riprova',
    approveVarOpportunityError: 'Si è verificato un errore durante l\'aggiornamento dell\'opportunità. Verifica che non sia in uso e riprova',
    upgradeVarOpportunityError: 'Si è verificato un errore durante l\'aggiornamento dell\'opportunità. Verifica che non sia in uso e riprova',
    winVarOpportunityError: 'Si è verificato un errore durante l\'aggiornamento dell\'opportunità. Verifica che non sia in uso e riprova',
    loseVarOpportunityError: 'Si è verificato un errore durante l\'aggiornamento dell\'opportunità. Verifica che non sia in uso e riprova',
    deleteUserError: 'Si è verificato un errore durante la cancellazione. Verifica che non sia in uso e riprova',
    createWalletSubscriptionError: 'Si è verificato un errore nell\'associazione della subscription al wallet. Per favore riprova',
    createWalletPlantError: 'Si è verificato un errore nel creare il dominio. Per favore riprova',
    walletActionErrorNotEnoughCreditsTitle: 'Crediti insufficienti',
    walletActionErrorNotEnoughCredits: 'Il wallet non ha abbastanza crediti per effettuare l\'operazione. Verifica l\'operazione o ricarica il wallet e riprova',
    warningMinCreditsCard: 'Attenzione! Minimo numero di crediti richiesto = {{credits}}',
    loginJagoOTPEmailFailedTitle: 'Errore invio email',
    loginJagoOTPEmailFailed: 'Si è verificato un errore nell\'invio della mail, riprovare tra 5 minuti',
    loginJagoOTPFailedTitle: 'Errore verifica codice',
    loginJagoOTPFailed: 'La verifica del codice di accesso non è andata a buon fine. Controllare il codice immesso oppure aspettare 5 minuti e riprovare l\'accesso',
    getGatewayDetailsError: 'Si è verificato un errore nell\'ottenere i dettagli dell\'HyperGate. Riprova oppure contatta il Support Sofia',
    createDeploymentError: 'Si è verificato un errore nel creare il deployment. Per favore riprova',
    addonV364missing: 'Non è stato possibile trovare l\'add-on relativo a V364 nel sistema, contattare l\'assistenza.',
  },
  forms: {
    rowsPerPage: 'Elementi per pagina',
    permissionsDetails: 'Dettaglio permessi',
    rolePermissions: 'Permessi',
    clusterAndIseoCompany: 'Cluster e consociata ISEO',
    createCardSuccess: 'Ricarica creata con successo',
    cardDetails: 'Dettaglio ricarica',
    status: 'Stato',
    customerInfo: 'Dettagli cliente',
    details: 'Dettagli',
    costs: 'Costi',
    hostName: 'Hostname',
    domainType: 'Tipologia',
    user: 'Utente',
    logoutConfirmation: 'Sei sicuro di voler effettuare il logout?',
    send: 'Invia',
    askQuestion: 'Scrivici',
    ticketOpenSuccessfully: 'La tua domanda è stata inviata al nostro team! Uno dei nostri membri ti contatterà a breve.',
    ticketOpenError: 'Si è verificato un errore durante l\'invio della richiesta. Per favore riprova',
    writeUsAQuestionDescription: 'Hai una domanda? Scrivici la tua richiesta e uno dei nostri Product Specialist sarà pronto ad assisterti',
    deviceType: 'Tipo dispositivo',
    createNewMarketplace: 'Nuovo marketplace',
    createNewCustomer: 'Crea',
    firstName: 'Nome',
    lastName: 'Cognome',
    email: 'Email',
    contactEmail: 'Email di contatto',
    export: 'Esporta',
    open: 'Apri',
    add: 'Aggiungi',
    actions: 'Azioni',
    search: 'Cerca',
    remove: 'Rimuovi',
    delete: 'Elimina',
    deleteRequest: 'Richiedi eliminazione',
    deleteAdminOnly: 'Elimina (admin)',
    copy: 'Copia',
    expiresAt: 'Scadenza: {{expirationDate}}',
    error: 'Errore',
    activateLicense: 'Attiva subscription',
    loadingUserList: 'Carico gli utenti...',
    cancel: 'Annulla',
    confirm: 'Conferma',
    warning: 'Attenzione',
    close: 'Chiudi',
    reset: 'Reset',
    save: 'Salva',
    spinnerLoading: 'Caricamento...',
    detailsGeneric: 'Dettagli',
    createNewDomain: 'Crea nuovo dominio',
    walletName: 'Nome wallet',
    domainName: 'Nome dominio',
    domainNamePreview: 'Anteprima',
    domainFormTitle: 'Crea un nuovo dominio Luckey',
    invalidDomainName: 'Nome dominio non valido. Nessuno spazio, solo lettere minuscole, numeri e trattini',
    selectProduct: 'Seleziona una tipologia',
    name: 'Nome',
    lastname: 'Cognome',
    createNewUser: 'Crea nuovo Manager',
    selectRole: 'Seleziona ruolo utente',
    createUserForDomain: 'Crea un nuovo utente Manager per il dominio selezionato',
    newUser: 'Nuovo utente',
    password: 'Password',
    letUserChoosePassword: 'Fai scegliere password all\'utente: manda link di impostazione password all\'email indicata',
    domainCreateSuccess: 'Dominio creato con successo',
    domainCreateUserAfterCreation: 'Vuoi creare un utente per il nuovo dominio?',
    domainCreationConfirm: 'Si vuole procedere a creare il nuovo dominio?',
    createDomain: 'Crea dominio',
    username: 'Username',
    domainExtendsConfirm: 'Si vuole procedere ad estendere il dominio col prodotto selezionato?',
    createNewInstaller: 'Crea nuovo Installer',
    newInstaller: 'Nuovo Installer',
    createInstallerForDomain: 'Crea un nuovo utente installatore per il dominio selezionato',
    deleteDomain: 'Richiedi cancellazione dominio',
    active: 'Attivo',
    disabled: 'Disattivato',
    impersonateUserDescription: 'Prima di impersonare l\'utente all\'interno dell\'impianto ti avvisiamo che:',
    impersonateUserDescriptionOne: 'L\'inizio della sessione e la sua durata appariranno nei logs dell\'impianto',
    impersonateUserDescriptionTwo: 'Ogni tua azione sarà registrata e apparirà nei logs dell\'impianto',
    impersonateUserDescriptionThree: 'Qualsiasi azione che effettuerai sull\'impianto avrà affetto in maniera istantanea e in alcuni casi irreversibile',
    selectSessionDuration: 'Seleziona durata della sessione di supporto',
    impersonateReadOnly: 'Modalità sola lettura',
    impersonateReadOnlyDescription: 'Accedi all\'impianto in modalità sola lettura, potrai visualizzare i dati dell\'impianto ma senza poterli modificare',
    impersonateReadOnlyWarning: 'Attenzione: La sessione di supporto e la sua durata saranno comunque visibile nei logs dell\'impianto',
    minutes: '{{minutes}} minuti',
    selectSessionDurationPlaceholder: 'Durata sessione',
    installationModeActive: 'Attiva',
    installationModeDisabled: 'Disattivata',
    customerFormTitle: 'Cliente Luckey',
    varFormTitle: 'Value Added Reseller (VAR)',
    varFormAdminSection: 'Sezione admin',
    varFormTitleVar: 'Informazioni aziendali',
    plantThemeFormTitle: 'Tema Luckey personalizzato',
    customerName: 'Nome cliente',
    invalidCustomerName: 'Nome cliente non valido',
    marketplaceFormTitle: 'Marketplace Luckey',
    marketplaceName: 'Nome marketplace',
    invalidMarketplaceName: 'Nome marketplace non valido',
    createNewWallet: 'Nuovo Wallet',
    whatsNewTitle: 'Luckey what\'s new!',
    whatsNewSubtitle: 'Scopri le ultime novità su Luckey e Sofia part of ISEO',
    required: 'Obbligatorio',
    notes: 'Note aggiuntive',
    cardCode: 'Codice ricarica',
    recharge: 'Ricarica',
    rechargeWalletFormDescription: 'Ricarica i crediti di questo wallet inserendo il codice di ricarica. La ricarica avrà effetto immediatamente',
    subscriptionName: 'Nome subscription',
    subscriptionFixedPrice: 'Costo di attivazione (crediti)',
    subscriptionBurnRate: 'Costo ricorrente (crediti)',
    subscriptionVisible: 'Visibile nel marketplace',
    createNewSubscriptionDescription: 'Crea una nuova subscription nel sistema. La nuova subscription sarà disponibile a tutti i domini',
    subscriptionBurnRateDescription: 'Indica quanti Cloud Credits consumerà al giorno questa subscription una volta attivata',
    subscriptionFixedPriceDescription: 'Indica quanti Cloud Credits consumerà questa subscription al momento dell\'attivazione',
    subscriptionLogic: 'Logica di conteggio',
    selectLogic: 'Seleziona logica',
    subscriptionLogicDescription: 'Indica quali elementi del sistema verranno conteggiati per determinare i vincoli del sistema',
    FIXED: 'Non mettere vincoli di elementi',
    COUNT_USERS_LOCKS: 'Limita separatamente il numero di utenti e le serrature del dominio',
    COUNT_LOCKS: 'Limita solo il numero serrature del dominio',
    COUNT_ELEMENTS: 'Limita la somma serrature e utenti del dominio',
    maxLocks: 'Massimo numero di serrature',
    maxUsers: 'Massimo numero di utenti',
    maxElements: 'Massimo numero di elementi (utenti + serrature)',
    subscriptionMaxLocksDescription: 'Indica il massimo numero di serrature installabili con questa subscription',
    subscriptionMaxUsersDescription: 'Indica il massimo numero di utenti attivabili con questa subscription',
    subscriptionMaxElementsDescription: 'Indica il massimo numero di elementi con questa subscription',
    subscriptionResource: 'Risorsa',
    cardQuantity: 'Numero di crediti',
    cardQuantityDescription: 'Indica il numero di crediti che saranno ricaricati al momento dell\'utilizzo della ricarica',
    cardVar: 'Assegna ricarica ad un VAR',
    cardWallet: 'Assegna ricarica ad un wallet specifico',
    cardNotes: 'Note aggiuntive',
    createCardConfirm: 'Sei sicuro di voler creare la ricarica?',
    createVarOpportunityConfirm: 'Sei sicuro di voler creare la nuova opportunità?',
    createSubscrptionConfirm: 'Sei sicuro di voler creare la nuova subscription?',
    rechargeWalletConfirm: 'Sei sicuro di voler ricaricare il wallet di questo cliente? L\'operazione non può essere annullata',
    createdAt: 'Data creazione',
    copiedSuccess: 'Copiato!',
    logout: 'Esci',
    deleteCardConfirm: 'Sei sicuro di voler eliminare questa ricarica? L\'operazione non può essere annullata',
    enableCardConfirm: 'Sei sicuro di voler abilitare all\'attivazione questa ricarica? Una volta abilitata, questa ricarica potrà essere usata. L\'operazione non può essere annullata',
    newTierSubscription: 'Nuova subscription principale',
    pluginAndService: 'Add-on e servizi',
    newPluginServiceSubscription: 'Nuovo add-on o servizio',
    subscriptionTierSelectionTitle: 'Subscription principale',
    subscriptionTierSelectionDescription: 'Selezionando la subscription principale il nuovo add-on erediterà automaticamente le logiche di conteggio della subscription principale',
    selectTier: 'Seleziona subscription',
    createPluginSubscriptionConfirm: 'Sei sicuro di voler procedere alla creazione del nuovo add-on/servizio?',
    createWalletSubscriptionConfirm: 'Sei sicuro di voler associare la subscription al wallet selezionato? La subscription sarà attivata anche in tutti gli impianti gestiti tramite questo wallet',
    deleteWalletSubscriptionConfirm: 'Sei sicuro di voler rimuovere la subscription dal wallet selezionato? La subscription sarà rimossa anche da tutti gli impianti gestiti tramite questo wallet',
    editSubscriptionConfirm: 'Sei sicuro di voler modificare la subscription?',
    walletSelectSubscriptionTitle: 'Subscription da attivare',
    walletSelectSubscriptionDescription: 'Seleziona la subscription da attivare su questo wallet. Ricorda che la licensa sarà attivata anche in tutti gli impianti gestiti tramite questo wallet',
    createWalletPlantConfirm: 'Sei sicuro di voler creare il nuovo dominio per il wallet selezionato?',
    enableEmailForPlant: 'Abilita servizio email',
    enableEmailForPlantDescription: 'Abilitia l\'invio di email per questo impianto (email di attivazione, notifice, etc.)',
    enableIrisRequiredForPlant: 'Richiedi Servizio 1ris',
    enableIrisRequiredForPlantDescription: 'Se selezionato, il dominio sarà preparato per includere il Servizio 1ris',
    cardSubscriptionsLinked: 'Subscriptions associate',
    cardSubscriptionsLinkedDescription: 'Associa uno o più add-on o servizi a questa ricarica, saranno attivati una volta che la ricarica verrà effettuata',
    cardMarketplacesLinked: 'Marketplace associazione subscription',
    cardMarketplacesLinkedDescription: 'Seleziona un marketplace per selezionare le subscriptions da associare a questa ricarica',
    mainTierDescription: 'Associa una subscription principale a questa ricarica, sarà attivata una volta che la ricarica verrà effettuata',
    sku: 'SKU',
    customerData: 'Anagrafica cliente',
    customerVat: 'P.IVA',
    customerFiscalCode: 'Codice fiscale',
    customerCountry: 'Stato',
    customerAddress: 'Indirizzo',
    customerCity: 'Città',
    customerEmail: 'Email',
    customerPhone: 'Telefono',
    customerCrmId: 'SFDC ACCOUNT ID',
    customerMarketplace: 'Marketplace',
    customerCertifiedEmail: 'PEC',
    customerCluster: 'Cluster',
    customerVar: 'VAR',
    customerCompany: 'Consociata ISEO',
    customerPostalCode: 'CAP',
    customerPrincipalSubDivision: 'Provincia, Stato, Contea, ecc.',
    iseoSalesOrderReference: 'Ordine di vendita ISEO (ISEO -> Cliente)',
    iseoSalesOrderDate: 'Data ordine di vendita ISEO',
    iseoPurchaseOrderReference: 'Ordine di acquisto ISEO (ISEO -> Sofia)',
    iseoPurchaseOrderDate: 'Data ordine di acquisto ISEO',
    sofiaSalesOrderReference: 'Ordine di vendita Sofia (Sofia -> ISEO)',
    sofiaSalesOrderDate: 'Data conferma ordine di vendita Sofia',
    pickDate: 'Scegli data',
    crmSource: 'CRM sorgente opportunità',
    crmId: 'Identificativo univoco opportunità CRM',
    sofiaProjectId: 'Identificativo riferimento progetto Sofia',
    crmLink: 'Link a opportunità in CRM',
    kamReference: 'KAM di riferimento',
    edit: 'Modifica',
    editCardConfirm: 'Sei sicuro di voler modificare la ricarica?',
    editVarOpportunityConfirm: 'Sei sicuro di voler modificare questa opportunità?',
    insertEmailOTPLoginForm: 'Inserisci l\'email con la quale sei stato aggiunto in piattaforma.',
    insertEmailOTPLoginFormDisclaimer: 'Se l\'email è autorizzata riceverai il codice OTP per effettuare l\'accesso',
    insertOTPOTPLoginForm: 'Inserisci il codice OTP che hai ricevuto alla mail indicata.',
    insertOTPOTPLoginFormDisclaimer: 'Nel caso in cui non si riceva il codice dopo 5 minuti ripetere l\'operazione',
    otp: 'OTP',
    pickDateTo: 'Scegli data fine:',
    pickDateFrom: 'Scegli data inizio:',
    provisioningType: 'Tipologia di attivazione',
    AUTOMATIC: 'Attivazione automatica',
    MANUAL: 'Attivazione manuale',
    SEMI_AUTOMATIC: 'Attivazione semi-automatica',
    AUTOMATIC_Description: 'L\'estensione può essere attivata in autonomia dallo store. I crediti di attivazione verranno scalati immediatamente',
    MANUAL_Description: 'Estensione attivabile solo a valle di richiesta esplicita del cliente. I crediti saranno scalati manualmente a valle dell\'approvazione della richiesta',
    SEMI_AUTOMATIC_Description: 'Estensione attivabile solo a valle di richiesta esplicita del cliente. I crediti di attivazione saranno scalati al momento della richiesta',
    requestToQuote: 'Quotazione richiesta',
    requestToQuoteDescription: 'Indica se l\'estensione è soggetta a quotazione al posto dell\'utilizzo diretto dei crediti (es. integrazioni e customizzazioni)',
    subscriptionCanBeDeactivated: 'Disabilitabile da manager',
    subscriptionCanBeDeactivatedDescription: 'Indica se il manager può disabilitare la subscription dal Luckey Store',
    selectSegmentType: 'Seleziona segmento',
    sofiaInvoiceNumber: 'Numero fattura sofia',
    sofiaInvoiceAmount: 'Ammontare fattura sofia',
    sofiaInvoiceYear: 'Anno fattura sofia',
    iseoInvoiceNumber: 'Numero fattura ISEO',
    iseoInvoiceAmount: 'Ammontare fattura ISEO',
    iseoInvoiceYear: 'Anno fattura ISEO',
    freeOfCharge: 'Gratuita',
    freeOfChargeReason: 'Motivazione',
    currency: 'Valuta',
    sofiaInvoiceDate: 'Data fattura',
    iseoInvoiceDate: 'Data fattura',
    sofiaInvoiceRecipient: 'Destinatario fattura',
    iseoInvoiceRecipient: 'Destinatario fattura',
    serviceSupplementaryInvoice: 'Complemento di Servizio',
    serviceSupplementaryInvoiceNumber: 'Numero fattura',
    serviceSupplementaryInvoiceAmount: 'Ammontare fattura',
    serviceSupplementaryInvoiceDate: 'Data fattura',
    serviceSupplementaryInvoiceRecipient: 'Destinatario fattura',
    iseoInvoiceIssuer: 'Emittente fattura',
    backdropClosing: 'Sei sicuro di voler chiudere il form senza salvare?',
    roles: 'Ruoli',
  },
  activations: {
    getInfo: '(Visualizza la guida)',
    createNewCustomer: 'Crea customer',
    createNewWallet: 'Crea wallet',
    createNewScratchcard: 'Crea scratchcard',
    yearsString: 'anni',
    monthsString: 'mesi',
    daysString: 'giorni',
    addNewActivationButton: 'Aggiungi richiesta attivazione',
    home: 'OD Home',
    activationRequests: 'Richieste attivazione',
    rechargeRequests: 'Richieste ricarica',
    walletStatus: 'Stato ordini',
    noActivationsFoundTitle: 'Nessuna richiesta trovata',
    noActivationsFoundDescription: 'Non ci sono richieste nel sistema. Creane di nuove per iniziare.',
    selloutOrder: 'Ordine Sell-out',
    intragroupOrder: 'Ordine Intra-group',
    custref: 'Cust. Ref.',
    cluster: 'Cluster',
    rejectedReason: 'Motivo di rifiuto',
    changeStatusToUnderReview: 'Manda in revisione',
    changeStatusToInvoiced: 'Contrassegna fatturata',
    changeStatusToCreated: 'Contrassegna creata',
    changeStatusToRejected: 'Rifiuta richiesta',
    changeStatusToProcessing: 'Accetta richiesta',
    changeStatusAdmin: 'Imposta stato (admin)',
    changeCustRef: 'Imposta Cust. Ref.',
    search: 'Cerca...',
    resetFilters: 'Resetta filtri',
    statusSelection: 'Qualsiasi stato',
    clusterSelection: 'Qualsiasi cluster',
    statusDRAFT: 'Bozza',
    statusUNDER_REVIEW: 'In revisione',
    statusREJECTED: 'Rifiutata',
    statusPROCESSING: 'In elaborazione',
    statusCREATED: 'Creata',
    statusINVOICED: 'Fatturata',
    statusACTIVE: 'Attiva',
    statusDELETED: 'Eliminata',
    saveDraft: 'Salva come bozza',
    customMarketplaceWarning: 'Il cliente non è associato al marketplace predefinito. Dovrai specificare manualmente la baseline subscription ed eventuali addons nella sezione \'Note\'',
    subscriptionNotFoundError: 'Subscription non trovata',
    deleteActivationConfirm: 'Sei sicuro di voler eliminare questa richiesta? L\'operazione non può essere annullata',
    deleteActivationError: 'Si è verificato un errore nella cancellazione della richiesta. Per favore riprova',
    promoteActivationConfirm: 'Sei sicuro di voler mandare la richiesta in revisione? Non sarà più possibile modificare la richiesta',
    promoteActivationError: 'Si è verificato un errore nella promozione della richiesta. Per favore riprova',
    createdActivationConfirm: 'Sei sicuro di voler mandare la richiesta in stato CREATA?',
    createdActivationError: 'Si è verificato un errore nel cambio stato della richiesta. Per favore riprova',
    invoicedActivationConfirm: 'Sei sicuro di voler segnare la richiesta come fatturata?',
    invoicedActivationError: 'Si è verificato un errore nel cambio stato della richiesta. Per favore riprova',
    editStatusActivationConfirm: 'Sei sicuro di voler modificare lo stato di questa richiesta? L\'operazione non può essere annullata',
    editStatusActivationError: 'Si è verificato un errore nella modifica stato della richiesta. Per favore riprova',
    rejectActivationConfirm: 'Specifica le motivazioni per il rifiuto di questa richiesta.',
    rejectActivationError: 'Si è verificato un errore nel cambio stato della richiesta. Per favore riprova',
    processingActivationConfirm: 'Sei sicuro di voler accettare la richiesta?',
    processingActivationError: 'Si è verificato un errore nel cambio stato della richiesta. Per favore riprova',
    newFileName: 'Specifica il nome del file',
    newFileExtensionError: 'Il file selezionato ha un\'estensione non supportata',
    newFileDuplicateNameError: 'Questo nome è già stato usato per un altro file. Cambia il nome e ripeti l\'operazione.',
    editCustrefConfirm: 'Specifica il Cust. Ref. di questa richiesta.',
    editCustrefError: 'Si è verificato un errore nell\'aggiornamento del Cust. Ref., per favore riprova',
    editActivation: 'Modifica richiesta',
    newActivation: 'Aggiungi richiesta',
    viewActivation: 'Visualizza richiesta',
    newActivationError: 'Si è verificato un errore nella creazione della richiesta. Per favore riprova',
    editActivationError: 'Si è verificato un errore nel salvataggio della richiesta. Per favore riprova',
    noSubscriptionFoundDescription: 'La lista di subscription è vuota. Se hai impostato dei filtri di ricerca prova a resettarli',
    customerSearch: 'Cerca tra i clienti esistenti',
    customerSearchEnterName: 'Cerca per nome...',
    customerSearchNoMatch: 'Nessun cliente trovato...',
    customerProceedWithSelected: 'Usa cliente selezionato',
    customerProceedWithNew: 'Crea nuovo cliente',
    customerConfirmData: 'Conferma dati cliente',
    customerData: 'Anagrafica cliente',
    varSearch: 'Cerca tra i VAR esistenti',
    varSearchEnterName: 'Cerca per nome...',
    varSearchNoMatch: 'Nessun VAR trovato...',
    varProceedWithSelected: 'Usa VAR selezionato',
    varData: 'Anagrafica VAR',
    selectCustomerOrVar: 'Seleziona il destinatario della richiesta (cliente o VAR)',
    varSfdcId: 'SFDC ACCOUNT ID',
    varName: 'Nome',
    customerFormTitle: 'Cliente Luckey',
    customerName: 'Nome cliente',
    customerVat: 'P.IVA',
    customerFiscalCode: 'Codice fiscale',
    customerCountry: 'Stato',
    customerAddress: 'Indirizzo',
    customerCity: 'Città',
    customerEmail: 'Email',
    customerPhone: 'Telefono',
    customerCrmId: 'SFDC ACCOUNT ID',
    customerMarketplace: 'Marketplace',
    customerCertifiedEmail: 'PEC',
    customerCluster: 'Cluster',
    customerCompany: 'Consociata ISEO',
    customerPostalCode: 'CAP',
    customerPrincipalSubDivision: 'Provincia, Stato, Contea, ecc.',
    exportActivationsCSV: 'Esporta CSV',
    salesAndOrdersData: 'Vendita e ordini',
    basicData: 'Informazioni impianto',
    formEnterAnswer: 'La tua risposta',
    formEnterAnswerGT0: 'Numero maggiore di zero',
    formEnterAnswerFrom0To600: 'Numero compreso tra 0 e 600',
    formEnterAnswerOwnerEmail: 'Email owner',
    formEnterAnswerKamEmail: 'Email Key Account Manager',
    formEnterAnswerPmEmail: 'Email PM',
    formEnterAnswerSystemEngineerEmail: 'Email System Engineer',
    optId: 'Opportunity ID (SFDC)',
    salesOrderConfirmationNumber: 'Sales Order Confirmation Number (sell-out)',
    salesOrderConfirmationNumber_desc: '',
    selloutFiles: 'Sell-out order PDF (hardware + software + professional services) *',
    selloutFiles_desc: 'Necessario per tracciare correttamente le revenues Blossom.',
    selloutFiles_desc2: 'Limite numero files: 5  ~  Limite dimensione singolo file: 10MB  ~  Estensioni file ammesse: ',
    selloutFilesAdd: 'Carica file',
    selloutFilesDownload: 'Download',
    selloutFilesReplace: 'Sostituisci',
    quotationToolFile: 'Quotation tool (facoltativo)',
    quotationToolFile_desc: 'Carica il file quotation tool usato per questo preventivo.',
    quotationToolFile_desc2: 'Limite numero files: 1  ~  Limite dimensione singolo file: 10MB',
    purchaseOrderNumberSofia: 'Purchase Order Number to Sofia',
    baselineSubscription: 'Baseline Luckey Subscription',
    baselineSubscription_BASIC: 'Luckey Basic',
    baselineSubscription_ENTERPRISE: 'Luckey Enterprise (Standard)',
    baselineSubscription_ENGINE: 'Luckey Engine',
    baselineSubscription_undefined: 'Usa subscription custom',
    subscriptionData: 'Subscription info',
    noSubscriptionFound: 'Nessuna subscription trovata.',
    noSubscriptionFoundPleaseCheck: ' Controlla i campi ',
    selectedSubscription: 'Subscription selezionata: ',
    plantName: 'System Name',
    plantName_desc: 'Minuscolo, senza spazi e, se necessario, separato da trattini - es: example-plant-name.',
    keyAccountManager: 'Email Key Account Manager',
    keyAccountManager_desc: 'Indirizzo email del Key Account Manager',
    systemManagerEmails: 'Email System Administrator (una o più di una) *',
    systemManagerEmails_desc: 'Indirizzo email della persona responsabile del controllo accessi per gli utenti sulla piattaforma web di gestione',
    installerEmail: 'Email installatore (facoltativo)',
    installerEmail_desc: '',
    pmEmail: 'Email PM (facoltativo)',
    pmEmail_desc: 'Deve appartenere a @sofialocks.com o @iseo.com',
    systemEngineerEmail: 'Email System Engineer (facoltativo)',
    systemEngineerEmail_desc: 'Deve appartenere a @sofialocks.com o @iseo.com',
    numberOfElements: 'Numero di elementi da gestire',
    numberOfElements_desc: 'Gli elementi sono la somma di: utenti, serrature ed inviti attivi',
    V364orF9000: 'Serrature V364/1RIS o cilindri F9000?',
    V364orF9000_desc: 'Sono presenti serrature V364/1RIS o cilindri F9000 nel sistema? In tal caso, verrà aggiunto automaticamente l\'addon relativo.',
    addons: 'Add-ons & Plug-ins',
    addons_desc: 'Seleziona gli add-ons da aggiungere alla baseline subscription',
    credits: 'Numero di crediti',
    credits_desc: 'Crediti venduti con questa attivazione',
    expectedEnd: 'Secondo le informazioni immesse, i crediti esauriranno in: ',
    finalData: 'Altre informazioni',
    notes: 'Note',
    notes_desc: 'Scrivi qui eventuali note aggiuntive riguardanti la richiesta di attivazione',
    notifications: 'Notifiche',
    notifications_desc: 'Persone da notificare per eventi riguardanti la richiesta; di default, il proprietario della richiesta ed il Key Account Manager saranno notificati',
    editBaselineSubscriptionWarning: 'Sei sicuro di voler cambiare la baseline subscription? Gli add-ons attualmente selezionati saranno resettati.',
    editBaselineSubscriptionError: 'Errore interno, per favore ripeti l\'operazione.',
    editNumberOfElementsWarning: 'Sei sicuro di voler cambiare il numero di elementi? Gli add-ons disponibili saranno resettati.',
    editNumberOfElementsError: 'Errore interno, per favore ripeti l\'operazione.',
    customerMarketplaceError: 'Il cliente selezionato non è associato ad un marketplace. Per favore, contattare l\'assistenza per risolvere il problema',
    customerClusterError: 'Il cliente selezionato non è associato ad un cluster. Per favore, contattare l\'assistenza per risolvere il problema',
    invoiceData: 'Fatturazione',
    invoiceFile: 'PDF fattura *',
    invoiceFile_desc: 'Carica il PDF della fattura.',
    invoiceFile_desc2: 'Limite numero files: 1  ~  Limite dimensione singolo file: 10MB',
    invoiceNumber: 'Numero fattura di sell-out (al cliente) *',
    setInvoiceData: 'Carica fattura',
    dataMissingCreated: 'Specifica numero e PDF fattura per procedere',
  },
  recharges: {
    addNewRechargeButton: 'Aggiungi richiesta ricarica',
    noRechargesFoundTitle: 'Nessuna richiesta trovata',
    noRechargesFoundDescription: 'Non ci sono richieste nel sistema. Creane di nuove per iniziare.',
    customerAndWallet: 'Cliente e wallet',
    seeCustomer: 'Info',
    seeCustomerDetails: 'Vedi dettagli cliente',
    selloutOrder: 'Ordine Sell-out',
    intragroupOrder: 'Ordine Intra-group',
    custref: 'Cust. Ref.',
    mode:'Tipologia rinnovo contrattuale:',
    creditsSuggested:'Crediti suggeriti',
    creditsConfirmed:'Crediti confermati',
    rejectedReason: 'Motivo di rifiuto',
    deletedReason: 'Motivo di eliminazione',
    createdAt: 'Data creazione',
    updatedAt: 'Data ultima modifica',
    customerSearch: 'Cerca tra i clienti esistenti',
    customerSearchEnterName: 'Cerca per nome...',
    customerSearchNoMatch: 'Nessun cliente trovato...',
    varCustomerSearch: 'Cerca tra i clienti del VAR',
    varSearch: 'Cerca tra i VAR esistenti',
    varSearchEnterName: 'Cerca per nome...',
    varSearchNoMatch: 'Nessun VAR trovato...',
    plantSearch: 'Seleziona il plant di riferimento',
    plantSearchEnterName: 'Seleziona plant',
    plantSearchNoMatch: 'Nessun plant trovato...',
    plantProceedWithSelected: 'Usa plant/wallet selezionato',
    dataMissingTakenCharge: 'Specifica crediti confermati e ordine sell-out per procedere',
    dataMissingScratchcardCreated: 'Specifica numero e PDF fattura per procedere',
    changeStatusToSelloutOrderGenerated: 'Manda in revisione',
    changeStatusToInvoiced: 'Contrassegna fatturata',
    changeStatusToCreated: 'Contrassegna creata',
    changeStatusToRejected: 'Rifiuta richiesta',
    changeStatusToScratchcardCreated: 'Scratchcard creata',
    changeStatusToWalletRecharged: 'Wallet ricaricato',
    changeStatusAdmin: 'Imposta stato (admin)',
    createCard: 'Crea scratchcard',
    changeCustRef: 'Imposta Cust. Ref.',
    search: 'Cerca...',
    resetFilters: 'Resetta filtri',
    statusSelection: 'Qualsiasi stato',
    clusterSelection: 'Qualsiasi cluster',
    statusTO_BE_MANAGED: 'Da gestire',
    statusTAKEN_CHARGE: 'Presa in carico',
    statusREQUEST_TO_DELETE: 'Richiesta eliminazione',
    statusSELLOUT_ORDER_GENERATED: 'In revisione',
    statusSCRATCHCARD_CREATED: 'Scratchcard generata',
    statusWALLET_RECHARGED: 'Ricaricato',
    statusREJECTED: 'Rifiutato',
    statusDELETED: 'Eliminata',
    statusINVOICED: 'Fatturata',
    saveRequest: 'Salva richiesta',
    createRequest: 'Crea richiesta ricarica',
    takeCharge: 'Prendi in carico',
    AUTO_RENEWABLE: 'Rinnovabile automaticamente',
    NOT_AUTO_RENEWABLE: 'NON rinnovabile automaticamente',
    deleteRechargeConfirm: 'Sei sicuro di voler eliminare questa richiesta? Per favore, specifica il motivo dell\'eliminazione. L\'operazione non può essere annullata',
    deleteAdminRechargeConfirm: 'Sei sicuro di voler eliminare definitivamente questa richiesta? L\'operazione non può essere annullata',
    deleteRechargeError: 'Si è verificato un errore nella cancellazione della richiesta. Per favore riprova',
    takechargeRechargeConfirm: 'Specifica il tipo di rinnovo per procedere con la richiesta',
    takechargeRechargeDirectConfirm: 'Sei sicuro di voler prendere in carico questa richiesta?',
    takechargeRechargeError: 'Si è verificato un errore nella presa in carico della richiesta. Per favore riprova',
    promoteRechargeConfirm: 'Sei sicuro di voler mandare la richiesta in revisione? Non sarà più possibile modificare la richiesta',
    promoteRechargeError: 'Si è verificato un errore nella promozione della richiesta. Per favore riprova',
    createdRechargeConfirm: 'Sei sicuro di voler mandare la richiesta in stato CREATA?',
    createdRechargeError: 'Si è verificato un errore nel cambio stato della richiesta. Per favore riprova',
    invoicedRechargeConfirm: 'Sei sicuro di voler segnare la richiesta come fatturata?',
    invoicedRechargeError: 'Si è verificato un errore nel cambio stato della richiesta. Per favore riprova',
    editStatusRechargeConfirm: 'Sei sicuro di voler modificare lo stato di questa richiesta? L\'operazione non può essere annullata',
    editStatusRechargeError: 'Si è verificato un errore nella modifica stato della richiesta. Per favore riprova',
    rejectRechargeConfirm: 'Specifica le motivazioni per il rifiuto di questa richiesta.',
    rejectRechargeError: 'Si è verificato un errore nel cambio stato della richiesta. Per favore riprova',
    scratchcardCreatedRechargeConfirm: 'Sei sicuro di voler accettare la richiesta e confermare di aver creato la scratchcard?',
    scratchcardCreatedRechargeError: 'Si è verificato un errore nel cambio stato della richiesta. Per favore riprova',
    walletRechargedRechargeConfirm: 'Sei sicuro di voler completare la richiesta e confermare di aver ricaricato il wallet?',
    walletRechargedRechargeError: 'Si è verificato un errore nel cambio stato della richiesta. Per favore riprova',
    newFileName: 'Specifica il nome del file',
    newFileExtensionError: 'Il file selezionato ha un\'estensione non supportata',
    newFileDuplicateNameError: 'Questo nome è già stato usato per un altro file. Cambia il nome e ripeti l\'operazione.',
    editCustrefConfirm: 'Specifica il Cust. Ref. di questa richiesta.',
    editCustrefError: 'Si è verificato un errore nell\'aggiornamento del Cust. Ref., per favore riprova',
    editRecharge: 'Modifica richiesta ricarica',
    newRecharge: 'Aggiungi richiesta ricarica',
    viewRecharge: 'Visualizza richiesta ricarica',
    newRechargeError: 'Si è verificato un errore nella creazione della richiesta. Per favore riprova',
    editRechargeError: 'Si è verificato un errore nel salvataggio della richiesta. Per favore riprova',
    exportRechargesCSV: 'Esporta CSV',
    basicData: 'Parametri di ricarica wallet',
    ordersData: 'Ordini',
    invoiceData: 'Fatturazione',
    formEnterAnswer: 'La tua risposta',
    formEnterAnswerGT0: 'Numero maggiore di zero',
    sfdcId: 'Opportunity ID (SFDC)',
    salesOrderConfirmationNumber: 'Sales Order Confirmation Number (sell-out)',
    salesOrderConfirmationNumber_desc: '',
    selloutFiles: 'Sell-out order PDF (hardware + software + professional services)',
    selloutFiles_desc: 'Necessario per tracciare correttamente le revenues Blossom.',
    selloutFiles_desc2: 'Limite numero files: 5  ~  Limite dimensione singolo file: 10MB  ~  Estensioni file ammesse: ',
    selloutFilesAdd: 'Carica file',
    selloutFilesDownload: 'Download',
    selloutFilesReplace: 'Sostituisci',
    invoiceFile: 'PDF fattura *',
    invoiceFile_desc: 'Carica il PDF della fattura.',
    invoiceFile_desc2: 'Limite numero files: 1  ~  Limite dimensione singolo file: 10MB',
    invoiceNumber: 'Numero fattura di sell-out (al cliente) *',
    purchaseOrderNumberSofia: 'Purchase Order Number to Sofia',
    orderContactEmail: 'Email di contatto relativa all\'ordine',
  },
  vars: {
    customers: 'Clienti',
    varsManagement: 'VARs',
    varSettings: 'Impostazioni',
    varInfoPage: 'Training & Risorse',
    varUsersPage: 'Utenti',
    vars: 'VAR',
    plantThemes: 'Temi personalizzati',
    editVarPersonalConfirm: 'Stai modificando i dati aziendali, sei sicuro di voler procedere?',
    editVarConfirm: 'Stai modificando l\'anagrafica VAR, sei sicuro di voler procedere?',
    editVar: 'Modifica VAR',
    newVar: 'Nuovo VAR',
    varCreationConfirm: 'Sei sicuro di voler creare il nuovo VAR?',
    createVar: 'Crea VAR',
    noVarFindTitle: 'Nessun VAR trovato',
    noVarFindDescription: 'La lista dei VAR è vuota. Se hai impostato un filtro di ricerca prova a modificarlo e riprovare',
    noPlantThemeFindTitle: 'Nessun tema personalizzato trovato',
    noPlantThemeFindDescription: 'La lista dei temi personalizzati è vuota. Se hai impostato un filtro di ricerca prova a modificarlo e riprovare',
    clusterSelection: 'Qualsiasi cluster',
    varSelection: 'Qualsiasi VAR',
    varName: 'Nome',
    varAddress: 'Indirizzo',
    varEmail: 'Email contatto principale',
    varPhone: 'Telefono',
    varCluster: 'Cluster',
    varVat: 'P. IVA',
    varNameTech: 'Nome riferimento tecnico',
    varEmailTech: 'Email riferimento tecnico',
    varPhoneTech: 'Telefono riferimento tecnico',
    varNameComm: 'Nome riferimento commerciale',
    varEmailComm: 'Email riferimento commerciale',
    varPhoneComm: 'Telefono riferimento commerciale',
    varTechContact: 'Riferimento tecnico',
    varCommercialContact: 'Riferimento commerciale',
    varSfdcid: 'SFDC ACCOUNT ID',
    varSupportEmail: 'Support: email',
    varSupportHours: 'Support: orario di apertura',
    varCompanyName: 'Nome azienda',
    varCustomers: 'Clienti del VAR',
    varUsers: 'Utenti del VAR',
    varOpportunities: 'Opportunità del VAR',
    editPlantThemeConfirm: 'Stai modificando i dati del tema personalizzato, sei sicuro di voler procedere?',
    editPlantTheme: 'Modifica tema personalizzato',
    newPlantTheme: 'Nuovo tema personalizzato',
    plantThemeCreationConfirm: 'Sei sicuro di voler creare questo nuovo tema personalizzato?',
    editVarPersonal: 'Modifica informazioni aziendali',
    plantThemeName: 'Nome',
    plantThemeColour: 'Colore *',
    plantThemeColourDescription: 'Colore tema',
    plantThemeLogo: 'Logo',
    plantThemeDefault: 'Usa come predefinito',
    logoFile: 'Immagine logo *',
    logoFile_desc: 'Estensioni ammesse: ',
    logoFileAdd: 'Carica file',
    logoFileDownload: 'Download',
    logoFileReplace: 'Sostituisci',
    deletePlantThemeConfirm: 'Sei sicuro di voler eliminare questo tema personalizzato? L\'operazione non può essere annullata',
    plantThemeSearchEnterName: 'Seleziona tema',
    plantThemeSearch: 'Seleziona il tema da impostare',
    domainThemeTitle: 'Tema personalizzato',
    domainThemeDescription: 'Imposta il tuo tema personalizzato su questo dominio',
    plantThemeModifySuccess: 'Configurazioni tema personalizzato modificate e aggiornate',
    cannotDeleteAssociatedTheme: 'Impossibile eliminare un tema personalizzato che è attualmente associato ad un impianto.',
    plantThemeAssociatedPlants: 'Impianti associati a questo tema',
    newVarUser: 'Nuovo utente VAR',
    varUserCreationConfirm: 'Vuoi creare il nuovo utente VAR?',
    createVarUser: 'Crea utente VAR',
    varUserFormTitle: 'Utente VAR',
    createNewVarUser: 'Crea nuovo utente VAR',
    noVarUserFindTitle: 'Nessun utente VAR',
    noVarUserFindDescription: 'La lista degli utenti VAR è vuota',
    deleteVarUserConfirm: 'Sei sicuro di voler eliminare questo utente VAR? L\'operazione non può essere annullata',
    deleteVarOpportunityConfirm: 'Sei sicuro di voler eliminare questa opportunità? L\'operazione non può essere annullata',
    rejectVarOpportunityConfirm: 'Sei sicuro di voler rifiutare questa opportunità? L\'operazione non può essere annullata',
    approveVarOpportunityConfirm: 'Sei sicuro di voler approvare questa opportunità? L\'operazione non può essere annullata',
    upgradeVarOpportunityConfirm: 'Sei sicuro di voler mandare questa opportunità in revisione? L\'operazione non può essere annullata',
    winVarOpportunityConfirm: 'Sei sicuro di voler impostare questa opportunità come VINTA? L\'operazione non può essere annullata',
    lostVarOpportunityConfirm: 'Sei sicuro di voler impostare questa opportunità come PERSA? L\'operazione non può essere annullata',
    deleteUserConfirm: 'Sei sicuro di voler eliminare questo utente? L\'operazione non può essere annullata',
    varUserUsername: 'Username',
    varUserFirstname: 'Nome',
    varUserLastname: 'Cognome',
    varUserEmail: 'Email',
    varUsersList: 'Utenti',
    newFileExtensionError: 'Il file selezionato ha un\'estensione non supportata',
    agreementFile: 'Accordo',
    opportunities: 'Opportunità',
    opportunityManagement: 'Dati opportunità',
    opportunityID: 'ID',
    opportunityOpty: 'SFDC OPTY ID',
    opportunityVar: 'VAR',
    opportunityCustomer: 'Cliente',
    opportunityName: 'Nome',
    opportunityValue: 'Valore',
    opportunityStatus: 'Stato',
    opportunityActions: 'Azioni',
    opportunityDescription: 'Descrizione',
    opportunityNotes: 'Note',
    opportunityCrm: 'CRM',
    noOpportunityFindTitle: 'Nessuna opportunità',
    noOpportunityFindDescription: 'La lista delle opportunità è vuota',
    opportunityStatus_DRAFT: 'Bozza',
    opportunityStatus_ON_APPROVAL: 'In approvazione',
    opportunityStatus_APPROVED: 'Approvata',
    opportunityStatus_CANCELLED: 'Cancellata',
    opportunityStatus_REJECTED: 'Rifiutata',
    opportunityStatus_WON: 'Vinta',
    opportunityStatus_LOST: 'Persa',
    opportunityFiles: 'Allegati',
    newOpportunity: 'Nuova opportunità',
    editOpportunity: 'Modifica opportunità',
    opportunitySyncSFDC: 'Sync with SFDC',
    exportOpportunities: 'Esporta CSV',
    upgradeOpportunity: 'Manda in revisione',
    approveOpportunity: 'Approva',
    rejectOpportunity: 'Rifiuta',
    wonOpportunity: 'Imposta come VINTA',
    lostOpportunity: 'Imposta come PERSA',
  },
  language: "Lingua",
  languages: {
    it: "Italiano",
    en: "Inglese",
    ITALIAN: "Italiano",
    ENGLISH: "Inglese",
    menuHeading: "Scegli una lingua",
  },
  productInfo: {
    software: 'Software',
    salesMaterial: 'Sales Material',
    hardware: 'Hardware',
  },
  users: {
    usersManagement: 'Gestione utenti',
    users: 'Utenti',
    noUserFindTitle: 'Nessun utente trovato',
    noUserFindDescription: 'La lista utenti è vuota. Prova a modificare i filtri di ricerca se impostati',
    search: 'Cerca...',
    roleSelection: 'Role',
    clusterSelection: 'Cluster',
    varSelection: 'VAR',
    newUser: 'Crea',
    userFirstname: 'Nome',
    userLastname: 'Cognome',
    userEmail: 'Email',
    userFormTitle: 'Dati utente',
    editUserForm: 'Modifica utente',
    newUserForm: 'Crea utente',
  },
  permissions: {
    NAVIGATION: 'Visibilità menu',
    CLUSTER: 'Cluster',
    VAR: 'VAR',
    CUSTOMER: 'Clienti',
    OPPORTUNITY: 'Opportunità',
    WALLET: 'Wallet dei clienti',
    DOMAIN: 'Domini dei clienti',
    GATEWAYS: 'Gateway',
    CARD: 'Ricariche',
    PLANT_ACTIVATION: 'Attivazioni sistemi',
    RECHARGE_REQUEST: 'Richieste di ricarica',
    ACTIVATION_REQUEST: 'Richieste di attivazione',
    EXPORT: 'Esportare le entità',
    CREATE: 'Creare nuova entità',
    UPDATE: 'Aggiornare entità',
    MARK_LOST: 'Marcare entità come Persa',
    MARK_WON: 'Marcare entità come Vinta',
    CANCEL: 'Marcare entità come Cancellata',
    MARK_TO_BE_APPROVED: 'Marcare entità come Approvata',
    RECHARGE: 'Ricaricare',
    SUBSCRIPTION_UPDATE: 'Aggiornare subscriptions',
    DELETE_REQUEST: 'Inviare richiesta di cancellazione',
    CREATION_REQUEST: 'Inviare richiesta di creazione',
    MANAGER_CREATE: 'Creare amministratori',
    MANAGER_UPDATE: 'Aggiornare amministratori',
    INSTALLER_CREATE: 'Creare installatori',
    INSTALLER_UPDATE: 'Aggiornare installatori',
    IMPERSONATE_WRITE: 'Impersonare amministratori',
    ADDITIONAL_INFO_UPDATE: 'Modificare informazioni aggiuntive',
    CUSTOMERS: 'Clienti',
    PLANT_ACTIVATIONS: 'Attivazioni sistemi',
    RECHARGE_REQUESTS: 'Richieste di ricarica',
    ACTIVATION_REQUESTS: 'Richieste di attivazioni',
    CARDS: 'Ricariche',
    VAR_USERS: 'Utenti VAR',
    VAR_SETTINGS: 'Impostazioni VAR',
    OPPORTUNITIES: 'Opportunità',
    PRODUCTINFO: 'Informazioni prodotto',
    USER_READ: 'Vedere utenti',
    USER_UPDATE: 'Aggiornare utenti',
    USER_CREATE: 'Creare utenti',
    PLANT_THEME: 'Cambiare tema dominio',
    READ: 'Leggere valori entità',
    ORDERS_STATUS: 'Stato ordini',
    FILTER: 'Filtrare entità',
    VAR_MANAGEMENT: 'Gestione VAR',
    MARKETPLACES: 'Marketplaces',
    DOMAINS: 'Domini dei clienti',
    PRICELIST: 'Price list',
    SALESINFO: 'Sales info',
    PLATFORM_USERS: 'Gestione utenti',
    APPROVE: 'Marcare entità come Approvata',
    REJECT: 'Marcare entità come Rifiutata',
    EXTEND: 'Estendere entità',
    CONFIG: 'Leggere configurazioni',
    IRIS_ENABLE: 'Abilitare 1RIS per il dominio',
    MARK_TO_PROMOTE: 'Marcare entità come In revisione',
    MARK_TO_INVOCE: 'Marcare entità come Da Fatturare',
    DELETE: 'Cancellare entità',
    EDIT: 'Aggiornare entità',
    SUBSCRIPTION_DELETE: 'Rimuovere subscriptions',
  }
};

export default translations;