import { PaginatedDataDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import * as UserDecoder from '../../codec/userInDTODecoder';
import * as AuthApi from '../../repository/jago/authAPI';
import * as FreshDeskAPI from '../../repository/jago/freshDeskAPI';
import { IUserFormDTO } from '../../repository/jago/model/input/IUserFormDTO';
import { IUserInDTO } from '../../repository/jago/model/input/IUserInDTO';
import { IUserRoleInDTO } from '../../repository/jago/model/input/IUserRoleInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IUserOutDTO } from '../../repository/jago/model/output/IUserOutDTO';
import { IUserRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import * as OtpApi from '../../repository/jago/otpAPI';
import * as UsersAPI from '../../repository/jago/usersAPI';
import * as AuthService from '../../services/AuthService';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import PermissionsParserProvider from '../../services/PermissionService/PermissionsParserProvider';
import { LocalStorageRepository } from '../../services/StorageService/LocalStorageRepository';
import { IState } from '../store';
import { ActionsUnion, createAction, IThunkAction } from '../utils';
import { IUserDecodedJWT } from './../../repository/jago/model/input/IUserDecodedJWT';
import { Roles } from './../../services/PermissionService/PermissionConstants';
import UserActionTypesEnum from './model/UserActionTypesEnum';
import { IUsersInterface } from './user.reducers';



export const UserActions = {
  saveUserData: createAction<typeof UserActionTypesEnum.SAVE_USER_DATA, any>(UserActionTypesEnum.SAVE_USER_DATA),
  setUserAuthenticated: createAction<typeof UserActionTypesEnum.SET_USER_AUTHENTICATION, boolean>(UserActionTypesEnum.SET_USER_AUTHENTICATION),
  saveUsers: createAction<typeof UserActionTypesEnum.SAVE_USERS, PaginatedDataDTO<IUserInDTO>>(UserActionTypesEnum.SAVE_USERS),
  saveUsersRoles: createAction<typeof UserActionTypesEnum.SAVE_USERS_ROLES, PaginatedDataDTO<IUserRoleInDTO>>(UserActionTypesEnum.SAVE_USERS_ROLES),
  setSelectedUser: createAction<typeof UserActionTypesEnum.SET_SELECTED_USER, IUserInDTO | {}>(UserActionTypesEnum.SET_SELECTED_USER),
  resetUserData: createAction<typeof UserActionTypesEnum.RESET_USER_UDATA>(UserActionTypesEnum.RESET_USER_UDATA),
};

export function setUserInfoFromLocalStorage(): IThunkAction<IUsersInterface, IState> {
  return (dispatch, getState) => {
    const storage = new LocalStorageRepository();
    const { user } = getState();
    if (_.isEmpty(user.userData)) {
      const token = storage.readKey<string>('token', '');
      if (token) {
        const decodedJWT= jwt.decode(token) as IUserDecodedJWT;
        // TODO REMOVE
        let userRole = Roles.ROLE_UNDEFINED;
        if (decodedJWT) {
          if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_SOFIA_ADMIN)) {
            userRole = Roles.ROLE_VASHO_SOFIA_ADMIN;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_GLOBAL_SEGMENT_LEADER)) {
            userRole = Roles.ROLE_VASHO_GLOBAL_SEGMENT_LEADER;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_GLOBAL_SALES_OPERATION_MANAGER)) {
            userRole = Roles.ROLE_VASHO_GLOBAL_SALES_OPERATION_MANAGER;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_SALES_OPERATION_MANAGER)) {
            userRole = Roles.ROLE_VASHO_SALES_OPERATION_MANAGER;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_MARKET_LEADER)) {
            userRole = Roles.ROLE_VASHO_MARKET_LEADER;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_EUSM)) {
            userRole = Roles.ROLE_VASHO_EUSM;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_KAM)) {
            userRole = Roles.ROLE_VASHO_KAM;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_GLOBAL_SOC_MANAGER)) {
            userRole = Roles.ROLE_VASHO_GLOBAL_SOC_MANAGER;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_LOCAL_SOC_MANAGER)) {
            userRole = Roles.ROLE_VASHO_LOCAL_SOC_MANAGER;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_VAR_ADMIN)) {
            userRole = Roles.ROLE_VASHO_VAR_ADMIN;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_VAR_SALES_MANAGER)) {
            userRole = Roles.ROLE_VASHO_VAR_SALES_MANAGER;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_VAR_SALES_REPRESENTATIVE)) {
            userRole = Roles.ROLE_VASHO_VAR_SALES_REPRESENTATIVE;
          } else if (decodedJWT.authorities.some(e=>e===Roles.ROLE_VASHO_VAR_SUPPORT)) {
            userRole = Roles.ROLE_VASHO_VAR_SUPPORT;
          }
        }

        if (userRole === Roles.ROLE_UNDEFINED) {
          dispatch(userLogout());
          return;
        }

        AbilityProvider.getAbilityHelper().setUserRole(userRole);
        if (PermissionsParserProvider.getPermissionsParser().parse(userRole)) {
          AbilityProvider.getAbilityHelper().setUserPermissions(PermissionsParserProvider.getPermissionsParser().parse(userRole));
        }
        const rolePermissions = AbilityProvider.getAbilityHelper().userPermissions;
        const decodedToSave = {
          ..._.omit(decodedJWT, 'authorities'),
          authorities: [userRole],
          rolePermissions
        };
        dispatch(UserActions.saveUserData(decodedToSave || {}));
        storage.storeKey('isAuthenticated', true);
        dispatch(UserActions.setUserAuthenticated(true));
        AuthService.setRequestInterceptor(token);
        // SET INTERCEPTORS
      } else {
        storage.storeKey('isAuthenticated', false);
        dispatch(UserActions.setUserAuthenticated(false));
      }
    }
    return getState().user;
  }
}

export function userJagoLoginWithGoogle(googleToken: string): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const storage = new LocalStorageRepository();
      const authResponse = await AuthApi.jagoLoginWithGoogle({ token: googleToken });
      if (authResponse && authResponse.data && authResponse.data.token) {
        AuthService.setRequestInterceptor(authResponse.data.token);
        storage.storeKey('token', authResponse.data.token);
        storage.storeKey('isAuthenticated', true);
        dispatch(setUserInfoFromLocalStorage());
      }
    } catch (error) {
      throw error;
    }
  }
}

export function sendOTPEmail(email: string): IThunkAction<void, IState> {
  return async () => {
    try {
      await OtpApi.sendOTPViaEmail({ email });
    } catch (error) {
      throw error;
    }
  }
}

export function userJagoLoginWithOTP(code: string): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const storage = new LocalStorageRepository();
      // TODO: FIX ME
      const authResponse = await OtpApi.verifyOTP({ code });
      if (authResponse && authResponse.data && authResponse.data.token) {
        AuthService.setRequestInterceptor(authResponse.data.token);
        storage.storeKey('token', authResponse.data.token);
        storage.storeKey('isAuthenticated', true);
        dispatch(setUserInfoFromLocalStorage());
      }
    } catch (error) {
      throw error;
    }
  }
}


export function userLogout(): IThunkAction<void, IState> {
  return (dispatch, getState) => {
    const storage = new LocalStorageRepository();
      storage.storeKey<string>('token', '');
      storage.storeKey<string>('user', '');
      storage.storeKey('isAuthenticated', false);
      dispatch(UserActions.saveUserData({}));
      dispatch(UserActions.setUserAuthenticated(false));
      AuthService.ejectRequestInterceptor();
  }
}

export function openLearingTicket(ticketBody: string): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    const { userData } = getState().user;
    const { VAR: { data } } = getState().vars;
    const varData = data as IVarInDTO;
    try {
      const email = userData.user_name || 'no-email';
      const ticketDTO = {
        description: (`
        <div>
          <h4>Learning Request from VAR ${varData && !_.isEmpty(varData) ? varData.name : ''}. Requester ${email}</h4>
          "${ticketBody}"
        </div>
        `),
        email,
        subject: `VASHO - Learning Request`,
        group_id: 2043001794126,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  }
} 

export function openCloseDomainTicket(domainName: string): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    const { userData } = getState().user;
    const { VAR: { data } } = getState().vars;
    const varData = data as IVarInDTO;
    try {
      const email = userData.user_name || 'no-email';
      const ticketDTO = {
        description: (`
        <div>
          <h4>Close Domain Request from VAR ${varData && !_.isEmpty(varData) ? varData.name : ''}. Requester ${email}</h4>
          <h4>Domain: ${domainName}</h4>
        </div>
        `),
        email,
        subject: `VASHO - Close Domain Request`,
        group_id: 2043001823679,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  }
} 

export function openCloseCustomerTicket(customerId: number, customerName: string): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    const { userData } = getState().user;
    const { VAR: { data } } = getState().vars;
    const varData = data as IVarInDTO;
    try {
      const email = userData.user_name || 'no-email';
      const ticketDTO = {
        description: (`
        <div>
          <h4>Delete Customer Request from VAR ${varData && !_.isEmpty(varData) ? varData.name : ''}. Requester ${email}</h4>
          <h4>Customer ID: ${customerId}</h4>
          <h4>Customer name: ${customerName}</h4>
        </div>
        `),
        email,
        subject: `VASHO - Delete Customer Request`,
        group_id: 2043001823679,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  }
} 

export function openCloseWalletTicket(walletId: number, walletName: string): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    const { userData } = getState().user;
    const { VAR: { data } } = getState().vars;
    const varData = data as IVarInDTO;
    try {
      const email = userData.user_name || 'no-email';
      const ticketDTO = {
        description: (`
        <div>
          <h4>Close Wallet Request from VAR ${varData && !_.isEmpty(varData) ? varData.name : ''}. Requester ${email}</h4>
          <h4>Wallet ID: ${walletId}</h4>
          <h4>Wallet: ${walletName}</h4>
        </div>
        `),
        email,
        subject: `VASHO - Close Wallet Request`,
        group_id: 2043001823679,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  }
} 

export function fetchUsers(params: IUserRequestParamsDTO = { page: 0, pageSize: 1000 }): IThunkAction<Promise<PaginatedDataDTO<IUserInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await UsersAPI.fetchUsers(params);
      if (response && response.data) {
        const decodedData = UserDecoder.decode(response.data);
        dispatch(UserActions.saveUsers(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchUsersRoles(params: PaginationQueryParamsDTO = { page: 0, pageSize: 1000 }): IThunkAction<Promise<PaginatedDataDTO<IUserRoleInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await UsersAPI.fetchUsersRoles(params);
      if (response && response.data) {
        const decodedData = UserDecoder.decodeRoles(response.data);
        dispatch(UserActions.saveUsersRoles(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function setSelectedUser(user: IUserInDTO | {}): IThunkAction<void, IState> {
  return (dispatch ) => {
    dispatch(UserActions.setSelectedUser(user));
  }
}

export function resetUserData(): IThunkAction<void, IState> {
  return (dispatch ) => {
    dispatch(UserActions.resetUserData());
  }
}

export function deleteUser(userId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deleteResponse = await UsersAPI.deleteUser(userId);
      if (deleteResponse && deleteResponse.data) {
        return deleteResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function createNewUser(newUserData: IUserFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const newUser: IUserOutDTO = UserDecoder.encodeUserFromForm(newUserData);
      const createResponse = await UsersAPI.createNewUser(newUser);
      if (createResponse && createResponse.data) {
        return createResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editUser(userId: number, newUserData: IUserFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const newUser: IUserOutDTO ={
        ...UserDecoder.encodeUserFromForm(newUserData),
        email: undefined,
      };
      const updateResponse = await UsersAPI.editUser(userId, newUser);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export type UserActionsType = ActionsUnion<typeof UserActions>;
