import { PaginatedDataDTO } from "@bottega52/commons-pagination";
import { Button, Card } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import WalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import * as React from "react";
import { connect, ConnectedProps } from 'react-redux';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as UserActions from '../../redux/user/user.actions';
import { ICustomerInDTO } from "../../repository/jago/model/input/ICustomerInDTO";
import { IWalletDetailedDTO } from '../../repository/jago/model/input/IWalletDetailedDTO';
import { IWalletFormDTO } from "../../repository/jago/model/input/IWalletFormDTO";
import { IWalletInDTO } from "../../repository/jago/model/input/IWalletInDTO";
import { IWalletRequestParamsDTO } from "../../repository/jago/model/output/IWalletRequestParamsDTO";
import { IOrderDirectionEnum } from "../../repository/jago/model/output/RequestParamsDTOs";
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';
import WalletForm from "../Forms/WalletForm";
import { ModalTypes } from "../Modals/ModalTypes";
import Permission from "../Permission/Permission";
import ReduxLanguage from "../ReduxLanguage";
import WalletDetailsPage from "../Wallets/WalletDetailsPage";
import WalletsList from "../Wallets/WalletsList";
import { IWalletStatusEnum } from "../../repository/jago/model/input/IWalletStatusEnum";

const styles = createStyles({
  container: {
    display: 'flex',
    marginTop: 20,
    flexDirection: 'column',
  },
  cardContainer: {
    padding: 10,
    borderLeft: '3px solid #5AC0B1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
});

export interface ICustomerWalletSectionState {
}

type IReduxProps = ConnectedProps<typeof connector>;

export interface ICustomerWalletSectionProps extends WithStyles<typeof styles> {
  language: string;
  wallets: PaginatedDataDTO<IWalletInDTO>;
  customer: ICustomerInDTO;
}

export type ComponentProps = ICustomerWalletSectionProps & IReduxProps;

class CustomerWalletSection extends React.Component<ComponentProps, ICustomerWalletSectionState> {


  public onOpenWalletForm(wallet?: IWalletDetailedDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_WALLET_FORM_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
      modalProps: {
        content: (
          <WalletForm
            wallet={wallet}
            onCreateNewWallet={walletData => wallet ? this.editWalletRequest(wallet.id, walletData) : this.onCreateNewWalletRequest(walletData) }
          />
        ),
        titleMessageKey: 'forms.createNewWallet',
      }
    }));
  }

  public onCreateNewWalletRequest(walletData: IWalletFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_CUSTOMER_WALLET_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newWallet',
        successMessageKey: 'credits.walletCreationConfirm',
        confirmMessageKey: 'credits.createWallet',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewWalletConfirm(walletData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_CUSTOMER_WALLET_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onCreateNewWalletConfirm(walletData: IWalletFormDTO) {
    const { dispatch, customer } = this.props;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_CUSTOMER_WALLET_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.createNewCustomerWallet(customer.id, walletData));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_WALLET_FORM_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCustomerWalletError',
        }
      }));
    }
  }

  public editWalletRequest(walletId: number, walletData: IWalletFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_CUSTOMER_WALLET_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.editWallet',
        successMessageKey: 'credits.walletEditConfirm',
        confirmMessageKey: 'credits.editWallet',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.editWalletConfirm(walletId, walletData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_CUSTOMER_WALLET_CONFIRM_MODAL`)),
      }
    }));
  }

  async editWalletConfirm(walletId: number, walletData: IWalletFormDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(ModalsActions.hideModal(`EDIT_CUSTOMER_WALLET_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const walletUpdated = await dispatch<any>(CreditsActions.editCustomerWallet(walletId, walletData));
      dispatch<any>(CreditsActions.setSelecteWallet(walletUpdated));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_WALLET_FORM_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCustomerWalletError',
        }
      }));
    }
  }

  public onAppenWallets(page: number) {
    const { dispatch, customer } = this.props;
    const params: IWalletRequestParamsDTO = { customerId: customer.id, page };
    dispatch<any>(CreditsActions.appendCustomerWallets(params));
  }

  public onDeleteWalletRequest(wallet: IWalletDetailedDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_WALLET_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'domains.walletDeletionTitle',
        successMessageKey: 'domains.walletDeletionConfirm',
        confirmMessageKey: 'domains.sendDeletionRequest',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteWalletConfirm(wallet),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_WALLET_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onDeleteWalletConfirm(wallet: IWalletDetailedDTO) {
    const { dispatch } = this.props;
    try {
      const walletName = wallet.name
      const walletId = wallet.id
      dispatch<any>(ModalsActions.hideModal(`DELETE_WALLET_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.openCloseWalletTicket(walletId,walletName));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_DOMAIN_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'domains.walletDeletionSuccess',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_DELETION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteWalletRequestError',
        }
      }));
    }
  }

  editWallet(wallet: IWalletDetailedDTO) {
    this.onOpenWalletForm(wallet);
  }

  public async onShowWalletDetails(wallet: IWalletInDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      dispatch<any>(CreditsActions.setSelecteWallet(wallet));
      await dispatch<any>(CreditsActions.fetchCustomerWalletSubscriptions({ walletId: wallet.id, page: 0, pageSize: 200 }));
      await dispatch<any>(CreditsActions.fetchCustomerWalletCredits({ walletId: wallet.id, page: 0, orderDir: IOrderDirectionEnum.DESC, pageSize: 50 }));
      await dispatch<any>(CreditsActions.fetchCustomerWalletPlants({ walletId: wallet.id, page: 0, pageSize: 50 }));
      await dispatch<any>(CreditsActions.fetchCustomerWalletUsage({ walletId: wallet.id, page: 0, orderDir: IOrderDirectionEnum.DESC, pageSize: 50 }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_DOMAIN_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(CreditsActions.setSelecteWallet({})),
          overflowDisabled: true,
          content: (
            <WalletDetailsPage
              onEditWallet={(wallet) => this.editWallet(wallet)}
              onDeleteWalletRequest={(wallet) => this.onDeleteWalletRequest(wallet)}
            />
          ),
          titleMessageKey: 'credits.walletDetails',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getWalletDetailsError',
        }
      }));
    }
  }
  
  public render() {
    const { wallets, customer, classes } = this.props;

    return (
      <div className={classes.container}>
        <Card className={classes.cardContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <WalletIcon className={classes.icon} />
              <h3 className={classes.title}>{<ReduxLanguage languageKey="credits.customerWallets" languageProps={{ customerName: customer.name }} />}</h3>
            </div>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.WALLET_CREATE}>
              <Button
                variant="contained"
                style={{ fontWeight: 'bold', backgroundColor: '#5AC0B1', fontSize: 11, color: 'white' }}
                onClick={() => this.onOpenWalletForm()}
              >
                <ReduxLanguage languageKey="forms.createNewWallet" />
              </Button>
            </Permission>
          </div>
          <WalletsList
            wallets={wallets}
            onRowClick={wallet => this.onShowWalletDetails(wallet)}
            onFetchMoreItems={page => this.onAppenWallets(page)}
          />
        </Card>
      </div>
    );
  }
}


function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    wallets: state.credits.wallets.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(CustomerWalletSection as any));