import { PaginatedDataDTO } from "@bottega52/commons-pagination";
import { Card } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as VarOpportunityDecoder from '../../codec/varOpportunityInDTODecoder';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as VarsActions from '../../redux/vars/vars.actions';
import { IVarInDTO } from "../../repository/jago/model/input/IVarInDTO";
import { IVarOpportunityFormDTO } from "../../repository/jago/model/input/IVarOpportunityFormDTO";
import { IVarOpportunityInDTO } from "../../repository/jago/model/input/IVarOpportunityInDTO";
import { IVarOpportunityOutDTO } from "../../repository/jago/model/output/IVarOpportunityOutDTO";
import CustomerDetailsView from "../Customers/CustomerDetailsView";
import VarOpportunityForm from "../Forms/VarOpportunityForm";
import { ModalTypes } from "../Modals/ModalTypes";
import ReduxLanguage from "../ReduxLanguage";
import VarOpportunitiesTable from "./VarOpportunitiesTable";

const styles = createStyles({
  container: {
    display: 'flex',
    marginTop: 20,
    flexDirection: 'column',
  },
  cardContainer: {
    padding: 10,
    borderLeft: '3px solid #5AC0B1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 350px)',
    width: '100%',
    marginTop: 10
  }
});

export interface IVarOpportunitiesSectionState {
  pageSize: number;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface IVarOpportunitiesSectionProps extends WithStyles<typeof styles> {
  language: string;
  opportunities: PaginatedDataDTO<IVarOpportunityInDTO>;
  VAR: IVarInDTO;
}

export type ComponentProps = IVarOpportunitiesSectionProps & IReduxProps;

class VarOpportunitiesSection extends React.Component<ComponentProps, IVarOpportunitiesSectionState> {

  constructor(props: ComponentProps) {
    super(props);
    this.state  = {
      pageSize: 100
    };
  }

  public async onOpenCustomerInfo(customerId: number) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const customer = await dispatch<any>(CreditsActions.fetchCustomerDetails(customerId));
      dispatch<any>(CreditsActions.setSelecteCustomer(customer));
      await dispatch<any>(CreditsActions.fetchCustomerWallets({pageSize: 200, customerId: customerId }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CUSTOMER_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(CreditsActions.setSelecteCustomer({})),
          content: (
            <CustomerDetailsView
              onEditCustomerData={() => {}}
              hideEditCustomer={true}
              hideDeleteCustomer={true}
              onVarClick={varId => {
                dispatch<any>(ModalsActions.hideModal(`OPERATIONAL_CUSTOMER_MODAL`));
                this.onOpenVarDetails(varId)
              }}
            />
          ),
          titleMessageKey: 'credits.customerDetails',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getCustomerDetailsError',
        }
      }));
    }
  }

  public onOpenVarDetails(varId: number) {
    const { history } = this.props
    history.push(`/vars?varId=${varId}`);
  } 

  async onOpenOpportunityForm(opportunityToEdit: IVarOpportunityInDTO) {
    const { dispatch, VAR } = this.props;
    try {
      const opportunity = opportunityToEdit?VarOpportunityDecoder.decodeVarOpportunityContentToForm(opportunityToEdit):undefined
      const varId = opportunity.varId
      if (!varId)
        throw new Error()
      dispatch<any>(ModalsActions.showModal(`OPPORTUNITY_FORM_VIEW`, {
        modalType: opportunityToEdit?ModalTypes.OPERATIONAL_VIEW_MODAL:ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
        modalProps: {
          content: (
            <VarOpportunityForm
              opportunity={opportunity}
              varId={varId}
              onCreateEditVarOpportunity={opportunity => this.onEditOpportunityRequest(opportunityToEdit.id, opportunity)}
              onOpenCustomerInfo={(customerId: number) => this.onOpenCustomerInfo(customerId)}
            />
          ),
          titleMessageKey: opportunityToEdit ?  'vars.editOpportunity' : 'vars.newOpportunity',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }

  onEditOpportunityRequest(opportunityId: number, opportunity: IVarOpportunityFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.editOpportunity',
        successMessageKey: 'forms.editVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditOpportunityConfirm(opportunityId, opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onEditOpportunityConfirm(opportunityId: number, opportunity: IVarOpportunityFormDTO) {
    const { dispatch, VAR } = this.props;
    const { pageSize } = this.state;
    try {
      const opportunityOut: IVarOpportunityOutDTO = VarOpportunityDecoder.encodeVarOpportunityFromForm(opportunity,false)
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.editVarOpportunityAdmin(opportunity.customerId, opportunityId, opportunityOut));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('OPPORTUNITY_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('EDIT_OPPORTUNITY_CONFIRM_MODAL'));
      dispatch<any>(VarsActions.fetchOpportunities({ page:0, pageSize, varId:VAR.id.toString() }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_EDIT`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.editVarOpportunityError',
        }
      }));
    }
  }

  onDeleteVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.deleteVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, VAR } = this.props;
    const { pageSize } = this.state;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.deleteVarOpportunity(opportunity.customerId, opportunity.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page:0, pageSize, varId:VAR.id.toString() }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteVarOpportunityError',
        }
      }));
    }
  }

  onRejectVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`REJECT_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.rejectVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onRejectVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`REJECT_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onRejectVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, VAR } = this.props;
    const { pageSize } = this.state;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      // TODO
      //await dispatch<any>(VarsActions.deleteVarOpportunity(opportunity.customerId, opportunity.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('REJECT_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page:0, pageSize, varId:VAR.id.toString() }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_REJECT`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.rejectVarOpportunityError',
        }
      }));
    }
  }

  onApproveVarOpportunityRequest(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`APPROVE_OPPORTUNITY_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.approveVarOpportunityConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onApproveVarOpportunityConfirm(opportunity),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`APPROVE_OPPORTUNITY_CONFIRM_MODAL`)),
      }
    }));
  }

  async onApproveVarOpportunityConfirm(opportunity: IVarOpportunityInDTO) {
    const { dispatch, VAR } = this.props;
    const { pageSize } = this.state;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.approveVarOpportunity(opportunity.customerId, opportunity.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('APPROVE_OPPORTUNITY_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchOpportunities({ page:0, pageSize, varId:VAR.id.toString() }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITY_APPROVE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.approveVarOpportunityError',
        }
      }));
    }
  }

  async onPageSizeChange(pageSize) {
    const { dispatch, VAR } = this.props;
    this.setState({ pageSize }, async () => {
      await dispatch<any>(VarsActions.fetchOpportunities({ page: 0, pageSize, varId:VAR.id.toString() }));
    })
  }

  async onFetchOpportunitiesOnPage(page) {
    const { dispatch, VAR } = this.props;
    const { pageSize } = this.state;
    await dispatch<any>(VarsActions.fetchOpportunities({ page, pageSize, varId:VAR.id.toString() }));
  }
  
  public render() {
    const { opportunities, classes } = this.props;
    const { pageSize } = this.state;
    return (
      <div className={classes.container}>
        <Card className={classes.cardContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <MonetizationOnOutlinedIcon className={classes.icon} />
              <h3 className={classes.title}>{<ReduxLanguage languageKey="vars.varOpportunities"/>}</h3>
            </div>
          </div>
          <div className={classes.tableContainer}>
            <VarOpportunitiesTable
              rowsPerPage={pageSize}
              opportunities={opportunities}
              hideVarColumn={true}
              onRowClick={(opportunity) => this.onOpenOpportunityForm(opportunity)}
              onDeleteVarOpportunityRequest={(opportunity)=> this.onDeleteVarOpportunityRequest(opportunity)}
              onRejectVarOpportunityRequest={(opportunity)=> this.onRejectVarOpportunityRequest(opportunity)}
              onApproveVarOpportunityRequest={(opportunity)=> this.onApproveVarOpportunityRequest(opportunity)}
              onUpgradeVarOpportunityRequest={()=> {}}
              onWinVarOpportunityRequest={()=> {}}
              onLoseVarOpportunityRequest={()=> {}}
              onPageSizeChange={pageSize => this.onPageSizeChange(pageSize)}
              onFetchElementsOnPage={page => this.onFetchOpportunitiesOnPage(page)}
            />
          </div>
        </Card>
      </div>
    );
  }
}


function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    vars: state.vars.vars.data,
    userData: state.user.userData,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(VarOpportunitiesSection)));