import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import { ActivationstatusOptions } from '../../codec/activationInDTODecoder';
import { IActivationsSearchDTO } from '../../repository/jago/model/input/IActivationsSearchDTO';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';
import translations from '../../translations/i18next';
import Permission from '../Permission/Permission';
import ReduxLanguage from '../ReduxLanguage';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems:'center',
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});


export interface IActivationStatusOption {
  label: string;
  value: string;
  original: string;
}
export interface IActivationClusterOption {
  label: string;
  value: string;
}

export interface IActivationSearchBarProps extends WithStyles<typeof styles> {
  onSearch: (searchValues: IActivationsSearchDTO) => void;
  onResetSearch: () => void;
  totalElements: string | number;
  onCreateNewEntityButtonClick: () => void;
  clusters: PaginatedDataDTO<IClusterInDTO>;
  onExportCSV: () => void;
}

export interface IActivationSearchBarState {
  searchValues: Record<keyof IActivationsSearchDTO, string>;
  statusSelected: IActivationStatusOption | undefined;
  clusterSelected: IClusterInDTO | undefined;
}

class ActivationSearchBar extends React.Component<IActivationSearchBarProps, IActivationSearchBarState> {

  constructor(props: IActivationSearchBarProps) {
    super(props);
    this.state = {
      searchValues: {
        search: "",
        status: "",
        cluster: "",
      },
      statusSelected: undefined,
      clusterSelected: undefined,
    }
  }

  public onSetSearchValue(field: keyof IActivationsSearchDTO, value: string) {
    const { searchValues } = this.state;
    searchValues[field] = value;
    this.setState({ searchValues });
    this.onSearch()
  }

  public onSelectStatus(statusOption: IActivationStatusOption) {
    this.onSetSearchValue('status', statusOption && statusOption.original ? statusOption.original : '');
    this.setState({ statusSelected: statusOption });
  }

  public onSelectCluster(clusterOption: IClusterInDTO) {
    this.onSetSearchValue('cluster', clusterOption && clusterOption.id ? clusterOption.id.toString() : '');
    this.setState({ clusterSelected: clusterOption });
  }

  public onSearch() {
    const { onSearch } = this.props;
    onSearch(this.state.searchValues);
  }

  public onResetFields() {
    const { onResetSearch } = this.props;
    this.setState({
        searchValues:{
          search: "",
          status: "",
          cluster: "",
        },
        statusSelected: undefined,
        clusterSelected: undefined,
    });
    onResetSearch();
  }

  public render() {
    const { classes, onCreateNewEntityButtonClick, totalElements, clusters, onExportCSV } = this.props;
    const { searchValues, statusSelected, clusterSelected } = this.state;
    const statusOptionsFormatted = _.map(ActivationstatusOptions, value => ({
      label: <ReduxLanguage languageKey={`activations.status${value}`} />,
      value: translations.t(`activations.status${value}`),
      original: value
    }));
    return (
      <div className={classes.container}>
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey="activations.search" /> }
          variant="outlined"
          type="search"
          style={{ marginLeft: 0, marginRight: 10 }}
          value={searchValues.search}
          onChange={e => this.onSetSearchValue('search', e.target.value)}
        />
        <Select
          isClearable
          key={`my_unique_select_key__${searchValues.status}`}
          value={statusSelected}
          options={statusOptionsFormatted}
          placeholder={<ReduxLanguage languageKey={'activations.statusSelection'} />}
          onChange={(e) => {this.onSelectStatus(e)}}
          styles={{
            menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
            control: (styles) => ({ ...styles, minHeight: 55, minWidth: 250, marginLeft: 10, marginRight: 10 }),
          }} 
        />
        <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.CLUSTER_FILTER}>
          <Select
            isClearable
            isSearchable={false}
            value={clusterSelected}
            options={(clusters as PaginatedDataDTO<IClusterInDTO>).content}
            placeholder={<><ReduxLanguage languageKey={'activations.clusterSelection'} /></>}
            formatOptionLabel={(o) => <>[{o.market}] - <b>{o.name}</b></>}
            isOptionSelected={(o) => clusterSelected?.id === o.id}
            onChange={(e) => this.onSelectCluster(e)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, minWidth: 350, marginLeft: 10, marginRight: 10, maxWidth:350 }),
            }} 
          />
        </Permission>
        <span style={{flexGrow:'1'}}></span>
        <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.ACTIVATION_REQUEST_CREATE}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#5AC0B1', color: 'white', marginRight: 20, fontWeight: 'bold'  }}
            onClick={() => onCreateNewEntityButtonClick()}
          >
            <ReduxLanguage languageKey="forms.add" />
          </Button>
        </Permission>
        <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.ACTIVATION_REQUEST_EXPORT}>
          <Button
            variant="contained"
            style={{ backgroundColor: 'cornflowerblue', color: 'white', marginRight: 20, fontWeight: 'bold'  }}
            onClick={() => onExportCSV()}
          >
            <ReduxLanguage languageKey="activations.exportActivationsCSV" />
          </Button>
        </Permission>
      </div>
    );
  }
}

export default withStyles(styles)(ActivationSearchBar);
