import MomentUtils from '@date-io/moment';
import { Button, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import Select from 'react-select';
import moment from 'moment';
import * as React from "react";
import { IWalletFormDTO } from '../../repository/jago/model/input/IWalletFormDTO';
import translations from '../../translations/i18next';
import ReduxLanguage from "../ReduxLanguage";
import { IWalletStatusEnum, WalletStatusMap } from '../../repository/jago/model/input/IWalletStatusEnum';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { Roles } from '../../services/PermissionService/PermissionConstants';
import { VAR_USERS_ROLES } from '../../utils/Utils';



const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});

export interface IWalletFormState {
  wallet: IWalletFormDTO;
  errors: { [key: string]: boolean; };
};

export interface IWalletFormProps extends WithStyles<typeof styles>{
  onCreateNewWallet: (wallet: IWalletFormDTO) => void;
  wallet?: IWalletFormDTO;
}

class WalletForm extends React.Component<IWalletFormProps, IWalletFormState> {

  constructor(props: IWalletFormProps) {
    super(props);
    this.state = {
      wallet: {
        name: props.wallet && props.wallet.name ? props.wallet.name : '',
        contactEmail: props.wallet && props.wallet.contactEmail ? props.wallet.contactEmail : '',
        notes: props.wallet && props.wallet.notes ? props.wallet.notes : '',
        status: props.wallet && props.wallet.status? props.wallet.status : IWalletStatusEnum.PENDING,
      },
      errors: {
        name: false,
        contactEmail: false,
        notes: false,
      },
    };
  }

  public onChangeField(field: keyof IWalletFormDTO, value: string) {
    const { wallet, errors } = this.state;
    this.setState({
      errors: { ...errors, [field]: value ? false : true }
    });
    wallet[field] = value;
    this.setState({ wallet });
  }

  public onSubmitForm() {
    const { wallet, errors } = this.state;
    if (!wallet.name) {
      this.setState({
        errors: { ...errors, 'name': true }
      });
      return;
    }
    const { onCreateNewWallet } = this.props;
    onCreateNewWallet(wallet);
  }

  public render() {
    const { classes, wallet: initialWalletData } = this.props;
    const { wallet, errors } = this.state;
    const canSubmitForm = wallet.name;

    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))

    const initialStatus = initialWalletData && initialWalletData.status ? initialWalletData.status : 
      (isVar ? IWalletStatusEnum.READY_TO_ACTIVATE : IWalletStatusEnum.PENDING);
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey={'forms.walletName'} />}
            variant="outlined"
            fullWidth
            required
            error={errors.name}
            value={wallet.name}
            style={{ marginTop: 20 }}
            onChange={e => this.onChangeField('name', e.target.value)}
          />
          {errors.name ? <h4 className={classes.errorText}>{`${translations.t('forms.required')}`}</h4> : null}
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey={'forms.contactEmail'} />}
            variant="outlined"
            fullWidth
            error={errors.contactEmail}
            value={wallet.contactEmail}
            style={{ marginTop: 20 }}
            onChange={e => this.onChangeField('contactEmail', e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey={'forms.notes'} />}
            variant="outlined"
            fullWidth
            rows={4}
            multiline
            error={errors.notes}
            value={wallet.notes}
            style={{ marginTop: 20 }}
            onChange={e => this.onChangeField('notes', e.target.value)}
          />
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3 }}>{<ReduxLanguage languageKey={'credits.walletStatus'} />}<span style={{ color: 'red' }}>*</span></h4>
          <Select
            options={_.map(WalletStatusMap[initialStatus], (v) => ({ value: v }))}
            isClearable
            isDisabled={initialStatus=== IWalletStatusEnum.ACTIVE}
            defaultValue={{ value: initialStatus }}
            placeholder={<ReduxLanguage languageKey={'forms.selectSegmentType'} />}
            onChange={(e) => this.onChangeField('status', e?.value)}
            getOptionLabel={(v) => translations.t(`credits.${v.value}`)}  
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55 }),
            }}
          />
          <Button
            variant="contained"
            disabled={!canSubmitForm}
            style={{ width: '100%', opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(WalletForm);