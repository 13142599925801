import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import 'moment/locale/it';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import OrdersStatusSearchBar from '../../components/SearchBar/OrdersStatusSearchBar';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import { IActivationInDTO } from '../../repository/jago/model/input/IActivationInDTO';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';

const styles = createStyles({
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  }
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IOrdersStatusProps extends WithStyles<typeof styles> {
  language: string;
  activations: PaginatedDataDTO<IActivationInDTO>;
  clusters: PaginatedDataDTO<IClusterInDTO>;
  marketplaces: PaginatedDataDTO<IMarketplaceInDTO>;
}

export type ComponentProps = IOrdersStatusProps & IReduxProps;

export interface IActivationNewPageState {
  clickupUrl: string;
};

class OrdersStatus extends React.Component<ComponentProps,IActivationNewPageState> {

  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      clickupUrl: 'https://sharing.clickup.com/18336344/l/h/hfjjr-10855/94d1b4adf0e7ea1',
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPEN_PARAMETERS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  render() {
    const { classes } = this.props;
    const { clickupUrl } = this.state;
    return (
      <div style={{ backgroundColor: 'white', maxHeight:'calc(100vh - 64px)',minHeight:'calc(100vh - 64px)'}}>
          <iframe src={clickupUrl} title="description"
            style={{border: 0, width:'calc(100%)', maxHeight:'calc(100vh)',minHeight:'calc(100vh)'}}>
          </iframe>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(OrdersStatus as any));