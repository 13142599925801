import { Button, Card, IconButton, Link } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Edit } from "@material-ui/icons";
import VisibilityIcon from '@material-ui/icons/Visibility';
import UserIcon from '@material-ui/icons/Person';
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { IState } from "../../redux/store";
import CustomerWalletSection from "./CustomerWalletSection";
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import { IVarInDTO } from "../../repository/jago/model/input/IVarInDTO";
import { PaginatedDataDTO } from "@bottega52/commons-pagination";
import Permission from "../Permission/Permission";
import CustomerOpportunitySection from "./CustomerOpportunitySection";
import { VAR_USERS_ROLES } from "../../utils/Utils";
import ReduxLanguage from "../ReduxLanguage";
import { RED } from "../../style/StyleConsts";
import { ICustomerInDTO } from "../../repository/jago/model/input/ICustomerInDTO";

const styles = createStyles({
  container: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    paddingBottom: 50,
  },
  headerContainer: {
    marginTop: 20,
    padding: 10,
    borderLeft: '3px solid #0D47A1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface ICustomerDetailsViewProps extends WithStyles<typeof styles>{
  onEditCustomerData: () => void;
  onVarClick: (varId: number) => void;
  hideEditCustomer?: boolean;
  hideDeleteCustomer?: boolean;
  onDeleteCustomerRequest?: () => void;
}

export type ComponentProps = ICustomerDetailsViewProps & IReduxProps;
class CustomerDetailsView extends React.Component<ComponentProps, any> {

  public render() {
    const { selectedCustomer, classes, onEditCustomerData, onDeleteCustomerRequest,
      vars, onVarClick, hideEditCustomer, hideDeleteCustomer } = this.props;
    const varsData = (vars as PaginatedDataDTO<IVarInDTO>)
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    const canOpenVarDetails = AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.NAVIGATION_VAR_MANAGEMENT);
    return (
      <div className={classes.container}>
        <Card className={classes.headerContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <UserIcon className={classes.icon} />
              <div className={classes.headerTextContainer}>
                <h3 className={classes.title}>{selectedCustomer && selectedCustomer.name ? selectedCustomer.name : ''}</h3>
                <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.VAR_READ}>
                  {selectedCustomer && selectedCustomer.varId && varsData.content && varsData.content.filter(e=>e.id===selectedCustomer.varId).length > 0 ? (
                    <div style={{ marginLeft:0, cursor: canOpenVarDetails ? 'pointer': 'auto' }} onClick={canOpenVarDetails ? () => onVarClick(selectedCustomer.varId) : () => null}>
                      <h3 style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '0.8em', display: 'inline', border: '1px solid grey', borderRadius: 5, marginRight: -2 }}>
                        {"VAR "}
                      </h3>
                      <h3
                        className={canOpenVarDetails ? "linkLabel" : ''}
                        style={{ fontSize: '0.8em', display: 'inline', marginLeft: -10, paddingLeft: 20, paddingRight: 5, color: canOpenVarDetails ? 'slateblue': '#3f3f3f' }}
                        onClick={canOpenVarDetails ? () => onVarClick(selectedCustomer.varId) : () => null}
                      >
                        {varsData.content.filter(e=>e.id === selectedCustomer.varId)[0].name}
                      </h3>
                    </div>
                  ) : null}
                </Permission>
              </div>
            </div>
            <span style={{flexGrow:1}}></span>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.CUSTOMER_DELETE_REQUEST}>
              {!hideDeleteCustomer ? (
                <Button
                  variant="contained"
                  style={{ backgroundColor: RED, fontSize: 11, fontWeight:'bold', color: 'white', marginRight: 60, marginLeft: 60}}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteCustomerRequest();
                  }}
                >
                  <ReduxLanguage languageKey="forms.deleteRequest" />
                </Button>
              ):null}
            </Permission>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.CUSTOMER_UPDATE}>
              {!hideEditCustomer ? (
                <IconButton onClick={() => onEditCustomerData()}>
                  <Edit />
                </IconButton>
              ) : null}
            </Permission>
          </div>
        </Card>
        <CustomerWalletSection
          customer={selectedCustomer}
        />
        {isVar ? (
          <CustomerOpportunitySection
            customer={selectedCustomer}
          />
        ):null}
        
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    selectedCustomer: state.credits.customers.selectedDetailedCustomer,
    vars: state.vars.vars.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(CustomerDetailsView as any));