import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { Button, createStyles, WithStyles } from '@material-ui/core';
import { DeleteOutline, Domain, PublicOutlined } from '@material-ui/icons';
import AssignmentIndIcon from '@material-ui/icons/AssignmentIndOutlined';
import MarketplacesIcon from '@material-ui/icons/Storefront';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/styles';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import 'moment/locale/it';
import qs from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as CustomerDecoder from '../../codec/customerDTOCodec';
import CustomerDetailsView from '../../components/Customers/CustomerDetailsView';
import CustomersTable from '../../components/Customers/CustomersTable';
import CustomerForm from '../../components/Forms/CustomerForm';
import ExportUsagesCSVForm from '../../components/Forms/ExportUsagesCSVForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import Permission from '../../components/Permission/Permission';
import ReduxLanguage from '../../components/ReduxLanguage';
import CustomersSearchBar from '../../components/SearchBar/CustomersSearchBar';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as DomainsActions from '../../redux/domains/domains.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as UserActions from '../../redux/user/user.actions';
import * as VarsActions from '../../redux/vars/vars.actions';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { ICustomerFormDTO } from '../../repository/jago/model/input/ICustomerFormDTO';
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import { ICustomersSearchDTO } from '../../repository/jago/model/input/ICustomersSearchDTO';
import { IDomainOptionDTO } from '../../repository/jago/model/input/IDomainOptionDTO';
import { IProductInDTO } from '../../repository/jago/model/input/IProductInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { ICustomerRequestParamsDTO, IDomainRequestParamsDTO, IExportWalletUsageCSVParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import { getDomainsOptions } from '../../repository/utils';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import translations from '../../translations/i18next';
import { VAR_USERS_ROLES } from '../../utils/Utils';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 160px)',
    width: '100%'
  },
});
export interface ICustomersPageState {
  searchValues: Record<keyof ICustomersSearchDTO, string>;
  domainsOptions: IDomainOptionDTO[];
  rowsPerPage: number;
  isLoading: boolean;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface ICustomersPageProps extends WithStyles<typeof styles> {
  language: string;
  customers: PaginatedDataDTO<ICustomerInDTO>;
  products: IProductInDTO[];
}

export type ComponentProps = ICustomersPageProps & IReduxProps;

class CustomersPage extends React.Component<ComponentProps, ICustomersPageState> {
private tableColumns: GridColumns<ICustomerInDTO>;
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      searchValues: {
        name: '',
        hostname: '',
        varId: '',
        clusterId: '',
      },
      domainsOptions: [],
      isLoading: false,
      rowsPerPage: 50,
    }
  }

  public async componentDidMount() {
    const { dispatch, history } = this.props;
    const { rowsPerPage } = this.state;
    const parsed = qs.parse(history.location.search);
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    this.setState({ isLoading: true });
    try {
      if (isVar) {
        dispatch<any>(VarsActions.fetchVar());
        await dispatch<any>(VarsActions.fetchPlantThemes({ page: 0, pageSize: 500 }));
      } else {
        await dispatch<any>(CreditsActions.fetchClusters());
        dispatch<any>(VarsActions.fetchVars({ page: 0, pageSize: 500 }));
      }
      await dispatch<any>(CreditsActions.fetchCustomers({ page: 0, pageSize: rowsPerPage }));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
    if (parsed && parsed.customerId) {
      try {
        this.setState({ isLoading: true });
        const customerId = Number(parsed.customerId);
        const customer = await dispatch<any>(CreditsActions.fetchCustomerDetails(customerId));
        this.setState({ isLoading: false });
        if (customer) {
          this.onRowClick(customer);
        } else {
          dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_DETAILS`, {
            modalType: ModalTypes.ERROR_MODAL,
            modalProps: {
              titleMessageKey: 'errors.error',
              errorMessageKey: 'errors.getCustomerDetailsError',
            }
          }));
        }
      } catch (error) {
        this.setState({ isLoading: false });
        dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_DETAILS`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'errors.getCustomerDetailsError',
          }
        }));
      }
    }
  }

  async onOpenNewCustomerForm() {
    const { dispatch } = this.props;
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    if (!isVar) {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.fetchMarketplaces());
      await dispatch<any>(CreditsActions.fetchClusters());
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }

    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_CUSTOMER_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
      modalProps: {
        content: (
          <CustomerForm
            onCreateNewCustomer={(newCustomerData: ICustomerFormDTO) => this.onCreateNewCustomerRequest(newCustomerData)}
            isVar={isVar}
          />
        ),
        titleMessageKey: 'forms.customerFormTitle',
      }
    }));
  }

  async openUsagesCSVExportModal() {
    const { dispatch } = this.props;

    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_USAGES_CSV_EXPORT_MODAL`, {
      modalType: ModalTypes.CONTENT_MODAL,
      modalProps: {
        content: (
          <ExportUsagesCSVForm
            onSubmit={(data: IExportWalletUsageCSVParamsDTO) => {
              this.exportUsagesCSV(data);
            }}
          />
        ),
        titleMessageKey: 'forms.customerFormTitle',
      }
    }));
  }

  async onOpenEditCustomerForm() {
    const { dispatch, selectedCustomer, vars } = this.props;
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    if (selectedCustomer) {
      if (!isVar) {
        dispatch<any>(SettingsActions.setSpinnerVisible(true));
        await dispatch<any>(CreditsActions.fetchMarketplaces());
        await dispatch<any>(CreditsActions.fetchClusters());
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
      }
      
      const customerFormData = CustomerDecoder.decodeCustomerContentToForm(
        selectedCustomer,vars&&(vars as PaginatedDataDTO<IVarInDTO>).content?(vars as PaginatedDataDTO<IVarInDTO>).content:[]
      );
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_CUSTOMER_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
            <CustomerForm
              customer={customerFormData}
              onCreateNewCustomer={(newCustomerData: ICustomerFormDTO) => this.onEditCustomerRequest(selectedCustomer.id, newCustomerData)}
              isVar={isVar}
            />
          ),
          titleMessageKey: 'forms.customerFormTitle',
        }
      }));
    }
  }

  public onEditCustomerRequest(customerId: number, newCustomerData: ICustomerFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_CUSTOMER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.editCustomer',
        successMessageKey: 'credits.editCreationConfirm',
        confirmMessageKey: 'credits.editCustomer',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditCustomerConfirm(customerId, newCustomerData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_DOMAIN_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onEditCustomerConfirm(customerId: number, newCustomerData: ICustomerFormDTO) {
    const { dispatch, VAR } = this.props;
    const { rowsPerPage } = this.state;
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    const currentVar = isVar?(VAR as IVarInDTO):undefined
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_CUSTOMER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const customerUpdated = await dispatch<any>(CreditsActions.editCustomer(customerId, newCustomerData, currentVar));
      dispatch<any>(CreditsActions.setSelecteCustomer(customerUpdated));
      await dispatch<any>(CreditsActions.fetchCustomers({ pageSize: rowsPerPage }));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CREATE_CUSTOMER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCustomerError',
        }
      }));
    }
  }

  public onCreateNewCustomerRequest(newCustomerData: ICustomerFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_CUSTOMER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newCustomer',
        successMessageKey: 'credits.customerCreationConfirm',
        confirmMessageKey: 'credits.createCustomer',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewCustomerConfirm(newCustomerData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_DOMAIN_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onCreateNewCustomerConfirm(newCustomerData: ICustomerFormDTO) {
    const { dispatch, VAR } = this.props;
    const { rowsPerPage } = this.state;
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    const currentVar = isVar?(VAR as IVarInDTO):undefined
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_CUSTOMER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.createNewCustomer(newCustomerData,currentVar));

      await dispatch<any>(CreditsActions.fetchCustomers({ pageSize: rowsPerPage }));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CREATE_CUSTOMER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCustomerError',
        }
      }));
    }
  }

  public onOpenVarDetails(varId: number) {
    const { history } = this.props
    history.push(`/vars?varId=${varId}`);
  } 

  public async onRowClick(customer: ICustomerInDTO) {
    const { dispatch } = this.props;
    try {
      const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      dispatch<any>(CreditsActions.setSelecteCustomer(customer));
      await dispatch<any>(CreditsActions.fetchCustomerWallets({ pageSize: 200, customerId: customer.id }));
      if (isVar)
        await dispatch<any>(VarsActions.fetchOpportunities({ page:0, pageSize: 1000, customerId: customer.id.toString() }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CUSTOMER_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(CreditsActions.setSelecteCustomer({})),
          content: (
            <CustomerDetailsView
              onEditCustomerData={() => this.onOpenEditCustomerForm()}
              onVarClick={varId => {
                dispatch<any>(ModalsActions.hideModal(`OPERATIONAL_CUSTOMER_MODAL`));
                this.onOpenVarDetails(varId)
              }}
              onDeleteCustomerRequest={() => this.onDeleteCustomerRequest(customer)}
            />
          ),
          titleMessageKey: 'credits.customerDetails',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getCustomerDetailsError',
        }
      }));
    }
  }

  public onDeleteCustomerRequest(customer: ICustomerInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_CUSTOMER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'domains.customerDeletionTitle',
        successMessageKey: 'domains.customerDeletionConfirm',
        confirmMessageKey: 'domains.sendDeletionRequest',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteCustomerConfirm(customer),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_CUSTOMER_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onDeleteCustomerConfirm(customer: ICustomerInDTO) {
    const { dispatch } = this.props;
    try {
      const customerName = customer.name
      const customerId = customer.id
      dispatch<any>(ModalsActions.hideModal(`DELETE_CUSTOMER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.openCloseCustomerTicket(customerId,customerName));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CUSTOMER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'domains.customerDeletionSuccess',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_DELETION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteCustomerRequestError',
        }
      }));
    }
  }

  public onDeleteCustomerRequestAdmin(customer: ICustomerInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_CUSTOMER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'domains.customerDeletionTitle',
        successMessageKey: 'domains.customerAdminDeletionConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteCustomerAdminConfirm(customer),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_CUSTOMER_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onDeleteCustomerAdminConfirm(customer: ICustomerInDTO) {
    const { dispatch, customers } = this.props;
    const { rowsPerPage } = this.state;
    const pagination = customers.pagination as PaginationDTO;
    try {
      const customerId = customer.id
      dispatch<any>(ModalsActions.hideModal(`DELETE_CUSTOMER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.deleteCustomer(customerId));
      dispatch<any>(CreditsActions.fetchCustomers({ page: pagination.number, pageSize: rowsPerPage }));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CUSTOMER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'domains.customerAdminDeletionSuccess',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_DELETION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteCustomerRequestError',
        }
      }));
    }
  }

  exportUsagesCSV(data: IExportWalletUsageCSVParamsDTO) {
    const { dispatch } = this.props;
    dispatch<any>(CreditsActions.exportWalletsUsageCSV(data));
  }

  exportWalletCreditUsageCSV() {
    const { dispatch } = this.props;
    dispatch<any>(CreditsActions.exportWalletCreditUsageCSV());
  }

  public async onSearchReset() {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    const params: ICustomerRequestParamsDTO = { page: 0, pageSize: rowsPerPage };
    this.setState({ searchValues: { name: '', hostname: '', varId: '', clusterId: '' }, isLoading: true });
    try {
      await dispatch<any>(CreditsActions.fetchCustomers(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }
  
  public onUpdateSearchValues(values: Record<keyof ICustomersSearchDTO, string>) {
    this.setState({ searchValues: values });
  }

  async onFetchDomains(value: string) {
    const { dispatch } = this.props;
    const params: IDomainRequestParamsDTO = { serviceName: 'LUCKEY', hostName: value };
    const domains = await dispatch<any>(DomainsActions.fetchSofiaDomainsSearch(params));
    const domainsOptions = getDomainsOptions(domains);
    this.setState({ domainsOptions });
  }

  public async onSearch(values: ICustomersSearchDTO) {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    this.setState({ searchValues: { name: values.name || '', hostname: values.hostname || '', varId: values.varId || undefined, clusterId: values.clusterId || undefined }, isLoading: true });
    const params: ICustomerRequestParamsDTO = { page: 0, pageSize: rowsPerPage, hostname: values.hostname, name: values.name, varId: values.varId, clusterId: values.clusterId };
    const filteredParams: ICustomerRequestParamsDTO = _.pickBy(params, param => param !== '') as ICustomerRequestParamsDTO;
    try {
      await dispatch<any>(CreditsActions.fetchCustomers(filteredParams));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public async onFetchCustomersOnPage(page: number) {
    const { dispatch } = this.props;
    const { searchValues, rowsPerPage } = this.state;
    const params: ICustomerRequestParamsDTO = { page, pageSize: rowsPerPage, name: searchValues.name || '', hostname: searchValues.hostname || '', };
    const filteredParams: ICustomerRequestParamsDTO = _.pickBy(params, param => param !== '') as ICustomerRequestParamsDTO;
    try {
      this.setState({ isLoading: true });
      await dispatch<any>(CreditsActions.fetchCustomers(filteredParams));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public async handleChangeRowsPerPage(pageSize: number) {
    const { dispatch } = this.props;
    this.setState({ rowsPerPage: pageSize, isLoading: true }, async () => {
      const { searchValues } = this.state;
      const params: ICustomerRequestParamsDTO = { page: 0, pageSize, name: searchValues.name || '', hostname: searchValues.hostname || '', };
      const filteredParams: ICustomerRequestParamsDTO = _.pickBy(params, param => param !== '') as ICustomerRequestParamsDTO;
      try {
        await dispatch<any>(CreditsActions.fetchCustomers(filteredParams));
        this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    });
  }

  public onVarClick(customerVar) {
    const { history } = this.props
    history.push(`/vars?varId=${customerVar.id}`);
  }

  render() {
    const { domainsOptions, rowsPerPage } = this.state;
    const { classes, customers, vars, clusters } = this.props;
    const customersPagination = !_.isEmpty(customers.pagination) ? (customers.pagination as PaginationDTO) : null;
    const varsData = (vars as PaginatedDataDTO<IVarInDTO>)
    const varsOptions = varsData.content;
    const clustersData = (clusters as PaginatedDataDTO<IClusterInDTO>)
    const clustersOptions = clustersData.content;
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    const isSofiaAdmin = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN]);
    const canOpenVarDetails = AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.NAVIGATION_VAR_MANAGEMENT);

    this.tableColumns = [
      {
        field: 'name',
        headerName: translations.t('gateways.name'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 350,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          const customer = params.row;
          return (
            <div>
              <h3 style={{ margin: 0, fontSize: 15, fontWeight: 'bold', whiteSpace: 'normal' }}>{customer.name}</h3>
            </div>
        )}
      }
    ];
    if (!isVar) {
      this.tableColumns.push(
      {
        field: 'var',
        headerName: translations.t('vars.vars'),
        renderCell: (params: any) => {
          const customer = params.row;
          const customerVar = _.find(varsData.content, { id: customer.varId });
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {!isVar && customerVar && !_.isEmpty(customerVar) && customerVar.name ? (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <AssignmentIndIcon style={{ fontSize: '1em', fontWeight: 'bold', color: canOpenVarDetails ? 'slateblue': '#3f3f3f'  }}/>
                    <h4
                      className={canOpenVarDetails ? "linkLabel" : ''}
                      style={{ padding: 6, marginLeft: 0, fontSize: '1em', fontWeight: 'bold', color: canOpenVarDetails ? 'slateblue': '#3f3f3f' }}
                      onClick={canOpenVarDetails ? (e) => { e.stopPropagation(); this.onVarClick(customerVar); } : () => null}
                    >
                      {customerVar.name}
                    </h4>
                  </div>
              ):null}
            </div>
          )
        },
        type: 'string',
        sortable: false,
        flex: 1,
        minWidth: 150,
      })
    }
    this.tableColumns.push({
      field: 'clusters',
      headerName: isSofiaAdmin ? translations.t('forms.clusterAndIseoCompany') : translations.t('activations.cluster'),
      renderCell: (params: any) => {
        const customer = params?.row;
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {isSofiaAdmin  ? (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
              <Domain style={{ fontSize: 15 }}/>
                <h4 style={{ margin: 0, marginLeft: 5 }}>{customer.company}</h4>
            </div>
            ): null}
            {customer.cluster && customer.cluster.market && customer.cluster.name ? (
              <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'normal' }}>
                <PublicOutlined style={{ fontSize: 15 }}/>
                  <h4>{<>[{customer.cluster.market}] - {customer.cluster.name}</>}</h4>
              </div>
            ) : null}
          </div>
        )
      },
      type: 'string',
      sortable: false,
      flex: 1,
      minWidth: 350,
    })

    if (!isVar) {
      this.tableColumns.push({
          field: 'marketplace',
          headerName: translations.t('forms.customerMarketplace'),
          renderCell: (params: any) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {params?.row.marketplace && params?.row.marketplace.name ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MarketplacesIcon style={{ fontSize: 15 }}/>
                      <h4>{params?.row.marketplace.name}</h4>
                  </div>
                ) : null}
              </div>
            )
          },
          type: 'string',
          sortable: false,
          minWidth: 180,
      })
    }

    if (isSofiaAdmin) {
      this.tableColumns.push({
          field: 'actions',
          headerName: translations.t('forms.actions'),
          renderCell: (params: any) => {
            return (
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'darkred',
                  flexShrink: 0,
                  marginRight: 20,
                  width: 'fit-content',
                  color: 'white',
                  padding: 4,
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontWeight: 'bold'}}
                onClick={(e) => {e.stopPropagation(); this.onDeleteCustomerRequestAdmin(params.row)}}
              >
                <DeleteOutline style={{ fontSize: 15 }}/>
                <h5 style={{ fontWeight: 'bold', margin: 0, marginLeft: 5 }} ><ReduxLanguage languageKey={"forms.delete"} /></h5>
              </Button>
            )
          },
          type: 'string',
          sortable: false,
          minWidth: 150,
    })
    }


    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <CustomersSearchBar
            searchValues={this.state.searchValues}
            domainsOptions={domainsOptions}
            varsOptions={varsOptions}
            clustersOptions={clustersOptions}
            totalElements={customersPagination ? customersPagination.totalElements : ''}
            onSearch={(values: ICustomersSearchDTO) => this.onSearch(values)}
            onResetSearch={() => this.onSearchReset()}
            onUpdateSearchValues={values => this.onUpdateSearchValues(values)}
            onFilterDomains={value => this.onFetchDomains(value)}
            onCreateNewEntityButtonClick={() => this.onOpenNewCustomerForm()}
          />
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.CUSTOMER_EXPORT}>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              <Button
                variant="contained"
                style={{ backgroundColor: 'cornflowerblue', color: 'white', fontSize: 10, marginLeft: 10, fontWeight: 'bold'  }}
                onClick={() => this.openUsagesCSVExportModal()}
              >
                <ReduxLanguage languageKey="credits.exportWalletsUsageCSV" />
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: 'cornflowerblue', color: 'white', fontSize: 10, marginLeft: 20, fontWeight: 'bold'  }}
                onClick={() => this.exportWalletCreditUsageCSV()}
              >
                <ReduxLanguage languageKey="credits.exportWalletsCreditUsageCSV" />
              </Button>
            </div>
          </Permission>
        </div>
        <div className={classes.tableContainer}>
        <CustomersTable
          customers={customers}
          rowsPerPage={rowsPerPage}
          rowHeight={65}
          isLoading={this.state.isLoading}
          tableColumns={this.tableColumns}
          onFetchCustomersOnPage={(page) => this.onFetchCustomersOnPage(page)}
          onPageSizeChange={(pageSize) => this.handleChangeRowsPerPage(pageSize)}
          onRowClick={user => this.onRowClick(user)}
        />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    products: state.products.products,
    customers: state.credits.customers.data,
    selectedCustomer: state.credits.customers.selectedDetailedCustomer,
    vars: state.vars.vars.data,
    VAR: state.vars.VAR.data,
    clusters: state.credits.clusters.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(CustomersPage)));