import { PaginatedDataDTO, PaginationDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { WithStyles } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import { createStyles, withStyles } from '@material-ui/styles';
import { Stack } from '@mui/material';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import 'moment/locale/it';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import NewMarketplaceForm from '../../components/Forms/NewMarketplaceForm';
import MarketplaceDetailsView from '../../components/Marketplace/MarketplaceDetailsView';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import SearchBar from '../../components/SearchBar/SearchBar';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import { IMarketplaceFormDTO } from '../../repository/jago/model/input/IMarketplaceFormDTO';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { IMarketplacesRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import translations from '../../translations/i18next';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  logo: {
    width: '20%',
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 160px)',
    width: '100%'
  },
});

export interface IMarketplacesPageState {
  searchText: string;
  rowsPerPage: number;
  isLoading: boolean;
}

type IReduxProps = ConnectedProps<typeof connector>;

export interface IMarketplacesPageProps extends WithStyles<typeof styles> {
  language: string;
  marketplaces: PaginatedDataDTO<IMarketplaceInDTO>;
}

export type ComponentProps = IMarketplacesPageProps & IReduxProps;

class MarketplacesPage extends React.Component<ComponentProps, IMarketplacesPageState> {
  private marketplacesColumns: GridColumns<IMarketplaceInDTO>;
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      searchText: '',
      rowsPerPage: 100,
      isLoading: false,
    }
  }

  public async componentDidMount() {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    const params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: rowsPerPage };
    try {
      this.setState({ isLoading: false });
      await dispatch<any>(CreditsActions.fetchMarketplaces(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }

  }

  public async onSearchTextChange(text: string) {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    this.setState({ searchText: text, isLoading: true });
    const params: IMarketplacesRequestParamsDTO = { name: text, pageSize: rowsPerPage };
    try {
      await dispatch<any>(CreditsActions.fetchMarketplaces(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }


  public async onFetchMarkeplacesPerPage(page: number) {
    const { dispatch } = this.props;
    const { searchText, rowsPerPage } = this.state;
    const params: IMarketplacesRequestParamsDTO = { page, pageSize: rowsPerPage, name: searchText };
    try {
      this.setState({ isLoading: true });
      await dispatch<any>(CreditsActions.fetchMarketplaces(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  public onOpenNewMarketplaceForm() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_MARKETPLACE_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <NewMarketplaceForm
            onCreateNewMarketplace={(data) => this.onCreateMarketplaceRequest(data)}
          />
        ),
        titleMessageKey: 'forms.marketplaceFormTitle',
      }
    }));
  }

  public onCreateMarketplaceRequest(data: IMarketplaceFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_MARKETPLACE_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newMarketplace',
        successMessageKey: 'credits.marketplaceCreationConfirm',
        confirmMessageKey: 'credits.createMarketplace',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateMarketplaceConfirm(data),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_MARKETPLACE_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onCreateMarketplaceConfirm(data: IMarketplaceFormDTO) {
    const { dispatch } = this.props;
    const { rowsPerPage, searchText } = this.state;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_MARKETPLACE_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.createMarketplace(data));
      const params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: rowsPerPage, name: searchText };
      dispatch<any>(CreditsActions.fetchMarketplaces(params));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CREATE_MARKETPLACE_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCustomerError',
        }
      }));
    }
  }

  public async onRowClick(marketplace: IMarketplaceInDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      dispatch<any>(CreditsActions.CreditsActions.setSelectedMarketplace(marketplace));
      await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptions(marketplace.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_MARKETPLACE_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(CreditsActions.CreditsActions.setSelectedMarketplace({})),
          content: (
            <MarketplaceDetailsView />
          ),
          titleMessageKey: 'credits.marketplaceDetails',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_MARKETPLACE_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getMarketplaceDetailsError',
        }
      }));
    }
  }

  public handleChangeRowsPerPage(pageSize: number) {
    const { dispatch } = this.props;
    const { searchText } = this.state;
    this.setState({ rowsPerPage: pageSize, isLoading: true }, async () => {
      const params: PaginationQueryParamsDTO = { page: 0, pageSize, name: searchText };
      try {
        await dispatch<any>(CreditsActions.fetchMarketplaces(params));
        this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    });
  }


  render() {
    const { classes, marketplaces } = this.props;
    const { rowsPerPage } = this.state;
    this.marketplacesColumns = [
      {
        field: 'name',
        headerName: translations.t('gateways.name'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          const marketPlace = params.row;
          return <h3 style={{ marginLeft: 0, fontWeight: 'bold' }}>{marketPlace.name}</h3>
        }
      },
    ];
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <SearchBar
            searchTextTranslationKey="credits.marketplaceName"
            newEntityTranslationKey="forms.createNewMarketplace"
            searchDebounceDelay={200}
            onCreateNewEntityButtonClick={() => this.onOpenNewMarketplaceForm()}
            onSearchTextChange={(text: string) => this.onSearchTextChange(text)}
          />
        </div>
        <div className={classes.tableContainer}>
          <DataGrid
            getRowId={(d) => d.id}
            rows={marketplaces.content || []}
            columns={this.marketplacesColumns}
            rowHeight={50}
            loading={this.state.isLoading}
            onPageSizeChange={(pageSize) => this.handleChangeRowsPerPage(pageSize)}
            onPageChange={page => this.onFetchMarkeplacesPerPage(page)}
            page={(marketplaces?.pagination as PaginationDTO)?.number}
            rowCount={(marketplaces?.pagination as PaginationDTO)?.totalElements}
            pageSize={rowsPerPage}
            paginationMode="server"
            onRowClick={row => this.onRowClick(row.row)}
            componentsProps={{
              pagination: {
                labelRowsPerPage: translations.t('forms.rowsPerPage')
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <SearchOutlined style={{ alignSelf: 'center' }} />
                    <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='credits.noMarketplaceFoundTitle' /></h3>
                    <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='credits.noMarketplaceFoundDescription' /></h4>
                  </div>
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='credits.noMarketplaceFoundTitle' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='credits.noMarketplaceFoundDescription' /></h4>
                </Stack>
              )
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    marketplaces: state.credits.marketplaces.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(MarketplacesPage as any));