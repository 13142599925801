import { PaginatedDataDTO, PaginationDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import 'moment/locale/it';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as RechargeDecoder from '../../codec/rechargeInDTODecoder';
import RechargesTable from '../../components/Activations/RechargesTable';
import CustomerDetailsView from '../../components/Customers/CustomerDetailsView';
import RechargeForm from '../../components/Forms/RechargeForm';
import RechargeNewForm from '../../components/Forms/RechargeNewForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import RechagesSearchBar from '../../components/SearchBar/RechagesSearchBar';
import * as RechargesActions from '../../redux/activations/recharges.actions';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as VarsActions from '../../redux/vars/vars.actions';
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { IRechargeFormDTO } from '../../repository/jago/model/input/IRechargeFormDTO';
import { IRechargeInDTO } from '../../repository/jago/model/input/IRechargeInDTO';
import { IRechargesSearchDTO } from '../../repository/jago/model/input/IRechargesSearchDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IWalletInDTO } from '../../repository/jago/model/input/IWalletInDTO';
import { ICardOutDTO } from '../../repository/jago/model/output/ICardOutDTO';
import translations from '../../translations/i18next';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 160px)',
    width: '100%'
  },
});

export interface IOption {
  id: string;
  value: string;
}

type IReduxProps = ConnectedProps<typeof connector>;

export interface IWalletRechargesPageProps extends WithStyles<typeof styles> {
  language: string;
  recharges: PaginatedDataDTO<IRechargeInDTO>;
  customers: PaginatedDataDTO<ICustomerInDTO>;
  wallets: PaginatedDataDTO<IWalletInDTO>;
}

export type ComponentProps = IWalletRechargesPageProps & IReduxProps & RouteComponentProps<any>;

export interface IWalletRechargesPageState {
  IseoMarketplace: IMarketplaceInDTO;
  searchValuesCurrent: IRechargesSearchDTO;
  pageSize: number;
  isLoading: boolean;
};

class WalletRechargesPage extends React.Component<ComponentProps,IWalletRechargesPageState> {

  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      IseoMarketplace: null,
      searchValuesCurrent: {},
      pageSize: 100,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    try {
      this.setState({ isLoading: true });
      //dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.fetchCustomers());
      await dispatch<any>(RechargesActions.fetchWallets());
      await dispatch<any>(RechargesActions.fetchRecharges({ page: 0, pageSize, ...this.state.searchValuesCurrent }));
      try {
        dispatch<any>(VarsActions.fetchVars( {page:0, pageSize:500}))
        this.setState({ isLoading: false });
      }
      catch (error) {
        this.setState({ isLoading: false });
      }
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      this.setState({ isLoading: false });
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPEN_PARAMETERS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  onFetchItemsOnPage(page: number) {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    const params: PaginationQueryParamsDTO = { page, pageSize, ...this.state.searchValuesCurrent };
    dispatch<any>(RechargesActions.fetchRecharges(params));
  }

  onPageSizeChange(pageSize) {
    const { dispatch } = this.props;
    this.setState({ pageSize}, () => {
      const params: PaginationQueryParamsDTO = { page: 0, pageSize, ...this.state.searchValuesCurrent };
      dispatch<any>(RechargesActions.fetchRecharges(params));
    })
  }

  async onSearchRecharges(searchValues: IRechargesSearchDTO) {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    this.setState({ searchValuesCurrent: searchValues, isLoading: true });
    try {
      await dispatch<any>(RechargesActions.fetchRecharges({ page: 0, pageSize, ...searchValues }));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  async onResetSearch() {
    const { dispatch } = this.props;
    const { pageSize } = this.state;
    try {
      this.setState({ isLoading: true });
      dispatch<any>(RechargesActions.fetchRecharges({ page: 0, pageSize, ...this.state.searchValuesCurrent }));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  onDeleteRechargeRequest(recharge: IRechargeInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_RECHARGE_CONFIRM_MODAL`, {
      modalType: ModalTypes.TEXTFIELD_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'recharges.deleteRechargeConfirm',
        initialText: "",
        fieldLabel: null,
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: (text: string) => {if (!text) return; this.onDeleteRechargeConfirm(recharge,text)},
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_RECHARGE_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteRechargeConfirm(recharge: IRechargeInDTO, text: string) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      let rejectedRecharge = RechargeDecoder.encodeRechargeInFromOut(recharge);
      rejectedRecharge.deletedNotes = text;
      await dispatch<any>(RechargesActions.editRecharge(recharge.id, rejectedRecharge, null));
      await dispatch<any>(RechargesActions.deleteRecharge(recharge.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_RECHARGE_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_RECHARGE_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.deleteRechargeError',
        }
      }));
    }
  }

  onDeleteRechargeAdminRequest(recharge: IRechargeInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_RECHARGE_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'recharges.deleteAdminRechargeConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteRechargeAdminConfirm(recharge),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_RECHARGE_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteRechargeAdminConfirm(recharge: IRechargeInDTO) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.deleteRechargeAdmin(recharge.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_RECHARGE_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_RECHARGE_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.deleteRechargeError',
        }
      }));
    }
  }

  onPromoteRechargeRequest(recharge: IRechargeInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`PROMOTE_RECHARGE_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'recharges.promoteRechargeConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onPromoteRechargeConfirm(recharge),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`PROMOTE_RECHARGE_CONFIRM_MODAL`)),
      }
    }));
  }

  async onPromoteRechargeConfirm(recharge: IRechargeInDTO) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.promoteRecharge(recharge.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('PROMOTE_RECHARGE_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_RECHARGE_PROMOTE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.promoteRechargeError',
        }
      }));
    }
  }

  onInvoicedRechargeRequest(recharge: IRechargeInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`INVOICED_RECHARGE_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'recharges.invoicedRechargeConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onInvoicedRechargeConfirm(recharge),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`INVOICED_RECHARGE_CONFIRM_MODAL`)),
      }
    }));
  }

  async onInvoicedRechargeConfirm(recharge: IRechargeInDTO) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.invoicedRecharge(recharge.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('INVOICED_RECHARGE_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_RECHARGE_INVOICED`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.invoicedRechargeError',
        }
      }));
    }
  }

  onRejectRechargeRequest(recharge: IRechargeInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`REJECT_RECHARGE_CONFIRM_MODAL`, {
      modalType: ModalTypes.TEXTFIELD_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'recharges.rejectRechargeConfirm',
        initialText: "",
        fieldLabel: null,
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: (text: string) => this.onRejectRechargeConfirm(recharge,text),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`REJECT_RECHARGE_CONFIRM_MODAL`)),
      }
    }));
  }

  async onRejectRechargeConfirm(recharge: IRechargeInDTO, rejectedNotes: string) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.promoteRechargeAdmin(recharge.id,RechargeDecoder.RechargeStatusOptions.REJECTED));
      let rejectedRecharge = RechargeDecoder.encodeRechargeInFromOut(recharge);
      rejectedRecharge.rejectedNotes = rejectedNotes;
      await dispatch<any>(RechargesActions.editRechargeAdmin(recharge.id, rejectedRecharge, null));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('REJECT_RECHARGE_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_RECHARGE_REJECT`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.rejectRechargeError',
        }
      }));
    }
  }

  onRejectInvoicedRechargeRequest(recharge: IRechargeInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`REJECT_INVOICED_RECHARGE_CONFIRM_MODAL`, {
      modalType: ModalTypes.TEXTFIELD_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'recharges.rejectRechargeConfirm',
        initialText: "",
        fieldLabel: null,
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: (text: string) => this.onRejectInvoicedRechargeConfirm(recharge,text),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`REJECT_INVOICED_RECHARGE_CONFIRM_MODAL`)),
      }
    }));
  }

  async onRejectInvoicedRechargeConfirm(recharge: IRechargeInDTO, rejectedNotes: string) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.promoteRechargeAdmin(recharge.id,RechargeDecoder.RechargeStatusOptions.SCRATCHCARD_CREATED));
      let rejectedRecharge = RechargeDecoder.encodeRechargeInFromOut(recharge);
      rejectedRecharge.rejectedInvoiceNotes = rejectedNotes;
      await dispatch<any>(RechargesActions.editRechargeAdmin(recharge.id, rejectedRecharge, null));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('REJECT_INVOICED_RECHARGE_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_RECHARGE_REJECT_INVOICED`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.rejectRechargeError',
        }
      }));
    }
  }

  onScratchcardCreatedRechargeRequest(recharge: IRechargeInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`PROCESSING_RECHARGE_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'recharges.scratchcardCreatedRechargeConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onScratchcardCreatedRechargeConfirm(recharge),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`PROCESSING_RECHARGE_CONFIRM_MODAL`)),
      }
    }));
  }

  async onScratchcardCreatedRechargeConfirm(recharge: IRechargeInDTO) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.promoteRechargeAdmin(recharge.id,RechargeDecoder.RechargeStatusOptions.SCRATCHCARD_CREATED));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('PROCESSING_RECHARGE_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_RECHARGE_PROCESSING`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.scratchcardCreatedRechargeError',
        }
      }));
    }
  }

  onWalletRechargedRechargeRequest(recharge: IRechargeInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`COMPLETED_RECHARGE_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'recharges.walletRechargedRechargeConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onWalletRechargedRechargeConfirm(recharge),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`COMPLETED_RECHARGE_CONFIRM_MODAL`)),
      }
    }));
  }

  async onWalletRechargedRechargeConfirm(recharge: IRechargeInDTO) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.promoteRechargeAdmin(recharge.id,RechargeDecoder.RechargeStatusOptions.WALLET_RECHARGED));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('COMPLETED_RECHARGE_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_COMPLETED_RECHARGE_MODAL`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.walletRechargedRechargeError',
        }
      }));
    }
  }

  onEditStatusAdmin(recharge: IRechargeInDTO, newStatus: string) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_STATUS_ADMIN_RECHARGE_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'recharges.editStatusRechargeConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditStatusAdminConfirm(recharge,newStatus),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_STATUS_ADMIN_RECHARGE_CONFIRM_MODAL`)),
      }
    }));
  }

  async onEditStatusAdminConfirm(recharge: IRechargeInDTO, newStatus: string) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.promoteRechargeAdmin(recharge.id,newStatus));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('EDIT_STATUS_ADMIN_RECHARGE_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_RECHARGE_EDIT_STATUS_ADMIN`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.editStatusRechargeError',
        }
      }));
    }
  }

  async onOpenEditRechargeForm(rechargeToEdit: IRechargeInDTO) {
    const { dispatch } = this.props;
    const { customers, wallets } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.fetchRecharge(rechargeToEdit.id));
      const rechargeFormData = RechargeDecoder.decodeRechargeContentToForm((this.props.rechargeDetailed as IRechargeInDTO));
      dispatch<any>(ModalsActions.showModal(`EDIT_RECHARGE_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
              <RechargeForm
                recharge={rechargeFormData}
                onEditRecharge={(newRechargeData: IRechargeFormDTO) => this.onEditRechargeConfirm(rechargeToEdit.id, newRechargeData)}
                customers={customers}
                wallets={wallets}
                onOpenCustomerInfo={(customer: ICustomerInDTO) => this.onOpenCustomerInfo(customer)}
              />
          ),
          titleMessageKey: 'recharges.editRecharge',
          titleLinkText: <ReduxLanguage languageKey={"activations.getInfo"}/>,
          titleLinkUrl: "https://help-center.sofialocks.com/",
        }
      }));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }

  public async onEditRechargeConfirm(rechargeId: number, newRechargeData: IRechargeFormDTO) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(ModalsActions.hideModal(`EDIT_RECHARGE_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.editRecharge(rechargeId, null, newRechargeData));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
      dispatch<any>(ModalsActions.hideModal('EDIT_RECHARGE_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_RECHARGE_EDIT`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.editRechargeError',
        }
      }));
    }
  }

  onEditCustrefRequest(recharge: IRechargeInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_CUSTREF_CONFIRM_MODAL`, {
      modalType: ModalTypes.TEXTFIELD_MODAL,
      modalProps: {
        icon: null,
        titleMessageKey: "",
        successMessageKey: 'recharges.editCustrefConfirm',
        initialText: recharge?.walletRegistry?.custRefID || "",
        fieldLabel: null,
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: (text: string) => this.onEditCustrefConfirm(recharge,text),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_CUSTREF_CONFIRM_MODAL`)),
      }
    }));
  }

  async onEditCustrefConfirm(recharge: IRechargeInDTO, custRefId: string) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      let editedRecharge = RechargeDecoder.encodeRechargeInFromOut(recharge);
      editedRecharge.walletRegistry.custRefID = custRefId;
      await dispatch<any>(RechargesActions.editRechargeAdmin(recharge.id, editedRecharge, null));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('EDIT_CUSTREF_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_ACTIVATION_EDIT_CUSTREF`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.editCustrefError',
        }
      }));
    }
  }

  onTakeChargeRechargeRequest(recharge: IRechargeInDTO) {
    const { dispatch } = this.props;
    const options: IOption[] = [
      {
        id:"AUTO_RENEWABLE",
        value: translations.t('recharges.AUTO_RENEWABLE')
      },
      {
        id:"NOT_AUTO_RENEWABLE",
        value: translations.t('recharges.NOT_AUTO_RENEWABLE')
      }
    ]
    // TEMP: user does not specify the option (for now it's always "NOT_AUTO_RENEWABLE")
    if (true) {
      dispatch<any>(ModalsActions.showModal(`TAKECHARGE_RECHARGE_CONFIRM_MODAL`, {
        modalType: ModalTypes.CONFIRM_MODAL,
        modalProps: {
          icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
          titleMessageKey: 'forms.warning',
          successMessageKey: 'recharges.takechargeRechargeDirectConfirm',
          confirmMessageKey: 'forms.confirm',
          cancelMessageKey: 'forms.cancel',
          onConfirm: (text: string) => this.onTakeChargeRechargeConfirm(recharge,"NOT_AUTO_RENEWABLE"),
          onCancel: () => dispatch<any>(ModalsActions.hideModal(`TAKECHARGE_RECHARGE_CONFIRM_MODAL`)),
        }
      }));
    } else {
      dispatch<any>(ModalsActions.showModal(`TAKECHARGE_RECHARGE_CONFIRM_MODAL`, {
        modalType: ModalTypes.SELECT_MODAL,
        modalProps: {
          icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
          titleMessageKey: 'forms.warning',
          initialID: null,
          options: options,
          fieldLabel: null,
          placeHolder: null,
          successMessageKey: 'recharges.takechargeRechargeConfirm',
          confirmMessageKey: 'forms.confirm',
          cancelMessageKey: 'forms.cancel',
          onConfirm: (value: IOption|{}) => {if (!(value as IOption) || !(value as IOption).id) return; this.onTakeChargeRechargeConfirm(recharge,(value as IOption).id)},
          onCancel: () => dispatch<any>(ModalsActions.hideModal(`TAKECHARGE_RECHARGE_CONFIRM_MODAL`)),
        }
      }));
    }
    
  }

  async onTakeChargeRechargeConfirm(recharge: IRechargeInDTO, mode: string) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      let newRecharge = RechargeDecoder.encodeRechargeInFromOut(recharge);
      newRecharge.mode = mode;
      await dispatch<any>(RechargesActions.editRecharge(recharge.id, newRecharge, null));
      await dispatch<any>(RechargesActions.takeChargeRecharge(recharge.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('TAKECHARGE_RECHARGE_CONFIRM_MODAL'));
      dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_TAKECHARGE_RECHARGE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.takechargeRechargeError',
        }
      }));
    }
  }
  
  onCreateNewScratchcardRequest(recharge: IRechargeInDTO) {
    const { dispatch, wallets } = this.props;
    // Parso la scratchcard dal DTO
    let walletKam = "N/A"
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const walletId = recharge.walletId;
      const walletSearch = wallets.content.filter(e=>e.id===walletId)
      if (walletSearch.length)
        walletKam = walletSearch[0].contactEmail?walletSearch[0].contactEmail:walletKam
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCardError',
        }
      }));
    }
    const newCardData: ICardOutDTO = ({
      varId: recharge.walletRegistry.varId?recharge.walletRegistry.varId:null,
      quantity: recharge.creditsConfirmed,
      walletRechargeId: recharge.id,
      walletId: recharge.walletId,
      kamReference: walletKam,
      iseoSalesOrderReference: recharge.walletRegistry.salesOrderConfirmationNumber,
      iseoPurchaseOrderReference: recharge.walletRegistry.purchaseOrderNumberSofia,
      sofiaSalesOrderReference: "N/A",
      crmSource: "SFDC_ISEO",
      crmId: recharge.sfdcId,
      sofiaProjectId: recharge && recharge.walletRegistry && recharge.walletRegistry.custRefID ? recharge.walletRegistry.custRefID : "N/A",
    } as ICardOutDTO)
    dispatch<any>(ModalsActions.showModal(`CREATE_CARD_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newCard',
        successMessageKey: 'forms.createCardConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewScratchcardConfirm(newCardData, recharge),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_CARD_CONFIRM_MODAL`)),
      }
    }));
  }

  async onCreateNewScratchcardConfirm(card: ICardOutDTO, recharge: IRechargeInDTO) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(ModalsActions.hideModal('CREATE_CARD_CONFIRM_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const cardResult: ICustomerInDTO = await dispatch<any>(CreditsActions.createSystemCard(card));
      if (cardResult&&cardResult.id) {
        let editedRecharge = RechargeDecoder.encodeRechargeInFromOut(recharge);
        editedRecharge.walletRegistry.scratchcardId = cardResult.id;
        await dispatch<any>(RechargesActions.editRechargeAdmin(recharge.id, editedRecharge, null));
        dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
      }
      else {
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
        dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'errors.createCardError',
          }
        }));
      }
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCardError',
        }
      }));
    }
  }

  async onOpenNewRechargeForm() {
    const { dispatch } = this.props;
    const { customers, wallets, vars } = this.props;
    try {
      const varsData = (vars as PaginatedDataDTO<IVarInDTO>)
      dispatch<any>(ModalsActions.showModal(`CREATE_RECHARGE_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
        modalProps: {
          content: (
              <RechargeNewForm
                onCreateNewRecharge={(wallet: IWalletInDTO, VAR: IVarInDTO|undefined) => this.onCreateNewRechargeConfirm(wallet,VAR)}
                customers={customers}
                wallets={wallets}
                vars={varsData}
                onOpenCustomerInfo={(customer: ICustomerInDTO) => this.onOpenCustomerInfo(customer)}
              />
          ),
          titleMessageKey: 'recharges.newRecharge',
          titleLinkText: <ReduxLanguage languageKey={"activations.getInfo"}/>,
          titleLinkUrl: "https://help-center.sofialocks.com/",
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }

  public async onCreateNewRechargeConfirm(wallet: IWalletInDTO, VAR: IVarInDTO|undefined) {
    const { dispatch, recharges } = this.props;
    const { pageSize } = this.state;
    const pagination = recharges.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(RechargesActions.createNewRecharge(wallet,VAR));
      await dispatch<any>(RechargesActions.fetchRecharges({ page: pagination.number, pageSize, ...this.state.searchValuesCurrent }));
      dispatch<any>(ModalsActions.hideModal('CREATE_RECHARGE_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_RECHARGE_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'recharges.newRechargeError',
        }
      }));
    }
  }

  public onOpenVarDetails(varId: number) {
    const { history } = this.props
    history.push(`/vars?varId=${varId}`);
  } 

  public async onOpenCustomerInfo(customer: ICustomerInDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      dispatch<any>(CreditsActions.setSelecteCustomer(customer));
      await dispatch<any>(CreditsActions.fetchCustomerWallets({pageSize: 200, customerId: customer.id }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CUSTOMER_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(CreditsActions.setSelecteCustomer({})),
          content: (
            <CustomerDetailsView
              onEditCustomerData={() => {}}
              hideEditCustomer={true}
              hideDeleteCustomer={true}
              onVarClick={varId => {
                dispatch<any>(ModalsActions.hideModal(`OPERATIONAL_CUSTOMER_MODAL`));
                this.onOpenVarDetails(varId);
              }}
            />
          ),
          titleMessageKey: 'credits.customerDetails',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getCustomerDetailsError',
        }
      }));
    }
  }

  exportRechargesCSV() {
    const { dispatch } = this.props;
    dispatch<any>(RechargesActions.exportRechargesCSV());
  }

  render() {
    const { recharges, classes, customers, wallets } = this.props;
    const { pageSize } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <RechagesSearchBar
            onCreateNewEntityButtonClick={() => this.onOpenNewRechargeForm()}
            onSearch={(searchValues: IRechargesSearchDTO) => this.onSearchRecharges(searchValues)}
            onResetSearch={() => this.onResetSearch()}
            onExportCSV={() => this.exportRechargesCSV()}
          />
        </div>
        <div className={classes.tableContainer}>
        <RechargesTable
          rowsPerPage={pageSize}
          recharges={recharges}
          customers={customers}
          isLoading={this.state.isLoading}
          wallets={wallets}
          onNewRecharge={() => this.onOpenNewRechargeForm()}
          onEditRecharge={(recharge) => this.onOpenEditRechargeForm(recharge)}
          onDeleteRechargeRequest={recharge => this.onDeleteRechargeRequest(recharge)}
          onDeleteRechargeAdminRequest={recharge => this.onDeleteRechargeAdminRequest(recharge)}
          onPromoteRechargeRequest={recharge => this.onPromoteRechargeRequest(recharge)}
          onRejectRechargeRequest={recharge => this.onRejectRechargeRequest(recharge)}
          onScratchcardCreatedRechargeRequest={recharge => this.onScratchcardCreatedRechargeRequest(recharge)}
          onInvoicedRechargeRequest={recharge => this.onInvoicedRechargeRequest(recharge)}
          onEditStatusAdmin={(recharge,newStatus) => this.onEditStatusAdmin(recharge,newStatus)}
          onTakeChargeRechargeRequest={(recharge) => this.onTakeChargeRechargeRequest(recharge)}
          onOpenCustomerInfo={(customer: ICustomerInDTO) => this.onOpenCustomerInfo(customer)}
          onEditCustrefRequest={recharge => this.onEditCustrefRequest(recharge)}
          onRejectInvoicedRechargeRequest={recharge => this.onRejectInvoicedRechargeRequest(recharge)}
          onWalletRechargedRechargeRequest={recharge => this.onWalletRechargedRechargeRequest(recharge)}
          onCreateNewScratchcardRequest={recharge => this.onCreateNewScratchcardRequest(recharge)}
          onFetchItemsOnPage={page => this.onFetchItemsOnPage(page)}
          onPageSizeChange={pageSize => this.onPageSizeChange(pageSize)}
        />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    recharges: state.recharges.recharges.data,
    rechargeDetailed: state.recharges.rechargeDetailed.data,
    customers: state.recharges.customers.data,
    wallets: state.recharges.wallets.data,
    vars: state.vars.vars.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(WalletRechargesPage as any));