import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import MomentUtils from '@date-io/moment';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CreatableSelect from 'react-select/creatable';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { Delete } from "@material-ui/icons";
import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RedoIcon from '@material-ui/icons/Redo';
import WarningIcon from '@material-ui/icons/Warning';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CircularProgress from '@mui/material/CircularProgress';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import moment from 'moment';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import Select from 'react-select';
import { ActivationFileAcceptedExtensions, ActivationInvoiceFileName, ActivationQuotationToolFileName, ActivationstatusOptions } from '../../codec/activationInDTODecoder';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import * as ActivationsActions from '../../redux/activations/activations.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import { IActivationAddonDTO } from '../../repository/jago/model/input/IActivationAddonDTO';
import { IActivationNewFormDTO } from '../../repository/jago/model/input/IActivationNewFormDTO';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { IUserDecodedJWT } from '../../repository/jago/model/input/IUserDecodedJWT';
import { ICustomerRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import { LocalStorageRepository } from '../../services/StorageService/LocalStorageRepository';
import Utils, { ActivationBaselineSubscription, CountryInfo, ISEOCompanyInfo, VAR_USERS_ROLES } from '../../utils/Utils';
import ReduxLanguage from "../ReduxLanguage";
import translations from '../../translations/i18next';
import { IKamInDTO } from '../../repository/jago/model/input/IKamInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IActivationNewFormState {
  isCustomer: boolean;
  activation: IActivationNewFormDTO;
  initCustomer: boolean;
  customerSectionCompleted: boolean;
  customerSearchInput: string;
  varSearchInput: string;
  customerSelected: ICustomerInDTO;
  varSelected: IVarInDTO;
  customersLoading: boolean;
  varsLoading: boolean;
  ISEOCompanies: ISEOCompanyInfo[];
  countries: CountryInfo[];
  baselineSubscriptions: ActivationBaselineSubscription[];
  errors: Record<keyof IActivationNewFormDTO, boolean>;
  systemManagerEmails_local: string[];
  submitterEmails_local: string[];
  baselineSubscription_selected: ISubscriptionInDTO;
  available_addons: ISubscriptionInDTO[];
  show_addon_V364: boolean;
  available_addon_V364: ISubscriptionInDTO;
  selected_addons: ISubscriptionInDTO[];
  numberOfElements_input : number;
  selectedFileName: string;
};

export interface IActivationNewFormProps extends WithStyles<typeof styles>{
  onCreateEditActivation: (activation: IActivationNewFormDTO) => void;
  defaultMarketplace: IMarketplaceInDTO;
  activation?: IActivationNewFormDTO;
  locked: boolean;
  forcedVar?: IVarInDTO;
}

export type ComponentProps = IActivationNewFormProps & IReduxProps;
class ActivationNewForm extends React.Component<ComponentProps, IActivationNewFormState> {
  meEmail: string = null;
  inputFileRef = null;
  inputFileRefQuotationTool = null;
  inputFileRefInvoice = null;

  constructor(props: ComponentProps) {
    super(props);
    this.inputFileRef = React.createRef();
    this.inputFileRefQuotationTool = React.createRef();
    this.inputFileRefInvoice = React.createRef();
    
    // Owner email init
    if (props.userData&&('user_name' in props.userData)&&(typeof props.userData.user_name === 'string') && props.userData.user_name.length>0) {
      this.meEmail = props.userData.user_name;
    }
    else {
      console.error("NO user_name found in UserData!")
      console.error(props.userData)
    }

    this.state = {
      isCustomer: (props.activation&&props.activation.varId)||props.forcedVar?false:true,
      ISEOCompanies: Utils.getAvailableISEOCompanies(),
      countries: Utils.getAvailableCountries(),
      baselineSubscriptions: Utils.activationBaselineSubscriptions,
      baselineSubscription_selected: null,
      available_addons: [],
      show_addon_V364: false,
      available_addon_V364: null,
      selected_addons: [],
      systemManagerEmails_local: props.activation&&props.activation.systemManagerEmails&&props.activation.systemManagerEmails.length>0?
        props.activation.systemManagerEmails:[""],
      submitterEmails_local: props.activation&&props.activation.submitterEmails&&props.activation.submitterEmails.length>0?
        props.activation.submitterEmails.filter(
          e=>e!==props.activation.ownerEmail&&
          (!props.activation.kam||!props.activation.kam.email||e!==props.activation.kam.email)&&
          e!==props.activation.pmEmail&&
          e!==props.activation.systemEngineerEmail
        ):[],
      numberOfElements_input: null,
      selectedFileName: null,
      initCustomer: !Boolean(props.activation)&&!props.forcedVar,
      customerSectionCompleted: Boolean(props.activation)||Boolean(props.forcedVar),
      customerSearchInput: "",
      varSearchInput: "",
      customerSelected: null,
      varSelected: props.forcedVar?props.forcedVar:null,
      customersLoading: false,
      varsLoading: false,
      activation: props.activation ? props.activation : {
        status: null,
        cluster: null,
        marketplace: props.defaultMarketplace?props.defaultMarketplace:null,
        walletId: null,
        scratchcardId: null,
        varId: props.forcedVar?props.forcedVar.id:null,
        varName: props.forcedVar?props.forcedVar.name:"",
        varSfdcId: props.forcedVar?props.forcedVar.sfcdId:"",
        customerId: null,
        customerName: "",
        customerCountry: "",
        customerVAT: "",
        customerAddress: "",
        customerFiscalCode: "",
        customerCity: "",
        customerEmail: "",
        customerPostalCode: "",
        customerPEC: "",
        customerProvince: "",
        customerPhone: "",
        iseoCompanyName: "",
        CRMID: "",
        custRefID: "",
        optId: "",
        salesOrderConfirmationNumber: "",
        purchaseOrderNumberSofia: "",
        baselineSubscription: "",
        baselineSubscriptionID: null,
        plantName: props.forcedVar?"__VAR__":"",
        kam: null,
        systemManagerEmails: [],
        installerEmail: "",
        pmEmail: "",
        systemEngineerEmail: "",
        numberOfElements: null,
        V364orF9000: null,
        notes: "",
        submitterEmails: [],
        ownerEmail: this.meEmail,
        rejectedNotes: "",
        addons: [],
        credits: null,
        expectedCreditsEndDays: null,
        invoiceNumber: "",
        documents: [],
        documentsOUT: {},
        clusterIdForVar: props.forcedVar?props.forcedVar.clusterId:null,
      },
      errors: {
        status: false,
        cluster: false,
        marketplace: false,
        walletId: false,
        scratchcardId: false,
        varId: false,
        varName: false,
        varSfdcId: false,
        customerId: false,
        customerName: false,
        customerCountry: false,
        customerVAT: false,
        customerAddress: false,
        customerFiscalCode: false,
        customerCity: false,
        customerEmail: false,
        customerPostalCode: false,
        customerPEC: false,
        customerProvince: false,
        customerPhone: false,
        iseoCompanyName: false,
        CRMID: false,
        custRefID: false,
        optId: false,
        salesOrderConfirmationNumber: false,
        purchaseOrderNumberSofia: false,
        baselineSubscription: false,
        baselineSubscriptionID: false,
        plantName: false,
        kam: false,
        systemManagerEmails: false,
        installerEmail: false,
        pmEmail: false,
        systemEngineerEmail: false,
        numberOfElements: null,
        V364orF9000: null,
        notes: false,
        submitterEmails: false,
        ownerEmail: false,
        rejectedNotes: false,
        addons: false,
        credits: false,
        expectedCreditsEndDays: false,
        invoiceNumber: false,
        documents: false,
        documentsOUT: false,
        clusterIdForVar: false,
      },
    };
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    // Init per caso "edit"
    if (this.props.activation) {
      // Init numberOfElements_input
      this.setState({ numberOfElements_input:this.state.activation.numberOfElements===null?null:this.state.activation.numberOfElements })
      // Init baselineSubscription_selected
      this.update_baselineSubscription_selected(this.state.activation.baselineSubscription,this.state.activation.numberOfElements,true)
    }
    dispatch<any>(SettingsActions.setSpinnerVisible(false));
  }

  computeExpectedCreditsEnd(creditsIn: number|undefined, baselineSubscription_selectedIn: ISubscriptionInDTO|undefined, selected_addonsIn: ISubscriptionInDTO[]|undefined) {
    // Se arg===undefined --> prendo valore dallo state
    const credits = creditsIn!==undefined?creditsIn:this.state.activation.credits;
    const baselineSubscription_selected = baselineSubscription_selectedIn!==undefined?baselineSubscription_selectedIn:this.state.baselineSubscription_selected;
    const selected_addons = selected_addonsIn!==undefined?selected_addonsIn:this.state.selected_addons;
    if (!baselineSubscription_selected) return;
    let expectedCreditsEndDays = 0;
    if (credits&&credits>0) {
      let burnRateSum = 0;
      if (baselineSubscription_selected&&baselineSubscription_selected.burnRate)
        burnRateSum += baselineSubscription_selected.burnRate
      selected_addons.forEach((addon)=>{
        if(addon&&addon.burnRate)
          burnRateSum += addon.burnRate
      })
      expectedCreditsEndDays = burnRateSum > 0 ? credits / (burnRateSum / 100) : 0;
    }
    return Math.floor(expectedCreditsEndDays);
  }

  async onChangeIsCustomer(value?: any) {
    const { activation, errors, selected_addons, available_addon_V364 } = this.state;
    let isCustomer_new = value==="T"
    this.setState({ isCustomer: isCustomer_new })
  }

  async onChangeValue(field: keyof IActivationNewFormDTO, mandatory: boolean, value?: any) {
    const { activation, errors, selected_addons, available_addon_V364 } = this.state;
    // Custom errors on specific fields
    let customError = false;
    let customErrorOptionalField = false;
    let customValue = undefined;
    let newCreditsValue = undefined;
    let newSelectedAddons = undefined;
    if (field==="V364orF9000") {
      if (value!=="T"&&value!=="F") customError = true;
      customValue = value==="T"?true:value==="F"?false:null;
      // Gestione manuale dell'add-on relativo a V364 (aggiunta/rimozione in base alla scelta dell'utente)
      if (customValue && available_addon_V364!=null && selected_addons.filter(e=>e.id===available_addon_V364.id).length===0) {
        // Lo aggiungo
        newSelectedAddons = [available_addon_V364, ...selected_addons]
        this.setState({ selected_addons:newSelectedAddons })
      }
      if (!customValue && available_addon_V364!=null && selected_addons.filter(e=>e.id===available_addon_V364.id).length>0) {
        // Lo rimuovo
        newSelectedAddons = selected_addons.filter(e=>e.id!==available_addon_V364.id)
        this.setState({ selected_addons:newSelectedAddons })
      }
    }
    if (field==="numberOfElements") {
      if (value===null||value<=0||(activation.baselineSubscription==="BASIC"&&value>600)) customError = true;
    }
    if (field==="credits") {
      if (value===null||value<=0) customError = true;
      newCreditsValue = value;
    }
    if (field==="plantName") {
      let pattern = /^[a-z0-9\-]+$/;
      if (!value||!pattern.test(value)) customError = true;
    }
    if (field==="pmEmail") {
      if (value && !value.endsWith("@sofialocks.com") && !value.endsWith("@iseo.com")) customErrorOptionalField = true;
    }
    if (field==="systemEngineerEmail") {
      if (value && !value.endsWith("@sofialocks.com") && !value.endsWith("@iseo.com")) customErrorOptionalField = true;
    }
    this.setState({
      errors: { ...errors, [field]: customErrorOptionalField || ((!value||customError) && mandatory) ? true : false }
    });
    (activation as any)[field] = customValue===undefined?value:customValue;
    this.setState({ activation });
    // Se han cambiato la baseline subscription o il numero di elementi, devo pescare la subscription relativa
    if (field==="baselineSubscription"||field==="numberOfElements")
      await this.update_baselineSubscription_selected(field==="baselineSubscription"?value:null,field==="numberOfElements"?value:null,false)
    // Se han cambiato la baseline subscription, ricontrollo il numero di elementi
    if (field==="baselineSubscription") {
      if (value==="BASIC")
        this.setState({errors: { ...errors, "numberOfElements": (activation.numberOfElements>100||activation.numberOfElements<0) }});
      else
        this.setState({errors: { ...errors, "numberOfElements": (activation.numberOfElements<0) }});
    }
    // Devo anche calcolare la data stimata di esaurimento crediti
    let expectedCreditsEndDays = this.computeExpectedCreditsEnd(newCreditsValue?newCreditsValue:undefined,undefined,newSelectedAddons?newSelectedAddons:undefined);
    (activation as any)["expectedCreditsEndDays"] = expectedCreditsEndDays;
    this.setState({ activation });
  }

  async update_baselineSubscription_selected(baselineSubscription_new: string, numberOfElements_new: number, isInit: boolean) {
    const { activation } = this.state;
    const { dispatch } = this.props;
    // Prendo la baselineSubscription (string "BASIC"||"ENTERPRISE"||"ENGINE"||una delle hidden...) && il numero di elementi
    let baselineSubscription = baselineSubscription_new?baselineSubscription_new:activation.baselineSubscription;
    let numberOfElements = numberOfElements_new?numberOfElements_new:activation.numberOfElements;
    // Cerco la subscription corretta nel DB e la setto
    let foundSubscription: ISubscriptionInDTO = null;
    if (baselineSubscription&&numberOfElements) {
      let baselineSubscriptions: ISubscriptionInDTO[] = Utils.getBaselineSubscriptionsFromName(
        (this.props.marketplaceSubscriptions.data as PaginatedDataDTO<ISubscriptionInDTO>).content,baselineSubscription
      )
      for (let i=0; i<baselineSubscriptions.length; i++) {
        if (baselineSubscriptions[i].maxElements>=numberOfElements&&(foundSubscription==null||baselineSubscriptions[i].maxElements<foundSubscription.maxElements))
          foundSubscription = baselineSubscriptions[i]
      }
    }
    this.setState({ baselineSubscription_selected: foundSubscription });
    (activation as any)["baselineSubscriptionID"] = foundSubscription?.id?foundSubscription.id:null;
    this.setState({ activation });
    // Proseguo solo se ho trovato la subscription, cercando gli add-ons
    if (foundSubscription&&foundSubscription.id) {
      if (!this.props.defaultMarketplace) return;
      try {
        dispatch<any>(SettingsActions.setSpinnerVisible(true));
        await dispatch<any>(ActivationsActions.ActivationsActions.setSelectedMarketplaceMainTier(foundSubscription));
        await dispatch<any>(ActivationsActions.fetchMarketplaceMainTierSubscriptions(this.props.defaultMarketplace.id, foundSubscription.id, { page: 0, pageSize: 50 }));
        // Setto la lista di addons selezionabili
        const { marketplaceSubscriptions } = this.props;
        const subscriptionAddons: ISubscriptionInDTO[] = 
          (marketplaceSubscriptions.selectedMainTierSubcriptions as PaginatedDataDTO<ISubscriptionInDTO>).content;
        const unboundedAddons: ISubscriptionInDTO[] = 
          (marketplaceSubscriptions.data as PaginatedDataDTO<ISubscriptionInDTO>).content.filter(e=>Utils.isUnboundedAddon(e));
        let available_addons = [...subscriptionAddons,...unboundedAddons].filter(e=>!_.includes(e.sku, '004S228') && !_.includes(e.sku, '006S001'))
        if (baselineSubscription === "BASIC") {
          // TODO FILTRARE ADDONS E PLUGINS SE BASIC
          available_addons = available_addons.filter(e=>true)
        }
        this.setState({ available_addons });
        // Setto se devo richiedere all'utente l'add-on V364 o meno e, in tal caso, lo trovo
        let show_addon_V364 = baselineSubscription!=="BASIC";
        this.setState({ show_addon_V364 });
        if (show_addon_V364) {
          let tempSearchV364 = [...subscriptionAddons,...unboundedAddons].filter(e=>_.includes(e.sku, '004S228') || _.includes(e.sku, '006S001'))
          if (tempSearchV364.length>0) {
            this.setState({ available_addon_V364: tempSearchV364[0]});
          }
          else {
            this.setState({ available_addon_V364: null});
            // Non ho trovato l'add-on per V364 ma doveva esserci, avviso l'utente!
            dispatch<any>(SettingsActions.setSpinnerVisible(false));
            dispatch<any>(ModalsActions.showModal(`ERROR_ADDON_V364_MISSING_CONFIRM_MODAL`, {
              modalType: ModalTypes.ERROR_MODAL,
              modalProps: {
                titleMessageKey: 'errors.error',
                errorMessageKey: 'errors.addonV364missing',
              }
            }));
          }
        }
        else 
          this.setState({ available_addon_V364: null});
        
        // Se è una init perché sto modificando una activation esistente e ho trovato la baselineSubscription che era stata salvata, 
        // setto gli addons del backend, altrimenti resetto gli addons
        if (isInit&&this.props.activation.baselineSubscriptionID===foundSubscription.id) {
          let addons: ISubscriptionInDTO[] = [];
          this.props.activation.addons.forEach((item,i)=>{
            let tempSearch1: ISubscriptionInDTO[] = subscriptionAddons.filter(e=>e.id===item.id)
            if (tempSearch1.length>0) 
              addons.push(tempSearch1[0])
            let tempSearch2: ISubscriptionInDTO[] = unboundedAddons.filter(e=>e.id===item.id)
            if (tempSearch2.length>0) 
              addons.push(tempSearch2[0])
          });
          this.setState({selected_addons:addons})
        }
        else
          this.setState({selected_addons:[]})
        // Fine
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
      } 
      catch (error) {
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
        dispatch<any>(ModalsActions.showModal(`ERROR_GET_ADDONS_CONFIRM_MODAL`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'errors.openSystemParametersError',
          }
        }));
      }
    }
  }

  onEditBaselineSubscriptionRequest(newValue: string) {
    const { dispatch } = this.props;
    // Prima di tutto verifico che sia un valore vuoto oppure accettato (tra quelli hidden e non)
    if (newValue&&!Utils.activationBaselineSubscriptions.some(e=>e.name===newValue)) {
      dispatch<any>(ModalsActions.showModal(`ERROR_NO_SUBSCRIPTION_FOUND`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.subscriptionNotFoundError',
        }
      }));
      return;
    }

    // Check se sto modificando la subscription, se no procedo senza conferma
    if (this.state.baselineSubscription_selected==null) {
      this.onEditBaselineSubscriptionConfirm(newValue);
      return;
    }
    
    dispatch<any>(ModalsActions.showModal(`EDIT_BASELINESUBSCRIPTION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'activations.editBaselineSubscriptionWarning',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditBaselineSubscriptionConfirm(newValue),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_BASELINESUBSCRIPTION_CONFIRM_MODAL`)),
      }
    }));
  }

  async onEditBaselineSubscriptionConfirm(newValue: string) {
    const { dispatch } = this.props;
    // Resetto il flag V364
    const { activation } = this.state;
    (activation as any)["V364orF9000"] = null;
    this.setState({ activation });
    // Procedo con la modifica, che andrà a modificare anche la baselineSubscription
    try {
      dispatch<any>(ModalsActions.hideModal('EDIT_BASELINESUBSCRIPTION_CONFIRM_MODAL'));
      this.onChangeValue('baselineSubscription', true, newValue)
    } catch (error) {
      dispatch<any>(ModalsActions.showModal(`ERROR_EDIT_BASELINESUBSCRIPTION_CONFIRM_MODAL`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.editBaselineSubscriptionError',
        }
      }));
    }
  }

  onEditNumberOfElementsRequest(newValue: number) {
    // Check se sto modificando la subscription, se no procedo senza conferma
    if (this.state.baselineSubscription_selected==null) {
      this.onEditNumberOfElementsConfirm(newValue);
      return;
    }
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_NUMBEROFELEMENTS_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'activations.editNumberOfElementsWarning',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditNumberOfElementsConfirm(newValue),
        onCancel: () => {
          this.setState({ numberOfElements_input:this.state.activation.numberOfElements===null?null:this.state.activation.numberOfElements})
          dispatch<any>(ModalsActions.hideModal(`EDIT_NUMBEROFELEMENTS_CONFIRM_MODAL`))
        },
      }
    }));
  }

  async onEditNumberOfElementsConfirm(newValue: number) {
    const { dispatch } = this.props;
    // Resetto il flag V364
    const { activation } = this.state;
    (activation as any)["V364orF9000"] = null;
    this.setState({ activation });
    // Procedo con la modifica, che andrà a modificare anche la baselineSubscription
    try {
      dispatch<any>(ModalsActions.hideModal('EDIT_NUMBEROFELEMENTS_CONFIRM_MODAL'));
      this.onChangeValue('numberOfElements', true, newValue)
    } catch (error) {
      dispatch<any>(ModalsActions.showModal(`ERROR_EDIT_NUMBEROFELEMENTS_CONFIRM_MODAL`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.editNumberOfElementsError',
        }
      }));
    }
  }

  toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Rimuovo la prima parte, i.e. "data:application/pdf;base64,"
      let resultAsString = (reader.result as string);
      let resultSplitted = resultAsString.split(',');
      if (resultSplitted.length>1)
        resolve(resultSplitted[1])
    };
    reader.onerror = reject;
  });

  async onNewInputFile(newFile: File) {
    const { dispatch } = this.props;
    var extensionOK = false;
    var fileName: string = "";
    var fileExtension: string = "";
    var fileExtSearch: number = newFile&&newFile.name?newFile.name.lastIndexOf("."):-1
    if (fileExtSearch>0&&fileExtSearch<newFile.name.length) {
      fileName = newFile.name.substring(0,fileExtSearch);
      fileExtension = newFile.name.substring(fileExtSearch+1);
      extensionOK = ActivationFileAcceptedExtensions.some(e=>e.toUpperCase()===fileExtension.toUpperCase())
    }
    if (!extensionOK) {
      dispatch<any>(ModalsActions.showModal(`NEWFILE_EXTENSION_ERROR_MODAL`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.newFileExtensionError',
        }
      }));
      return;
    }
    dispatch<any>(ModalsActions.showModal(`NEWFILE_NAME_MODAL`, {
      modalType: ModalTypes.TEXTFIELD_MODAL,
      modalProps: {
        icon: null,
        titleMessageKey: "",
        successMessageKey: 'activations.newFileName',
        initialText: fileName?fileName:"",
        fieldLabel: "",
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: (text: string) => this.onNewInputFileConfirmed(newFile,text,fileExtension),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`NEWFILE_NAME_MODAL`)),
      }
    }));
  }

  async onNewInputFileConfirmed(newFile: File,newName: string,newExtension: string) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.hideModal(`NEWFILE_NAME_MODAL`))
    const { selectedFileName, activation } = this.state;
    let encodedFile = null;
    try {encodedFile = await this.toBase64(newFile)} catch(e) {return}
    if (encodedFile===null) return;
    if (selectedFileName!==null) {
      activation.documents = this.state.activation.documents.map((item,i) => {
        if (selectedFileName===item.name) return {name:item.name,contentBase64:encodedFile};
        else return item;
      });
    }
    else {
      if (newName!==null&&newExtension!==null) {
        // Se per qualche motivo arcano mi han messo un fileName che include ActivationQuotationToolFileName, rimuovo gli underscore e pippa
        if(newName.includes(ActivationQuotationToolFileName))
          newName = newName.replaceAll("__","")
        // Se per qualche motivo arcano mi han messo un fileName che include ActivationInvoiceFileName, rimuovo gli underscore e pippa
        if(newName.includes(ActivationInvoiceFileName))
          newName = newName.replaceAll("__","")
        // Controllo che il nome dato non sia già presente, altrimenti puppa
        let tempSearch = activation.documents.filter(e=>e.name===(newName+"."+newExtension))
        if (tempSearch.length>0) {
          dispatch<any>(ModalsActions.showModal(`NEWFILE_DUPLICATE_NAME_ERROR_MODAL`, {
            modalType: ModalTypes.ERROR_MODAL,
            modalProps: {
              titleMessageKey: 'errors.error',
              errorMessageKey: 'activations.newFileDuplicateNameError',
            }
          }));
          return;
        }
        activation.documents = [...activation.documents, {name:(newName+"."+newExtension),contentBase64:encodedFile}]
      }
    }
    this.setState({ activation })
  }

  async onNewQuotationToolFileConfirmed(newFile: File) {
    const { dispatch } = this.props;
    const { activation } = this.state;
    let encodedFile = null;
    try {encodedFile = await this.toBase64(newFile)} catch(e) {return}
    if (encodedFile===null) return;
    // Se esiste già un file ActivationQuotationToolFileName, sostituisco quello, altrimenti creo nuovo
    let tempSearch = activation.documents.filter(e=>e.name.includes(ActivationQuotationToolFileName))
    if (tempSearch.length>0) {
      activation.documents = this.state.activation.documents.map((item,i) => {
        if (item.name.includes(ActivationQuotationToolFileName)) return {name:item.name,contentBase64:encodedFile};
        else return item;
      });
    }
    else {
      // Piglio l'estensione del file (il filename lo piazzo io)
      var fileExtension: string = "";
      var fileExtSearch: number = newFile&&newFile.name?newFile.name.lastIndexOf("."):-1
      if (fileExtSearch>0&&fileExtSearch<newFile.name.length)
        fileExtension = newFile.name.substring(fileExtSearch+1);
      else {
        dispatch<any>(ModalsActions.showModal(`NEWFILE_EXTENSION_ERROR_MODAL`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'activations.newFileExtensionError',
          }
        }));
        return;
      }
      activation.documents = [...activation.documents, {name:(ActivationQuotationToolFileName+"."+fileExtension),contentBase64:encodedFile}]
    }
    this.setState({ activation })
  }

  async onNewInvoiceFileConfirmed(newFile: File) {
    const { dispatch } = this.props;
    const { activation } = this.state;
    let encodedFile = null;
    try {encodedFile = await this.toBase64(newFile)} catch(e) {return}
    if (encodedFile===null) return;
    // Se esiste già un file ActivationInvoiceFileName, sostituisco quello, altrimenti creo nuovo
    let tempSearch = activation.documents.filter(e=>e.name.includes(ActivationInvoiceFileName))
    if (tempSearch.length>0) {
      activation.documents = this.state.activation.documents.map((item,i) => {
        if (item.name.includes(ActivationInvoiceFileName)) return {name:item.name,contentBase64:encodedFile};
        else return item;
      });
    }
    else {
      // Piglio l'estensione del file (il filename lo piazzo io)
      var fileExtension: string = "";
      var fileExtSearch: number = newFile&&newFile.name?newFile.name.lastIndexOf("."):-1
      if (fileExtSearch>0&&fileExtSearch<newFile.name.length)
        fileExtension = newFile.name.substring(fileExtSearch+1);
      else {
        dispatch<any>(ModalsActions.showModal(`NEWFILE_EXTENSION_ERROR_MODAL`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'activations.newFileExtensionError',
          }
        }));
        return;
      }
      activation.documents = [...activation.documents, {name:(ActivationInvoiceFileName+"."+fileExtension),contentBase64:encodedFile}]
    }
    this.setState({ activation })
  }

  async onCustomerSearchInputChanged(newInputValue: string) {
    const { dispatch } = this.props;
    this.setState({ customerSearchInput: newInputValue })
    if (!newInputValue) {
      await dispatch<any>(ActivationsActions.resetCustomersSearch());
      this.setState({ customersLoading: false })
      return;
    }
    this.setState({ customersLoading: true })
    const params: ICustomerRequestParamsDTO = { name: newInputValue };
    await dispatch<any>(ActivationsActions.fetchCustomersSearch(params));
    this.setState({ customersLoading: false })
  }

  async onVarSearchInputChanged(newInputValue: string) {
    const { dispatch } = this.props;
    this.setState({ varSearchInput: newInputValue })
    if (!newInputValue) {
      await dispatch<any>(ActivationsActions.resetVarsSearch());
      this.setState({ varsLoading: false })
      return;
    }
    this.setState({ varsLoading: true })
    const params: ICustomerRequestParamsDTO = { name: newInputValue };
    await dispatch<any>(ActivationsActions.fetchVarsSearch(params));
    this.setState({ varsLoading: false })
  }

  customerInitDone() {
    const { customerSelected, activation } = this.state;
    const { marketplaces, clusters, defaultMarketplace } = this.props;
    const { dispatch } = this.props;
    if (customerSelected) {
      // Controllo che il customer abbia settato il marketplace && il cluster, se no avviso
      if (!customerSelected.marketplace.id) {
        dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_MARKETPLACE_MISSING`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'activations.customerMarketplaceError',
          }
        }));
        return;
      }
      const marketplacesData = (marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content;
      let foundMarketplace = marketplacesData.filter(e=>e.id===customerSelected.marketplace.id).length>0?
        marketplacesData.filter(e=>e.id===customerSelected.marketplace.id)[0]:undefined
      if (!foundMarketplace) {
        dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_MARKETPLACE_MISSING`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'activations.customerMarketplaceError',
          }
        }));
        return;
      }
      if (!customerSelected.cluster.id) {
        dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_CLUSTER_MISSING`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'activations.customerClusterError',
          }
        }));
        return;
      }
      const clustersData = (clusters as PaginatedDataDTO<IClusterInDTO>).content;
      let foundCluster = clustersData.filter(e=>e.id===customerSelected.cluster.id).length>0?
        clustersData.filter(e=>e.id===customerSelected.cluster.id)[0]:undefined
      if (!foundCluster) {
        dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_MARKETPLACE_MISSING`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'activations.customerClusterError',
          }
        }));
        return;
      }
      activation.customerId = customerSelected.id;
      activation.customerName = customerSelected.name;
      activation.customerVAT = customerSelected.vat;
      activation.customerFiscalCode = customerSelected.fiscalCode?customerSelected.fiscalCode:"";
      activation.customerEmail = customerSelected.email;
      activation.customerPEC = customerSelected.certifiedEmail;
      activation.customerPhone = customerSelected.phone;
      activation.customerCountry = customerSelected.country;
      activation.customerAddress = customerSelected.address;
      activation.customerCity = customerSelected.city;
      activation.customerPostalCode = customerSelected.postalCode;
      activation.customerProvince = customerSelected.principalSubDivision;
      activation.CRMID = customerSelected.crmId;
      activation.cluster = foundCluster;
      activation.marketplace = foundMarketplace;
      activation.iseoCompanyName = customerSelected.company;
      this.setState({ activation });
      this.setState({ customerSectionCompleted: true })
      // Se sto customer non è associato al defaultMarketplace --> avviso utente che dovrà specificare a mano alcune cose
      if (foundMarketplace.name!==defaultMarketplace.name) {
        dispatch<any>(ModalsActions.showModal(`WARNING_MARKETPLACE_CUSTOM_MODAL`, {
          modalType: ModalTypes.CONFIRM_MODAL,
          modalProps: {
            icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
            titleMessageKey: 'forms.warning',
            successMessageKey: 'activations.customMarketplaceWarning',
            onCancel: () => dispatch<any>(ModalsActions.hideModal(`WARNING_MARKETPLACE_CUSTOM_MODAL`)),
          }
        }));
      }
    }
    this.setState({ initCustomer: false })
  }

  varInitDone() {
    const { varSelected, activation } = this.state;
    const { marketplaces, clusters, defaultMarketplace } = this.props;
    const { dispatch } = this.props;
    if (varSelected) {
      // Controllo che il customer abbia settato il cluster, se no avviso
      if (!varSelected.clusterId) {
        dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_CLUSTER_MISSING`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'activations.customerClusterError',
          }
        }));
        return;
      }
      const clustersData = (clusters as PaginatedDataDTO<IClusterInDTO>).content;
      let foundCluster = clustersData.filter(e=>e.id===varSelected.clusterId).length>0?
        clustersData.filter(e=>e.id===varSelected.clusterId)[0]:undefined
      if (!foundCluster) {
        dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_MARKETPLACE_MISSING`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'activations.customerClusterError',
          }
        }));
        return;
      }
      activation.varId = varSelected.id
      activation.varName = varSelected.name
      activation.varSfdcId = varSelected.sfcdId
      activation.cluster = foundCluster;
      activation.marketplace = defaultMarketplace;
      activation.plantName = "__VAR__"
      this.setState({ activation });
      this.setState({ customerSectionCompleted: true })
    }
    this.setState({ initCustomer: false })
  }

  customerDataIsOk() {
    const { activation } = this.state;
    return activation.customerName && activation.customerCountry && activation.customerVAT && 
    !_.isEmpty(activation.cluster) && !_.isEmpty(activation.marketplace) && activation.iseoCompanyName;
  }

  customerNewDone() {
    const { activation } = this.state;
    const { dispatch, defaultMarketplace } = this.props;
    this.setState({ customerSectionCompleted: true })
    // Se sto customer non è associato al defaultMarketplace --> avviso utente che dovrà specificare a mano alcune cose
    if (activation.marketplace.name!==defaultMarketplace.name) {
      dispatch<any>(ModalsActions.showModal(`WARNING_MARKETPLACE_CUSTOM_MODAL`, {
        modalType: ModalTypes.CONFIRM_MODAL,
        modalProps: {
          icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
          titleMessageKey: 'forms.warning',
          successMessageKey: 'activations.customMarketplaceWarning',
          onCancel: () => dispatch<any>(ModalsActions.hideModal(`WARNING_MARKETPLACE_CUSTOM_MODAL`)),
        }
      }));
    }
  }

  onSubmitForm() {
    let { activation, systemManagerEmails_local, submitterEmails_local, baselineSubscription_selected } = this.state;
    if (!this.canSubmitForm())
      return;
    // Piazzo le liste di email
    let submitterEmails_new = structuredClone(submitterEmails_local);
    submitterEmails_new.push(activation.ownerEmail)
    if (activation.kam&&activation.kam.email)
      submitterEmails_new.push(activation.kam.email)
    if (activation.pmEmail)
      submitterEmails_new.push(activation.pmEmail)
    if (activation.systemEngineerEmail)
      submitterEmails_new.push(activation.systemEngineerEmail)
    submitterEmails_new = submitterEmails_new.filter(e=>e)
    activation.submitterEmails = submitterEmails_new
    let systemManagerEmails_new = structuredClone(systemManagerEmails_local);
    systemManagerEmails_new = systemManagerEmails_new.filter(e=>e)
    activation.systemManagerEmails = systemManagerEmails_new
    // Resetto il flag su V364 se non è ENTERPRISE
    if (baselineSubscription_selected&&activation.baselineSubscription!=="ENTERPRISE")
      activation.V364orF9000 = null;
    // Piazzo gli addon. NOTA: se V364===true, devo aggiungere manualmente l'addon relativo
    let addons: IActivationAddonDTO[] = this.state.selected_addons.map((item,i) => {return {id:item.id,sku:item.sku,name:item.name}});
    activation.addons = addons;
    // Piazzo i documents che sono stati aggiunti || aggiornati
    let newDocuments = {};
    for (let i=0; i<activation.documents.length; i++) {
      if (activation.documents[i].name&&activation.documents[i].contentBase64) {
        newDocuments[activation.documents[i].name] = activation.documents[i].contentBase64;
      }
    }
    activation.documentsOUT = newDocuments;
    // Salvo
    const { onCreateEditActivation } = this.props;
    onCreateEditActivation(activation);
  }

  canSubmitForm() {
    const { activation, errors, systemManagerEmails_local, baselineSubscription_selected, show_addon_V364, available_addon_V364, isCustomer } = this.state;
    const { locked, defaultMarketplace } = this.props;
    return (!_.isEmpty(activation.cluster) || activation.clusterIdForVar) && 
    (!isCustomer || activation.customerName) && 
    (!isCustomer || activation.customerCountry) && 
    (!isCustomer || activation.customerVAT) && 
    (!isCustomer || !_.isEmpty(activation.marketplace)) && 
    (!isCustomer || activation.iseoCompanyName) && 
    activation.optId && activation.salesOrderConfirmationNumber && 
    activation.kam && activation.kam.email &&
    activation.plantName && 
    (!isCustomer || activation.marketplace.name!==defaultMarketplace.name || (activation.baselineSubscription && baselineSubscription_selected)) && 
    (!isCustomer || activation.marketplace.name!==defaultMarketplace.name || (activation.numberOfElements!==null && activation.numberOfElements>0)) && 
    (!isCustomer || activation.marketplace.name!==defaultMarketplace.name || (activation.baselineSubscription!=="ENTERPRISE"||activation.V364orF9000===true||activation.V364orF9000===false)) &&
    (!isCustomer || activation.marketplace.name!==defaultMarketplace.name || (!show_addon_V364 || available_addon_V364!==null)) && 
    (activation.credits!==null && activation.credits>0) && 
    (!isCustomer || systemManagerEmails_local.filter(e=>e).length>0) && 
    activation.documents.length>0 && 
    (!isCustomer || !errors.pmEmail) && 
    (!isCustomer || !errors.systemEngineerEmail) && 
    (activation.status!=ActivationstatusOptions.CREATED||(activation.invoiceNumber&&activation.documents.filter(e=>e.name.includes(ActivationInvoiceFileName)).length>=1));
  }

  public render() {
    const { classes, clusters, marketplaces, defaultMarketplace, locked, customersSearch, varsSearch } = this.props;
    const { kams } = this.props; // TODO DOPO FIX KAMS --> const { users } = this.props;
    const { activation, initCustomer, customerSectionCompleted, customerSelected, customersLoading, customerSearchInput, varSelected, varSearchInput, varsLoading, 
      errors, ISEOCompanies, baselineSubscriptions, baselineSubscription_selected, selected_addons,  
      systemManagerEmails_local, submitterEmails_local, numberOfElements_input, selectedFileName, available_addons, 
      show_addon_V364, available_addon_V364, countries, isCustomer } = this.state;

    let expectedCreditsEndString = "";
    const startDate = moment();
    const endDate = moment().add(activation.expectedCreditsEndDays, 'days');
    const years = endDate.diff(startDate, 'years');
    startDate.add(years, 'years');
    const months = endDate.diff(startDate, 'months');
    startDate.add(months, 'months');
    const days = endDate.diff(startDate, 'days');
    const yearsString = translations.t('activations.yearsString')
    const monthsString = translations.t('activations.monthsString')
    const daysString = translations.t('activations.daysString')
    if (years>0)
      expectedCreditsEndString = `${years} ${yearsString} ${months} ${monthsString} ${days} ${daysString}`;
    else {
      if (months>0) 
        expectedCreditsEndString = `${months} ${monthsString} ${days} ${daysString}`;
      else
        expectedCreditsEndString = `${days} ${daysString}`;
    }
    
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))

    const marketplacesData = (marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content

    const customersSearchData = customersSearch&&customersSearch.data&&(customersSearch.data as PaginatedDataDTO<ICustomerInDTO>).content?
      (customersSearch.data as PaginatedDataDTO<ICustomerInDTO>).content.filter(e=>!e.varId):[]
    const varsSearchData = varsSearch&&varsSearch.data&&(varsSearch.data as PaginatedDataDTO<IVarInDTO>).content?
      (varsSearch.data as PaginatedDataDTO<IVarInDTO>).content:[]

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          {customerSectionCompleted && (activation.status === ActivationstatusOptions.CREATED || activation.status===ActivationstatusOptions.INVOICED || activation.status===ActivationstatusOptions.ACTIVE) ? (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="rechargeInvoiceDataAccordion"
              >
              <div>
                <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.invoiceData'} />}</h2>
              </div>
              </AccordionSummary>
              <AccordionDetails style={{display:'flex',flexDirection:'column', backgroundColor: 'white'}}>
                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.invoiceNumber'} />}</h3>
                  <TextField
                    autoComplete='off'
                    id="outlined-basic"
                    label={activation.invoiceNumber?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                    variant="outlined"
                    fullWidth
                    required
                    disabled={!(activation.status===ActivationstatusOptions.CREATED)||!AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.ACTIVATION_REQUEST_EDIT)}
                    error={errors.invoiceNumber}
                    value={activation.invoiceNumber}
                    style={{ marginBottom: 20, marginTop:10 }}
                    onChange={e => this.onChangeValue('invoiceNumber', true, e.target.value)}
                  />

                  <input type='file' style={{display: 'none'}} ref={this.inputFileRefInvoice} 
                    onChange={(e) => {
                      if(e.target.files&&e.target.files.length>0) {
                        this.onNewInvoiceFileConfirmed(e.target.files[0])
                        e.target.value = null
                      }
                    }}
                  />
                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.invoiceFile'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.invoiceFile_desc'} />}</h5>
                  {activation.documents.filter(e=>e.name.includes(ActivationInvoiceFileName)).map((document,index) => (
                    <Card key={index}
                      style={{padding:10,backgroundColor:'white',display:'flex',flexDirection:'row', alignItems:'center',marginTop:10}} elevation={2}>
                      <div style={{fontWeight:'bold',maxWidth:'70%',overflow:'hidden',textOverflow:'ellipsis',fontSize:'0.8em'}}>
                        {"Invoice"}
                      </div>
                      <span style={{flexGrow:1}}/>
                      {document.url?(
                        <a href={document.url}>
                          <Button
                            variant="contained"
                            style={{backgroundColor:'darkcyan'}}
                            className={classes.actionButton}
                          >
                            <CloudDownloadIcon className={classes.icon}/>
                            <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesDownload" /></h5>
                          </Button>
                        </a>
                      ):null}
                      {!(activation.status===ActivationstatusOptions.CREATED)||!AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.ACTIVATION_REQUEST_EDIT)?null:(
                        <Button
                          variant="contained"
                          style={{backgroundColor:'#282c34'}}
                          className={classes.actionButton}
                          onClick={(e) => {
                            if (this.inputFileRefInvoice&&this.inputFileRefInvoice.current)
                              this.inputFileRefInvoice.current.click();
                          }}
                        >
                          <RedoIcon className={classes.icon}/>
                          <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesReplace" /></h5>
                        </Button>
                      )}
                    </Card>
                  ))}
                  {activation.documents.filter(e=>e.name.includes(ActivationInvoiceFileName)).length>=1||
                  !(activation.status===ActivationstatusOptions.CREATED)||!AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.ACTIVATION_REQUEST_EDIT)?(null):(
                    <Button
                      variant="contained"
                      style={{backgroundColor:'#282c34', marginTop:10}}
                      className={classes.actionButton}
                      onClick={(e) => {
                          if (this.inputFileRefInvoice&&this.inputFileRefInvoice.current)
                            this.inputFileRefInvoice.current.click();
                      }}
                    >
                      <CloudUploadIcon className={classes.icon}/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesAdd" /></h5>
                    </Button>
                  )}
                  <h6 style={{ fontWeight: 'normal', marginTop:10, marginBottom:20 }}>
                    {<ReduxLanguage languageKey={'activations.invoiceFile_desc2'} />}
                  </h6>
              </AccordionDetails>
            </Accordion>
          ):null}

          {isVar ? null : (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="activationCustomerDataAccordion"
              >
              <div>
                <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={isCustomer?'activations.customerData':'activations.varData'} />}</h2>
              </div>
              </AccordionSummary>
              {initCustomer?(
                <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                  <div style={{display:'flex',flexDirection:'column'}}>
                    <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.selectCustomerOrVar'} />}</h5>
                    <FormControl component="fieldset" disabled={locked} style={{marginBottom:20, marginLeft:10}} required>
                      <RadioGroup value={isCustomer?"T":"F"} 
                        onChange={e => this.onChangeIsCustomer(e.target.value)}>
                        <FormControlLabel value={"T"} control={<Radio />} label="Customer" />
                        <FormControlLabel value={"F"} control={<Radio />} label="VAR" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={isCustomer?'activations.customerSearch':'activations.varSearch'} />}</h5>
                  {isCustomer?(
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <Autocomplete
                        id="customer-search"
                        style={{ width: 400 }}
                        getOptionLabel={(option) => option.name}
                        filterOptions={(x) => (x as ICustomerInDTO[])}
                        options={customersSearchData}
                        autoComplete
                        loading={customersLoading}
                        includeInputInList
                        filterSelectedOptions
                        value={customerSelected}
                        noOptionsText={<ReduxLanguage languageKey={customerSearchInput?'activations.customerSearchNoMatch':'activations.customerSearchEnterName'} />}
                        onChange={(event, newValue) => {this.setState({ customerSelected: !newValue?null:(newValue as ICustomerInDTO) });}}
                        onInputChange={(event, newInputValue) => {this.onCustomerSearchInputChanged(newInputValue)}}
                        renderInput={(params) => (
                          <TextField
                            autoComplete='off'
                            {...params}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {customersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                      <Button
                        variant="contained"
                        style={{backgroundColor:customerSelected?'green':'cornflowerblue',paddingTop:10,paddingBottom:10}}
                        className={classes.actionButton}
                        onClick={e=>this.customerInitDone()}
                      >
                        <h5 className={classes.actionButtonText}>
                          <ReduxLanguage languageKey={customerSelected?"activations.customerProceedWithSelected":"activations.customerProceedWithNew"} />
                        </h5>
                        <ArrowForwardIcon className={classes.icon} style={{marginLeft:10}}/>
                      </Button>
                    </div>
                  ):(
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <Autocomplete
                        id="var-search"
                        style={{ width: 400 }}
                        getOptionLabel={(option) => option.name}
                        filterOptions={(x) => (x as IVarInDTO[])}
                        options={varsSearchData}
                        autoComplete
                        loading={varsLoading}
                        includeInputInList
                        filterSelectedOptions
                        value={varSelected}
                        noOptionsText={<ReduxLanguage languageKey={varSearchInput?'activations.varSearchNoMatch':'activations.varSearchEnterName'} />}
                        onChange={(event, newValue) => {this.setState({ varSelected: !newValue?null:(newValue as IVarInDTO) });}}
                        onInputChange={(event, newInputValue) => {this.onVarSearchInputChanged(newInputValue)}}
                        renderInput={(params) => (
                          <TextField
                            autoComplete='off'
                            {...params}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {varsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                      <Button
                        variant="contained"
                        style={{backgroundColor:varSelected?'green':'lightgrey',paddingTop:10,paddingBottom:10}}
                        disabled={!varSelected}
                        className={classes.actionButton}
                        onClick={e=>this.varInitDone()}
                      >
                        <h5 className={classes.actionButtonText}>
                          <ReduxLanguage languageKey={"activations.varProceedWithSelected"} />
                        </h5>
                        <ArrowForwardIcon className={classes.icon} style={{marginLeft:10}}/>
                      </Button>
                    </div>
                  )}
                </AccordionDetails>
              ):(
                isCustomer?(
                  <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                    <div style={{display:'flex',flexDirection:'row'}}>
                      <div style={{ flex: 1, marginRight: 10 }}>
                        <TextField
                          autoComplete='off'
                          id="outlined-basic"
                          label={<ReduxLanguage languageKey={'activations.customerName'} />}
                          variant="outlined"
                          fullWidth
                          required
                          disabled={locked||customerSectionCompleted}
                          error={errors.customerName}
                          value={activation.customerName}
                          style={{ marginTop: 20 }}
                          onChange={e => this.onChangeValue('customerName', true, e.target.value)}
                        />
                        <TextField
                          autoComplete='off'
                          id="outlined-basic"
                          label={<ReduxLanguage languageKey={'activations.customerVat'} />}
                          variant="outlined"
                          fullWidth
                          required
                          disabled={locked||customerSectionCompleted}
                          error={errors.customerVAT}
                          value={activation.customerVAT}
                          style={{ marginTop: 20 }}
                          onChange={e => this.onChangeValue('customerVAT', true, e.target.value)}
                        />
                        <TextField
                          autoComplete='off'
                          id="outlined-basic"
                          label={<ReduxLanguage languageKey={'activations.customerFiscalCode'} />}
                          variant="outlined"
                          fullWidth
                          disabled={locked||customerSectionCompleted}
                          error={errors.customerFiscalCode}
                          value={activation.customerFiscalCode}
                          style={{ marginTop: 20 }}
                          onChange={e => this.onChangeValue('customerFiscalCode', false, e.target.value)}
                        />

                        <TextField
                          autoComplete='off'
                          id="outlined-basic"
                          label={<ReduxLanguage languageKey={'activations.customerEmail'} />}
                          variant="outlined"
                          fullWidth
                          disabled={locked||customerSectionCompleted}
                          error={errors.customerEmail}
                          value={activation.customerEmail}
                          style={{ marginTop: 20 }}
                          onChange={e => this.onChangeValue('customerEmail', false, e.target.value)}
                        />

                        <TextField
                          autoComplete='off'
                          id="outlined-basic"
                          label={<ReduxLanguage languageKey={'activations.customerCertifiedEmail'} />}
                          variant="outlined"
                          fullWidth
                          disabled={locked||customerSectionCompleted}
                          error={errors.customerPEC}
                          value={activation.customerPEC}
                          style={{ marginTop: 20 }}
                          onChange={e => this.onChangeValue('customerPEC', false, e.target.value)}
                        />

                        <TextField
                          autoComplete='off'
                          id="outlined-basic"
                          label={<ReduxLanguage languageKey={'activations.customerPhone'} />}
                          variant="outlined"
                          fullWidth
                          disabled={locked||customerSectionCompleted}
                          error={errors.customerPhone}
                          value={activation.customerPhone}
                          style={{ marginTop: 20 }}
                          onChange={e => this.onChangeValue('customerPhone', false, e.target.value)}
                        />
                      </div>
                      <div style={{ flex: 1, marginLeft: 10 }}>
                        <Select
                          options={countries}
                          isClearable
                          value={_.find(countries, { value: activation.customerCountry })}
                          formatOptionLabel={(o) => <>{o.value}</>}
                          isOptionSelected={(o, selectedValues) => _.findIndex(selectedValues, (sv) => sv === o) >= 0}
                          placeholder={<><ReduxLanguage languageKey={'forms.customerCountry'} /> *</>}
                          onChange={(data) => this.onChangeValue('customerCountry', true, data?.value)}
                          isDisabled={locked||customerSectionCompleted}
                          required
                          styles={{
                            menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                            control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
                          }} 
                        />

                        <TextField
                          autoComplete='off'
                          id="outlined-basic"
                          label={<ReduxLanguage languageKey={'activations.customerAddress'} />}
                          variant="outlined"
                          fullWidth
                          disabled={locked||customerSectionCompleted}
                          error={errors.customerAddress}
                          value={activation.customerAddress}
                          style={{ marginTop: 20 }}
                          onChange={e => this.onChangeValue('customerAddress', false, e.target.value)}
                        />
                        
                        <TextField
                          autoComplete='off'
                          id="outlined-basic"
                          label={<ReduxLanguage languageKey={'activations.customerCity'} />}
                          variant="outlined"
                          fullWidth
                          disabled={locked||customerSectionCompleted}
                          error={errors.customerCity}
                          value={activation.customerCity}
                          style={{ marginTop: 20 }}
                          onChange={e => this.onChangeValue('customerCity', false, e.target.value)}
                        />
                        
                        <TextField
                          autoComplete='off'
                          id="outlined-basic"
                          label={<ReduxLanguage languageKey={'activations.customerPostalCode'} />}
                          variant="outlined"
                          fullWidth
                          disabled={locked||customerSectionCompleted}
                          error={errors.customerPostalCode}
                          value={activation.customerPostalCode}
                          style={{ marginTop: 20 }}
                          onChange={e => this.onChangeValue('customerPostalCode', false, e.target.value)}
                        />

                        <TextField
                          autoComplete='off'
                          id="outlined-basic"
                          label={<ReduxLanguage languageKey={'activations.customerPrincipalSubDivision'} />}
                          variant="outlined"
                          fullWidth
                          disabled={locked||customerSectionCompleted}
                          error={errors.customerProvince}
                          value={activation.customerProvince}
                          style={{ marginTop: 20 }}
                          onChange={e => this.onChangeValue('customerProvince', false, e.target.value)}
                        />
                      </div>
                    </div>
                    <TextField
                      autoComplete='off'
                      id="outlined-basic"
                      label={<ReduxLanguage languageKey={'activations.customerCrmId'} />}
                      variant="outlined"
                      fullWidth
                      disabled={locked||customerSectionCompleted}
                      error={errors.CRMID}
                      value={activation.CRMID}
                      style={{ marginTop: 20 }}
                      onChange={e => this.onChangeValue('CRMID', false, e.target.value)}
                    />
                    <Select
                      options={(clusters as PaginatedDataDTO<IClusterInDTO>).content}
                      isClearable
                      isSearchable={false}
                      formatOptionLabel={(o) => <>[{o.market}] - <b>{o.name}</b></>}
                      value={activation.cluster}
                      isDisabled={locked||customerSectionCompleted}
                      required
                      isOptionSelected={(o) => activation.cluster?.id === o.id}
                      placeholder={<><ReduxLanguage languageKey={'activations.customerCluster'} /> *</>}
                      onChange={(data) => this.onChangeValue('cluster', true, data)}
                      styles={{
                        menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                        control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
                      }} 
                    />
                    <Select
                      options={ISEOCompanies}
                      isClearable
                      isSearchable={false}
                      value={_.find(ISEOCompanies, { name: activation.iseoCompanyName })}
                      isDisabled={locked||customerSectionCompleted}
                      required
                      formatOptionLabel={(o) => <>[{o.country}] - <b>{o.name}</b></>}
                      isOptionSelected={(o, selectedValues) => _.findIndex(selectedValues, (sv) => sv === o) >= 0}
                      placeholder={<><ReduxLanguage languageKey={'activations.customerCompany'} /> *</>}
                      onChange={(data) => this.onChangeValue('iseoCompanyName', true, data?.name)}
                      styles={{
                        menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                        control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
                      }} 
                    />
                    {/* 
                    <Select
                      options={marketplacesData}
                      isClearable
                      isSearchable={false}
                      formatOptionLabel={(o) => <>{o.name}</>}
                      value={activation.marketplace}
                      isDisabled={locked||customerSectionCompleted}
                      required
                      isOptionSelected={(o) => activation.marketplace?.id === o.id}
                      placeholder={<><ReduxLanguage languageKey={'activations.customerMarketplace'} /> *</>}
                      onChange={(data) => this.onChangeValue('marketplace', true, data)}
                      styles={{
                        menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                        control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
                      }} 
                    />
                    */}
                    <CreatableSelect
                      options={marketplacesData}
                      isClearable
                      value={activation.marketplace}
                      isDisabled={locked||customerSectionCompleted}
                      filterOption={(option, input) => {
                        // Se sto scrivendo qualcosa, faccio vedere solo la option (tra le non-default) che in questo momento corrisponde esattamente all'input
                        if (input)
                          return option.data.name!==defaultMarketplace.name && option.data.name===input;
                        // Se questa option è stata impostata, la devo far vedere (default o meno che sia)
                        if (activation.marketplace&&activation.marketplace.name&&option.data.name===activation.marketplace.name)
                          return true;
                        // La faccio vedere solo se è il defaultMarketplace
                        return option.data.name===defaultMarketplace.name;
                      }}
                      formatOptionLabel={(o) => <>{o?o.name:""}</>}
                      isOptionSelected={(o, selectedValues) => _.findIndex(selectedValues, (sv) => sv === o) >= 0}
                      placeholder={activation.marketplace?"":<><ReduxLanguage languageKey={'activations.customerMarketplace'} /> *</>}
                      onChange={(data) => this.onChangeValue('marketplace', true, data)}
                      styles={{
                        menu: (styles) => Object.assign(styles, { zIndex: 1000, maxWidth:400 }),
                        control: (styles) => ({ ...styles, minHeight: 55, marginBottom: 20, marginTop: 10, maxWidth:400 }),
                      }} 
                    />

                    {customerSectionCompleted?null:(
                      <Button
                        variant="contained"
                        style={{backgroundColor:'cornflowerblue',paddingTop:10,paddingBottom:10,marginTop:20,marginLeft:0}}
                        className={classes.actionButton}
                        onClick={e=>this.customerNewDone()}
                        disabled={!this.customerDataIsOk()}
                      >
                        <h5 className={classes.actionButtonText}>
                          <ReduxLanguage languageKey={"activations.customerConfirmData"} />
                        </h5>
                        <ArrowForwardIcon className={classes.icon} style={{marginLeft:10}}/>
                      </Button>
                    )}
                  </AccordionDetails>
                ):(
                  <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                    <TextField
                      autoComplete='off'
                      id="outlined-basic"
                      label={<ReduxLanguage languageKey={'activations.varName'} />}
                      variant="outlined"
                      fullWidth
                      required
                      disabled={true}
                      error={errors.varName}
                      value={activation.varName}
                      style={{ marginTop: 20 }}
                      onChange={e => this.onChangeValue('varName', true, e.target.value)}
                    />
                    <TextField
                      autoComplete='off'
                      id="outlined-basic"
                      label={<ReduxLanguage languageKey={'activations.varSfdcId'} />}
                      variant="outlined"
                      fullWidth
                      required
                      disabled={true}
                      error={errors.varSfdcId}
                      value={activation.varSfdcId}
                      style={{ marginTop: 20 }}
                      onChange={e => this.onChangeValue('varSfdcId', true, e.target.value)}
                    />

                    <Select
                      options={(clusters as PaginatedDataDTO<IClusterInDTO>).content}
                      isClearable
                      isSearchable={false}
                      formatOptionLabel={(o) => <>[{o.market}] - <b>{o.name}</b></>}
                      value={activation.cluster}
                      isDisabled={true}
                      required
                      isOptionSelected={(o) => activation.cluster?.id === o.id}
                      placeholder={<><ReduxLanguage languageKey={'activations.customerCluster'} /> *</>}
                      onChange={(data) => this.onChangeValue('cluster', true, data)}
                      styles={{
                        menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                        control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
                      }} 
                    />
                  </AccordionDetails>
                )
              )}
            </Accordion>
          )}

          {customerSectionCompleted?(
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="activationBasicDataAccordion"
              >
              <div>
                <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.salesAndOrdersData'} />}</h2>
              </div>
              </AccordionSummary>
              <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.optId'} />}</h3>
                <TextField
                  autoComplete='off'
                  id="outlined-basic"
                  label={activation.optId?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                  variant="outlined"
                  fullWidth
                  required
                  disabled={locked}
                  error={errors.optId}
                  value={activation.optId}
                  style={{ marginBottom: 20, marginTop:10 }}
                  onChange={e => this.onChangeValue('optId', true, e.target.value)}
                />

                <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.salesOrderConfirmationNumber'} />}</h3>
                <TextField
                  autoComplete='off'
                  id="outlined-basic"
                  label={activation.salesOrderConfirmationNumber?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                  variant="outlined"
                  fullWidth
                  required
                  disabled={locked}
                  error={errors.salesOrderConfirmationNumber}
                  value={activation.salesOrderConfirmationNumber}
                  style={{ marginBottom: 20, marginTop:10 }}
                  onChange={e => this.onChangeValue('salesOrderConfirmationNumber', true, e.target.value)}
                />

                <input type='file' style={{display: 'none'}} ref={this.inputFileRef} 
                  onChange={(e) => {
                    if(e.target.files&&e.target.files.length>0) {
                      if (selectedFileName===null)
                        this.onNewInputFile(e.target.files[0])
                      else
                        this.onNewInputFileConfirmed(e.target.files[0],null,null)
                      e.target.value = null
                    }
                  }}
                />
                <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.selloutFiles'} />}</h3>
                <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.selloutFiles_desc'} />}</h5>
                {activation.documents.filter(e=>!e.name.includes(ActivationQuotationToolFileName)&&!e.name.includes(ActivationInvoiceFileName)).map((document,index) => (
                  <Card key={index}
                    style={{padding:10,backgroundColor:'white',display:'flex',flexDirection:'row', alignItems:'center',marginTop:10}} elevation={2}>
                    <div style={{fontWeight:'bold',maxWidth:'70%',overflow:'hidden',textOverflow:'ellipsis',fontSize:'0.8em'}}>
                      {document.name}
                    </div>
                    <span style={{flexGrow:'1'}}/>
                    {document.url?(
                      <a href={document.url}>
                        <Button
                          variant="contained"
                          style={{backgroundColor:'darkcyan'}}
                          className={classes.actionButton}
                        >
                          <CloudDownloadIcon className={classes.icon}/>
                          <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesDownload" /></h5>
                        </Button>
                      </a>
                    ):null}
                    {locked?null:(
                      <Button
                        variant="contained"
                        style={{backgroundColor:'#282c34'}}
                        className={classes.actionButton}
                        onClick={(e) => {
                          this.setState({selectedFileName:document.name})
                          if (this.inputFileRef&&this.inputFileRef.current)
                            this.inputFileRef.current.click();
                        }}
                      >
                        <RedoIcon className={classes.icon}/>
                        <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesReplace" /></h5>
                      </Button>
                    )}
                  </Card>
                ))}
                {activation.documents.filter(e=>!e.name.includes(ActivationQuotationToolFileName)).length>=5||locked?(null):(
                  <Button
                    variant="contained"
                    style={{backgroundColor:'#282c34', marginTop:10}}
                    className={classes.actionButton}
                    onClick={(e) => {
                      this.setState({selectedFileName:null})
                        if (this.inputFileRef&&this.inputFileRef.current)
                          this.inputFileRef.current.click();
                    }}
                  >
                    <CloudUploadIcon className={classes.icon}/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesAdd" /></h5>
                  </Button>
                )}
                <h6 style={{ fontWeight: 'normal', marginTop:10, marginBottom:20 }}>
                  {<ReduxLanguage languageKey={'activations.selloutFiles_desc2'} />}
                  {ActivationFileAcceptedExtensions.map((extension,index) => (
                    <span>
                      {(index>0?", ":"")+extension}
                    </span>
                  ))}
                  <span>.</span>
                </h6>

                <input type='file' style={{display: 'none'}} ref={this.inputFileRefQuotationTool} 
                  onChange={(e) => {
                    if(e.target.files&&e.target.files.length>0) {
                      this.onNewQuotationToolFileConfirmed(e.target.files[0])
                      e.target.value = null
                    }
                  }}
                />
                <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.quotationToolFile'} />}</h3>
                <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.quotationToolFile_desc'} />}</h5>
                {activation.documents.filter(e=>e.name.includes(ActivationQuotationToolFileName)).map((document,index) => (
                  <Card key={index}
                    style={{padding:10,backgroundColor:'white',display:'flex',flexDirection:'row', alignItems:'center',marginTop:10}} elevation={2}>
                    <div style={{fontWeight:'bold',maxWidth:'70%',overflow:'hidden',textOverflow:'ellipsis',fontSize:'0.8em'}}>
                      {"Quotation tool"}
                    </div>
                    <span style={{flexGrow:1}}/>
                    {document.url?(
                      <a href={document.url}>
                        <Button
                          variant="contained"
                          style={{backgroundColor:'darkcyan'}}
                          className={classes.actionButton}
                        >
                          <CloudDownloadIcon className={classes.icon}/>
                          <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesDownload" /></h5>
                        </Button>
                      </a>
                    ):null}
                    {locked?null:(
                      <Button
                        variant="contained"
                        style={{backgroundColor:'#282c34'}}
                        className={classes.actionButton}
                        onClick={(e) => {
                          if (this.inputFileRefQuotationTool&&this.inputFileRefQuotationTool.current)
                            this.inputFileRefQuotationTool.current.click();
                        }}
                      >
                        <RedoIcon className={classes.icon}/>
                        <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesReplace" /></h5>
                      </Button>
                    )}
                  </Card>
                ))}
                {activation.documents.filter(e=>e.name.includes(ActivationQuotationToolFileName)).length>=1||locked?(null):(
                  <Button
                    variant="contained"
                    style={{backgroundColor:'#282c34', marginTop:10}}
                    className={classes.actionButton}
                    onClick={(e) => {
                        if (this.inputFileRefQuotationTool&&this.inputFileRefQuotationTool.current)
                          this.inputFileRefQuotationTool.current.click();
                    }}
                  >
                    <CloudUploadIcon className={classes.icon}/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesAdd" /></h5>
                  </Button>
                )}
                <h6 style={{ fontWeight: 'normal', marginTop:10, marginBottom:20 }}>
                  {<ReduxLanguage languageKey={'activations.quotationToolFile_desc2'} />}
                </h6>

                <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.purchaseOrderNumberSofia'} />}</h3>
                <TextField
                  autoComplete='off'
                  id="outlined-basic"
                  label={activation.purchaseOrderNumberSofia?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                  variant="outlined"
                  fullWidth
                  disabled={locked}
                  error={errors.purchaseOrderNumberSofia}
                  value={activation.purchaseOrderNumberSofia}
                  style={{ marginBottom: 20, marginTop:10 }}
                  onChange={e => this.onChangeValue('purchaseOrderNumberSofia', false, e.target.value)}
                />
              </AccordionDetails>
            </Accordion>
          ):null}

          {customerSectionCompleted?(
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="activationBasicDataAccordion"
              >
              <div>
                <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.basicData'} />}</h2>
              </div>
              </AccordionSummary>
              {isCustomer?(
                <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.plantName'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.plantName_desc'} />}</h5>
                  <TextField
                    autoComplete='off'
                    id="outlined-basic"
                    label={activation.plantName?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                    variant="outlined"
                    fullWidth
                    required
                    disabled={locked}
                    error={errors.plantName}
                    value={activation.plantName}
                    style={{ marginBottom: 20, marginTop:10 }}
                    onChange={e => this.onChangeValue('plantName', true, e.target.value)}
                  />

                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.keyAccountManager'} />}</h3>
                  <Autocomplete
                    options={(kams as PaginatedDataDTO<IKamInDTO>).content /* // TODO DOPO FIX KAMS --> (users as PaginatedDataDTO<IKamInDTO>).content */}
                    isClearable
                    autoComplete
                    value={activation.kam}
                    disabled={locked}
                    getOptionLabel={(option) => option.email}
                    renderInput={(params) => <TextField {...params} variant="outlined" style={{ maxWidth:400, margin: '10px 0px' }} label={<ReduxLanguage languageKey={'activations.keyAccountManager_desc'} />}/>}
                    placeholder={<><ReduxLanguage languageKey={'activations.formEnterAnswer'} /> *</>}
                    // onInputChange={(event, newInputValue) => { this.setState({ searchText: newInputValue }); debouncedSearch(newInputValue); }}
                    onChange={(e, data) => this.onChangeValue('kam', true, data)}
                    styles={{
                      menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                      control: (styles) => ({ ...styles, minHeight: 55 })
                    }} 
                  />

                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.systemManagerEmails'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.systemManagerEmails_desc'} />}</h5>
                  {systemManagerEmails_local.map((email_local,index) => (
                    <div key={index} style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:10}}>
                      <TextField
                        autoComplete='off'
                        id="outlined-basic"
                        label={email_local?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                        variant="outlined"
                        fullWidth
                        disabled={locked}
                        error={false}
                        value={email_local}
                        style={{ marginBottom: 10, marginTop:10, maxWidth:400 }}
                        onChange={e => {
                          let newValue = e.target.value;
                          let updatedState = this.state.systemManagerEmails_local.map((item,i) => {
                            if (i===index) return newValue;
                            else return item;
                          });
                          this.setState({ systemManagerEmails_local:updatedState })
                        }}
                      />
                      {locked?(null):(
                        <IconButton onClick={() => this.setState({systemManagerEmails_local: this.state.systemManagerEmails_local.filter(e=>e!==email_local)})} 
                          disabled={!systemManagerEmails_local||systemManagerEmails_local.length<=1}>
                          <Delete style={{ color: !systemManagerEmails_local||systemManagerEmails_local.length<=1?'grey':'red' }} />
                        </IconButton>
                      )}
                    </div>
                  ))}
                  {locked?(null):(
                    <IconButton onClick={() => this.setState({systemManagerEmails_local: [...this.state.systemManagerEmails_local, ""]})} disabled={false}
                      style={{marginTop:'-20px',width:'fit-content'}}>
                      <AddBoxIcon style={{ color: 'green' }} />
                    </IconButton>
                  )}

                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.pmEmail'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.pmEmail_desc'} />}</h5>
                  <TextField
                    autoComplete='off'
                    id="outlined-basic"
                    label={activation.pmEmail?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                    variant="outlined"
                    fullWidth
                    disabled={locked}
                    error={errors.pmEmail}
                    value={activation.pmEmail}
                    style={{ marginBottom: 20, marginTop:10, maxWidth:400 }}
                    onChange={e => this.onChangeValue('pmEmail', false, e.target.value)}
                  />

                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.systemEngineerEmail'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.systemEngineerEmail_desc'} />}</h5>
                  <TextField
                    autoComplete='off'
                    id="outlined-basic"
                    label={activation.systemEngineerEmail?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                    variant="outlined"
                    fullWidth
                    disabled={locked}
                    error={errors.systemEngineerEmail}
                    value={activation.systemEngineerEmail}
                    style={{ marginBottom: 20, marginTop:10, maxWidth:400 }}
                    onChange={e => this.onChangeValue('systemEngineerEmail', false, e.target.value)}
                  />
                  
                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.installerEmail'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.installerEmail_desc'} />}</h5>
                  <TextField
                    autoComplete='off'
                    id="outlined-basic"
                    label={activation.installerEmail?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                    variant="outlined"
                    fullWidth
                    disabled={locked}
                    error={errors.installerEmail}
                    value={activation.installerEmail}
                    style={{ marginBottom: 20, marginTop:10, maxWidth:400 }}
                    onChange={e => this.onChangeValue('installerEmail', false, e.target.value)}
                  />
                </AccordionDetails>
              ):(
                <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.keyAccountManager'} />}</h3>
                  <Autocomplete
                    options={(kams as PaginatedDataDTO<IKamInDTO>).content /* // TODO DOPO FIX KAMS --> (users as PaginatedDataDTO<IKamInDTO>).content */}
                    isClearable
                    disabled={locked}
                    autoComplete
                    value={activation.kam}
                    getOptionLabel={(option) => option.email}
                    renderInput={(params) => <TextField {...params} variant="outlined" style={{ maxWidth:400, margin: '10px 0px' }} label={<ReduxLanguage languageKey={'activations.keyAccountManager_desc'} />}/>}
                    placeholder={<><ReduxLanguage languageKey={'activations.formEnterAnswer'} /> *</>}
                    // onInputChange={(event, newInputValue) => { this.setState({ searchText: newInputValue }); debouncedSearch(newInputValue); }}
                    onChange={(e, data) => this.onChangeValue('kam', true, data)}
                    styles={{
                      menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                      control: (styles) => ({ ...styles, minHeight: 55 })
                    }} 
                  />
                </AccordionDetails>
              )}
            </Accordion>
          ):null}
          
          {customerSectionCompleted?(
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="activationSubscriptionDataAccordion"
              >
              <div>
                <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.subscriptionData'} />}</h2>
              </div>
              </AccordionSummary>
              {isCustomer?(
                <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                  {activation.marketplace.name===defaultMarketplace.name?(
                    <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.baselineSubscription'} />}</h3>
                  ):null}
                  {activation.marketplace.name===defaultMarketplace.name?(
                    <CreatableSelect
                      options={baselineSubscriptions}
                      isClearable
                      value={_.find(baselineSubscriptions, { name: activation.baselineSubscription })}
                      isDisabled={locked}
                      filterOption={(option, input) => {
                        // Se sto scrivendo qualcosa, faccio vedere solo la option (tra le hidden) che in questo momento corrisponde esattamente all'input
                        if (input)
                          return option.data.hidden && option.data.name===input;
                        // Se questa option è stata impostata, la devo far vedere (hidden o meno che sia)
                        if (option.data.name===activation.baselineSubscription)
                          return true;
                        // La faccio vedere solo se non è hidden
                        return !option.data.hidden;
                      }}
                      formatOptionLabel={(o) => <>{o.label}</>}
                      isOptionSelected={(o, selectedValues) => _.findIndex(selectedValues, (sv) => sv === o) >= 0}
                      placeholder={activation.baselineSubscription?"":<><ReduxLanguage languageKey={'activations.formEnterAnswer'} /> *</>}
                      onChange={(data) => this.onEditBaselineSubscriptionRequest(data?.name)}
                      styles={{
                        menu: (styles) => Object.assign(styles, { zIndex: 1000, maxWidth:400 }),
                        control: (styles) => ({ ...styles, minHeight: 55, marginBottom: 20, marginTop: 10, maxWidth:400 }),
                      }} 
                    />
                  ):null}

                  {activation.marketplace.name===defaultMarketplace.name?(
                    <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.numberOfElements'} />}</h3>
                  ):null} 
                  {activation.marketplace.name===defaultMarketplace.name?(
                    <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.numberOfElements_desc'} />}</h5>
                  ):null}
                  {activation.marketplace.name===defaultMarketplace.name?(
                    <TextField
                      autoComplete='off'
                      id="outlined-basic"
                      label={<ReduxLanguage languageKey={activation.baselineSubscription==="BASIC"?'activations.formEnterAnswerFrom0To600':'activations.formEnterAnswerGT0'} />}
                      variant="outlined"
                      type='number'
                      onWheel={(e) => {if(e.target&&('blur' in e.target)&&(typeof e.target.blur === 'function')) e.target.blur()}}
                      fullWidth
                      required
                      disabled={locked}
                      error={errors.numberOfElements}
                      value={numberOfElements_input}
                      style={{ marginBottom: 20, marginTop:10, maxWidth:400  }}
                      onChange={e => this.setState({ numberOfElements_input:e.target.value===null||isNaN(parseInt(e.target.value))?null:parseInt(e.target.value) })}
                      onBlur={e => {if(numberOfElements_input!==activation.numberOfElements) this.onEditNumberOfElementsRequest(this.state.numberOfElements_input)}}
                    />
                  ):null}

                  {activation.marketplace.name===defaultMarketplace.name&&!baselineSubscription_selected?(
                    <h4 style={{ fontWeight: 'bold', color: 'red' }}>{<ReduxLanguage languageKey={'activations.noSubscriptionFound'}/>}
                    {<ReduxLanguage languageKey={'activations.noSubscriptionFoundPleaseCheck'} />}{<ReduxLanguage languageKey={'activations.baselineSubscription'} />}
                    {" & "}{<ReduxLanguage languageKey={'activations.numberOfElements'} />}</h4>
                  ):(null)}

                  {baselineSubscription_selected?(
                    <h4 style={{ fontWeight: 'bold', marginBottom:'0px' }}>
                      {<ReduxLanguage languageKey={'activations.selectedSubscription'}/>}
                    </h4>
                  ):(null)}
                  {baselineSubscription_selected?(
                    <h4 style={{ fontWeight: 'bold', marginBottom:'20px', textDecoration:'underline' }}>
                      {"[" + baselineSubscription_selected.sku + "] " + baselineSubscription_selected.name}
                    </h4>
                  ):(null)}
                  
                  {baselineSubscription_selected&&show_addon_V364?(
                    <div style={{display:'flex',flexDirection:'column'}}>
                      <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.V364orF9000'} />}</h3>
                      <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.V364orF9000_desc'} />}</h5>
                      <FormControl component="fieldset" disabled={locked} style={{marginBottom:10}} required>
                        <RadioGroup value={activation.V364orF9000===true?"T":activation.V364orF9000===false?"F":null} 
                          onChange={e => this.onChangeValue('V364orF9000', true, e.target.value)}>
                          <FormControlLabel value={"T"} control={<Radio />} label="Yes" />
                          <FormControlLabel value={"F"} control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  ):(null)}

                  {baselineSubscription_selected?(
                    <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.addons'} />}</h3>
                  ):null}
                  {baselineSubscription_selected?(
                    <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.addons_desc'} />}</h5>
                  ):null}
                  {baselineSubscription_selected?(
                    <Autocomplete
                      id="actionsPlugins"
                      options={available_addons}
                      multiple
                      style={{ marginTop: 10, marginBottom: 10 }}
                      getOptionLabel={(option) => "["+option.sku+"] "+option.name}
                      value={selected_addons}
                      disabled={locked}
                      onChange={(e, values) => {
                        let new_selected_addons = _.isEmpty(values)?[]:(values as ISubscriptionInDTO[]);
                        // Tengo aggiunto forzatamente l'addon V364 se necessario
                        if (show_addon_V364&&
                          activation.V364orF9000&&
                          available_addon_V364!=null&&
                          new_selected_addons.filter(e=>e.id===available_addon_V364.id).length===0) {
                          new_selected_addons.unshift(available_addon_V364)
                        }
                        this.setState({ selected_addons: new_selected_addons });
                        // Devo anche calcolare la data stimata di esaurimento crediti
                        let expectedCreditsEndDays = this.computeExpectedCreditsEnd(undefined,undefined,new_selected_addons);
                        (activation as any)["expectedCreditsEndDays"] = expectedCreditsEndDays;
                        this.setState({ activation });
                      }}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                  ):null}

                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.credits'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.credits_desc'} />}</h5>
                  <TextField
                    autoComplete='off'
                    id="outlined-basic"
                    label={<ReduxLanguage languageKey={'activations.formEnterAnswerGT0'} />}
                    variant="outlined"
                    type='number'
                    onWheel={(e) => {if(e.target&&('blur' in e.target)&&(typeof e.target.blur === 'function')) e.target.blur()}}
                    fullWidth
                    required
                    disabled={locked}
                    error={errors.credits}
                    value={activation.credits}
                    style={{ marginBottom: 20, marginTop:10, maxWidth:400 }}
                    onChange={e => this.onChangeValue('credits', true, e.target.value===null||isNaN(parseInt(e.target.value))?null:parseInt(e.target.value)) }
                  />

                  {baselineSubscription_selected&&activation.expectedCreditsEndDays&&activation.expectedCreditsEndDays>0?(
                    <div>
                      <h5 style={{ fontWeight: 'bold', marginTop:-10 }}>{<ReduxLanguage languageKey={'activations.expectedEnd'} />}</h5>
                      <h5 style={{ fontWeight: 'normal', textDecoration:'underline', marginBottom:10 }}>
                        {expectedCreditsEndString}
                      </h5>
                    </div>
                  ):null}
                </AccordionDetails>
              ):(
                <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.credits'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.credits_desc'} />}</h5>
                  <TextField
                    autoComplete='off'
                    id="outlined-basic"
                    label={<ReduxLanguage languageKey={'activations.formEnterAnswerGT0'} />}
                    variant="outlined"
                    type='number'
                    onWheel={(e) => {if(e.target&&('blur' in e.target)&&(typeof e.target.blur === 'function')) e.target.blur()}}
                    fullWidth
                    required
                    disabled={locked}
                    error={errors.credits}
                    value={activation.credits}
                    style={{ marginBottom: 20, marginTop:10, maxWidth:400 }}
                    onChange={e => this.onChangeValue('credits', true, e.target.value===null||isNaN(parseInt(e.target.value))?null:parseInt(e.target.value)) }
                  />
                </AccordionDetails>
              )}
            </Accordion>
          ):(null)}

          {customerSectionCompleted?(
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="activationFinalDataAccordion"
              >
              <div>
                <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.finalData'} />}</h2>
              </div>
              </AccordionSummary>
              <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.notes'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.notes_desc'} />}</h5>
                  <TextField
                    multiline={true}
                    maxRows={5}
                    minRows={5}
                    autoComplete='off'
                    id="outlined-basic"
                    label={activation.notes?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                    variant="outlined"
                    fullWidth
                    disabled={locked}
                    error={errors.notes}
                    value={activation.notes}
                    style={{ marginBottom: 20, marginTop:10 }}
                    onChange={e => this.onChangeValue('notes', false, e.target.value)}
                  />
                  
                  <h3 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'activations.notifications'} />}</h3>
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.notifications_desc'} />}</h5>
                  <TextField
                    autoComplete='off'
                    id="outlined-basic"
                    label={<ReduxLanguage languageKey={'activations.formEnterAnswerOwnerEmail'} />}
                    variant="outlined"
                    fullWidth
                    disabled={true}
                    error={false}
                    value={activation.ownerEmail}
                    style={{ marginBottom: 10, marginTop:10, maxWidth:400 }}
                    onChange={e => {}}
                  />
                  <TextField
                    autoComplete='off'
                    id="outlined-basic"
                    label={<ReduxLanguage languageKey={'activations.formEnterAnswerKamEmail'} />}
                    variant="outlined"
                    fullWidth
                    disabled={true}
                    error={false}
                    value={activation.kam && activation.kam.email ? activation.kam.email:
                      (!AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.KAM_READ) && activation && activation.keyAccountManagerEmail) ? 
                      activation.keyAccountManagerEmail:
                      "Key Account Manager"
                    }
                    style={{ marginBottom: 10, marginTop:10, maxWidth:400 }}
                    onChange={e => {}}
                  />
                  {activation.pmEmail&&!errors.pmEmail?(
                    <TextField
                      autoComplete='off'
                      id="outlined-basic"
                      label={<ReduxLanguage languageKey={'activations.formEnterAnswerPmEmail'} />}
                      variant="outlined"
                      fullWidth
                      disabled={true}
                      error={false}
                      value={activation.pmEmail}
                      style={{ marginBottom: 10, marginTop:10, maxWidth:400 }}
                      onChange={e => {}}
                    />
                  ):null}
                  {activation.systemEngineerEmail&&!errors.systemEngineerEmail?(
                    <TextField
                      autoComplete='off'
                      id="outlined-basic"
                      label={<ReduxLanguage languageKey={'activations.formEnterAnswerSystemEngineerEmail'} />}
                      variant="outlined"
                      fullWidth
                      disabled={true}
                      error={false}
                      value={activation.systemEngineerEmail}
                      style={{ marginBottom: 10, marginTop:10, maxWidth:400 }}
                      onChange={e => {}}
                    />
                  ):null}
                  {submitterEmails_local.map((email_local,index) => (
                    <div key={index} style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:10}}>
                      <TextField
                        autoComplete='off'
                        id="outlined-basic"
                        label={email_local?"":<ReduxLanguage languageKey={'activations.formEnterAnswer'} />}
                        variant="outlined"
                        fullWidth
                        disabled={locked}
                        error={false}
                        value={email_local}
                        style={{ marginBottom: 10, marginTop:10, maxWidth:400 }}
                        onChange={e => {
                          let newValue = e.target.value;
                          let updatedState = this.state.submitterEmails_local.map((item,i) => {
                            if (i===index) return newValue;
                            else return item;
                          });
                          this.setState({ submitterEmails_local:updatedState })
                        }}
                      />
                      {locked?(null):(
                        <IconButton onClick={() => this.setState({submitterEmails_local: this.state.submitterEmails_local.filter(e=>e!==email_local)})}>
                          <Delete style={{ color: 'red' }} />
                        </IconButton>
                      )}
                    </div>
                  ))}
                  {locked?(null):(
                    <IconButton onClick={() => this.setState({submitterEmails_local: [...this.state.submitterEmails_local, ""]})} disabled={false}
                      style={{marginTop:'0px',width:'fit-content'}}>
                      <AddBoxIcon style={{ color: 'green' }} />
                    </IconButton>
                  )}
              </AccordionDetails>
            </Accordion>
          ):null}
          {!customerSectionCompleted||(locked&&activation.status!=ActivationstatusOptions.CREATED)||
            !AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.ACTIVATION_REQUEST_EDIT)?null:(
            <div style={{position:'sticky',bottom:'0',paddingTop:'20px',backgroundColor:'white',marginLeft:'-5px',marginRight:'-5px',zIndex:1}}>
              <Button
                variant="contained"
                disabled={!this.canSubmitForm()}
                style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', fontWeight: 'bold' }}
                onClick={() => this.onSubmitForm()}
              >
                <ReduxLanguage languageKey={activation.status===ActivationstatusOptions.CREATED?"forms.save":"activations.saveDraft"} />
              </Button>
            </div>
          )}
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    clusters: state.activations.clusters.data,
    marketplaces: state.activations.marketplaces.data,
    marketplaceSubscriptions: state.activations.marketplaceSubscriptions,
    customersSearch: state.activations.customersSearch,
    varsSearch: state.activations.varsSearch,
    userData: state.user.userData,
    kams: state.activations.kams.data,
    users: state.user.users.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(ActivationNewForm));